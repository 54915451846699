import { getCachedStripePromise } from '@app/components/StripeElements/StripeElementsWrapper';
import { ErrorNotification } from '@app/components/layout';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  Elements,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import React, { ReactElement, useState, ReactNode } from 'react';

type PayoutCardProps = {
  addCardRequest: (external_account_token: string) => Promise<void>;
  onSuccess: () => void;
  onCancel: () => void;
};

const Heading = ({ children }: { children: ReactNode }) => (
  <div className="mb-2 text-sm font-medium text-gray-700">{children}</div>
);

const inputStyle = {
  'iconColor': '#676D7C',
  'color': 'black',
  'fontWeight': '400',
  'fontFamily': 'inherit',
  'fontSize': '16px',
  'fontSmoothing': 'antialiased',
  ':-webkit-autofill': {
    color: '#5eb863',
  },
  '::placeholder': {
    color: '#676D7C',
  },
};

const invalidStyle = {
  'iconColor': '#EC2727',
  'color': '#EC2727',
  'fontWeight': '400',
  'fontFamily': 'inherit',
  'fontSize': '16px',
  'fontSmoothing': 'antialiased',
  ':-webkit-autofill': {
    color: '#FB5758',
  },
  '::placeholder': {
    color: '#EC2727',
  },
};

const CARD_OPTIONS = { style: { base: inputStyle, invalid: invalidStyle } };

function PayoutCard({ addCardRequest, onSuccess, onCancel }: PayoutCardProps): ReactElement {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      return;
    }

    try {
      const { error, token: stripeToken } = await stripe.createToken(cardElement, {
        currency: 'usd',
      });

      if (error) {
        setError(error.message || null);
        setLoading(false);
        return;
      }

      if (!stripeToken?.id) {
        setError('Could not create card token.');
        setLoading(false);
        return;
      }

      try {
        await addCardRequest(stripeToken?.id);
      } catch (e: unknown) {
        const message =
          axios.isAxiosError(e) && e.response?.data.errors?.length > 0
            ? `${e.response?.data.errors[0]}`
            : '';

        setLoading(false);
        setError(message);
        return;
      }

      setError(null);
      setLoading(false);
      onSuccess();
    } catch (e: unknown) {
      Sentry.captureException(e);
      setLoading(false);
      setError('There was a problem adding your debit card. Please try again later.');
    }
  };

  return (
    <div>
      {errorMessage && <ErrorNotification error={errorMessage} />}
      <div>
        <Heading>Card number</Heading>
        <div className="mb-1 rounded border p-2">
          <CardNumberElement options={CARD_OPTIONS} />
        </div>
        <div className="flex w-full justify-between">
          <div className="w-1/2">
            <Heading>Expiration</Heading>
            <div className="mb-1 mr-6 rounded border p-2">
              <CardExpiryElement options={CARD_OPTIONS} />
            </div>
          </div>
          <div className="w-1/2">
            <Heading>CVC</Heading>
            <div className="mb-1 rounded border p-2">
              <CardCvcElement options={CARD_OPTIONS} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 mt-4 flex items-center justify-end">
        <div className="mr-2">
          <Button onClick={() => onCancel()}>Cancel</Button>
        </div>
        <div>
          <LoadingButton
            color="primary"
            loading={loading}
            disabled={!stripe}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)}
          >
            Add card
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default function AddPayoutCard(props: PayoutCardProps): ReactElement {
  return (
    <div className=" bg-white">
      <Elements
        stripe={getCachedStripePromise(
          ApiEndpoints.PAYMENTS_ENDPOINTS.PAYMENT_INTEGRATION_ENDPOINT,
        )}
      >
        <PayoutCard {...props} />
      </Elements>
    </div>
  );
}
