import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import PaymentModalFooter from './PaymentModalFooter';

interface ReviewPaymentFooterProps {
  payNowHandler: () => void;
  paymentAmount: string;
  onBack: () => void;
  loading: boolean;
}

const ReviewPaymentFooter = ({
  payNowHandler,
  paymentAmount,
  onBack,
  loading,
}: ReviewPaymentFooterProps): ReactElement => {
  return (
    <PaymentModalFooter>
      <Button
        size="small"
        onClick={onBack}
        disabled={loading}
        data-testid="ModalFooter-Cancel-Button"
      >
        Back
      </Button>
      <LoadingButton
        size="small"
        onClick={payNowHandler}
        disabled={loading}
        loading={loading}
        data-testid="ModalFooter-Next-Button"
      >
        Pay {paymentAmount}
      </LoadingButton>
    </PaymentModalFooter>
  );
};

export default ReviewPaymentFooter;
