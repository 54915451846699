import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

type Props =
  | {
      netNew: boolean;
      optIn?: never;
    }
  | { optIn: boolean; netNew?: never };

export default function PrepaidAllSet({ optIn, netNew }: Props): ReactElement {
  const navigate = useNavigate();
  const { trigger, isMutating } = useCreateCustomerOnboardingEvent();

  const optInCopy = (
    <>
      <div className="mb-8 text-lg font-medium">
        <div className="my-4">
          Enjoy using your new AtoB Unlimited card on everything from fuel, maintenance, and
          business expenses.
        </div>
      </div>
    </>
  );

  const netNewCopy = (
    <div className="text-lg font-medium">
      <div className="my-4">
        Enjoy using your new AtoB Unlimited card on everything from fuel, maintenance, and business
        expenses.
      </div>
    </div>
  );

  return (
    <div className="text-primary mb-6 flex flex-col rounded-xl bg-white px-5 py-5">
      <div className="flex items-center text-3xl">
        <span className="bg-prepaid-completed-step mr-2 flex h-8 w-8 items-center justify-center rounded-full">
          <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
        <h2 className="font-bold">You're all set!</h2>
      </div>
      {optIn && optInCopy}
      {netNew && netNewCopy}
      <div className="flex">
        <div>
          <LoadingButton
            color="secondary"
            loading={isMutating}
            onClick={() => {
              if (netNew) {
                trigger({
                  customer_onboarding_event: {
                    name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
                  },
                });
              }
              navigate('/');
            }}
          >
            Done
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
