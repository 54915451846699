import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import OnboardingCard from './OnboardingCard';

export default function OnboardingWrapper({
  header,
  leftContent,
  rightContent,
  hideStepper,
}: {
  header?: ReactNode;
  leftContent: ReactNode;
  rightContent: ReactNode;
  hideStepper?: boolean;
}): ReactElement {
  return (
    <div
      className={classNames(
        'bg-background flex min-h-screen',
        hideStepper ? 'justify-center' : 'flex-col items-center',
      )}
    >
      {header}
      {!hideStepper && (
        <div className="flex w-full max-w-screen-xl flex-col px-0 md:flex-row md:px-20">
          <div className="sticky top-0 z-10 h-fit md:top-[63px] md:pr-10">{leftContent}</div>
          <div className="mb-[120px] flex-1 md:max-w-[600px]">{rightContent}</div>
        </div>
      )}
      {hideStepper && (
        <div className="flex w-full">
          <OnboardingCard />
          <div className="flex-1 px-6 py-10 md:px-12 md:py-0">{rightContent}</div>
        </div>
      )}
    </div>
  );
}
