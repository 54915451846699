import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  PaperProps,
  Select,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import { HTMLAttributes, ReactElement, useCallback, useState, UIEvent, ReactNode } from 'react';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';

export const LargeMessage = ({ children }: HTMLAttributes<HTMLDivElement>): ReactElement => (
  <div className="flex h-full w-full text-sm text-gray-600">
    <div className="w-full py-8 text-center">{children}</div>
  </div>
);

export function FilterDropdownInfiniteList<
  T extends Record<string, unknown> & { id: string | number },
>({
  customHeader,
  name,
  keyFunc,
  onSelect,
  onReset,
  renderNoneItem,
  selectedIds,
  multiselect = true,
  renderValue,
  renderListItem,
  isDisabled = false,
  PaperProps,
}: {
  name: string;
  keyFunc: (s: string) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<T>>;
  onSelect: (value: string[]) => void;
  customHeader?: ReactNode;
  renderNoneItem?: (item: { id: 'none' }, isSelected: boolean) => ReactNode;
  onReset?: () => void;
  selectedIds: string[];
  renderValue?: () => ReactNode;
  multiselect?: boolean;
  renderListItem: (item: T, isSelected: boolean) => ReactNode;
  isDisabled?: boolean;
  PaperProps?: PaperProps;
}): ReactElement {
  const [searchString, setSearchString] = useState('');

  const { data, error, isLoading, isValidating, setSize } = useSWRInfinite<
    PaginatedEndpointResponse<T>
  >(keyFunc(searchString), apiGetFetcher);

  const handleScroll = useCallback(
    (scrollHeight: number, scrollTop: number, clientHeight: number) => {
      if (data == null || isLoading || isValidating) {
        return;
      }

      if ((scrollHeight - clientHeight) * 0.7 > scrollTop) {
        return;
      }
      if (
        data[data.length - 1].meta?.pagination.current_page ==
        data[data.length - 1].meta?.pagination.total_pages
      ) {
        return;
      }
      setSize((size) => size + 1);
    },
    [data, isLoading, isValidating, setSize],
  );

  return (
    <FormControl className="min-w-min flex-1" disabled={isDisabled}>
      <InputLabel className="sr-only">{name}</InputLabel>
      <Select
        data-testid="select"
        size="small"
        value={selectedIds}
        multiple={multiselect}
        onChange={(e) => {
          if (Array.isArray(e.target.value)) {
            onSelect(e.target.value);
          } else {
            onSelect([e.target.value]);
          }
        }}
        renderValue={renderValue}
        displayEmpty
        MenuProps={{
          autoFocus: false,
          classes: {
            list: classNames('pt-0', selectedIds.length > 0 && 'pb-0'),
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            ...PaperProps,
            onScroll: (e: UIEvent<HTMLElement>) => {
              handleScroll(
                e.currentTarget.scrollHeight,
                e.currentTarget.scrollTop,
                e.currentTarget.clientHeight,
              );
            },
          },
        }}
      >
        <ListSubheader className="border-b px-4 pb-2.5 pt-3 leading-none" value={searchString}>
          <TextField
            size="small"
            value={searchString}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch as IconProp} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchString(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {customHeader}
        {error && <ListSubheader>Error loading data, please try again later</ListSubheader>}
        {renderNoneItem != null &&
          (searchString == '' || 'none'.includes(searchString.toLowerCase())) &&
          renderNoneItem({ id: 'none' }, selectedIds.indexOf('none') > -1)}
        {data?.map((pageData) =>
          pageData.data.map((item) => {
            const isSelected = selectedIds.indexOf(item.id.toString()) > -1;
            return renderListItem(item, isSelected);
          }),
        )}
        {!(isLoading || isValidating) && data?.length === 1 && data[0].data.length === 0 && (
          <ListSubheader>No results</ListSubheader>
        )}
        {(isLoading || isValidating) && (
          <ListSubheader
            className={classNames(
              'flex w-full items-center justify-center',
              data?.length === 1 && data[0].data.length === 0 && 'pt-2',
            )}
          >
            <CircularProgress data-testid="progress" />
          </ListSubheader>
        )}
        {selectedIds.length > 0 && (
          <ListSubheader
            className={classNames(
              'sticky bottom-0 flex w-full items-center px-1 pb-1',
              data?.length === 1 && data[0].data.length === 0 && 'pt-2',
            )}
          >
            <Button
              color="tertiary"
              size="small"
              className="mt-1 underline"
              onClick={() => {
                onSelect([]);
                onReset?.();
              }}
            >
              Reset
            </Button>
          </ListSubheader>
        )}
      </Select>
    </FormControl>
  );
}
