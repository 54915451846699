import { SettingsPageEntityType } from '@app/constants/settings';
import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import { PHONE_ERROR_MESSAGE, PHONE_REGEX } from '@atob-developers/shared/src/utils/phoneUtils';
import * as Yup from 'yup';

type FormDataType = {
  label: string;
  type: DataItemType;
  path: string;
  required: boolean;
  validation?: Yup.StringSchema;
  validationOnChange?: Yup.StringSchema;
};

export const generateFormValidationSchema = (form: FormDataType[]) => {
  const schema: Record<string, Yup.StringSchema> = {};
  if (form) {
    form.forEach((item) => {
      if (item.validation) {
        schema[item.path] = item.validation;
      }
    });
  }
  return Yup.object(schema);
};

export const validateForm = async (
  values: Record<string, string | null>,
  options: {
    form?: FormDataType[];
    schema?: Yup.ObjectSchema<Record<string, string | undefined>>;
  },
): Promise<{ formHasValidationErrors: boolean; validationErrors: Record<string, string> }> => {
  let formHasValidationErrors = false;
  // Trim trailing whitespaces
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Object.keys(values).map((key) => (values[key] = values[key]?.trim()));

  if (!options.form && !options.schema) {
    return {
      formHasValidationErrors,
      validationErrors: { globalError: 'Form configuration or schema required!' },
    };
  }

  const schema = options?.schema ?? generateFormValidationSchema(options.form || []);

  const errors: Record<string, string> = {};
  await schema.validate(values, { abortEarly: false }).catch((err: Yup.ValidationError) => {
    formHasValidationErrors = err?.errors.length > 0;

    err.inner.forEach((e: { path?: string; message: string }) => {
      if (e.path) errors[e.path] = e.message;
    });
  });

  return { formHasValidationErrors, validationErrors: errors };
};

export const validateFieldAndGetError = async (
  formItem: FormDataType,
  value: string,
): Promise<string | null> => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await formItem.validationOnChange.validate(value);
    return null;
  } catch (e) {
    return (e as Yup.ValidationError).message;
  }
};

type AddressValidationSchemaType = {
  address1: string;
  city: string;
  state: string;
  zip: string;
};

type EmailValidationSchemaType = {
  email: string;
};

type OTPBackupEmailvalidationSchemaType = {
  otp_backup_email: string | undefined;
};

type PhoneValidationSchemaType = {
  phone: string;
};

type CompanyPreferredNameValidationSchemaType = {
  company_preferred_name: string;
};

type FleetTypeValidationSchemaType = {
  fleet_type: string;
};

const emailValidationSchema: Yup.ObjectSchema<EmailValidationSchemaType> = Yup.object().shape({
  email: Yup.string().email().required('Email is required!'),
});

const otpBackupEmailValidationSchema: Yup.ObjectSchema<OTPBackupEmailvalidationSchemaType> =
  Yup.object().shape({
    otp_backup_email: Yup.string().email().optional(),
  });

const companyPrefferedNameValidationSchema: Yup.ObjectSchema<CompanyPreferredNameValidationSchemaType> =
  Yup.object().shape({
    company_preferred_name: Yup.string().required('Please enter a preferred company name'),
  });

const phoneValidationSchema: Yup.ObjectSchema<PhoneValidationSchemaType> = Yup.object().shape({
  phone: Yup.string()
    .matches(PHONE_REGEX, { message: PHONE_ERROR_MESSAGE.INVALID_LENGTH })
    .max(14, PHONE_ERROR_MESSAGE.INVALID_LENGTH)
    .required('Phone number is required!'),
});

const addressValidationSchema: Yup.ObjectSchema<AddressValidationSchemaType> = Yup.object().shape({
  address1: Yup.string().required('Address is required!'),
  city: Yup.string().required('City is required!'),
  state: Yup.string().required('State is required!'),
  zip: Yup.string().required('Zip is required!'),
});

const fleetTypeValidationSchema: Yup.ObjectSchema<FleetTypeValidationSchemaType> =
  Yup.object().shape({
    fleet_type: Yup.string()
      .required('Fleet type is required!')
      .oneOf(['light', 'mixed', 'heavy'], 'Fleet type must be light, mixed, or heavy'),
  });

// Used for forms on Settings Page
export const getSettingsPageValidationSchema = (
  modalType: SettingsPageEntityType,
): Yup.ObjectSchema<Record<string, string | undefined>> => {
  if (modalType === SettingsPageEntityType.EMAIL_ADDRESS) {
    return emailValidationSchema;
  } else if (modalType === SettingsPageEntityType.PHONE_NUMBER) {
    return phoneValidationSchema;
  } else if (modalType === SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME) {
    return companyPrefferedNameValidationSchema;
  } else if (modalType === SettingsPageEntityType.FLEET_TYPE) {
    return fleetTypeValidationSchema;
  } else if (modalType === SettingsPageEntityType.OTP_BACKUP_EMAIL) {
    return otpBackupEmailValidationSchema;
  } else {
    return addressValidationSchema;
  }
};

// Used for validation on Settings page schemas
export const validateSettingsForm = async (
  values: Record<string, string | null>,
  modalType: SettingsPageEntityType,
): Promise<Record<string, string>> => {
  const schema = getSettingsPageValidationSchema(modalType);
  const { validationErrors } = await validateForm(values, { schema });

  return validationErrors;
};
