import { PaginationMeta } from '@app/@types/api.types';
import {
  DeserializedMonthlySubscriptionData,
  DeserializedPaymentsData,
} from '@app/@types/payments.types';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { PAYMENT_HISTORY_TABS, PaymentHistoryTabs } from '@app/constants/payment-history';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { backendPage } from '@app/lib/paging/PageInfo';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { faArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import PaymentHistoryContent from './PaymentHistoryContent';
import PaymentHistoryPageHeader from './PaymentPageHeader';

interface PaymentHistoryProps {
  payments: DeserializedPaymentsData[];
  rowsPerPage?: number;
  paymentsPaginationMeta: PaginationMeta;
  handlePaymentsPageChangeClick?: (updater: number) => void;
  handleSubscriptionsPageChangeClick?: (updater: number) => void;
  monthlySubscription: DeserializedMonthlySubscriptionData[];
  subscriptionsPaginationMeta: PaginationMeta;
  showMonthlySubscription?: boolean;
  withContentWrapper: boolean;
}

export const PaymentHistory = ({
  payments = [],
  rowsPerPage = DEFAULT_PAGE_SIZE,
  paymentsPaginationMeta,
  handlePaymentsPageChangeClick,
  handleSubscriptionsPageChangeClick,
  monthlySubscription,
  subscriptionsPaginationMeta,
  showMonthlySubscription = false,
  withContentWrapper,
}: PaymentHistoryProps): ReactElement => {
  const backendDataSource = backendPage(payments, rowsPerPage, paymentsPaginationMeta);
  const monthlySubscriptionDataSource = backendPage(
    monthlySubscription,
    rowsPerPage,
    subscriptionsPaginationMeta,
  );

  const [currentTab, setCurrentTab] = useSingleUrlState<PaymentHistoryTabs>(
    'tab',
    PaymentHistoryTabs.FUEL_CARD_BALANCE,
  );

  const showTabs = !useIsUberfreight(true) && showMonthlySubscription;

  let view;

  if (!payments.length && !showMonthlySubscription) {
    view = (
      <EmptyTable
        icon={faArrowRightArrowLeft}
        title="No payment history found"
        subtitle="Payment history will display here after you have made your first payment"
      />
    );
  } else {
    view = (
      <PaymentHistoryContent
        monthlySubscription={monthlySubscriptionDataSource}
        payments={backendDataSource}
        handlePaymentsPageChangeClick={handlePaymentsPageChangeClick}
        handleSubscriptionsPageChangeClick={handleSubscriptionsPageChangeClick}
        currentTab={currentTab || PaymentHistoryTabs.FUEL_CARD_BALANCE}
      />
    );
  }

  if (!withContentWrapper) {
    return view;
  }

  return (
    <PageContentWrapper
      className="pb-12"
      header={<PaymentHistoryPageHeader />}
      pageTabs={
        showTabs && (
          <UnderlinedTabs
            selectedTab={currentTab || PaymentHistoryTabs.FUEL_CARD_BALANCE}
            handleTabChange={(tab) => setCurrentTab(tab as PaymentHistoryTabs)}
            tabs={PAYMENT_HISTORY_TABS}
          />
        )
      }
    >
      {view}
    </PageContentWrapper>
  );
};

export default PaymentHistory;
