import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { FetcherKey, postFetcher } from '@app/utils/data/fetchers';
import { addYourVehiclesValidationSchema } from '@app/utils/validation/onboarding-validation';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { FieldArray, Formik, FormikErrors } from 'formik';
import useSWRMutation from 'swr/mutation';

const FUEL_TYPES = [
  { id: 'diesel', value: 'diesel', label: 'Diesel' },
  { id: 'unleaded', value: 'unleaded', label: 'Regular' },
  { id: 'plus', value: 'plus', label: 'Midgrade and below' },
  { id: 'super', value: 'super', label: 'Premium and below' },
];

type Vehicle = {
  name: string;
  vin: string;
  fuel_type: 'unleaded' | 'diesel' | 'plus' | 'super' | undefined;
  tank_capacity_gallons: string;
};

type CreateVehicleMutationHookParams = {
  bulk: {
    vehicles: Vehicle[];
  };
};

const initialVehicle = {
  name: '',
  vin: '',
  fuel_type: undefined,
  tank_capacity_gallons: '',
};

type OnboardingAddYourVehiclesProps = {
  nextStep: () => void;
};

export default function OnboardingAddYourVehicles({ nextStep }: OnboardingAddYourVehiclesProps) {
  const { trigger, isMutating } = useSWRMutation<
    unknown,
    AxiosError,
    FetcherKey,
    CreateVehicleMutationHookParams
  >(
    {
      url: '/vehicles/bulk_create',
    },
    postFetcher,
  );

  const { trigger: createOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const handleSkip = async () => {
    await createOnboardingEvent({
      customer_onboarding_event: {
        name: CustomerOnboardingEventName.CUSTOMER_SKIP_ADD_VEHICLES,
      },
    });

    nextStep();
  };

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header
        title="Add your vehicles"
        description="Track spend by vehicle by assigning cards to a vehicle. Begin by adding the vehicles in your fleet. You can connect your telematics to automatically sync your vehicles and expedite this process."
      />
      <Formik
        initialValues={{ vehicles: [] as Vehicle[] }}
        validationSchema={addYourVehiclesValidationSchema}
        onSubmit={async (values) => {
          await trigger({ bulk: { vehicles: values.vehicles } });
          nextStep();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <FieldArray name="vehicles">
            {({ push }) => {
              const vehicleErrors = errors.vehicles as FormikErrors<Vehicle[]> | undefined;

              return (
                <>
                  {values.vehicles.map((vehicle, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <TextField
                        fullWidth
                        placeholder="Vehicle Name"
                        name={`vehicles.${index}.name`}
                        value={vehicle.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.vehicles?.[index]?.name && !!vehicleErrors?.[index]?.name}
                        helperText={touched.vehicles?.[index]?.name && vehicleErrors?.[index]?.name}
                      />
                      <TextField
                        fullWidth
                        placeholder="VIN"
                        name={`vehicles.${index}.vin`}
                        value={vehicle.vin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.vehicles?.[index]?.vin && !!vehicleErrors?.[index]?.vin}
                        helperText={touched.vehicles?.[index]?.vin && vehicleErrors?.[index]?.vin}
                      />
                      <FormControl fullWidth>
                        <Select
                          fullWidth
                          name={`vehicles.${index}.fuel_type`}
                          displayEmpty
                          value={vehicle.fuel_type ?? ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.vehicles?.[index]?.fuel_type &&
                            !!vehicleErrors?.[index]?.fuel_type
                          }
                          renderValue={(value) => {
                            const item = FUEL_TYPES.find((item) => item.id === value);
                            if (!item) {
                              return <span className="text-secondary">Fuel Type</span>;
                            }

                            return item.label;
                          }}
                        >
                          {FUEL_TYPES.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              <ListItemText>{item.label}</ListItemText>
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.vehicles?.[index]?.fuel_type &&
                          !!vehicleErrors?.[index]?.fuel_type && (
                            <p className="text-error-1 mt-2 text-sm">
                              {vehicleErrors?.[index]?.fuel_type}
                            </p>
                          )}
                      </FormControl>
                      <TextField
                        fullWidth
                        placeholder="Tank Size (gal)"
                        name={`vehicles.${index}.tank_capacity_gallons`}
                        value={vehicle.tank_capacity_gallons ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.vehicles?.[index]?.tank_capacity_gallons &&
                          !!vehicleErrors?.[index]?.tank_capacity_gallons
                        }
                        helperText={
                          touched.vehicles?.[index]?.tank_capacity_gallons &&
                          vehicleErrors?.[index]?.tank_capacity_gallons
                        }
                      />
                    </div>
                  ))}
                  <Button
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    size="extra-small"
                    color="tertiary"
                    className="w-fit"
                    onClick={() => push(initialVehicle)}
                  >
                    Add vehicle
                  </Button>
                  <FooterActionButtons
                    helperButtonText="Skip for now"
                    hideBackButton
                    handleClickNext={handleSubmit}
                    handleClickHelperText={handleSkip}
                    isDisabled={!values.vehicles.length || !!errors.vehicles?.length || isMutating}
                    isLoading={isMutating}
                  />
                </>
              );
            }}
          </FieldArray>
        )}
      </Formik>
    </div>
  );
}
