import { TileContainer } from '@app/components/OverviewTiles/Tile';
import TilesContainer from '@app/components/OverviewTiles/TilesContainer';
import Skeleton from '@app/components/layout/Skeleton';
import useProduct from '@app/hooks/useProduct';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { ReactElement, useState } from 'react';
import AccountInfoModal from './WalletOverview/AccountInfoModal';
import { SecurityDepositDetails } from './WalletOverview/SecurityDepositDetails';
import WalletBalance from './WalletOverview/WalletBalance';
import type { AddressType, TreasuryFinancialAccount } from '@app/@types/customer.types';

type WalletOverviewProps = {
  financialAccount?: TreasuryFinancialAccount | null;
  isTrusted: boolean;
};

export const AccountInfoLink = ({
  isTrusted,
  financialAccount,
}: {
  isTrusted: boolean;
  financialAccount?: TreasuryFinancialAccount | null;
}): ReactElement => {
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);

  const modalToggle = () => {
    setShowAccountInfoModal(!showAccountInfoModal);
  };

  return (
    <>
      {isTrusted && (
        <div
          onClick={modalToggle}
          className="text-secondary cursor-pointer whitespace-nowrap text-sm underline"
        >
          Account info
        </div>
      )}
      {showAccountInfoModal && (
        <AccountInfoModal
          showAccountInfoModal={showAccountInfoModal}
          modalToggle={modalToggle}
          accountNumber={financialAccount?.account_number}
          routingNumber={financialAccount?.routing_number}
        />
      )}
    </>
  );
};

const DesktopWalletOverview = ({ financialAccount, isTrusted }: WalletOverviewProps) => {
  const [hasSecurityDeposit] = useProduct('security_deposits');

  return (
    <TilesContainer
      classes={{
        gridContainer: `bg-level-2 ${hasSecurityDeposit && 'grid-cols-2 grid-rows-1'}`,
      }}
      header={<div className="text-l p-4 font-semibold">Balance</div>}
    >
      <TileContainer className="border-level-2 border-t">
        <div className="mr-4 flex justify-between">
          <WalletBalance balance={financialAccount?.balance} />
          <div className="self-end">
            <AccountInfoLink isTrusted={isTrusted} financialAccount={financialAccount} />
          </div>
        </div>
      </TileContainer>
      {hasSecurityDeposit && (
        <TileContainer className="border-level-2 border-t">
          <SecurityDepositDetails />
        </TileContainer>
      )}
    </TilesContainer>
  );
};

const MobileWalletOverview = ({ financialAccount, isTrusted }: WalletOverviewProps) => {
  const [hasSecurityDeposit] = useProduct('security_deposits');

  return (
    <TilesContainer
      classes={{
        gridContainer: `bg-level-2`,
      }}
      header={<div className="p-4 text-lg font-semibold">Balance</div>}
    >
      <TileContainer className="border-level-2 border-t">
        <div className="mr-4 flex justify-between">
          <WalletBalance balance={financialAccount?.balance} />
          <div className="self-end">
            <AccountInfoLink isTrusted={isTrusted} financialAccount={financialAccount} />
          </div>
        </div>
      </TileContainer>
      {hasSecurityDeposit && (
        <TileContainer className="border-level-2">
          <SecurityDepositDetails />
        </TileContainer>
      )}
    </TilesContainer>
  );
};

export type StatusProps = {
  accountNumber?: string | null;
  routingNumber?: string | null;
  balance?: string | null;
  onAddFunds: () => void;
  onWithdrawFunds: () => void;
  loading?: boolean;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  isTrusted: boolean;
  status:
    | 'enrolled'
    | 'not_enrolled'
    | 'started'
    | 'pending_verification'
    | 'update_required'
    | 'suspended';
};

export default function WalletOverview(
  props: WalletOverviewProps & Pick<StatusProps, 'loading'>,
): ReactElement {
  const { loading } = props;
  const { isMobile } = useWindowWidth();
  const renderOverview = isMobile ? (
    <MobileWalletOverview {...props} />
  ) : (
    <DesktopWalletOverview {...props} />
  );

  return (
    <div>
      <div className="rounded-lg bg-white">
        {loading ? (
          <div className="border-level-2 overflow-hidden rounded-lg border">
            <Skeleton />
          </div>
        ) : (
          <div className="flex justify-between">{renderOverview}</div>
        )}
      </div>
    </div>
  );
}
