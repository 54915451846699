import { EndpointResponse, Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriverData } from '@app/@types/driver.types';
import {
  DriverSummaryData,
  PayrollDailySummaryData,
  PayrollDailySummaryDataResponse,
  PayrollTransactionData,
  TopLevelSummaryDataResponse,
} from '@app/@types/payroll.types';
import { Spacer } from '@app/components/elements';
import { ErrorNotification, Loading } from '@app/components/layout';
import { beginningOfDay, endOfDay } from '@app/components/layout/DatePicker';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { phoneFormat } from '@atob-developers/shared/src/utils/formatters';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PayrollTransactionTable from '../PayrollHistory/PayrollTransactionsTable';
import PayrollSuspendedBanner from './Banners/PayrollSuspendedBanner';
import PayrollTransactionsChart from './PayrollTransactionsChart';
import { mapTransactionstoState } from './payroll-context';

dayjs.extend(isoWeek);
dayjs.extend(utc);

function PayrollDriverDetails(): ReactElement {
  const { id: driverId } = useParams();
  const [error, setError] = useState<Error | null>(null);
  const [driver, setDriver] = useState<Entity<DriverData> | null>(null);
  const [allPayrollTransactions, setAllPayrollTransactions] = useState<PayrollTransactionData[]>(
    [],
  );
  const [driverSummary, setDriverSummary] = useState<DriverSummaryData | null>(null);
  const [transactionDailySummary, setTransactionDailySummary] = useState<PayrollDailySummaryData[]>(
    [],
  );

  useEffect(() => {
    axios
      .get<EndpointResponse<Entity<DriverData>>>(`/drivers/${driverId}`)
      .then(({ data }) => {
        setDriver(data.data);
      })
      .catch((e) => setError(e));
  }, [driverId]);

  useEffect(() => {
    const fetchPayrollHistoryData = async () => {
      const transactionData = await axios.get<
        PaginatedEndpointResponse<Entity<PayrollTransactionData>>
      >('/payroll/transactions', { params: { driver_id: driverId, include_itemizations: true } });

      const transactions = deserialize(transactionData.data).data;
      const mappedPayrollTransactions = mapTransactionstoState(transactions);

      setAllPayrollTransactions(mappedPayrollTransactions);
    };

    fetchPayrollHistoryData();
  }, [driverId]);

  useEffect(() => {
    const fetchPayrollSummaryData = async () => {
      const startDate = dayjs().month(0).date(1).set(beginningOfDay);
      const endDate = dayjs().set(endOfDay);
      const topLevelSummaryData = await axios.get<TopLevelSummaryDataResponse>(
        '/payroll/transactions/summary',
        {
          params: {
            'transaction_date[since]': startDate,
            'transaction_date[until]': endDate,
            'driver_id': driverId,
          },
        },
      );

      const driverSummaryData: DriverSummaryData[] =
        topLevelSummaryData.data.data.attributes.driver_summaries.data.map(
          (driverData: Entity<DriverSummaryData>) => {
            return {
              name: driverData.attributes.name,
              paid_last_week: driverData.attributes.paid_last_week,
              paid_this_month: driverData.attributes.paid_this_month,
              paid_in_daterange: driverData.attributes.paid_in_daterange,
            };
          },
        );

      if (driverSummaryData.length === 0) {
        setError(new Error('Fail to load driver payroll data'));
      }

      setDriverSummary(driverSummaryData[0]);
    };

    fetchPayrollSummaryData();
  }, [driverId]);

  useEffect(() => {
    const fetchPayrollDailySummaryData = async () => {
      const dailySummaryData = await axios.get<PayrollDailySummaryDataResponse>(
        '/payroll/transactions/daily_summary',
        { params: { driver_id: driverId } },
      );
      setTransactionDailySummary(dailySummaryData.data.data);
    };

    fetchPayrollDailySummaryData();
  }, [driverId]);

  if (error) {
    return <ErrorNotification error={error.message} />;
  }

  if (!driver || !driverSummary || !allPayrollTransactions || !transactionDailySummary) {
    return <Loading />;
  }

  const subtitle = [phoneFormat({ value: driver.attributes.phone }), driver.attributes.email]
    .filter((v) => v)
    .join(' | ');

  return (
    <div>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/payroll/overview">Payroll</Link>
          </li>
          <li className="is-active">
            <a aria-current="page">{driver.attributes.name}</a>
          </li>
        </ul>
      </nav>
      <PayrollSuspendedBanner />
      <div className="container">
        <h1 className="title">{driver.attributes.name}</h1>
        <div className="subtitle is-size-6 has-text-grey">{subtitle}</div>
      </div>
      <Spacer />
      <h1 className="title">Total Paid this Month: {driverSummary.paid_this_month}</h1>
      <Spacer />
      <div className="panel has-text-centered p-2">
        <div className="chart-container block">
          <Suspense fallback={<CircularProgress />}>
            <PayrollTransactionsChart transactions={transactionDailySummary} />
          </Suspense>
        </div>
      </div>
      <Spacer />
      <PageContentWrapper>
        <PayrollTransactionTable transactions={allPayrollTransactions} />
      </PageContentWrapper>
    </div>
  );
}

export default PayrollDriverDetails;
