import { PriceListingType } from '@app/@types/fuel_listings.types';

export const useHasDiscount = (priceListingType: PriceListingType | null) => {
  return priceListingType
    ? [
        PriceListingType.GUARANTEED_DISCOUNT_VALID_PRICE,
        PriceListingType.GUARANTEED_DISCOUNT_INVALID_PRICE,
        PriceListingType.GUARANTEED_PRICE_VALID_PRICE,
        PriceListingType.GUARANTEED_PRICE_INVALID_PRICE,
      ].includes(priceListingType)
    : false;
};
