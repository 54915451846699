import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import './RoundedCard.css';

/*
 * This will render as a normal div for screens under the `breakpoint` (if defined), and as a card for devices over the `breakpoint`.
 */
export default function RoundedCard({
  children,
  className,
  breakpoint,
}: PropsWithChildren<{ className?: string; breakpoint?: 'sm' | 'md' | 'lg' | 'xl' }>) {
  let responsiveClasses;

  switch (breakpoint) {
    case 'sm':
      responsiveClasses = 'sm:bg-level-0 sm:rounded-lg sm:rounded-card';
      break;
    case 'md':
      responsiveClasses = 'md:bg-level-0 md:rounded-lg md:rounded-card';
      break;
    case 'lg':
      responsiveClasses = 'lg:bg-level-0 lg:rounded-lg lg:rounded-card';
      break;
    default:
      responsiveClasses = 'bg-level-0 rounded-lg rounded-card';
  }

  return (
    <div
      className={classNames(responsiveClasses, 'border-level-2 overflow-hidden border', className)}
    >
      {children}
    </div>
  );
}
