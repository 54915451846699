import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import type { ItemProps, Row as RowType, RowItemProps, TableProps } from './Table.types';

export const ColumnItem = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div
      className={'text-black1 break-single overflow-hidden text-ellipsis pr-2 text-sm font-medium'}
    >
      {children}
    </div>
  );
};

export const RowItem = ({
  children,
  faded,
  flexCol,
  basis,
  overflowOverride,
}: PropsWithChildren<RowItemProps>) => {
  const textStyle = classNames(
    'flex break-normal h-full',
    flexCol ? 'flex-col items-start' : 'flex-row items-center gap-2',
    'text-black',
    { 'opacity-50': faded },
    'font-medium',
    'text-sm',
  );
  return (
    <div
      className={`xs:basis-${basis} basis-0 pr-3 text-sm ${
        overflowOverride ? '' : 'overflow-y-auto'
      } `}
    >
      <div className={textStyle}>{children}</div>
    </div>
  );
};

export default function Table<T extends RowType>({
  data,
  columns,
  handleRowClick,
  rowDataToUIMapper,
  hideArrow,
  floatingColumns = false,
  hideCursorPointer = false,
}: TableProps<T>) {
  const handleRowClickInternal = handleRowClick;
  return (
    <div className="w-full space-y-2">
      <Columns
        {...{
          data,
        }}
        floatingColumns={floatingColumns}
        hideArrow={hideArrow}
      >
        {columns}
      </Columns>
      {data.map((d) => (
        <Row
          key={d.id || d.key}
          row={d}
          handleRowClick={(e, key) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            handleRowClickInternal && handleRowClickInternal(e, key || d.id, d);
          }}
          {...{
            data,
          }}
          hideArrow={hideArrow}
          hideCursorPointer={hideCursorPointer}
        >
          {rowDataToUIMapper(d)}
        </Row>
      ))}
    </div>
  );
}

const Columns = <T extends RowType>({
  children,
  floatingColumns,
  hideArrow,
}: PropsWithChildren<ItemProps<T>>) => (
  <div
    className={classNames('xs:flex hidden w-full px-4 pb-2', {
      'bg-light-grey shadow-floating-column sticky top-0 z-10 pt-2': floatingColumns,
      'px-4': hideArrow,
      'pl-4 pr-10': !hideArrow,
    })}
  >
    <div className="xs:flex-row flex h-full w-full flex-col">{children}</div>
  </div>
);

const Row = <T extends RowType>({
  children,
  row,
  handleRowClick,
  hideArrow,
  hideCursorPointer,
}: PropsWithChildren<ItemProps<T>>) => {
  return (
    <div
      onClick={(event) => {
        if (handleRowClick && row) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          handleRowClick(event, row.key || row.id, row);
          event?.stopPropagation();
        }
      }}
      className={classNames(
        'xs:items-center flex h-auto min-h-[68px] w-full items-start justify-between rounded-lg bg-white p-4 shadow-md',
        {
          'cursor-pointer hover:bg-gray-50': !hideCursorPointer,
          'cursor-default': hideCursorPointer,
        },
      )}
    >
      <div className="xs:flex-row flex h-full w-full flex-col gap-1">{children}</div>
      {!hideArrow && <FontAwesomeIcon color="#858585" icon={faAngleRight} className="px-2" />}
    </div>
  );
};

export const BlankPlaceholder = () => <div className="text-grey2">—</div>;
