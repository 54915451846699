import { Treasury } from '@app/@types/customer.types';
import { FuelCardAccountDiscount } from '@app/@types/fuel-card-account.types';
import CollapsibleTilesContainer from '@app/components/OverviewTiles/CollapsibleTilesContainer';
import { PeriodHeader } from '@app/components/PrepaidAccountOverview/HeadingCard';
import useProduct from '@app/hooks/useProduct';
import { formatDayOfWeek } from '@app/utils/formatDate';
import { ReactElement, ReactNode, useState } from 'react';
import SavingsModal from '../../components/Modals/AccountOverviewModals/SavingsModal';
import { FindFuelPrimaryAction } from './PrimaryActions';
import { AvailableToSpendTile, PeriodSavingsTile } from './Tiles';

interface OverviewCardProps {
  periodAvailableAmount: string;
  isLoading: boolean;
  periodSpentLimit: string;
  currentWeekSpend: string;
  pendingSpendAmount: string;
  periodEndDate: string;
  potentialDiscountAmount: string;
  periodStartDate: string;
  fetchFuelCardAccountData: () => void;
  companyName: string;
  discountMetadata?: FuelCardAccountDiscount;
  discountAmount: string;
  lifetimeDiscountAmount: string;
  lifetimeAverageDiscountPerGallon: string;
  treasury: Treasury | null;
}

export const SectionTitle = ({ children }: { children: ReactNode }): ReactElement => (
  <div className="border-b-[1px] border-gray-100 px-4 py-[10px] text-sm font-semibold md:px-6">
    <p className="mr-3">{children}</p>
  </div>
);

const CreditOverviewCard = ({
  periodAvailableAmount,
  isLoading,
  periodSpentLimit,
  currentWeekSpend,
  pendingSpendAmount,
  periodEndDate,
  periodStartDate,
  discountMetadata,
  discountAmount,
  lifetimeDiscountAmount,
  potentialDiscountAmount,
  lifetimeAverageDiscountPerGallon,
}: OverviewCardProps): ReactElement => {
  const periodStartDayOfWeek = formatDayOfWeek(new Date(periodStartDate));
  const [showSavingsModal, setShowSavingsModal] = useState<boolean>(false);
  const modalToggle = () => setShowSavingsModal(!showSavingsModal);
  const [fuelFinder] = useProduct('fuel_finder');

  const primaryAction = fuelFinder && <FindFuelPrimaryAction />;

  return (
    <>
      <CollapsibleTilesContainer
        isLoading={isLoading}
        classes={{ gridContainer: 'grid-cols-1 md:grid-cols-2' }}
        header={<PeriodHeader periodStartDate={periodStartDate} periodEndDate={periodEndDate} />}
        collapsedPrimaryAction={primaryAction}
      >
        <AvailableToSpendTile
          isCredit={true}
          availableAmount={periodAvailableAmount}
          spendLimit={periodSpentLimit}
          currentWeekSpend={currentWeekSpend}
          periodStartDay={periodStartDayOfWeek}
          pendingSpendAmount={pendingSpendAmount}
        />
        <PeriodSavingsTile
          discountAmount={discountAmount}
          potentialDiscountAmount={potentialDiscountAmount}
          onDetailsClick={modalToggle}
        />
      </CollapsibleTilesContainer>
      {showSavingsModal && (
        <SavingsModal
          showSavingsModal={showSavingsModal}
          modalToggle={modalToggle}
          lifetimeDiscountAmount={lifetimeDiscountAmount}
          lifetimeAverageDiscountPerGallon={lifetimeAverageDiscountPerGallon}
          discountMetadata={discountMetadata}
        />
      )}
    </>
  );
};

export default CreditOverviewCard;
