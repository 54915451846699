import { EndpointResponse, Entity } from '@app/@types/api.types';
import {
  NotifiableRoles,
  NotificationChannels,
  NotificationResource,
  NotificationTypes,
  RoleNotificationSettingsData,
  UserNotificationSettingsData,
} from '@app/@types/notification_setting.types';
import { UserData } from '@app/@types/users.types';
import { notificationsList } from '@app/constants/notifications';
import { FetcherKey, apiGetFetcher, apiPostFetcher } from '@app/utils/data/fetchers';
import axios, { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import useSWR, { SWRResponse, useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

type UserNotificationSettingsEntity = Entity<UserNotificationSettingsData, 'user_notification'>;

type UserNotificationSettingsResponse = {
  data: UserNotificationSettingsData[];
} & SWRResponse<UserNotificationSettingsEntity>;

type RoleNotificationSettingsEntity = Entity<UserNotificationSettingsData, 'role_notification'>;

type RoleNotificationSettingsResponse = {
  data: RoleNotificationSettingsData[];
} & SWRResponse<RoleNotificationSettingsEntity>;

const PERSONAL_NOTIFICATIONS_KEY = {
  url: '/user_notification_settings/personal',
  params: {
    include: 'user,resource,notification',
    enabled: 'true',
  },
};

export function usePersonalNotificationSettings() {
  return useSWR<EndpointResponse<UserNotificationSettingsData[]>>(
    PERSONAL_NOTIFICATIONS_KEY,
    apiGetFetcher,
  );
}

export function useUserNotificationSettings({
  userId,
  shouldFetch,
}: {
  userId?: number;
  shouldFetch?: boolean;
}) {
  return useSWR<EndpointResponse<UserNotificationSettingsData[]>>(
    shouldFetch && {
      url: '/user_notification_settings',
      params: {
        include: 'user,resource,notification',
        enabled: 'true',
        user_id: userId ? userId : undefined,
      },
    },
    apiGetFetcher,
  );
}

export function useRoleNotificationSettings() {
  return useSWR<EndpointResponse<RoleNotificationSettingsData[]>>(
    {
      url: '/role_notification_settings',
      params: {
        include: 'notification,resource',
      },
    },
    apiGetFetcher,
  );
}

export type PersonalNotificationSettingsParam = {
  resource_type: NotificationResource;
  resource_id: string | number;
  notification_types: NotificationChannels[];
  custom_trigger?: { [key: string]: string } | null;
  enabled: boolean;
  type: NotificationTypes;
};

type PersonalNotificationSettingsResult = {
  updatePersonalNotificationSettings: (params: PersonalNotificationSettingsParam) => void;
  updateSettingsInProgress: boolean;
  error?: Error;
};

export function useUpdatePersonalNotificationSettings(): PersonalNotificationSettingsResult {
  const { trigger, error, isMutating } = useSWRMutation<
    EndpointResponse<UserNotificationSettingsData[]>,
    AxiosError,
    FetcherKey,
    PersonalNotificationSettingsParam
  >(PERSONAL_NOTIFICATIONS_KEY, apiPostFetcher, { populateCache: true });

  const updatePersonalNotificationSettings = useCallback(
    (params: PersonalNotificationSettingsParam) => {
      trigger(params);
    },
    [trigger],
  );

  return {
    updatePersonalNotificationSettings,
    updateSettingsInProgress: isMutating,
    error,
  };
}

export type BulkUserNotificationSettingsParam = {
  data: {
    resource_type?: NotificationResource;
    resource_id?: string;
    enabled?: boolean;
    type: NotificationTypes;
    custom_trigger?: { [key: string]: string } | null;
    user_id: string;
  }[];
  notification_types?: NotificationChannels[];
};

type BulkUpdateUserNotificationSettingsParams = {
  user_id: string;
  params: BulkUserNotificationSettingsParam;
};

type UpdateUserNotificationSettingsResult = {
  updateUserNotificationSettings: (params: BulkUpdateUserNotificationSettingsParams) => void;
  updateSettingsInProgress: boolean;
  error?: Error;
};

export const getUpdateUserNotificationSettingsParams = (
  user: UserData,
  selectedResources: string[],
  enabledNotifications: NotificationTypes[],
  allResources: { id: string; name: string }[],
): BulkUpdateUserNotificationSettingsParams => {
  /**
   * When a user updates their notifications, any setting not selected/enabled should be
   * updated to disabled state.
   */
  const userId = user.id.toString();
  const data = notificationsList
    .filter((notification) => notification.allowedRoles.includes(user.role))
    .reduce((acc, notification) => {
      if (notification.globalOnly) {
        // No need to add any other Tag setting for global only notification types
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc = acc.concat([
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {
            user_id: userId,
            enabled: enabledNotifications.includes(notification.id),
            resource_id: null,
            resource_type: null,
            type: notification.id,
          },
        ]);
      } else {
        acc = acc.concat(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          allResources.map(({ id: resource }) => ({
            user_id: userId,
            enabled:
              selectedResources.includes(resource) &&
              enabledNotifications.includes(notification.id),
            resource_id: resource === 'all' ? null : resource,
            resource_type: resource === 'all' ? null : 'Tag',
            type: notification.id,
          })),
        );
      }
      return acc;
    }, []);
  return { user_id: userId, params: { data, notification_types: ['Email', 'SMS'] } };
};

type DestroyUserNotificationSettingsResult = {
  destroyUserNotificationSettings: ({ ids, user_id }: { ids: number[]; user_id: string }) => void;
  updateSettingsInProgress: boolean;
  error?: Error;
};

export function useDestroyUserNotificationSettings(): DestroyUserNotificationSettingsResult {
  const { mutate } = useSWRConfig();
  const [updateSettingsInProgress, setUpdateSettingsInProgress] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const destroyUserNotificationSettings = useCallback(
    ({ ids, user_id }: { ids: number[]; user_id: string }) => {
      const results = mutate<UserNotificationSettingsResponse>(
        {
          url: '/user_notification_settings',
          params: {
            include: 'user,resource,notification',
            enabled: 'true',
            user_id,
          },
        },
        async () => {
          setUpdateSettingsInProgress(true);

          try {
            // Delete
            const params = {
              data: ids.map((id) => ({ id })),
            };
            const result = await axios.delete(
              '/user_notification_settings/bulk?include=user,resource,notification',
              { data: params },
            );
            setUpdateSettingsInProgress(false);
            return result.data;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              setError(e);
            } else {
              setError(new Error('Unknown error'));
            }
            setUpdateSettingsInProgress(false);
            return undefined;
          }
        },
      );
      return results;
    },
    [mutate],
  );

  return {
    destroyUserNotificationSettings,
    updateSettingsInProgress,
    error,
  };
}

export function useUpdateUserNotificationSettings(): UpdateUserNotificationSettingsResult {
  const { mutate } = useSWRConfig();
  const [updateSettingsInProgress, setUpdateSettingsInProgress] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const updateUserNotificationSettings = useCallback(
    ({ user_id, params }: { user_id: string; params: BulkUserNotificationSettingsParam }) => {
      mutate<UserNotificationSettingsResponse>(
        {
          url: '/user_notification_settings',
          params: {
            include: 'user,resource,notification',
            enabled: 'true',
            user_id,
          },
        },
        async () => {
          setUpdateSettingsInProgress(true);

          try {
            const result = await axios.post(
              '/user_notification_settings/bulk?include=user,resource,notification',
              params,
            );
            setUpdateSettingsInProgress(false);
            return result.data;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              setError(e);
            } else {
              setError(new Error('Unknown error'));
            }
            setUpdateSettingsInProgress(false);
            return undefined;
          }
        },
      );
    },
    [mutate],
  );

  return {
    updateUserNotificationSettings,
    updateSettingsInProgress,
    error,
  };
}

type RoleNotificationSettingsParam = {
  customer_role: NotifiableRoles;
  resource_id?: number;
  resource_type?: NotificationResource;
  type: NotificationTypes;
};

type UpdateRoleNotificationSettingsResult = {
  updateRoleNotificationSettings: (params?: RoleNotificationSettingsParam) => void;
  updateSettingsInProgress: boolean;
  error?: Error;
};

type DestroyRoleNotificationSettingsResult = {
  destroyRoleNotificationSettings: (id: { id: number }) => void;
  updateSettingsInProgress: boolean;
  error?: Error;
};

export function useUpdateRoleNotificationSettings(): UpdateRoleNotificationSettingsResult {
  const { mutate } = useSWRConfig();
  const [updateSettingsInProgress, setUpdateSettingsInProgress] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const updateRoleNotificationSettings = useCallback(
    (params?: RoleNotificationSettingsParam) => {
      mutate<RoleNotificationSettingsResponse>(
        {
          url: '/role_notification_settings',
          params: {
            include: 'notification,resource',
          },
        },
        async () => {
          setUpdateSettingsInProgress(true);

          try {
            const result = await axios.post('/role_notification_settings', params);
            setUpdateSettingsInProgress(false);
            return result.data;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              setError(e);
            } else {
              setError(new Error('Unknown error'));
            }
            setUpdateSettingsInProgress(false);
            return undefined;
          }
        },
      );
    },
    [mutate],
  );

  return {
    updateRoleNotificationSettings,
    updateSettingsInProgress,
    error,
  };
}

export function useDestroyRoleNotificationSettings(): DestroyRoleNotificationSettingsResult {
  const { mutate } = useSWRConfig();
  const [updateSettingsInProgress, setUpdateSettingsInProgress] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const destroyRoleNotificationSettings = useCallback(
    ({ id }: { id: number }) => {
      mutate<RoleNotificationSettingsResponse>(
        {
          url: '/role_notification_settings',
          params: {
            include: 'notification,resource',
          },
        },
        async () => {
          setUpdateSettingsInProgress(true);

          try {
            const result = await axios.delete(`/role_notification_settings/${id}`);
            setUpdateSettingsInProgress(false);
            return result.data;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              setError(e);
            } else {
              setError(new Error('Unknown error'));
            }
            setUpdateSettingsInProgress(false);
            return undefined;
          }
        },
      );
    },
    [mutate],
  );

  return {
    destroyRoleNotificationSettings,
    updateSettingsInProgress,
    error,
  };
}
