import { EndpointResponse } from '@app/@types/api.types';
import { AddressType } from '@app/@types/customer.types';
import {
  Coordinates,
  PlaceType,
  MyLocation,
  FuelGrade,
  FuelListing,
  ComputeRouteWithListingsResponse,
  SortType,
} from '@app/@types/fuel_listings.types';
import { RadiusOption } from '@app/components/FuelListings/RadiusSelect';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getFetcher, noArgPostFetcher } from '@app/utils/data/fetchers';
import { getEnvironment } from '@app/utils/environment';
import useScript, { UseScriptStatus } from '@atob-developers/shared/src/hooks/useScript';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useSWRImmutable from 'swr/immutable';
import {
  DISCOUNT_BOOST_BRAND_IDS,
  DRIVER_REWARDED_BRAND_IDS,
  DUMMY_MY_LOCATION,
  MILES_TO_METERS,
} from './fuelMap.const';

type FuelMapContextType = {
  placesService: google.maps.places.PlacesService | null;
  setPlacesService: Dispatch<SetStateAction<google.maps.places.PlacesService | null>>;
  gMap: google.maps.Map | null;
  setGMap: Dispatch<SetStateAction<google.maps.Map | null>>;
  addressCoords: Coordinates | undefined;
  setAddressCoords: Dispatch<SetStateAction<Coordinates | undefined>>;
  address: PlaceType | MyLocation;
  setAddress: Dispatch<SetStateAction<PlaceType | MyLocation>>;
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  setSelectedGrade: Dispatch<SetStateAction<FuelGrade | 'TRUCK DIESEL'>>;
  discountBoostOnly: boolean;
  setDiscountBoostOnly: Dispatch<SetStateAction<boolean>>;
  driverRewardedOnly: boolean;
  setDriverRewardedOnly: Dispatch<SetStateAction<boolean>>;
  routing: boolean;
  setRouting: Dispatch<SetStateAction<boolean>>;
  mapCenterCoords: Coordinates | undefined;
  setMapCenterCoords: Dispatch<SetStateAction<Coordinates | undefined>>;
  showMoreButton: boolean;
  setShowMoreButton: Dispatch<SetStateAction<boolean>>;
  fitBounds: boolean;
  setFitBounds: Dispatch<SetStateAction<boolean>>;
  destinationCoords: Coordinates | undefined;
  setDestinationCoords: Dispatch<SetStateAction<Coordinates | undefined>>;
  originAddressIsEligibleForMarker: boolean;
  setOriginAddressIsEligibleForMarker: Dispatch<SetStateAction<boolean>>;
  bounds: {
    top_left_longitude: number;
    top_left_latitude: number;
    bottom_right_longitude: number;
    bottom_right_latitude: number;
  } | null;
  setBounds: Dispatch<
    SetStateAction<{
      top_left_longitude: number;
      top_left_latitude: number;
      bottom_right_longitude: number;
      bottom_right_latitude: number;
    } | null>
  >;
  gmapScriptStatus: UseScriptStatus;
  filterHighFlowDieselLanes: boolean;
  setFilterHighFlowDieselLanes: Dispatch<SetStateAction<boolean>>;
  fuelRadiusData: EndpointResponse<FuelListing[]> | undefined;
  radiusIsLoading: boolean;
  fuelBoundsData: EndpointResponse<FuelListing[]> | undefined;
  boundsIsLoading: boolean;
  routeData: ComputeRouteWithListingsResponse | undefined;
  routeIsLoading: boolean;
  selectedSort: SortType;
  setSelectedSort: Dispatch<SetStateAction<SortType>>;
  routingDisplayRadius: number | null;
  setRoutingDisplayRadius: Dispatch<SetStateAction<number | null>>;
  discountOnly: boolean;
  setDiscountOnly: Dispatch<SetStateAction<boolean>>;
  userLocation: Coordinates | undefined;
  setUserLocation: Dispatch<SetStateAction<Coordinates | undefined>>;
  selectedRadius: RadiusOption;
  setSelectedRadius: Dispatch<SetStateAction<RadiusOption>>;
  defaultSettings: {
    isOTRCustomer: boolean;
    customerAddress: AddressType;
  };
  selectedFuelListingId: string | null;
  setSelectedFuelListingId: Dispatch<SetStateAction<string | null>>;
};

const FuelMapContext = createContext<FuelMapContextType | undefined>(undefined);

export const FuelMapContextProvider = ({ children }: { children: ReactNode }) => {
  const customer = useCustomer();
  const { VITE_GOOGLE_API_KEY } = getEnvironment();
  const defaultSettings = useMemo(
    () => ({
      isOTRCustomer:
        customer.fleet_type === 'heavy' ||
        customer.fleet_type === 'heavy_assumed' ||
        customer.fleet_type === 'mixed' ||
        customer.fleet_type === 'mixed_assumed',
      customerAddress: customer.company_address,
    }),
    [customer],
  );
  const [defaultToRecommended] = useFeatureFlags('fuel_map_default_recommended_sort');

  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [gMap, setGMap] = useState<google.maps.Map | null>(null);
  const [addressCoords, setAddressCoords] = useState<Coordinates>();
  const [address, setAddress] = useState<PlaceType | MyLocation>(DUMMY_MY_LOCATION);
  const [selectedGrade, setSelectedGrade] = useState<FuelGrade | 'TRUCK DIESEL'>(
    defaultSettings?.isOTRCustomer ? 'TRUCK DIESEL' : 'REGULAR',
  );
  const [discountBoostOnly, setDiscountBoostOnly] = useState(false);
  const [driverRewardedOnly, setDriverRewardedOnly] = useState(false);
  const [routing, setRouting] = useState(false);
  const [mapCenterCoords, setMapCenterCoords] = useState<Coordinates>();
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [fitBounds, setFitBounds] = useState(true);
  const [destinationCoords, setDestinationCoords] = useState<Coordinates>();
  const [originAddressIsEligibleForMarker, setOriginAddressIsEligibleForMarker] = useState(true);
  const [filterHighFlowDieselLanes, setFilterHighFlowDieselLanes] = useState(
    defaultSettings?.isOTRCustomer ?? false,
  );
  const [selectedRadius, setSelectedRadius] = useState<RadiusOption>(
    defaultSettings?.isOTRCustomer ? 100 : 5,
  );
  const [bounds, setBounds] = useState<{
    top_left_longitude: number;
    top_left_latitude: number;
    bottom_right_longitude: number;
    bottom_right_latitude: number;
  } | null>(null);
  const [selectedSort, setSelectedSort] = useState<SortType>(
    defaultToRecommended ? 'recommended' : 'discountedPrice',
  );
  const [routingDisplayRadius, setRoutingDisplayRadius] = useState<number | null>(null);
  const [discountOnly, setDiscountOnly] = useState(true);
  const [userLocation, setUserLocation] = useState<Coordinates>();
  const [selectedFuelListingId, setSelectedFuelListingId] = useState<string | null>(null);

  const gmapScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${VITE_GOOGLE_API_KEY}&v=weekly&callback=Function.prototype`,
  );
  const { data: fuelRadiusData, isLoading: radiusIsLoading } = useSWRImmutable<
    EndpointResponse<FuelListing[]>
  >(
    !routing &&
      addressCoords &&
      address.place_id != 'map_location' && {
        url: '/fuel_listings',
        params: {
          latitude: addressCoords?.latitude,
          longitude: addressCoords?.longitude,
          radius: selectedRadius * MILES_TO_METERS,
          filter_on_high_flow_diesel_lanes: filterHighFlowDieselLanes,
          electric: selectedGrade === 'ELECTRIC',
          ...(discountBoostOnly && { brand_ids: DISCOUNT_BOOST_BRAND_IDS.join(',') }),
          ...(driverRewardedOnly && { brand_ids: DRIVER_REWARDED_BRAND_IDS.join(',') }),
        },
      },
    getFetcher,
  );

  const { data: fuelBoundsData, isLoading: boundsIsLoading } = useSWRImmutable<
    EndpointResponse<FuelListing[]>
  >(
    !routing &&
      bounds &&
      address.place_id === 'map_location' && {
        url: '/fuel_listings/bounding_box',
        params: {
          ...bounds,
          filter_on_high_flow_diesel_lanes: filterHighFlowDieselLanes,
          ...(discountBoostOnly && { brand_ids: DISCOUNT_BOOST_BRAND_IDS.join(',') }),
          ...(driverRewardedOnly && { brand_ids: DRIVER_REWARDED_BRAND_IDS.join(',') }),
        },
      },
    getFetcher,
  );

  const { data: routeData, isLoading: routeIsLoading } =
    useSWRImmutable<ComputeRouteWithListingsResponse>(
      routing &&
        addressCoords &&
        destinationCoords && {
          url: '/fuel_listings/compute_route_with_listings',
          params: {
            origin: {
              latitude: addressCoords.latitude,
              longitude: addressCoords.longitude,
            },
            destination: {
              latitude: destinationCoords.latitude,
              longitude: destinationCoords.longitude,
            },
            filter_on_high_flow_diesel_lanes: filterHighFlowDieselLanes,
          },
        },
      noArgPostFetcher,
    );

  useEffect(() => {
    // Allows for the OTR specific settings to be changed if the customer declares they are OTR via the FleetTypePopup.
    setSelectedGrade(defaultSettings?.isOTRCustomer ? 'TRUCK DIESEL' : 'REGULAR');
    setFilterHighFlowDieselLanes(defaultSettings?.isOTRCustomer ?? false);
    setSelectedRadius(defaultSettings?.isOTRCustomer ? 100 : 5);
    setDiscountBoostOnly(false);
    setDriverRewardedOnly(false);
  }, [
    defaultSettings?.isOTRCustomer,
    setFilterHighFlowDieselLanes,
    setSelectedGrade,
    setSelectedRadius,
    setDiscountBoostOnly,
    setDriverRewardedOnly,
  ]);

  const hasSetCurLocDefaultRef = useRef(false);
  const [needToCheckCustomerAddress, setNeedToCheckCustomerAddress] = useState(false);
  useEffect(() => {
    if (!hasSetCurLocDefaultRef.current) {
      hasSetCurLocDefaultRef.current = true;
      const params = new URLSearchParams(window.location.search);
      if (params.has('address')) {
        setNeedToCheckCustomerAddress(true);
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setAddressCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setMapCenterCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setAddress({
            location_type: 'my_location',
            place_id: 'current_location',
            formatted_address: 'Current Location',
            description: 'Current Location',
            structured_formatting: {
              main_text: 'Current Location',
              secondary_text: '',
            },
            geometry: {
              location: {
                lat: () => position.coords.latitude,
                lng: () => position.coords.longitude,
              },
            },
          });
        },
        (_) => {
          setNeedToCheckCustomerAddress(true);
        },
      );
    }
  }, [
    filterHighFlowDieselLanes,
    routing,
    selectedRadius,
    setNeedToCheckCustomerAddress,
    setAddressCoords,
    setMapCenterCoords,
    setAddress,
  ]);

  // We are updating state here but a state update function
  // is guaranteed to be stable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (needToCheckCustomerAddress === false || gmapScriptStatus !== 'ready') {
      return;
    }
    setNeedToCheckCustomerAddress(false);

    const params = new URLSearchParams(window.location.search);
    let address;
    if (params.has('address')) {
      address = decodeURIComponent(params.get('address') ?? '');
    } else {
      address = `${defaultSettings.customerAddress.address1} ${
        defaultSettings.customerAddress.address2 ?? ''
      } ${defaultSettings.customerAddress.city} ${defaultSettings.customerAddress.state} ${
        defaultSettings.customerAddress.zip
      }`;
    }

    const service = new google.maps.Geocoder();
    void service.geocode(
      {
        address,
      },
      (places, status) => {
        if (status === google.maps.GeocoderStatus.OK && places && places.length > 0) {
          const place = places[0];
          setAddressCoords({
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
          });
          setMapCenterCoords({
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
          });
          setAddress({
            location_type: 'my_location',
            place_id: 'current_location',
            formatted_address: 'Current Location',
            description: place.formatted_address,
            structured_formatting: {
              main_text: place.formatted_address,
              secondary_text: '',
            },
            geometry: {
              location: {
                lat: () => place.geometry.location.lat(),
                lng: () => place.geometry.location.lng(),
              },
            },
          });
        }
      },
    );
  });

  const value = useMemo(
    () => ({
      placesService,
      setPlacesService,
      gMap,
      setGMap,
      addressCoords,
      setAddressCoords,
      address,
      setAddress,
      selectedGrade,
      setSelectedGrade,
      discountBoostOnly,
      setDiscountBoostOnly,
      driverRewardedOnly,
      setDriverRewardedOnly,
      routing,
      setRouting,
      mapCenterCoords,
      setMapCenterCoords,
      showMoreButton,
      setShowMoreButton,
      fitBounds,
      setFitBounds,
      destinationCoords,
      setDestinationCoords,
      originAddressIsEligibleForMarker,
      setOriginAddressIsEligibleForMarker,
      bounds,
      setBounds,
      gmapScriptStatus,
      defaultSettings,
      filterHighFlowDieselLanes,
      setFilterHighFlowDieselLanes,
      selectedRadius,
      setSelectedRadius,
      fuelRadiusData,
      radiusIsLoading,
      fuelBoundsData,
      boundsIsLoading,
      routeData,
      routeIsLoading,
      selectedSort,
      setSelectedSort,
      routingDisplayRadius,
      setRoutingDisplayRadius,
      discountOnly,
      setDiscountOnly,
      userLocation,
      setUserLocation,
      selectedFuelListingId,
      setSelectedFuelListingId,
    }),
    [
      placesService,
      gMap,
      addressCoords,
      address,
      selectedGrade,
      discountBoostOnly,
      setDiscountBoostOnly,
      driverRewardedOnly,
      setDriverRewardedOnly,
      routing,
      mapCenterCoords,
      showMoreButton,
      fitBounds,
      destinationCoords,
      originAddressIsEligibleForMarker,
      bounds,
      gmapScriptStatus,
      defaultSettings,
      filterHighFlowDieselLanes,
      selectedRadius,
      fuelRadiusData,
      radiusIsLoading,
      fuelBoundsData,
      boundsIsLoading,
      routeData,
      routeIsLoading,
      selectedSort,
      routingDisplayRadius,
      discountOnly,
      userLocation,
      selectedFuelListingId,
    ],
  );

  return <FuelMapContext.Provider value={value}>{children}</FuelMapContext.Provider>;
};

export const useFuelMapContext = () => {
  const context = useContext(FuelMapContext);
  if (!context) {
    throw new Error('useFuelMapContext must be used within FuelMapContextProvider');
  }

  return context;
};
