import { PartnerLogo } from '@app/components/layout/PartnerLogo';

export default function Header() {
  return (
    <div className="sticky top-0 z-10 hidden w-full justify-center bg-white md:flex">
      <div className="w-full max-w-screen-xl px-20 py-3">
        <PartnerLogo darkStyle className="max-h-[72px]" />
      </div>
    </div>
  );
}
