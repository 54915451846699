import useWindowWidth from '@app/hooks/useWindowWidth';
import {
  faDollarCircle,
  faFileArchive,
  faTrafficLightGo,
  faTruck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

const Benefit = ({
  title,
  reason,
  children,
}: {
  title: string;
  reason: string;
  children: ReactNode;
}) => {
  return (
    <div className="xs:mb-0 mb-8 max-w-[400px] last:mb-0 sm:mb-4">
      {children}
      <h2 className="text-black0 my-3 text-[24px] font-[400] leading-6 sm:my-4 sm:text-[24px]">
        {title}
      </h2>
      <p className="text-grey6 max-w-[300px] text-[16px] font-[400] sm:text-[18px]">{reason}</p>
    </div>
  );
};

export default function WalletBenefits({
  loading,
  onClick,
}: {
  loading: boolean;
  onClick: () => void;
}): ReactElement {
  const { isMobile } = useWindowWidth();
  const iconSize = isMobile ? '2x' : '3x';

  return (
    <div>
      <div className="max-w-72 rounded-md bg-white p-8 shadow-md">
        <div className="flex flex-col items-center">
          <h2
            className={classNames('text-atob-green text-center text-[32px] font-[700]', {
              'mb-8': !isMobile,
            })}
          >
            Benefits of AtoB Wallet
          </h2>
          {isMobile && (
            <div className="my-8 flex flex-col items-center">
              <div className="mb-4 w-full">
                <LoadingButton size="medium" loading={loading} onClick={onClick}>
                  Get Started
                </LoadingButton>
              </div>
              <h3 className="text-atob-green text-center font-[700]">Sign up today!</h3>
            </div>
          )}
          <div className="xs:grid xs:grid-rows-2 xs:grid-cols-2 xs:gap-x-8 my-4 sm:gap-y-4 md:gap-y-8">
            <Benefit
              title="No minimums"
              reason="No monthly fees, no overdraft fees, or minimum balance"
            >
              <FontAwesomeIcon icon={faTruck} size={iconSize} className="text-atob-green" />
            </Benefit>
            <Benefit title="Get rewarded" reason="Get cash back on your balance as fuel credit">
              <FontAwesomeIcon icon={faDollarCircle} size={iconSize} className="text-atob-green" />
            </Benefit>
            <Benefit title="Protected" reason="Account eligible for FDIC insurance">
              <FontAwesomeIcon icon={faFileArchive} size={iconSize} className="text-atob-green" />
            </Benefit>
            <Benefit title="Get up and running fast" reason="Set up your account in 5 minutes">
              <FontAwesomeIcon
                icon={faTrafficLightGo}
                size={iconSize}
                className="text-atob-green"
              />
            </Benefit>
          </div>
          {!isMobile && (
            <div className="flex flex-col items-center">
              <div className="max-w-[250px]">
                <LoadingButton size="medium" loading={loading} onClick={onClick}>
                  Get Started
                </LoadingButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
