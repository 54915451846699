import { ChargeEventData } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import { faStars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import ChargeEventFuelLevelsChart from './ChargeEventFuelLevelsChart';
import ChargeEventVehicleLocation from './ChargeEventVehicleLocation';

type TelematicsProps = {
  chargeEvent: ChargeEventData;
};

export default function ChargeEventTelematics({ chargeEvent }: TelematicsProps) {
  return (
    <RoundedCard className="flex-1">
      <RoundedCardTitle
        title="Telematics fraud protection"
        chip={
          <Chip
            size="small"
            color="black"
            label="Premium"
            className="text-dark-accent-11"
            icon={<FontAwesomeIcon icon={faStars} className="h-3 w-3" />}
          />
        }
      />
      <div className="flex flex-col lg:flex-row">
        <ChargeEventVehicleLocation chargeEvent={chargeEvent} />
        <RoundedCardCore className="lg:w-1/2">
          <Suspense fallback={<CircularProgress />}>
            <ChargeEventFuelLevelsChart chargeEvent={chargeEvent} />
          </Suspense>
        </RoundedCardCore>
      </div>
    </RoundedCard>
  );
}
