// Keep the IDs in sync with `app/models/merchant_category_group.rb`
export const AUTOMATED_FUEL_DISPENSERS = {
  name: 'AFD-only (Automated Fuel Dispensers)',
  id: 'automated_fuel_dispensers',
};

const FUEL_EXPENSES_CATEGORIES = Object.freeze({
  title: 'Fuel Expenses',
  min_spend_tier: 0,
  categories: [
    AUTOMATED_FUEL_DISPENSERS,
    { name: 'Fuel dealers', id: 'fuel_dealers_non_automotive' },
    { name: 'Petroleum', id: 'petroleum_and_petroleum_products' },
    { name: 'Service stations', id: 'service_stations' },
  ],
});
export const FUEL_CATEGORY_IDS = FUEL_EXPENSES_CATEGORIES.categories.map((c) => c.id);

const EXPENSES_CATEGORIES = Object.freeze([
  {
    title: 'Vehicle Expenses',
    min_spend_tier: 1,
    categories: [
      { name: 'Maintenance and Parts', id: 'MAINTENANCE_AND_PARTS' },
      { name: 'Towing and Roadside Assistance', id: 'TOWING_AND_ROADSIDE_ASSISTANCE' },
      { name: 'Car Washes', id: 'CAR_WASHES' },
      { name: 'Tolls and Parking', id: 'TOLLS_AND_PARKING' },
    ],
  },
  {
    title: 'Business Expenses',
    min_spend_tier: 2,
    categories: [
      { name: 'Vehicle Rentals and Leases', id: 'VEHICLE_RENTALS_AND_LEASES' },
      { name: 'Permits and Licensing', id: 'PERMITS_AND_LICENSING' },
      { name: 'Insurance', id: 'INSURANCE' },
      { name: 'Hardware and Wholesale Stores', id: 'HARDWARE_AND_WHOLESALE_STORES' },
      { name: 'Freight Services and Trucking', id: 'FREIGHT_SERVICES_AND_TRUCKING' },
    ],
  },
  {
    title: 'All Expenses',
    min_spend_tier: 3,
    categories: [
      { name: 'Groceries', id: 'GROCERIES' },
      { name: 'Restaurants', id: 'RESTAURANTS' },
      { name: 'Shopping', id: 'SHOPPING' },
      { name: 'Travel', id: 'TRAVEL' },
      { name: 'Recreation', id: 'RECREATION' },
      { name: 'Personal Services', id: 'PERSONAL_SERVICES' },
      { name: 'Miscellaneous Services', id: 'MISCELLANEOUS_SERVICES' },
    ],
  },
]);

export const ALL_CATEGORIES_GROUP_IDS = EXPENSES_CATEGORIES.flatMap((ec) =>
  ec.categories.map((c) => c.id),
);

export const categoriesCount = (customerSpendTier: number): number => {
  return EXPENSES_CATEGORIES.filter((ec) => ec.min_spend_tier <= customerSpendTier).flatMap((ec) =>
    ec.categories.map((c) => c.id),
  ).length;
};

const internalGenerateExpenseCategory = (
  selectedCategoryIds: string[],
  customerSpendTier: number,
) => {
  return EXPENSES_CATEGORIES.map((ec) => {
    return ec.min_spend_tier <= customerSpendTier
      ? {
          title: ec.title,
          categories: ec.categories.map((c) => {
            return { ...c, selected: selectedCategoryIds.includes(c.id) };
          }),
        }
      : null;
  }).filter((ec) => ec !== null);
};

export type ExpenseCategories = ReturnType<typeof internalGenerateExpenseCategory>;

export const generateExpenseCategory = (
  selectedCategoryIds: string[],
  customerSpendTier: number,
): ExpenseCategories => {
  return internalGenerateExpenseCategory(selectedCategoryIds, customerSpendTier);
};
