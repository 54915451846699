import { ErrorNotification } from '@app/components/layout';
import { guardAxiosError } from '@app/utils/error/guards';
import isNumeric from '@app/utils/is-numeric';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { ReactElement, useState } from 'react';
import { RecipientToCreate } from '../transfer.types';
import RecipientForm from './RecipientForm';
import type { Address } from '@app/components/elements/AddressInput';

const validateAddress = (
  address: Address,
  setAddressErrors: (errors: Record<string, string>) => void,
) => {
  setAddressErrors({});
  const { address1, city, state, zip } = address;
  const hasNotFilledOutAddress = !address1 && !city && !zip;

  if (hasNotFilledOutAddress) {
    return true;
  }

  if (!address1) {
    setAddressErrors({ address1: 'Address is required' });
    return false;
  }
  if (!city) {
    setAddressErrors({ city: 'City is required' });
    return false;
  }
  if (!state) {
    setAddressErrors({ state: 'State is required' });
    return false;
  }
  if (!zip) {
    setAddressErrors({ zip: 'Zip is required' });
    return false;
  }
  if (!isNumeric(zip)) {
    setAddressErrors({ zip: 'Zip must be numeric' });
    return false;
  }

  return true;
};

type AddRecipientErrors = {
  name: string | null;
  routing: string | null;
  account: string | null;
  confirmedAccount: string | null;
};

const initialErrors: AddRecipientErrors = {
  name: null,
  routing: null,
  account: null,
  confirmedAccount: null,
};

const AddRecipient = ({
  reset,
  onAddRecipient,
  isOwned = false,
  open,
}: {
  reset: () => void;
  onAddRecipient: (recipient: RecipientToCreate) => Promise<void>;
  open: boolean;
  isOwned?: boolean;
}): ReactElement => {
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [isBusinessAccount, setIsBusinessAccount] = useState(true);
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [confirmedAccountNumber, setConfirmedAccountNumber] = useState('');
  const [errors, setErrors] = useState<AddRecipientErrors>(initialErrors);
  const [overallError, setOverallError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [addressValues, setAddressValues] = useState({});
  const [addressErrors, setAddressErrors] = useState({});
  const [touched, setTouched] = useState({});

  const toggle = () => {
    setName('');
    setAccountNumber('');
    setRoutingNumber('');
    setConfirmedAccountNumber('');
    setAddressValues({});
    setAddressErrors({});
    setTouched({});
    setErrors(initialErrors);
    setOverallError(null);
    reset();
  };

  const validate = () => {
    const validationErrors = { ...initialErrors };
    const isValidAddress = validateAddress(addressValues as Address, setAddressErrors);
    if (!isValidAddress) {
      return;
    }

    if (!name) {
      validationErrors.name = 'Name is required';
    }
    if (!accountNumber) {
      validationErrors.account = 'Account number is required';
    }
    if (accountNumber !== confirmedAccountNumber) {
      validationErrors.confirmedAccount = 'Account numbers do not match';
    }
    if (!routingNumber) {
      validationErrors.routing = 'Routing number is required';
    }
    setErrors(validationErrors);
    return !Object.values(validationErrors).some((err) => err !== null);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (validate()) {
      setLoading(true);
      try {
        await onAddRecipient({
          billing_details: {
            name,
            address: addressValues as Address,
          },
          us_bank_account: {
            account_number: accountNumber,
            routing_number: routingNumber,
            account_holder_type: isBusinessAccount ? 'company' : 'individual',
          },
          display_name: nickname,
          customer_owned: isOwned,
        });
        reset();
      } catch (e: unknown) {
        if (!guardAxiosError(e)) {
          setOverallError('Something went wrong. Please try again.');
          return;
        }
        const errorMessage = e.response?.data?.errors[0] || e.message;
        setOverallError(errorMessage);
      }

      setLoading(false);
    }
  };

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="Add Recipient" onClose={toggle} />
      <ModalBodyContent>
        {overallError && <ErrorNotification error={overallError} />}
        <RecipientForm
          type="add"
          formId="add_recipient_form"
          onSubmit={onSubmit}
          errors={errors as Record<string, string>}
          name={name}
          setName={setName}
          nickname={nickname}
          setNickname={setNickname}
          isBusinessAccount={isBusinessAccount}
          setIsBusinessAccount={setIsBusinessAccount}
          routingNumber={routingNumber}
          setRoutingNumber={setRoutingNumber}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          confirmedAccountNumber={confirmedAccountNumber}
          setConfirmedAccountNumber={setConfirmedAccountNumber}
          addressValues={addressValues as Address}
          setAddressValues={setAddressValues}
          addressErrors={addressErrors}
          setAddressErrors={setAddressErrors}
          touched={touched}
          setTouched={setTouched}
        >
          <LoadingButton size="medium" loading={loading} type="submit" form="add_recipient_form">
            Add
          </LoadingButton>
        </RecipientForm>
      </ModalBodyContent>
    </Modal>
  );
};

export default AddRecipient;
