import { ChallengeMatch } from '@app/@types/challenge_match_card.types';
import { getFetcher } from '@app/utils/data/fetchers';
import { useMemo } from 'react';
import useSWR from 'swr';
import { TA_PARTNER_ID } from '../FuelMap/fuelMap.const';

export const useChallengeMatch = () => {
  const { data: challengeMatch, isLoading } = useSWR<ChallengeMatch>(
    { url: '/challenge_match' },
    getFetcher,
  );

  const currentDiscountBoost = useMemo(() => {
    const lastWeekCentsBack = challengeMatch?.last_week.achieved_level?.cents_back ?? 0;
    const thisWeekCentsBack = challengeMatch?.this_week.achieved_level?.cents_back ?? 0;
    return Math.max(lastWeekCentsBack, thisWeekCentsBack);
  }, [
    challengeMatch?.last_week.achieved_level?.cents_back,
    challengeMatch?.this_week.achieved_level?.cents_back,
  ]);

  const currentLevel = useMemo(() => {
    return challengeMatch?.levels
      .filter((level) => {
        return (challengeMatch.this_week.settled_amt.cents ?? 0) >= level.threshold_amount.cents;
      })
      .pop();
  }, [challengeMatch]);

  const nextLevel = useMemo(() => {
    return (
      challengeMatch?.levels.find((level) => {
        const amountNeeded = level.threshold_amount.cents;
        return (challengeMatch.this_week.settled_amt.cents ?? 0) < amountNeeded;
      }) ?? null
    );
  }, [challengeMatch]);

  const isTAOnly = useMemo(() => {
    return challengeMatch?.partners.length === 1 && challengeMatch?.partners[0] === TA_PARTNER_ID;
  }, [challengeMatch]);

  return {
    isLoading,
    challengeMatch,
    currentDiscountBoost,
    currentLevel,
    nextLevel,
    isTAOnly,
  };
};
