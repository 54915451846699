import { DestructuredBankAccountData } from '@app/@types/bankAccount.types';
import { faLinkSlash } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import Plaid from '../Plaid';
import { WarningBanner } from './Designs/WarningBanner';

const BankAccountReauthenticateBanner = ({
  primaryBankAccount,
  successfullyLinkedBankAccount,
  setSuccessfullyLinkedBankAccount,
  error,
  setError,
}: {
  primaryBankAccount: DestructuredBankAccountData;
  successfullyLinkedBankAccount: boolean;
  error: string | null;
  setError: (error: string) => void;
  setSuccessfullyLinkedBankAccount: (isAuthenticated: boolean) => void;
}): ReactElement | null => {
  if (
    successfullyLinkedBankAccount ||
    !primaryBankAccount?.is_primary ||
    primaryBankAccount?.plaid_item_status !== 'update_needed' ||
    primaryBankAccount?.skip_update_notification
  ) {
    return null;
  }

  if (error) {
    return (
      <WarningBanner>
        <div className="font-semibold">Your bank account is no longer connected</div>
        <div>{error}</div>
      </WarningBanner>
    );
  }

  return (
    <WarningBanner
      icon={faLinkSlash}
      customActionButton={
        <Plaid
          setError={() =>
            setError(
              'There was an error connecting to your bank account. Please contact support for assistance.',
            )
          }
          buttonProps={{
            size: 'medium',
            primary: true,
          }}
          text="Re-Link Bank Account"
          onSuccessCallback={() => setSuccessfullyLinkedBankAccount(true)}
          onLinkingCallback={() => {}}
        />
      }
    >
      <div className="font-semibold">Bank Disconnected</div>
      <div>
        Please re-link your bank account for faster payment processing and to be considered for a
        higher credit limit.
      </div>
    </WarningBanner>
  );
};

export default BankAccountReauthenticateBanner;
