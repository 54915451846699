import { ChargeEventData } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import DetailsItem from './DetailsItem';
import { driverAssociationReason, formatHelper } from './utils';

export default function ChargeEventDriverDetails({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}) {
  return (
    <RoundedCard className="lg:flex-1">
      <RoundedCardTitle title="Driver" />
      <RoundedCardCore>
        <DetailsItem
          label="Name"
          value={chargeEvent.driver_name ?? 'No driver assigned to this card'}
        />
        <DetailsItem
          label="Identification Method"
          value={driverAssociationReason(chargeEvent.driver_association_reason)}
        />
        <DetailsItem label="Unlocked By" value={formatHelper(chargeEvent.transactor_name)} />
        <DetailsItem label="Unlocked Method" value={formatHelper(chargeEvent.unlock_method)} />
      </RoundedCardCore>
    </RoundedCard>
  );
}
