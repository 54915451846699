import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DriverData, GenerateUnlockIdResult, ValidationResult } from '@app/@types/driver.types';
import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';
import { DriverFormDataType, DRIVER_FORM_DATA } from '@app/constants/drivers';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import logger from '@app/utils/datadog-logger';
import { validateFieldAndGetError, validateForm } from '@app/utils/validation/yup-validation';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import Modal, {
  ModalFooter,
  ModalBodyContent,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { debounce, throttle } from 'lodash-es';
import { ReactElement, useState, useRef } from 'react';

interface CreateDriverModalProps {
  open: boolean;
  toggle: () => void;
  enableStreamlinedDriverAppOnboarding: boolean;
  onDriversUpdate: () => void;
}

const addDriverSuccessMessage = 'Driver successfully added!';
const addDriverErrorMessage = 'There was an error adding the driver. Please try again.';

type DriverInfoUpdateStates = 'error' | 'successful' | 'none';

const DEBOUNCE_TIME_IN_MS = 400;

const CreateDriverInfoPrompt = ({
  addDriverState,
  setAddDriverState,
  message,
}: {
  addDriverState: DriverInfoUpdateStates;
  setAddDriverState: (state: DriverInfoUpdateStates) => void;
  message?: string;
}) => {
  if (addDriverState === 'none') {
    return null;
  } else if (addDriverState === 'error') {
    return (
      <DefaultPrompt
        clickHandler={() => setAddDriverState('none')}
        error={true}
        message={message || addDriverErrorMessage}
      />
    );
  }
  return (
    <DefaultPrompt
      clickHandler={() => setAddDriverState('none')}
      error={false}
      message={message || addDriverSuccessMessage}
    />
  );
};

const CreateDriverModal = ({
  open,
  toggle,
  enableStreamlinedDriverAppOnboarding,
  onDriversUpdate,
}: CreateDriverModalProps): ReactElement => {
  const [values, setValues] = useState<Record<string, string | null>>({});
  const [addDriverState, setAddDriverState] = useState<DriverInfoUpdateStates>('none');
  const [errors, setError] = useState<Record<string, string | null>>({});
  const [warnings, setWarning] = useState<Record<string, string | null>>({});
  const [allowSelfServePins] = useProduct('drivers_unlock_id');
  const [disableAutoInvite] = useFeatureFlags('disable_new_phone_welcome_sms');

  const { partnerName } = useChannelPartner();

  const addDriverHandler = async () => {
    setAddDriverState('none');
    const { formHasValidationErrors, validationErrors } = await validateForm(values, {
      form: DRIVER_FORM_DATA,
    });

    if (!formHasValidationErrors) {
      axios
        .post<EndpointResponse<Entity<DriverData>>>('/drivers', { driver: values })
        .then((res) => {
          const { data } = deserialize(res.data);
          // Kick off driver app invite for provided phone number
          if (
            enableStreamlinedDriverAppOnboarding &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            values.phone?.length > 0 &&
            !disableAutoInvite
          ) {
            axios.post<EndpointResponse<Entity<DriverData>>>(
              `/drivers/${data.id}/driver_app_invite`,
              {
                data,
                force_invite: false,
              },
            );
          }

          onDriversUpdate();
          promptClickHandler('successful');
          setValues({});
          setError({});
        })
        .catch((e) => {
          const error = e.response?.data?.error || 'Sorry, an unknown error occurred';
          setError({ ...errors, validation: error });
          promptClickHandler('error');
        });
    } else {
      setError(validationErrors);
      promptClickHandler('error');
    }
  };

  const promptClickHandler = (driverInfoUpdateState: DriverInfoUpdateStates) => {
    setAddDriverState(driverInfoUpdateState);
  };

  const verifyUnlockId = useRef(
    debounce(async (val: string) => {
      axios
        .get<ValidationResult>(`/drivers/validate_new_unlock_id`, {
          params: {
            unlock_id: val,
          },
        })
        .then((res) => {
          // We have to use the function version of setWarning, and can't rely on `warnings`,
          // because with `useRef`, we only get the initial value of `warnings`
          setWarning((currentWarnings) => {
            const { unlock_id: _, ...otherWarnings } = currentWarnings;
            if (res.data.warning) {
              return { ...otherWarnings, unlock_id: res.data.warning };
            } else {
              return { ...otherWarnings };
            }
          });
        })
        .catch((e) => {
          logger.error('Error trying to validate a new unlock ID', { error: e });
        });
    }, DEBOUNCE_TIME_IN_MS),
  );

  const generateUnlockId = useRef(
    throttle(async () => {
      axios
        .get<GenerateUnlockIdResult>(`/drivers/generate_new_unlock_id`, {})
        .then((res) => {
          // We have to use the function version of setWarning and setValues, and can't rely on `values`,
          // because with `useRef`, we only get the initial value of `values`
          setWarning((currentWarnings) => {
            const { unlock_id: _, ...otherWarnings } = currentWarnings;
            return { ...otherWarnings };
          });
          setValues((currentValues) => {
            return {
              ...currentValues,
              unlock_id: res.data.unlock_id || currentValues.unlock_id,
            };
          });
        })
        .catch((e) => {
          logger.error('Error trying to generate a new unlock ID', { error: e });
        });
    }, DEBOUNCE_TIME_IN_MS),
  );

  const handleValueChange = async (val: string, item: DriverFormDataType) => {
    setValues({
      ...values,
      [item.path]: val ? val : null,
    });
    if (item.validation && item.validationOnChange && !!val) {
      const error = await validateFieldAndGetError(item, val);
      setError({ ...errors, [item.path]: error });
    } else if (errors[item.path]) {
      setError({ ...errors, [item.path]: null });
    }

    if (item.path === 'unlock_id') {
      verifyUnlockId.current(val);
    }
  };

  const modalToggle = () => {
    setAddDriverState('none');
    setValues({}); // Clear state of modal
    setWarning({}); // Clear warnings
    toggle();
  };

  return (
    <Modal open={open} toggle={modalToggle}>
      <ModalHeader title="Add New Driver" onClose={modalToggle} />
      <ModalBodyContent>
        <CreateDriverInfoPrompt
          setAddDriverState={setAddDriverState}
          addDriverState={addDriverState}
          message={errors?.validation || undefined}
        />
        <div className="flex flex-col gap-6">
          {DRIVER_FORM_DATA.filter((item) => {
            return (
              item.path !== 'account_status' && (allowSelfServePins || item.path !== 'unlock_id')
            );
          }).map((item, idx) => (
            <div key={`driver-modal-element-${idx}`}>
              <FormElement
                element={{
                  ...item,
                  key: item.path,
                  inputType: item.path === 'phone' ? 'tel' : 'text',
                }}
                value={values?.[item.path] || ''}
                handleOnChange={async (val) => val !== null && (await handleValueChange(val, item))}
                required={item.required}
                error={errors?.[item.path] || undefined}
                warning={warnings?.[item.path] || undefined}
              />
              {item.path === 'unlock_id' && (
                <button
                  type="button"
                  className="text-atob-green text-sm hover:underline"
                  onClick={generateUnlockId.current}
                >
                  Generate Unique ID
                </button>
              )}
            </div>
          ))}
        </div>
        {enableStreamlinedDriverAppOnboarding && values.phone && !disableAutoInvite && (
          <div className="my-2 block text-sm font-medium text-gray-700">
            {`Your driver will automatically be eligible to use the ${partnerName} app`}
          </div>
        )}
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex justify-end gap-3">
          <div className="max-w-12">
            <Button color="secondary" size="small" onClick={modalToggle}>
              Cancel
            </Button>
          </div>
          <div className="max-w-40">
            <Button size="small" onClick={() => addDriverHandler()}>
              Add New Driver
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CreateDriverModal;
