import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { guardAxiosError } from '@app/utils/error/guards';
import isNumeric from '@app/utils/is-numeric';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import React, { ReactElement, useEffect, useState } from 'react';
import { RecipientToCreate, RecipientToEdit } from '../transfer.types';
import RecipientForm from './RecipientForm';
import type { Address } from '@app/components/elements/AddressInput';

const validateAddress = (
  address: Address,
  setAddressErrors: (errors: Record<string, string>) => void,
) => {
  setAddressErrors({});
  const { address1, city, state, zip } = address;
  const hasNotFilledOutAddress = !address1 && !city && !zip;

  if (hasNotFilledOutAddress) {
    return true;
  }

  if (!address1) {
    setAddressErrors({ address1: 'Address is required' });
    return false;
  }
  if (!city) {
    setAddressErrors({ city: 'City is required' });
    return false;
  }
  if (!state) {
    setAddressErrors({ state: 'State is required' });
    return false;
  }
  if (!zip) {
    setAddressErrors({ zip: 'Zip is required' });
    return false;
  }
  if (!isNumeric(zip)) {
    setAddressErrors({ zip: 'Zip must be numeric' });
    return false;
  }

  return true;
};

type EditRecipientErrors = {
  name: string | null;
  routing: string | null;
  account: string | null;
  confirmedAccount: string | null;
};

const initialErrors: EditRecipientErrors = {
  name: null,
  routing: null,
  account: null,
  confirmedAccount: null,
};

const EditRecipientModal = ({
  recipient,
  reset,
  onEditRecipient,
  open,
}: {
  recipient: RecipientToCreate;
  reset: () => void;
  onEditRecipient: (recipient: RecipientToEdit) => Promise<void>;
  open: boolean;
}): ReactElement => {
  const [name, setName] = useState(recipient?.billing_details?.name || '');
  const [nickname, setNickname] = useState(recipient.display_name || '');
  const [isBusinessAccount, setIsBusinessAccount] = useState(
    recipient.us_bank_account?.account_holder_type === 'company',
  );
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [confirmedAccountNumber, setConfirmedAccountNumber] = useState('');
  const [errors, setErrors] = useState<EditRecipientErrors>(initialErrors);
  const [overallError, setOverallError] = useState<string | null>(null);
  const [showErrorRefresh, setShowErrorRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressValues, setAddressValues] = useState(recipient.billing_details?.address || {});
  const [addressErrors, setAddressErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loadingAddress, setLoadingAddress] = useState(false);

  useEffect(() => {
    setShowErrorRefresh(false);
    if (!recipient.id) {
      return;
    }
    setLoadingAddress(true);

    axios
      .get(`/treasury/recipients/${recipient.id}`, {})
      .then((recipientResponse) => {
        const { data: recipientData } = deserialize(recipientResponse.data);
        setAddressValues(recipientData.billing_details.address);
        setName(recipientData.billing_details.name);
        setNickname(recipientData.display_name);
        setIsBusinessAccount(
          recipientData?.payment_details?.bank_account?.account_holder_type === 'company',
        );
      })
      .catch(() => {
        setOverallError('There was a problem loading this recipient. Please try again.');
        setShowErrorRefresh(true);
      })
      .finally(() => {
        setLoadingAddress(false);
      });
  }, [recipient.id]);

  const toggle = () => {
    setName('');
    setAccountNumber('');
    setRoutingNumber('');
    setConfirmedAccountNumber('');
    setAddressValues({} as React.SetStateAction<Address>);
    setAddressErrors({});
    setTouched({});
    setErrors(initialErrors);
    setOverallError(null);
    setShowErrorRefresh(false);
    reset();
  };

  const validate = () => {
    const validationErrors = { ...initialErrors };
    const isValidAddress = validateAddress(addressValues as Address, setAddressErrors);
    if (!isValidAddress) {
      return;
    }

    setErrors(validationErrors);
    return !Object.values(validationErrors).some((err) => err !== null);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (validate()) {
      setLoading(true);
      try {
        recipient.id &&
          (await onEditRecipient({
            billing_details: {
              name,
              address: addressValues as Address,
            },
            display_name: nickname,
            id: recipient.id,
          }));

        toggle();
      } catch (e: unknown) {
        if (!guardAxiosError(e)) {
          setOverallError('Something went wrong. Please try again.');
          return;
        }
        const errorMessage = e.response?.data?.errors[0] || e.message;
        setOverallError(errorMessage);
      }

      setLoading(false);
    }
  };

  if (showErrorRefresh) {
    return (
      <Modal open={open} toggle={toggle}>
        <ModalHeader title="Edit Recipient" onClose={toggle} />
        <ModalBodyContent>
          {overallError && <ErrorNotification error={overallError} />}
          <div className="flex h-[300px] w-full flex-col items-center">
            <div className="flex w-[200px] justify-center">
              <Button color="secondary" onClick={() => toggle()}>
                Refresh
              </Button>
            </div>
          </div>
        </ModalBodyContent>
      </Modal>
    );
  }

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="Edit Recipient" onClose={toggle} />
      <ModalBodyContent>
        {overallError && <ErrorNotification error={overallError} />}
        {loadingAddress ? (
          <>
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <RecipientForm
            type="edit"
            formId="edit_recipient_form"
            onSubmit={onSubmit}
            errors={errors as Record<string, string>}
            name={name}
            setName={setName}
            nickname={nickname}
            setNickname={setNickname}
            isBusinessAccount={isBusinessAccount}
            setIsBusinessAccount={setIsBusinessAccount}
            routingNumber={routingNumber}
            setRoutingNumber={setRoutingNumber}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
            confirmedAccountNumber={confirmedAccountNumber}
            setConfirmedAccountNumber={setConfirmedAccountNumber}
            addressValues={addressValues as Address}
            setAddressValues={setAddressValues}
            addressErrors={addressErrors}
            setAddressErrors={setAddressErrors}
            touched={touched}
            setTouched={setTouched}
          >
            <LoadingButton size="medium" loading={loading} type="submit" form="edit_recipient_form">
              Save
            </LoadingButton>
          </RecipientForm>
        )}
      </ModalBodyContent>
    </Modal>
  );
};

export default EditRecipientModal;
