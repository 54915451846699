import { EndpointResponse } from '@app/@types/api.types';
import { CardRequestData, CardRequestParams } from '@app/@types/card_requests.types';
import { CUSTOMER_QUERY_KEY } from '@app/hooks/query/useCustomerQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import axios from 'axios';
import useSWR, { SWRResponse, mutate } from 'swr';

export const useCardRequestQuery = (
  id?: string | null,
): SWRResponse<EndpointResponse<CardRequestData>, Error> => {
  return useSWR<EndpointResponse<CardRequestData>>(
    id && { url: `/card_requests/${id}` },
    apiGetFetcher,
  );
};

export const confirmCardRequest = (
  cardRequestId: string,
  params: CardRequestParams,
  callback: (() => void) | null = null,
): void => {
  axios.put(`/card_requests/${cardRequestId}/confirm`, params).finally(() => {
    if (callback !== null) {
      callback();
    } else {
      mutate(CUSTOMER_QUERY_KEY);
    }
  });
};
