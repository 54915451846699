import { InstantPayoutMethod } from '@app/@types/instant_payout_method.types';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { Formik } from 'formik';
import { capitalize } from 'lodash-es';
import * as Yup from 'yup';

export const AddInstantPayoutMethodRecipientModal = ({
  open,
  reset,
  onAddInstantPayoutMethodRecipient,
  type,
}: {
  open: boolean;
  reset: () => void;
  onAddInstantPayoutMethodRecipient: (recipient: Omit<InstantPayoutMethod, 'id'>) => Promise<void>;
  type: 'zelle' | 'paypal' | 'venmo';
}) => {
  const { addToast } = useToasts();

  const initialValues = {
    email: '',
    nickname: '',
  };

  const displayType = type === 'paypal' ? 'PayPal' : capitalize(type);

  const validationSchema = Yup.object({
    email: Yup.string().required(`${displayType} user ID is required`),
  });

  const addInstantPayoutMethodRecipient = async ({
    email,
    nickname,
  }: {
    email: string;
    nickname: string;
  }) => {
    try {
      await onAddInstantPayoutMethodRecipient({
        username: email,
        nickname,
        type,
        username_type: 'email',
      });
      reset();
    } catch (e) {
      addToast(`Failed to add ${displayType} account`, { appearance: 'error' });
    }
  };

  return (
    <Modal open={open} toggle={reset}>
      <ModalHeader title={`Add ${displayType} account`} onClose={reset} />
      <ModalBodyContent>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => addInstantPayoutMethodRecipient(values)}
        >
          {({ values, isSubmitting, submitForm, handleChange, handleBlur, touched, errors }) => (
            <div className="mb-4 flex flex-col gap-6">
              <TextField
                label={`${displayType} user ID (phone number or e-mail)`}
                placeholder={`${displayType} user ID`}
                name="email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors?.email}
                helperText={touched.email && errors?.email}
              />
              <TextField
                label={
                  <div>
                    Nickname <span className="text-secondary text-sm">(optional)</span>
                  </div>
                }
                name="nickname"
                placeholder={`${displayType} user nickname`}
                value={values?.nickname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <LoadingButton
                color="primary"
                fullWidth
                loading={isSubmitting}
                onClick={submitForm}
                startIcon={<FontAwesomeIcon icon={faPlus} />}
              >
                <span>Add {displayType} account</span>
              </LoadingButton>
            </div>
          )}
        </Formik>
      </ModalBodyContent>
    </Modal>
  );
};
