import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductBanner } from './Designs/ProductBanner';

export default function GetPremiumBanner(): ReactElement | null {
  const navigate = useNavigate();
  const { hideAtoBPremiumBanner } = useThemeConfiguration();

  if (hideAtoBPremiumBanner) {
    return null;
  }

  return (
    <ProductBanner
      actionText="Get Premium"
      bannerKey="integrations-transactions-premium"
      variant="premium"
      onClick={() => {
        navigate('/premium');
      }}
      icon={faStar}
    >
      Upgrade to AtoB Premium and automate industry-leading checks against fuel theft.
    </ProductBanner>
  );
}
