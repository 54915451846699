import Card from '@app/components/Onboarding/Card';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import {
  faArrowRightArrowLeft,
  faGasPump,
  faShieldCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingWhatCanIDoWithMyAtoBAccount() {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header title="What can I do with my AtoB account?" />
      <div className="flex w-full flex-col gap-4">
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-4 lg:grid-cols-3">
          <Card icon={faGasPump} title="You can buy fuel" descriptions={['']} />
          <Card
            icon={faArrowRightArrowLeft}
            title="Make non-fuel vehicle related expenses"
            descriptions={['']}
          />
          <Card icon={faShieldCheck} title="Pay your drivers" descriptions={['']} />
        </div>
      </div>
      <FooterActionButtons hideBackButton handleClickNext={goToNextStep} />
    </div>
  );
}
