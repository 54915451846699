import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import { ReactElement } from 'react';

export default function Perks(): ReactElement | null {
  const isUF = useIsUberfreight(true);

  const perksIframeUrl = isUF
    ? 'https://www.atob.com/atob-uber-freight-perks'
    : 'https://www.atob.com/atob-card-perks';

  return (
    <PageContentWrapper
      baseClassName="bg-transparent"
      header={<Header title={'Discounts Catalog'} />}
    >
      <iframe
        title="Atob Perks"
        className="min-h-screen-minus-100 sm:min-h-screen-minus-124 md:h-full"
        src={perksIframeUrl}
      />
    </PageContentWrapper>
  );
}
