import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import { getCurrencyFromCents } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { TransferSummary } from '../TransferSummary';
import {
  computeEstimatedReceptionTime,
  computeTotalFeeAmount,
  FeeConfiguration,
  NormalizedDestination,
  PaymentMethodType,
  transferTypeMapping,
} from '../utils';

export const ConfirmationModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
  onConfirm,
  loading,
  method,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee: FeeConfiguration;
  description: string;
  onConfirm: () => void;
  loading: boolean;
  method: PaymentMethodType;
}) => {
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = getCurrencyFromCents(
    computeTotalFeeAmount({ transferAmountCents, feeData: fee }),
  );

  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader title="Review Transfer" />
      <ModalBodyContent>
        <div className="mb-4 mt-2 flex flex-col gap-6">
          <TransferSummary
            transferType={transferTypeMapping[method]}
            arrivalTime={formatDate({
              dateValue: computeEstimatedReceptionTime(fee.type),
              pattern: 'MMMM D, YYYY',
            })}
            recipientName={destination.recipientName}
            feePercentage={fee.percentage}
            feeAmount={feeAmount}
            description={description}
            amount={transferAmountCents}
          />
          <div className="flex flex-col gap-3 md:flex-row">
            <Button color="secondary" fullWidth onClick={onClose}>
              Back to edit
            </Button>
            <LoadingButton color="primary" fullWidth onClick={onConfirm} loading={loading}>
              <span>Transfer now</span>
            </LoadingButton>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
