import Header from '@app/components/Navigation/Header';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import {
  ChargeEventQueryParams,
  ChargeEventsContext,
  DEFAULT_STATE,
} from '@app/contexts/ChargeEventsContextComponent';
import { useSortReducer } from '@app/hooks/paging/useSortReducer';
import { Tooltip } from '@atob-developers/shared/src/components/Tooltip';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { debounce, isEqual, pick } from 'lodash-es';
import { ReactElement, useCallback, useContext, useMemo, useRef } from 'react';
import SuspiciousActivitiesCardSearch from './SuspiciousActivitiesCardSearch';
import SuspiciousActivitiesPageContent from './SuspiciousActivitiesPageContent';

const monitoredKeys = [
  'by_tag_ids',
  'filter_authorized_at[from]',
  'filter_authorized_at[to]',
  'by_vehicle_ids',
  'by_driver_ids',
  'by_suspicious_reasons',
];

export default function SuspiciousActivitiesPage({
  resourceTagsEnabled,
}: {
  resourceTagsEnabled: boolean;
}): ReactElement {
  const { sort, toggleSort } = useSortReducer();
  const { queryParams, setQueryParams } = useContext(ChargeEventsContext);

  const setSelectedFilters = useCallback(
    (params: Partial<ChargeEventQueryParams>) => {
      setQueryParams((p) => ({ ...p, ...params, page: 1 }));
    },
    [setQueryParams],
  );

  const clearFilters = useCallback(() => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      'by_tag_ids': [],
      'filter_authorized_at[from]': null,
      'filter_authorized_at[to]': null,
      'by_vehicle_ids': [],
      'by_driver_ids': [],
      'by_suspicious_reasons': [],
    }));
  }, [setQueryParams]);

  const showClearFilters = useMemo(() => {
    const defaultMonitoredFields = pick(DEFAULT_STATE, monitoredKeys);
    const queryMonitoredFields = pick(queryParams, monitoredKeys);
    return !isEqual(defaultMonitoredFields, queryMonitoredFields);
  }, [queryParams]);

  return (
    <div className="flex h-full flex-col">
      <PageContentWrapper
        header={
          <SuspiciousActivitiesHeader
            cardLastFourSearch={queryParams.like_card_number_last_four ?? ''}
            onSearchByCardLastFour={(cardSearchLastFour) =>
              setSelectedFilters({
                like_card_number_last_four: cardSearchLastFour,
              })
            }
          />
        }
      >
        <SuspiciousActivitiesPageContent
          setCurrentPage={(page) => setQueryParams({ ...queryParams, page })}
          sort={sort}
          toggleSort={toggleSort}
          resourceTagsEnabled={resourceTagsEnabled}
          filters={queryParams}
          onSetFilters={setSelectedFilters}
          clearFilters={showClearFilters ? clearFilters : undefined}
        />
      </PageContentWrapper>
    </div>
  );
}

const pageContext =
  'These transactions have been flagged as potentially suspicious due to the location of where ' +
  'the charge was made in relation to the geolocation of all the trucks in your fleet.';

const SuspiciousActivitiesHeader = ({
  cardLastFourSearch,
  onSearchByCardLastFour,
}: {
  cardLastFourSearch: string;
  onSearchByCardLastFour: (key: string) => void;
}) => {
  const header = (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-2">
        <div>Suspicious Activities</div>
        <Tooltip icon={faQuestionCircle} title={pageContext} arrow />
      </div>
    </div>
  );

  const debouncedCardLastFourSearch = useRef(
    debounce((search) => onSearchByCardLastFour(search), 250),
  );

  return (
    <Header
      title={header}
      rightContent={
        <div className="ml-auto flex items-center gap-4">
          <SuspiciousActivitiesCardSearch
            search={cardLastFourSearch}
            onSearch={debouncedCardLastFourSearch.current}
          />
        </div>
      }
    />
  );
};

export const Error = (): ReactElement => <div>There was an issue loading this data</div>;
