import { CustomerData } from '@app/@types/customer.types';
import {
  ChangeAddressValue,
  CustomerChangeRequest,
  CustomerSettingsAPIWrapped,
} from '@app/@types/customer_settings.types';
import PreemptiveOTPValidator from '@app/app/PreemptiveOTPValidator';
import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { CUSTOMER_INCLUDES } from '@app/hooks/query/useCustomerQuery';
import { IAddressProps } from '@app/pages/Settings/SettingsPageRow';
import logger from '@app/utils/datadog-logger';
import { validateSettingsForm } from '@app/utils/validation/yup-validation';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import React, { ReactElement, useState } from 'react';
import { AddressForm } from './FormViews/AddressForm';
import { EmailPhoneForm } from './FormViews/EmailPhoneForm';
import { FleetTypeForm } from './FormViews/FleetTypeForm';
import TelematicsNotificationsForm from './FormViews/TelematicsNotificationsForm';

interface UpdateSettingsModalProps {
  open: boolean;
  toggle: () => void;
  type: SettingsPageEntityType;
  value: string | IAddressProps;
  setUpdateInfoPrompt: (type: SettingsPageEntityType) => void;
  setCustomer: (customer: CustomerData) => void;
  customer: CustomerData;
}

interface ModalFormProps {
  type: SettingsPageEntityType;
  values: Record<string, string | null>;
  errors: Record<string, string | undefined>;
  setValues: (values: Record<string, string | null>) => void;
  setErrors: (erros: Record<string, string | undefined>) => void;
  value: string | IAddressProps | boolean;
  customer: CustomerData;
  onClose: () => void;
}

const SettingsModalForm = ({
  type,
  values,
  errors,
  setValues,
  setErrors,
  value,
  onClose,
}: ModalFormProps) => {
  switch (type) {
    case SettingsPageEntityType.EMAIL_ADDRESS:
    case SettingsPageEntityType.PHONE_NUMBER:
    case SettingsPageEntityType.OTP_BACKUP_EMAIL:
      return (
        <PreemptiveOTPValidator onCloseWithoutVerification={onClose}>
          <EmailPhoneForm
            type={type}
            values={values}
            errors={errors}
            setValues={setValues}
            setErrors={setErrors}
            currentValue={value as string}
          />
        </PreemptiveOTPValidator>
      );
    case SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME:
      return (
        <EmailPhoneForm
          type={type}
          values={values}
          errors={errors}
          setValues={setValues}
          setErrors={setErrors}
          currentValue={value as string}
        />
      );
    case SettingsPageEntityType.COMPANY_ADDRESS:
    case SettingsPageEntityType.CARD_SHIPPING_ADDRESS:
      return (
        <PreemptiveOTPValidator onCloseWithoutVerification={onClose}>
          <AddressForm
            type={type}
            values={values}
            errors={errors}
            setValues={setValues}
            setErrors={setErrors}
            currentAddress={value as IAddressProps}
          />
        </PreemptiveOTPValidator>
      );
    case SettingsPageEntityType.TELEMATICS_NOTIFICATIONS_ENABLED:
      return <TelematicsNotificationsForm value={value as boolean} />;
    case SettingsPageEntityType.FLEET_TYPE:
      return (
        <FleetTypeForm
          values={values}
          errors={errors}
          setValues={setValues}
          setErrors={setErrors}
          currentValue={value as string}
        />
      );
    default:
      return null;
  }
};

const UpdateSettingsModal = ({
  open,
  toggle,
  type,
  value,
  setUpdateInfoPrompt,
  setCustomer,
  customer,
}: UpdateSettingsModalProps): ReactElement => {
  const [values, setValues] = useState<Record<string, string | null>>({});
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const isAddress =
    type === SettingsPageEntityType.COMPANY_ADDRESS ||
    type === SettingsPageEntityType.CARD_SHIPPING_ADDRESS;

  const getChangeRequestValues = (): CustomerChangeRequest => {
    if (type === SettingsPageEntityType.FLEET_TYPE) {
      throw new Error('Fleet type is not a supported change request type');
    }

    let changeRequest: CustomerChangeRequest = { change_request_type: type };
    if (isAddress) {
      changeRequest = {
        ...changeRequest,
        address_value: values as ChangeAddressValue,
      };
    } else {
      let newValue;
      if (type === SettingsPageEntityType.EMAIL_ADDRESS) {
        newValue = values['email'];
      } else if (type === SettingsPageEntityType.OTP_BACKUP_EMAIL) {
        newValue = values[SettingsPageEntityType.OTP_BACKUP_EMAIL] ?? null;
      } else if (type === SettingsPageEntityType.PHONE_NUMBER) {
        newValue = values['phone'] && values['phone'].replace(/[() -]/g, '');
      } else {
        newValue = values['company_preferred_name'];
      }

      changeRequest = {
        ...changeRequest,
        new_value: newValue,
      };
    }

    return changeRequest;
  };

  const submitChangeRequest = () => {
    const changeRequest = getChangeRequestValues();
    axios
      .post<CustomerSettingsAPIWrapped>(
        '/customer/change_request',
        {
          change_request: changeRequest,
        },
        {
          params: {
            include: CUSTOMER_INCLUDES,
          },
        },
      )
      .then((res) => {
        const deserialized = deserialize(res.data);
        if (deserialized.message != null) {
          return;
        }
        const customer = deserialize(res.data).data as CustomerData;
        setUpdateInfoPrompt(type);
        setCustomer(customer);
      })
      .finally(() => setUpdateInfoPrompt(type));
  };

  const submitCustomerUpdate = () => {
    axios
      .put(
        '/customer',
        {
          customer: {
            ...values,
          },
        },
        {
          params: {
            include: CUSTOMER_INCLUDES,
          },
        },
      )
      .then((res) => {
        const customer = deserialize(res.data).data as CustomerData;
        setUpdateInfoPrompt(type);
        setCustomer(customer);
      })
      .catch((err) => {
        logger.error('Error trying to update fleet type', { error: err });
      });
  };

  const updateSettings = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await validateSettingsForm(values, type).then((err) => {
      if (Object.keys(err)?.length > 0) {
        setErrors(err);
      } else if (type === SettingsPageEntityType.FLEET_TYPE) {
        submitCustomerUpdate();
        toggle();
      } else {
        submitChangeRequest();
        toggle();
      }
    });
  };

  return (
    <Modal open={open} toggle={toggle}>
      <form onSubmit={updateSettings}>
        <ModalHeader title={SETTINGS_ROW_OPTIONS[type].title} onClose={toggle} />
        <ModalBodyContent overflowVisible={isAddress}>
          <SettingsModalForm
            type={type}
            values={values}
            errors={errors}
            setValues={setValues}
            setErrors={setErrors}
            value={value}
            customer={customer}
            onClose={toggle}
          />
        </ModalBodyContent>
        <ModalFooter>
          <div className="flex flex-col-reverse gap-3 pt-2 sm:flex-row sm:justify-end ">
            <Button color="secondary" size="small" onClick={toggle}>
              Cancel
            </Button>
            {type !== SettingsPageEntityType.TELEMATICS_NOTIFICATIONS_ENABLED && (
              <Button size="small" type="submit">
                Save
              </Button>
            )}
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UpdateSettingsModal;
