import ErrorNotification from '@app/components/layout/ErrorNotification';
import {
  DayOfWeek,
  OperationalPeriodsData,
  daysOfWeek,
  setOperationalPeriods,
  useOperationalPeriods,
} from '@app/hooks/query/useOperationalPeriods';
import Card, { CardHeader, CardSection } from '@atob-developers/shared/src/components/Card';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faMoon } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, FormControl, FormControlLabel, Switch } from '@mui/material';
import axios from 'axios';
import { Formik, FormikProps } from 'formik';
import { ReactElement } from 'react';
import * as Yup from 'yup';
import { TimeSelect } from './TimeSelect';

export const MENU_MAX_HEIGHT = '300px';
export const FULL_DAY_VALUE = -1;
export const TIME_INTERVAL_STEP = 30;
export const MAXIMUM_INTERVALS_FOR_END = 48; // 24 hours * 2 intervals per hour
export const MAXIMUM_INTERVALS = 47; // Excludes the last interval so that a non-empty interval can be selected
export const BLOCKED_START_END_VALUE = [0, 0];

const daySchema = Yup.array().of(
  Yup.number()
    .min(0)
    .max(24 * 60 + TIME_INTERVAL_STEP),
);

const validationSchema = Yup.object().shape({
  monday: daySchema,
  tuesday: daySchema,
  wednesday: daySchema,
  thursday: daySchema,
  friday: daySchema,
  saturday: daySchema,
  sunday: daySchema,
});

export const OperationalPeriods = ({
  resourceType,
  resourceId,
}: {
  resourceType: 'Customer' | 'Tag';
  resourceId?: number;
}): ReactElement => {
  const { data, isLoading, error } = useOperationalPeriods(resourceType, resourceId);

  if (error) return <ErrorNotification error={error} />;

  const operationalPeriods = data?.data;

  return (
    <Card noPadding>
      <CardSection>
        <CardHeader
          title="Operational Hours"
          description="Transactions outside the operational hours will be blocked. (Times in PST)"
        />
      </CardSection>
      <CardSection>
        {isLoading || !operationalPeriods ? (
          <div data-testid="spinner">
            <CircularProgress />
          </div>
        ) : (
          <OperationalPeriodsForm
            resourceId={resourceId}
            resourceType={resourceType}
            operationalPeriods={operationalPeriods}
          />
        )}
      </CardSection>
    </Card>
  );
};

export const OperationalPeriodsForm = ({
  operationalPeriods,
  resourceId,
  resourceType,
}: {
  operationalPeriods: OperationalPeriodsData;
  resourceId?: number;
  resourceType: 'Customer' | 'Tag';
}): ReactElement => {
  const { addToast } = useToasts();
  return (
    <Formik
      initialValues={operationalPeriods}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await setOperationalPeriods(resourceType, values, resourceId);
          resetForm({ values });
          addToast('Operational hours saved', { appearance: 'success' });
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (e.response && e.response.data && e.response.data.message) {
              addToast(e.response.data.message, { appearance: 'error' });
            } else {
              addToast(e.message, { appearance: 'error' });
            }
          } else {
            addToast('An unknown error occurred', { appearance: 'error' });
          }

          resetForm();
        }
        setSubmitting(false);
      }}
    >
      {(formik) => (
        <div>
          <div className="flex w-full max-w-[720px] flex-col gap-4 px-4">
            {daysOfWeek.map((day) => (
              <OperationalPeriod dayLabel={day} formik={formik} key={day} />
            ))}
          </div>
          <div className="mt-8 flex justify-end">
            <LoadingButton
              size="small"
              loading={formik.isSubmitting}
              onClick={() => {
                formik.submitForm();
              }}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      )}
    </Formik>
  );
};

enum OperationalPeriodState {
  Blocked,
  Allowed,
  Sometimes,
}

export const OperationalPeriod = ({
  dayLabel,
  formik,
}: {
  dayLabel: string;
  formik: FormikProps<OperationalPeriodsData>;
}): ReactElement => {
  const day = dayLabel.toLowerCase() as DayOfWeek;

  const values = formik.values?.[day];
  const [currentStart, currentEnd] = values || [undefined, undefined];
  let state: OperationalPeriodState;

  if (values === undefined) {
    state = OperationalPeriodState.Allowed;
  } else if (currentStart === 0 && currentEnd === 0) {
    state = OperationalPeriodState.Blocked;
  } else {
    state = OperationalPeriodState.Sometimes;
  }

  return (
    <div key={dayLabel} className="grid h-16 grid-cols-3 items-center gap-4">
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={state !== OperationalPeriodState.Blocked}
              onChange={() => {
                if (state !== OperationalPeriodState.Blocked) {
                  formik.setFieldValue(day, BLOCKED_START_END_VALUE);
                } else {
                  formik.setFieldValue(day, undefined);
                }
              }}
            />
          }
          label={<div className="p-4 text-sm">{dayLabel}</div>}
        />
      </FormControl>

      {state === OperationalPeriodState.Blocked && (
        <div className="col-span-2 flex h-full items-center rounded-md bg-gray-100 p-2 pl-4 text-gray-500">
          <FontAwesomeIcon icon={faMoon} className="mr-2" />
          <div className="text-sm">Disabled (All transactions will be blocked)</div>
        </div>
      )}
      {state !== OperationalPeriodState.Blocked && (
        <TimeSelect
          day={day}
          dayLabel={dayLabel}
          label={'Start'}
          value={currentStart !== undefined ? currentStart : FULL_DAY_VALUE}
          onChange={(event) => {
            if (event.target.value === FULL_DAY_VALUE) {
              formik.setFieldValue(day, undefined);
              return;
            }

            let newEnd = currentEnd;
            if (currentEnd === undefined || currentEnd <= parseInt(event.target.value as string)) {
              newEnd = parseInt(event.target.value as string) + TIME_INTERVAL_STEP;
            }

            formik.setFieldValue(day, [event.target.value, newEnd]);
          }}
        />
      )}

      {state !== OperationalPeriodState.Blocked && (
        <TimeSelect
          day={day}
          dayLabel={dayLabel}
          label={'End'}
          maximum={MAXIMUM_INTERVALS_FOR_END}
          disabled={state === OperationalPeriodState.Allowed}
          value={state === OperationalPeriodState.Sometimes ? currentEnd : ''}
          minimum={state === OperationalPeriodState.Sometimes ? currentStart : undefined}
          onChange={(event) => {
            if (event.target.value === FULL_DAY_VALUE) {
              formik.setFieldValue(day, undefined);
              return;
            }

            formik.setFieldValue(day, [currentStart, event.target.value]);
          }}
        />
      )}
    </div>
  );
};
