import { FinancialAccountData } from '@app/@types/payments.types';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import AmountItem from '@app/components/PaymentModal/components/AmountItem';
import EditPaymentFooter from '@app/components/PaymentModal/components/EditPaymentFooter';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import Divider from '@atob-developers/shared/src/components/Divider';
import { convertNumbersWithCurrency } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { ReactElement, useState } from 'react';

interface WalletEditPaymentDetailsProps {
  closeModal: () => void;
  onNext: () => void;
  paymentAmount: string;
  setPaymentAmount: (value: string) => void;
  walletData: FinancialAccountData;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const WalletEditPaymentDetails = ({
  onNext,
  closeModal,
  paymentAmount,
  setPaymentAmount,
  walletData,
  showAmountDue,
  setShowAmountDue,
}: WalletEditPaymentDetailsProps): ReactElement | null => {
  const [error, setError] = useState<string | null>(null);

  const handleNextClick = () => {
    const inputAmountInCents = convertNumbersWithCurrency(paymentAmount) * 100;
    const walletAmountInCents = convertNumbersWithCurrency(walletData.balance) * 100;
    if (inputAmountInCents > walletAmountInCents) {
      setError('Not enough funds.');
    } else {
      onNext();
    }
  };

  return (
    <>
      <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
        <div className="flex flex-col gap-4">
          <PaymentDetailsHeaderText>Payment Details</PaymentDetailsHeaderText>
          <div className="flex flex-col gap-4">
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Wallet Account">
              Balance: {walletData.balance}
            </PaymentDetailItem>
            <Divider className="bg-gray-200" />
            <AmountItem
              amount={paymentAmount}
              setAmount={setPaymentAmount}
              setError={setError}
              error={error}
              showAmountDue={showAmountDue}
              setShowAmountDue={setShowAmountDue}
            />
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Est. Processing Date">Instant</PaymentDetailItem>
          </div>
        </div>
      </div>
      <EditPaymentFooter onCancel={closeModal} onNext={handleNextClick} />
    </>
  );
};

export default WalletEditPaymentDetails;
