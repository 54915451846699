import { CircularProgress } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { Fragment } from 'react';
import Pagination from '../Pagination/Pagination';
import { ResponsiveTableProps } from './ResponsiveTable';

export default function MobileListView<T extends GridValidRowModel>({
  data,
  mobileItemRenderer,
  onRowClick,
  onPageChange,
  paginationMeta,
  loading,
  pageSize,
}: ResponsiveTableProps<T>) {
  if (!loading && paginationMeta?.pagination?.total_pages === 0) {
    return <div className="text-secondary m-8 flex justify-center text-sm">Nothing to show</div>;
  }

  if (loading) {
    return (
      <div className="m-4 flex justify-center" data-testid="spinner">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        {data?.map((rowData, rowIndex) => (
          <Fragment key={rowIndex}>
            {mobileItemRenderer({ row: rowData, onClick: () => onRowClick?.(rowData) })}
          </Fragment>
        ))}
      </div>
      <Pagination pageSize={pageSize} paginationMeta={paginationMeta} onPageChange={onPageChange} />
    </div>
  );
}
