import { useOnboardingGuide } from '@app/pages/Onboarding/useOnboardingGuide';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  resendMessage: () => void;
  isDisabled?: boolean;
};

export default function CheckYourPhoneModal({ isOpen, onClose, resendMessage, isDisabled }: Props) {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <Modal open={isOpen}>
      <ModalHeader
        title="Check your phone for a text"
        onClose={onClose}
        className="justify-center"
        headerImage={<img src="images/onboarding/phone-check-header.png" />}
      >
        <p className="text-secondary pt-1.5 text-center text-base font-normal">
          Download and sign in to the AtoB app. Then simply follow the app prompts. You can also do
          it after finishing the account setup.
        </p>
      </ModalHeader>
      <ModalBodyContent>
        <Alert
          severity="info"
          title="Didn’t receive a message? Try again."
          primaryActionText="Resend message"
          primaryAction={resendMessage}
        />
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton loading={isDisabled} onClick={goToNextStep} className="w-full">
          Done
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
