import { useOnboardingGuide } from '@app/pages/Onboarding/useOnboardingGuide';
import Modal, { ModalFooter, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function DriverInvitationConfirmationModal({ isOpen, onClose }: Props) {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <Modal open={isOpen}>
      <ModalHeader title="" onClose={onClose}>
        <div className="flex justify-center">
          <img height={88} width={88} src="images/onboarding/checkmark.png" />
        </div>
        <p className="pt-6 text-center text-2xl font-semibold tracking-tight">
          Invitations have been sent
        </p>
        <p className="text-secondary pb-6 pt-1.5 text-center text-base font-normal">
          Ask your Driver to download the app.
        </p>
      </ModalHeader>
      <ModalFooter>
        <Button onClick={goToNextStep} className="w-full">
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}
