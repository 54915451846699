import PrepaidActivationPromotionBanner from '@app/components/Banner/PrepaidActivationPromotionBanner';
import TopLevelPrepaidMigrationBanner from '@app/components/Banner/TopLevelPrepaidMigrationBanner';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import PrepaidUnlimitedToFlexBanner from './PrepaidUnlimitedToFlexBanner';
import PrepaidUnlimitedToFlexV2Banner from './PrepaidUnlimitedToFlexV2Banner';

function usePrepaidBannerDetails(): {
  shouldShowPrepaidMigrationBanner: boolean;
  shouldShowPrepaidActivationPromo: boolean;
  shouldShowUnlimitedToFlexBanner: boolean;
  shouldShowUnlimitedToFlexV2Banner: boolean;
} {
  const [
    hasWallet,
    hasBilling,
    shouldShowPrepaidActivationPromo,
    shouldShowUnlimitedToFlexBanner,
    shouldShowUnlimitedToFlexV2Banner,
  ] = useProduct(
    'treasury',
    'billing',
    'unlimited_activation_retention_promo',
    'unlimited_to_flex_promo',
    'unlimited_to_flex_promo_v2',
  );
  const [prepaidPending, prepaidOptIn, prepaidMigration, prepaidChurned] = useFeatureFlags(
    'prepaid_pending',
    'prepaid_opt_in',
    'prepaid_migration',
    'prepaid_churned',
  );

  const shouldShowPrepaidMigrationBanner =
    !prepaidOptIn &&
    hasWallet &&
    (prepaidPending || prepaidMigration || prepaidChurned) &&
    hasBilling;

  return {
    shouldShowPrepaidMigrationBanner,
    shouldShowPrepaidActivationPromo,
    shouldShowUnlimitedToFlexBanner,
    shouldShowUnlimitedToFlexV2Banner,
  };
}

export default function PrepaidBanners(): ReactElement | null {
  const {
    shouldShowPrepaidMigrationBanner,
    shouldShowPrepaidActivationPromo,
    shouldShowUnlimitedToFlexBanner,
    shouldShowUnlimitedToFlexV2Banner,
  } = usePrepaidBannerDetails();
  const location = useLocation();
  const customer = useCustomer();
  const [prepaid] = useProduct('prepaid');

  if (['/unlimited', '/premium'].includes(location.pathname)) {
    return null;
  }

  if (shouldShowPrepaidMigrationBanner) {
    return (
      <TopLevelPrepaidMigrationBanner
        treasury={customer.treasury}
        companyName={customer.company_name}
        companyAddress={customer.company_address}
        isPrepaid={prepaid}
      />
    );
  }

  if (shouldShowPrepaidActivationPromo) {
    return <PrepaidActivationPromotionBanner />;
  }

  if (shouldShowUnlimitedToFlexV2Banner) {
    return <PrepaidUnlimitedToFlexV2Banner customer={customer} />;
  }

  if (shouldShowUnlimitedToFlexBanner) {
    return <PrepaidUnlimitedToFlexBanner />;
  }

  return null;
}
