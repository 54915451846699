import { EndpointResponse } from '@app/@types/api.types';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useUser from '@app/hooks/useUser';
import { FetcherKey, apiPutFetcher } from '@app/utils/data/fetchers';
import { faSunBright, faSunrise } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { ReactElement, ReactNode, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import OnboardingForm from './OnboardingForm';
import OnboardingRightUpsell from './OnboardingRightUpsell';

import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingAppNotifStep(): ReactElement | null {
  const { goToNextStep } = useOnboardingGuide();
  const { user } = useUser();
  const [selected, setSelected] = useState<'morning' | 'afternoon' | 'evening' | null>(null);
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent({
    reloadCustomer: false,
  });
  const { trigger, isMutating } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey,
    {
      app_notif_setting: { time: 'morning' | 'afternoon' | 'evening' };
    }
  >(
    {
      url: `/users/${user?.id}/set_app_notification_setting`,
    },
    apiPutFetcher,
  );
  return (
    <OnboardingForm>
      <h1 className="text-2xl font-semibold">Get daily notifications on the best fuel discounts</h1>
      <p className="text-base font-normal">When do you usually fuel your vehicle?</p>
      <NotificationItem
        icon={<FontAwesomeIcon icon={faSunrise} />}
        onClick={() => setSelected('morning')}
        selected={selected == 'morning'}
        disabled={isMutating}
      >
        Morning
      </NotificationItem>
      <NotificationItem
        icon={<FontAwesomeIcon icon={faSunBright} />}
        onClick={() => setSelected('afternoon')}
        selected={selected == 'afternoon'}
        disabled={isMutating}
      >
        Afternoon
      </NotificationItem>
      <NotificationItem
        icon={<FontAwesomeIcon icon={faSunBright} />}
        onClick={() => setSelected('evening')}
        selected={selected == 'evening'}
        disabled={isMutating}
      >
        Evening
      </NotificationItem>
      {selected != null && (
        <Button
          color="primary"
          disabled={isMutating}
          onClick={async () => {
            await trigger({
              app_notif_setting: {
                time: selected,
              },
            });
            createCustomerOnboardingEvent({
              customer_onboarding_event: {
                name: CustomerOnboardingEventName.CUSTOMER_APP_NOTIFICATION_SELECTED,
              },
            });
            goToNextStep();
          }}
        >
          Confirm Preference
        </Button>
      )}
    </OnboardingForm>
  );
}

function NotificationItem({
  icon,
  onClick,
  selected,
  children,
  disabled,
}: {
  icon: ReactNode;
  onClick: () => void;
  selected: boolean;
  children: ReactNode;
  disabled: boolean;
}): ReactElement {
  return (
    <button
      role="button"
      className={classNames(
        'flex cursor-pointer items-center gap-6 rounded-md  px-6 py-4 font-semibold',
        selected ? 'border-2 border-black' : 'border border-gray-300',
        disabled && 'cursor-not-allowed',
      )}
      onClick={onClick}
    >
      {icon}
      {children}
    </button>
  );
}

export function OnboardingAppNotifUpsell(): ReactElement {
  return (
    <OnboardingRightUpsell>
      <img src="/images/fleet-manager-promo-notifs.png" height={574} width={506} />
    </OnboardingRightUpsell>
  );
}
