import { MenuItem, Select, TextField, InputLabel, ListItemText } from '@mui/material';
import { ReactElement } from 'react';
import AssignDriver from '../AssignEntity/AssignDriver';
import AssignVehicle from '../AssignEntity/AssignVehicle';
import PhoneInput from '../Inputs/PhoneInput';
import DefaultPrompt from '../Prompt/DefaultPrompt';
import { DeviceReducer } from './useDeviceFormReducer';

const ASSIGNEE_ITEMS = [
  { id: '', value: '', label: 'None' },
  { id: 'Driver', value: 'Driver', label: 'Driver' },
  { id: 'Vehicle', value: 'Vehicle', label: 'Vehicle' },
];

type DeviceFormProps = {
  reducer: DeviceReducer;
};

export default function DeviceForm({ reducer }: DeviceFormProps): ReactElement {
  const { device, setErrors, setDeviceProperty } = reducer;

  if (!reducer.editing) {
    return (
      <div className="flex flex-col gap-8">
        <div className="space-y-2 text-sm font-medium">
          <p className="text-secondary">Name</p>
          <p className="text-primary">{device.name || '-'}</p>
        </div>
        <div className="space-y-2 text-sm font-medium">
          <p className="text-secondary">Phone Number</p>
          <p className="text-primary">{device.phone_number || '-'}</p>
        </div>
        <div className="space-y-2 text-sm font-medium">
          <p className="text-secondary">Assignment type</p>
          <p className="text-primary">{device.assignee_type || '-'}</p>
        </div>
        {device.assignee_name && (
          <div className="space-y-2 text-sm font-medium">
            <p className="text-secondary">Assigned to</p>
            <p className="text-primary">{device.assignee_name || '-'}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      {device.errors?.map((error, idx) => (
        <DefaultPrompt
          key={idx}
          message={error}
          clickHandler={() => {
            const errors = device.errors;
            errors.splice(idx, 1);
            setErrors(errors);
          }}
          error
        />
      ))}
      <TextField
        fullWidth
        required
        label="Name"
        placeholder="Name"
        size="small"
        value={device.name ?? ''}
        onChange={(e) => setDeviceProperty('name', e.target.value)}
        inputProps={{ 'data-testid': `input:name` }}
      />
      <div>
        <PhoneInput
          fullWidth
          required
          label="Phone Number"
          placeholder="Phone Number"
          size="small"
          value={device.phone_number ?? ''}
          onChange={(e) => setDeviceProperty('phone_number', e.target.value)}
          inputProps={{ 'data-testid': `input:phone_number` }}
        />
      </div>
      <div className="space-y-2">
        <InputLabel required id="input:assignee-type" className="text-primary text-sm font-medium">
          Assignment type
        </InputLabel>
        <Select
          fullWidth
          displayEmpty
          labelId="input:assignee-type"
          label="Assignment type"
          size="small"
          value={device?.assignee_type ?? ''}
          onChange={(e) => {
            if (e.target.value) {
              setDeviceProperty('assignee_type', e.target.value);
              setDeviceProperty('assignee_id', null);
            }
          }}
          renderValue={(value) => {
            const item = ASSIGNEE_ITEMS.find((item) => item.id === value);
            if (!item) {
              return <span className="text-secondary">Select Assignment Type</span>;
            }

            return item.label;
          }}
        >
          {ASSIGNEE_ITEMS.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </div>
      {device.assignee_type && (
        <div className="space-y-2">
          <InputLabel className="text-primary text-sm font-medium">Assigned to</InputLabel>
          {device?.assignee_type === 'Driver' && (
            <AssignDriver
              driverId={device?.assignee_id || null}
              onChange={(driverId) => driverId && setDeviceProperty('assignee_id', driverId)}
            />
          )}
          {device?.assignee_type === 'Vehicle' && (
            <AssignVehicle
              vehicleId={device?.assignee_id || null}
              onChange={(vehicleId) => vehicleId && setDeviceProperty('assignee_id', vehicleId)}
            />
          )}
        </div>
      )}
    </div>
  );
}
