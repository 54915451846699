import classNames from 'classnames';
import { ReactElement } from 'react';

export const Cell = ({ children }: { children: React.ReactNode }): ReactElement => (
  <div className="truncate">{children}</div>
);

/* CellDetail can be used to show a secondary line of text in a cell */
export const CellDetail = ({ children }: { children: React.ReactNode }): ReactElement => (
  <div className="truncate text-gray-400">{children}</div>
);

export const CellPlaceholder = ({ disabled }: { disabled?: boolean }) => (
  <p
    className={classNames(
      'text-xs font-medium leading-5 md:leading-4',
      disabled ? 'text-tertiary' : 'text-primary',
    )}
  >
    --
  </p>
);
