import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button, useMediaQuery, useTheme, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SavingsPoint = ({ point, subpoint }: { point: string; subpoint: string }): JSX.Element => {
  return (
    <>
      <div className="text-md mb-1 mt-2 font-semibold text-gray-900">{point}</div>
      <div className="mb-4 text-sm font-normal leading-normal">{subpoint}</div>
    </>
  );
};

const AtoBSavingsPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}): JSX.Element | null => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Modal open={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader
          title=""
          onClose={() => setIsOpen(false)}
          headerImage={
            <img
              src={isNotMobile ? '/images/atob-savings.png' : '/images/atob-savings-mobile.png'}
            />
          }
          showHeaderImageWithoutPadding
        />
        <ModalBodyContent>
          <SavingsPoint
            point="1. See your savings performance over time"
            subpoint="At a glance, see how much you have saved"
          />
          <SavingsPoint
            point="2. Where you saved, where you missed"
            subpoint="Now, we'll show you if there was an opportunity nearby for you to save more"
          />
          <SavingsPoint
            point="3. Use the fuel map to see the best discounts"
            subpoint="See the best discounts nearby or along your route"
          />
        </ModalBodyContent>
        <ModalFooter>
          <div className="flex flex-row items-center justify-end gap-4">
            {isNotMobile && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Dismiss
              </Link>
            )}
            <Button
              size="small"
              className="mx-auto w-full sm:mx-0 sm:w-60"
              onClick={() => {
                navigate('/fuel-map');
              }}
            >
              View Savings Nearby
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AtoBSavingsPopup;
