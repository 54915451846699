import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export default function MWebToAppPopupView({
  deviceType,
  isOpen,
  setIsOpen,
}: {
  deviceType: 'android' | 'ios';
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}): ReactElement | null {
  return (
    <>
      {/*
        Need this to kickstart the image load b/c we don't want to
        show the popup until the images is loaded.
        But the modal doesn't render children unless it's open
        */}
      <img
        className="hidden"
        src={
          deviceType === 'android' ? '/images/app_promo/android.png' : 'images/app_promo/ios.png'
        }
        onLoad={() => setIsOpen(true)}
      />
      <Modal open={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader
          title=""
          onClose={() => setIsOpen(false)}
          headerImage={
            <img
              src={
                deviceType === 'android'
                  ? '/images/app_promo/android.png'
                  : 'images/app_promo/ios.png'
              }
            />
          }
          showHeaderImageWithoutPadding
        />
        <ModalBodyContent>
          <h2 className="mb-3 text-3xl font-semibold text-gray-900">Open the AtoB App</h2>
          <div className="text-sm font-normal leading-normal">
            Get the steepest fuel discounts – <strong>only in the AtoB app.</strong> Manage your
            account, view card balance, and much more.
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <div className="flex flex-1 flex-col">
            <Button color="primary" href="https://atob-app.app.link/" className="pb mb-3">
              Open AtoB App
            </Button>
            <Button onClick={() => setIsOpen(false)}>Maybe later</Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
