import { DeviceData } from '@app/@types/device.types';
import DeviceForm from '@app/components/Devices/DeviceForm';
import { DeviceReducer, useDeviceFormReducer } from '@app/components/Devices/useDeviceFormReducer';
import { apiPutFetcher } from '@app/utils/data/fetchers';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import ArchiveDeviceModal from './ArchiveDeviceModal';
import RestoreDeviceModal from './RestoreDeviceModal';

type DeviceSidebarProps = {
  device: DeviceData;
  hide: () => void;
  refresh: () => void;
};

export const DeviceSidebar = (props: DeviceSidebarProps): ReactElement => {
  const { device, hide } = props;
  const reducer = useDeviceFormReducer(device);
  const { setDevice, editing, setEditing } = reducer;

  useEffect(() => {
    if ((device?.id || null) !== reducer.device?.id) setDevice(device);
    // When there's no device, we close the sidebar. Set the editing mode to readonly.
    if (!device && editing) setEditing(false);
  }, [device, editing, setEditing, setDevice, reducer]);

  return (
    <SwipeableDrawer anchor="right" open={!!device} onOpen={() => {}} onClose={() => hide()}>
      <div className="w-96 px-4 pb-2 pt-6 sm:px-6">
        <div className="flex items-end justify-between">
          <div className="text-lg font-bold text-gray-700 md:text-xl">
            {editing ? 'Edit Device' : device.name}
          </div>
          <div className="ml-3 flex h-7 items-center ">
            <button
              type="button"
              className="rounded-full bg-gray-200 text-gray-500 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => hide()}
            >
              <span className="sr-only">Close panel</span>
              <div className="h-6 w-6">
                <FontAwesomeIcon icon={faX} />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-6">
            <div className="pb-8 pt-4">
              <DeviceForm {...{ reducer }} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-shrink-0 items-center justify-end gap-4 p-4 py-6">
        {editing ? (
          <DeviceEditFooter {...{ reducer, ...props }} />
        ) : (
          <DevicePreviewFooter {...{ reducer, ...props }} />
        )}
      </div>
    </SwipeableDrawer>
  );
};

type DeviceSidebarElementProps = DeviceSidebarProps & {
  reducer: DeviceReducer;
};

function DevicePreviewFooter({
  device,
  reducer,
  hide,
  refresh,
}: DeviceSidebarElementProps): ReactElement {
  const [archiveModalActive, setArchiveModalActive] = useState(false);
  const [restoreModalActive, setRestoreModalActive] = useState(false);
  const { toggleEditing } = reducer;

  const createReset = (setModalActive: (active: boolean) => void) => {
    return () => {
      setModalActive(false);
      hide();
      refresh();
    };
  };

  return (
    <>
      {!device?.readonly && device?.archived_at === null && (
        <Button size="small" onClick={() => toggleEditing()}>
          Edit Device
        </Button>
      )}
      {device && (
        <>
          <ArchiveDeviceModal
            isActive={archiveModalActive}
            reset={createReset(setArchiveModalActive)}
            device={device}
          />
          <RestoreDeviceModal
            isActive={restoreModalActive}
            reset={createReset(setRestoreModalActive)}
            device={device}
          />
        </>
      )}
      {!device?.readonly && (
        <ArchiveOrRestoreDeviceButton
          device={device}
          setArchiveModalActive={setArchiveModalActive}
          setRestoreModalActive={setRestoreModalActive}
        />
      )}
    </>
  );
}

function ArchiveOrRestoreDeviceButton({
  device,
  setArchiveModalActive,
}: {
  device: DeviceData;
  setArchiveModalActive: (state: boolean) => void;
  setRestoreModalActive: (state: boolean) => void;
}): ReactElement | null {
  if (device?.archived_at === null) {
    return (
      <Button color="secondary" size="small" onClick={() => setArchiveModalActive(true)}>
        Remove Device
      </Button>
    );
  } else {
    // We don't allow restoring the device
    // TODO(elchin): should probably clean up all the restore logic
    return null;
  }
}

function DeviceEditFooter({ refresh, hide, reducer }: DeviceSidebarElementProps) {
  const { device, setEditing, setErrors } = reducer;
  const { isMutating: isLoading, trigger } = useSWRMutation(
    {
      url: `/devices/${device.id}`,
    },
    apiPutFetcher,
    {
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          setErrors([e.response?.data.error]);
        }
      },
      onSuccess: () => {
        setErrors([]);
        setEditing(false);
        hide();
        refresh();
      },
    },
  );

  return (
    <>
      <Button color="alert" size="small" onClick={() => setEditing(false)}>
        Discard Changes
      </Button>
      <LoadingButton
        size="small"
        onClick={() =>
          trigger({
            device,
          })
        }
        loading={isLoading}
      >
        Save Changes
      </LoadingButton>
    </>
  );
}
