import { ReactivateCesOrUnlimitedParams } from '@app/components/Modals/CardModals/ReactivateCesOrUnlimitedOfferModal';
import Checkbox from '@atob-developers/shared/src/components/Checkbox';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export const CesDetails = ({
  params,
  setParams,
  onNext,
  onBack,
  closeModal,
}: {
  params: ReactivateCesOrUnlimitedParams;
  setParams: (_: ReactivateCesOrUnlimitedParams) => void;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  const [cesAccepted, setCesAccepted] = useState<boolean>(false);

  const handleContinue = () => {
    setParams({ ...params, offer_accepted: cesAccepted });
    onNext();
  };

  return (
    <>
      <ModalHeader title="Reactivate your account" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-base text-gray-700">
          <div className="xs:mb-4 my-2"></div>

          <div className="mb-4 font-bold">Review Your Offer:</div>

          <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
            <div className="w-full px-4 pt-4">
              <div className="flex items-center md:flex-col md:items-start">
                <div className="mt-2 text-lg font-semibold text-black">
                  Credit Enablement Surcharge Offer
                </div>
              </div>
            </div>
            <div className="ml-4 flex flex-col items-start p-2">
              <ul className="list-disc p-1 text-sm">
                <li>
                  Reactivate your account on credit with Credit Enablement Surcharge; a surcharge of
                  30¢ per gallon applies to your transactions for the first 4 weeks. The surcharge
                  is removed after 4 weeks of on-time payments
                </li>
                <li>
                  If a payment is missed, CES surcharge will apply again 4 weeks from the time of
                  payment failure
                </li>
              </ul>
            </div>
          </div>

          <div className="flex items-center">
            <div className="xs:mt-5 mt-1">
              <Checkbox
                setChecked={() => {
                  setCesAccepted(!cesAccepted);
                }}
                checked={cesAccepted}
                label="I accept the terms and conditions"
                size="lg"
                name="ces-details-accept"
              />
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          className="flex-1"
          color="primary"
          onClick={handleContinue}
          disabled={cesAccepted === false}
        >
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};
