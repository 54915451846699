import classNames from 'classnames';
import { ReactNode } from 'react';

type RoundedCardCoreProps = {
  children: ReactNode;
  className?: string;
};

export default function RoundedCardCore({ children, className }: RoundedCardCoreProps) {
  return (
    <div
      className={classNames(
        'flex flex-1 flex-col items-start gap-3 p-4 md:px-6 md:py-5',
        className,
      )}
    >
      {children}
    </div>
  );
}
