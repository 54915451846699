import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { CardUnlockRequestWithRequester } from '@app/@types/card_unlock_request.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { DateAndTimeColumn } from '@app/components/TableV2/StandardColumns';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { ErrorNotification } from '@app/components/layout';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { getShortTimeZone } from '@atob-developers/shared/src/utils/formatters';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';

const COLUMN_DEFINISIONS: ColumnDefinition[] = [
  {
    field: 'created_at',
    ...DateAndTimeColumn(),
    headerName: `Created At (${getShortTimeZone()})`,
  },
  { field: 'locks_at', ...DateAndTimeColumn(), headerName: `Locks At (${getShortTimeZone()})` },
  {
    field: 'requester',
    headerName: 'Requested By',
    renderCell: ({ value }) => {
      return (
        <div className="my-4 text-right">
          <div>{value.name}</div>
        </div>
      );
    },
  },
  {
    field: 'unlock_source',
    headerName: 'Unlocked Via',
    renderCell: ({ value }) => {
      const displayName =
        value === 'driver_app'
          ? 'App'
          : value === 'sms'
          ? 'SMS'
          : value === 'admin'
          ? 'Customer Service'
          : 'Unknown';
      return (
        <div className="my-4 text-right">
          <div>{displayName}</div>
        </div>
      );
    },
  },
];

export default function CardUnlockRequestsList({
  cardID,
}: {
  cardID: number;
}): ReactElement | null {
  const [page, setPage] = useState(1);
  const { data, error, isLoading } = useSWR<
    PaginatedEndpointResponse<CardUnlockRequestWithRequester>
  >(
    {
      url: `/cards/${cardID}/card_unlock_requests`,
      params: {
        per: DEFAULT_PAGE_SIZE,
        all: false,
        page,
        include: 'requester',
      },
    },
    apiGetFetcher,
  );

  if (error) {
    return <ErrorNotification error={error} generic />;
  }

  return (
    <RoundedCard>
      <p className="border-level-2 border-b px-4 pb-8 pt-4 text-lg font-semibold">
        Card Unlock Requests
      </p>
      <ResponsiveTable
        columns={COLUMN_DEFINISIONS}
        data={data?.data ?? []}
        paginationMeta={data?.meta}
        loading={isLoading}
        onPageChange={setPage}
        mobileItemRenderer={() => <></>}
      />
    </RoundedCard>
  );
}
