import { LoadingButton } from '@mui/lab';
import React, { ReactElement } from 'react';
interface SubmitButtonProps {
  label: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dataTestId?: string;
}

function SubmitButton({
  label,
  loading,
  disabled,
  onClick = () => {},
  dataTestId = '',
}: SubmitButtonProps): ReactElement {
  return (
    <LoadingButton
      color="primary"
      onClick={onClick}
      disabled={disabled || loading}
      loading={loading}
      type="submit"
      data-testid={dataTestId}
    >
      {label}
    </LoadingButton>
  );
}

export default SubmitButton;
