import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const CreditLimitIncreaseRequestUnavailable = ({
  closeModal,
}: {
  closeModal: () => void;
}): ReactElement => {
  return (
    <>
      <ModalHeader title="" onClose={closeModal} />
      <ModalBodyContent>
        <div className="flex flex-col items-center">
          <div className="mb-3 mt-8 text-red-600">
            <FontAwesomeIcon className="fa-3x" icon={faCircleExclamation} />
          </div>
          <h1 className="mb-8 text-center text-2xl font-bold">Not Available at This Time</h1>
          <p className="text-md w-full text-center">
            You cannot submit a credit limit increase request at this time. This may be because you
            already submitted one in the last 3 months. If that's not the case or you urgently need
            more credit, please reach out to our support team.
          </p>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-center">
          <Button className="w-3/4" size="small" onClick={closeModal}>
            Done
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
