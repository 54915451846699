import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '../Switch/Switch';

export default function DriverRewardedStationsToggle() {
  const { driverRewardedOnly, setDriverRewardedOnly } = useFuelMapContext();

  return (
    <div className="flex h-auto flex-col gap-2 px-6">
      <div className="border-level-2 hover:bg-background flex items-center gap-2 rounded border p-3 shadow-sm">
        <div className="bg-accent-12 flex h-10 w-10 items-center justify-center rounded-full">
          <FontAwesomeIcon icon={faTrophy} className="text-accent-11 m-3 h-4 w-4" />
        </div>
        <p className="text-primary w-full truncate text-sm font-semibold">
          Show only driver reward stations
        </p>
        <div className="flex shrink-0 grow justify-end gap-2">
          <Switch
            checked={driverRewardedOnly}
            onChange={() => setDriverRewardedOnly((prev) => !prev)}
          />
        </div>
      </div>
    </div>
  );
}
