/* eslint-disable no-nested-ternary */
import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { StatementDataV1 } from '@app/@types/statements.types';
import { FilterDropdownDate } from '@app/components/Filters/FilterDropdownDate';
import FilterGroup from '@app/components/Filters/FilterGroup';
import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import StatementTable from '@app/components/tables/Statement/StatementTable';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import axios from 'axios';
import { isEqual, pick } from 'lodash-es';
import { ReactElement, useCallback, useMemo } from 'react';
import useSWR from 'swr';

const initialStatementsFiltersState = { 'filter_due_at[from]': null, 'filter_due_at[to]': null };

export function BillingStatements(): ReactElement {
  const [statementParams, setStatementParams] = useUrlState<{
    'filter_due_at[from]': string | null;
    'filter_due_at[to]': string | null;
    'page': number;
  }>({ ...initialStatementsFiltersState, page: 1 });

  const clearFilters = useCallback(() => {
    setStatementParams((prevParams) => ({
      ...prevParams,
      'filter_due_at[from]': null,
      'filter_due_at[to]': null,
    }));
  }, [setStatementParams]);

  const showClearFilters = useMemo(() => {
    const queryStateFilters = pick(statementParams, Object.keys(initialStatementsFiltersState));
    return !isEqual(queryStateFilters, initialStatementsFiltersState);
  }, [statementParams]);

  const { data, error, isLoading } = useSWR<PaginatedEndpointResponse<StatementDataV1>>(
    {
      url: `/accounting/statements`,
      params: {
        per: DEFAULT_PAGE_SIZE,
        all: false,
        ...statementParams,
      },
    },
    apiGetFetcher,
  );

  if (error) {
    return (
      <ErrorNotification error="We're having issues loading your statements data. Please try again or if the issue persists, contact support." />
    );
  }

  return (
    <>
      <PageContentWrapper className="pb-12" header={<Header title="Statements" />}>
        <RoundedCard>
          <FilterGroup
            className="border-b-level-2 !mx-0 border-b !py-4 px-6"
            clearFilters={showClearFilters ? clearFilters : undefined}
          >
            <div>
              <FilterDropdownDate
                onDateRangeChange={(fromDate, toDate) => {
                  setStatementParams((prevParams) => ({
                    ...prevParams,
                    'filter_due_at[from]': fromDate,
                    'filter_due_at[to]': toDate,
                  }));
                }}
                fromDate={statementParams['filter_due_at[from]']}
                toDate={statementParams['filter_due_at[to]']}
              />
            </div>
          </FilterGroup>
          <PreviousStatements
            statements={data}
            loading={isLoading}
            setPage={(page) => setStatementParams((p) => ({ ...p, page }))}
          />
        </RoundedCard>
      </PageContentWrapper>
    </>
  );
}

export function PreviousStatements({
  statements,
  loading,
  setPage,
}: {
  statements: PaginatedEndpointResponse<StatementDataV1> | undefined;
  loading: boolean;
  setPage: (page: number) => void;
}): ReactElement {
  const { addToast } = useToasts();
  const downloadAttachment = async (
    statementId: string,
    type: 'pdf' | 'csv',
    setLoading: (loading: boolean) => void,
  ) => {
    setLoading(true);
    let retryCount = 5;
    const tryDownload = async () => {
      try {
        const { data } = await axios.get(
          `/accounting/statements/${statementId}/download?type=${type}`,
        );
        window.open(data.url);
        setLoading(false);
      } catch (error: unknown) {
        retryCount--;

        if (!guardAxiosError(error)) {
          addToast('Try later', { appearance: 'info' });
          setLoading(false);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (error.response.status === 404 && retryCount > 0) {
          // 404 means the attachment is not ready. Retry in 5 seconds.
          setTimeout(async () => {
            await tryDownload();
          }, 5000);
        } else {
          // If we used all the retry count, directly show the error toast.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          addToast(error.response.data.errors, { appearance: 'info' });
          setLoading(false);
        }
      }
    };

    await tryDownload();
  };

  return (
    <StatementTable
      data={statements}
      downloadAttachment={downloadAttachment}
      loading={loading}
      setPage={setPage}
    />
  );
}
