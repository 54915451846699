import { MobileNavHeaderMenuContext } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { SwipeableDrawer } from '@mui/material';
import { ReactNode, useContext } from 'react';
import NavBarLogo from './NavBarLogo';

export default function NavBarWrapper({ children }: { children: ReactNode }) {
  const { mobileNavOpen, setMobileNavOpen } = useContext(MobileNavHeaderMenuContext);

  return (
    <>
      {/* Desktop */}
      <div className="bg-level-0 border-level-2 w-navbar-desktop hidden h-full flex-col gap-6 border-r p-8 md:flex">
        <NavBarLogo />
        {children}
      </div>

      {/* Mobile */}
      <SwipeableDrawer
        anchor="left"
        open={mobileNavOpen}
        onOpen={() => setMobileNavOpen(true)}
        classes={{
          root: 'inset-0 overflow-hidden z-side-drawer md:hidden',
          paper: 'w-full sm:w-navbar-mobile',
        }}
        onClose={() => setMobileNavOpen(false)}
      >
        <div className="flex flex-col">
          <NavBarLogo onClose={() => setMobileNavOpen(false)} />
          {children}
        </div>
      </SwipeableDrawer>
    </>
  );
}
