import { ChargeEventData } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailsItem from './DetailsItem';
import { formatHelper } from './utils';

export default function ChargeEventTransactionDetails({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}) {
  const forceCapture = chargeEvent.auth_approved && chargeEvent.auth_status == null;
  const closed = chargeEvent.auth_status === 'closed' || forceCapture;

  const formattedGrossAmount = formatHelper(chargeEvent.gross_amount_cents);
  const formattedNetAmount = formatHelper(chargeEvent.net_amount_cents);
  const formattedDiscountAmount = formatHelper(chargeEvent.discount_amount_cents);
  const formattedGallons =
    chargeEvent.quantity == null || chargeEvent.quantity === '0.0' ? 'N/A' : chargeEvent.quantity;
  const formattedPricePerGallon = formatHelper(chargeEvent.unit_price?.cents);
  const formattedDiscountPerGallon = formatHelper(chargeEvent.discount_unit_price?.cents);
  const formattedNetPricePerGallon = formatHelper(chargeEvent.net_unit_price?.cents);
  const formattedFuelType = formatHelper(chargeEvent.product_type);

  return (
    <RoundedCard>
      <RoundedCardTitle title="Transaction Details" />
      <div className="flex flex-col lg:flex-row">
        <RoundedCardCore className="border-level-2 border-b lg:border-b-0 lg:border-r">
          <DetailsItem label="Card used" value={`···· ${chargeEvent.card_last4}`} />
          <DetailsItem label="Gross amount" value={formattedGrossAmount} closed={closed} />
          <DetailsItem label="Discount amount" value={formattedDiscountAmount} closed={closed} />
          <DetailsItem label="Net amount" value={formattedNetAmount} closed={closed} />
          <DetailsItem label="Gallons" value={formattedGallons} closed={closed} />
          <DetailsItem label="Price per gallon" value={formattedPricePerGallon} closed={closed} />
          <DetailsItem
            label="Discount per gallon"
            value={formattedDiscountPerGallon}
            closed={closed}
          />
          <DetailsItem
            label="Net price per gallon"
            value={formattedNetPricePerGallon}
            closed={closed}
          />
        </RoundedCardCore>
        <RoundedCardCore>
          <div className="flex w-full flex-col gap-4">
            <DetailsItem label="Fuel Type" value={formattedFuelType} closed={closed} />
            <DetailsItem label="Purchase type" value="Fuel only" closed={closed} />
            <Alert
              severity="info"
              icon={<FontAwesomeIcon icon={faInfoCircle} className="h-5 w-5" />}
              title="Line item breakdown not available"
              content="This transaction does not have a line item breakdown"
            />
          </div>
        </RoundedCardCore>
      </div>
    </RoundedCard>
  );
}
