import {
  faCircleDot,
  faSquare,
  faCircle,
  faCircleExclamation,
  faCircleInfo,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
// We want solid icons here
// eslint-disable-next-line no-restricted-imports
import { faSquareCheck, faSquareMinus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { StepIconProps } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import classNames from 'classnames';
import tw, { theme as tailwindTheme } from 'twin.macro';

function StepIconComponent(props: StepIconProps) {
  const { active, completed, icon } = props;

  if (completed) {
    return <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-white" />;
  }

  return (
    <span
      className={classNames(
        'text-center text-base font-semibold',
        active ? 'text-primary' : 'text-secondary',
      )}
    >
      {icon}
    </span>
  );
}

export default createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          ...tw`rounded-t-lg bg-accent-23 shadow-none border-none before:content-none last-of-type:rounded-b-lg`,
        },
      },
    },
    MuiStepper: {
      variants: [
        {
          props: { orientation: 'vertical' },
          style: {
            ...tw`py-6 pr-6`,
            '& .MuiStepConnector-root': {
              ...tw`ml-4 w-0.5 -translate-x-1/2`,
              '& .MuiStepConnector-line': {
                ...tw`border-none h-8 bg-level-2 my-1`,
              },
            },
            '& .MuiStep-root': {
              ...tw`relative`,
              '&.Mui-completed + .MuiStepConnector-root .MuiStepConnector-line': {
                ...tw`bg-primary`,
              },
              '& + .MuiStepConnector-root': {
                '&.Mui-active .MuiStepConnector-line': {
                  ...tw`mt-1 mb-0.5`,
                },
              },
              '& .MuiStepLabel-root': {
                ...tw`p-0 space-x-3`,
                '& .MuiStepLabel-iconContainer': {
                  ...tw`flex items-center justify-center w-8 h-8 p-1 rounded-full bg-level-1`,
                },
                '& .MuiStepLabel-iconContainer.Mui-completed': {
                  ...tw`p-1.5 bg-accent-21`,
                },
                '& .MuiStepLabel-iconContainer.Mui-active': {
                  ...tw`shadow-primary`,
                },
                '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
                  ...tw`text-primary text-sm font-medium`,
                },
              },
            },
          },
        },
      ],
    },
    MuiStepLabel: {
      defaultProps: {
        StepIconComponent,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          ...tw`h-[6px] rounded-full`,
        },
        rail: {
          ...tw`bg-level-1 opacity-100`,
        },
        track: {
          ...tw`bg-accent-21`,
        },
        thumb: {
          ...tw`w-6 h-6 bg-level-0 border-2 border-accent-21 rounded-xl shadow-marker`,
          '&:hover': {
            ...tw`bg-level-2`,
          },
          '&:active': {
            ...tw`shadow-primary bg-level-0`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            ...tw`static translate-x-0 translate-y-0 text-primary font-medium mb-2 pointer-events-auto`,
            '&.Mui-error': {
              ...tw`text-error-1`,
            },
          },
          '& label[data-shrink="false"] + .MuiInputBase-root .MuiInputBase-input::placeholder': {
            ...tw`!opacity-100 text-secondary`,
          },
          '& .MuiInputBase-root .MuiInputBase-input:placeholder-shown': {
            ...tw`!truncate`,
          },
          '& .MuiFormHelperText-root': {
            ...tw`mt-2 mx-0 text-secondary text-sm`,
            '&.Mui-error': {
              ...tw`text-error-1`,
            },
          },
          '& .MuiInputBase-root': {
            '& .MuiInputBase-input': {
              ...tw`px-0 py-0`,
            },
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiInputLabel-root': {
              ...tw`text-sm`,
            },
            '& .MuiInputBase-root': {
              ...tw`px-3 py-2.5 min-h-[40px]`,
              '& .MuiInputBase-input': {
                ...tw`text-sm`,
              },
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '& .MuiInputLabel-root': {
              ...tw`text-base`,
            },
            '& .MuiInputBase-root': {
              ...tw`px-4 py-3 min-h-[48px]`,
              '& .MuiInputBase-input': {
                ...tw`text-base`,
              },
            },
          },
        },
      ],
      defaultProps: {
        size: 'medium',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: ({ ownerState }) => ({
          ...tw`rounded`,
          '&.MuiOutlinedInput-root': {
            ...tw`py-1 pr-1`,
            ...(ownerState.size === 'small' ? tw`pl-3` : tw`pl-4`),
          },
        }),
        input: {
          '&.MuiAutocomplete-input': {
            ...tw`pl-2 pr-0 py-0`,
          },
        },
        paper: {
          ...tw`mt-2 rounded-lg shadow-none border border-grey16`,
        },
        option: {
          ...tw`py-2.5`,
        },
        listbox: {
          ...tw`py-1`,
          '& .MuiAutocomplete-option': {
            ...tw`py-2.5 px-4`,
          },
        },
      },
      defaultProps: {
        popupIcon: <ExpandMoreRoundedIcon />,
        size: 'medium',
        ChipProps: {
          color: 'white',
          size: 'medium',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            ...tw`min-w-fit normal-case rounded font-semibold gap-2`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          ...tw`m-0`,
        },
        endIcon: {
          ...tw`m-0`,
        },
      },
      variants: [
        {
          props: { size: 'extra-small' },
          style: {
            ...tw`p-[6px] text-sm`,
          },
        },
        {
          props: { size: 'small' },
          style: {
            ...tw`p-[10px] text-sm`,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...tw`p-3 text-base`,
          },
        },
        {
          props: { size: 'large' },
          style: {
            ...tw`p-4 text-base`,
          },
        },
        {
          props: { color: 'primary' },
          style: {
            ...tw`bg-accent-21 text-background`,
            '&:hover': {
              ...tw`bg-accent-22`,
            },
            '&:focus': {
              ...tw`bg-accent-21 shadow-primary`,
            },
            '&:disabled': {
              ...tw`opacity-50 text-background`,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            ...tw`bg-accent-23 text-primary backdrop-blur-md`,
            '&:hover': {
              ...tw`bg-accent-23 bg-hover-gradient`,
            },
            '&:focus': {
              ...tw`bg-accent-23 shadow-primary backdrop-blur-none`,
            },
            '&:disabled': {
              ...tw`text-primary opacity-50`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            ...tw`bg-transparent text-primary`,
            '&:hover': {
              ...tw`bg-accent-23 backdrop-blur-md`,
            },
            '&:focus': {
              ...tw`bg-accent-23 shadow-primary`,
            },
            '&:disabled': {
              ...tw`opacity-50 text-primary`,
            },
          },
        },
        {
          props: { color: 'alert' },
          style: {
            ...tw`bg-error-1 text-background`,
            '&:hover': {
              ...tw`bg-error-1 bg-hover-gradient`,
            },
            '&:focus': {
              ...tw`bg-error-1 shadow-alert`,
            },
            '&:disabled': {
              ...tw`opacity-50 text-background`,
            },
          },
        },
      ],
      defaultProps: {
        color: 'primary',
        size: 'large',
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          '& .MuiCircularProgress-root': {
            ...tw`!w-6 !h-6`,
          },
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-level-1`,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-primary`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-primary`,
            },
          },
        },
        {
          props: { color: 'alert' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-background`,
            },
          },
        },
      ],
      defaultProps: {
        color: 'primary',
        size: 'large',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ...tw`rounded`,
        },
      },
      variants: [
        {
          props: { size: 'extra-small' },
          style: {
            ...tw`p-[6px]`,
          },
        },
        {
          props: { size: 'small' },
          style: {
            ...tw`p-[10px]`,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...tw`p-3`,
          },
        },
        {
          props: { size: 'large' },
          style: {
            ...tw`p-4`,
          },
        },
        {
          props: { color: 'primary' },
          style: {
            ...tw`bg-accent-21 text-background`,
            '&:hover': {
              ...tw`bg-accent-22`,
            },
            '&:disabled': {
              ...tw`opacity-50 text-background`,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            ...tw`bg-accent-23 text-primary backdrop-blur-md`,
            '&:hover': {
              ...tw`bg-accent-23 bg-hover-gradient`,
            },
            '&:disabled': {
              ...tw`text-primary opacity-50`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            ...tw`bg-transparent text-primary`,
            '&:hover': {
              ...tw`bg-accent-23 backdrop-blur-md`,
            },
            '&:disabled': {
              ...tw`opacity-50 text-primary`,
            },
          },
        },
        {
          props: { color: 'alert' },
          style: {
            ...tw`bg-error-1 text-background`,
            '&:hover': {
              ...tw`bg-error-1 bg-hover-gradient`,
            },
            '&:disabled': {
              ...tw`opacity-50 text-background`,
            },
          },
        },
      ],
      defaultProps: {
        size: 'small',
        color: 'secondary',
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          ...tw`gap-1`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...tw`flex items-center gap-1 w-fit h-fit px-1.5 py-1 rounded font-medium`,
          '& .MuiChip-label': {
            ...tw`px-0`,
          },
          '& .MuiChip-icon': {
            ...tw`mx-0 w-4 h-4 text-inherit`,
          },
          '& .MuiChip-deleteIcon': {
            ...tw`mx-0 w-4 h-4 text-inherit`,
            '&:hover': {
              ...tw`text-inherit`,
            },
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            ...tw`text-xs`,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...tw`text-sm`,
          },
        },
        {
          props: { color: 'white' },
          style: {
            '&': {
              ...tw`border border-solid border-level-2`,
            },
          },
        },
        {
          props: { color: 'grey' },
          style: {
            '&': {
              ...tw`text-secondary bg-level-1`,
            },
          },
        },
        {
          props: { color: 'green' },
          style: {
            '&': {
              ...tw`text-accent-11 bg-accent-12`,
            },
          },
        },
      ],
      defaultProps: {
        color: 'black',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...tw`p-0 w-5 h-5`,
          ...(ownerState.checked
            ? tw`rounded-sm bg-level-0 !text-accent-21`
            : tw`rounded-sm bg-level-0 text-level-2`),
          '&:hover': {
            ...(ownerState.checked ? tw`!text-accent-22` : tw`bg-level-1 text-level-3`),
          },
          '&.Mui-disabled': {
            ...tw`bg-level-0 text-level-2 opacity-50`,
          },
          '&.Mui-focusVisible': {
            ...tw`outline ring-2 ring-offset-2 ring-black rounded-sm`,
          },
        }),
      },
      defaultProps: {
        icon: <FontAwesomeIcon icon={faSquare} size="xl" />,
        checkedIcon: <FontAwesomeIcon icon={faSquareCheck} size="xl" />,
        indeterminateIcon: <FontAwesomeIcon icon={faSquareMinus} size="xl" />,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          ...tw`p-0 h-7 w-[50px]`,
        },
        thumb: {
          ...tw`shadow-none`,
        },
        switchBase: {
          ...tw`text-white opacity-100 p-0 m-1`,
          '+ .MuiSwitch-track': {
            ...tw`opacity-100 bg-ds-cool-gray-300 rounded-[14px]`,
          },
          '&.Mui-checked': {
            ...tw`text-white`,
          },
          '&.Mui-checked + .MuiSwitch-track': {
            ...tw`opacity-100 bg-green14`,
          },
          '&.Mui-disabled.Mui-checked': {
            ...tw`text-white`,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            ...tw`opacity-50 cursor-not-allowed`,
          },
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            '&.MuiSwitch-root': {
              ...tw`w-12 p-1`,
              '& .MuiButtonBase-root': {
                ...tw`bg-transparent`,
              },
              '& .MuiSwitch-track': {
                ...tw`bg-level-2`,
              },
              '&:hover .MuiSwitch-track': {
                ...tw`bg-level-3`,
              },
              '& .Mui-disabled': {
                '& .MuiSwitch-thumb': { ...tw`bg-level-0` },
                '& + .MuiSwitch-track': {
                  ...tw`bg-level-2`,
                },
              },
              '& .MuiSwitch-thumb': {
                ...tw`h-4 w-4 m-0.5 border-none`,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                '& + .MuiSwitch-track': {
                  ...tw`bg-accent-21`,
                },
                '&:hover + .MuiSwitch-track': {
                  ...tw`bg-accent-22`,
                },
              },
              '&:focus-within .MuiSwitch-switchBase + .MuiSwitch-track': {
                ...tw`shadow-primary`,
              },
            },
          },
        },
        {
          props: { color: 'red-green' },
          style: {
            '&.MuiSwitch-root': {
              ...tw`w-12 p-1`,
              '& .MuiButtonBase-root': {
                ...tw`bg-transparent p-0.5`,
              },
              '& .MuiSwitch-track': {
                ...tw`bg-error-1`,
              },
              '&:hover .MuiSwitch-track': {
                ...tw`bg-hover-gradient`,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                '& + .MuiSwitch-track': {
                  ...tw`bg-accent-11`,
                },
              },
              '&:focus-within .MuiSwitch-switchBase + .MuiSwitch-track': {
                ...tw`shadow-alert`,
              },
              '&:focus-within .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                ...tw`shadow-green`,
              },
            },
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              ...tw`rounded border-grey16`,
            },
            '&:hover fieldset': {
              ...tw`border-[1.5px] border-grey19`,
            },
            '& .MuiOutlinedInput-input': {
              ...tw`ring-transparent`,
            },
            '&.Mui-focused fieldset': {
              ...tw`border-2 border-ds-cool-gray-900`,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          ...tw`mx-0 gap-3`,
          '& .MuiFormControlLabel-label': {
            ...tw`font-medium text-secondary text-sm`,
          },
          '& .Mui-disabled': {
            ...tw`opacity-50`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiSvgIcon-root': {
            ...tw`text-ds-cool-gray-900`,
          },
        },
        select: {
          ...tw`text-sm`,
          '& .MuiListItemText-primary': {
            ...tw`text-sm`,
          },
          '& .MuiListItemIcon-root': {
            ...tw`hidden`,
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            ...tw`h-10`,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...tw`h-12`,
          },
        },
      ],
      defaultProps: {
        IconComponent: ExpandMoreRoundedIcon,
        size: 'medium',
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
      styleOverrides: {
        root: {
          ...tw`transform translate-y-1`,
        },
        paper: {
          ...tw`rounded-lg shadow-lg !max-h-[500px]`,
          '& .MuiMenuItem-root': {
            ...tw`!px-4 py-2.5 !gap-2`,
            '& .MuiListItemText-primary': {
              ...tw`text-sm text-grey17`,
            },
            '&.Mui-selected': {
              '& .MuiListItemText-primary': {
                ...tw`text-ds-cool-gray-900`,
              },
            },
            '& .MuiListItemIcon-root': {
              ...tw`justify-end text-ds-cool-gray-900`,
            },
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'standard',
        iconMapping: {
          success: <FontAwesomeIcon icon={faCheck} size="xs" />,
          error: <FontAwesomeIcon icon={faCircleExclamation} size="xs" />,
          warning: <FontAwesomeIcon icon={faCircleExclamation} size="xs" />,
          info: <FontAwesomeIcon icon={faCircleInfo} size="xs" />,
        },
      },
      styleOverrides: {
        root: {
          ...tw`rounded-lg p-4 flex items-center`,
          '& .MuiAlert-message': {
            ...tw`p-0 leading-5`,
            '& .MuiAlertTitle-root': {
              ...tw`m-0`,
            },
          },
          '& .MuiAlert-icon': {
            ...tw`pt-1 self-start`,
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'standard',
          },
          style: {
            '& .MuiAlert-message': {
              ...tw`text-secondary`,
              '& .MuiAlertTitle-root': {
                ...tw`text-primary`,
              },
            },
          },
        },
        {
          props: {
            variant: 'filled',
          },
          style: {
            '& .MuiAlert-message': {
              ...tw`text-level-0`,
            },
            '& .MuiAlert-icon': {
              ...tw`text-level-0`,
            },
            '& .MuiAlert-action': {
              ...tw`text-level-0`,
            },
          },
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            '& .MuiAlert-icon': {
              ...tw`text-secondary`,
            },
          },
        },
        {
          props: { severity: 'success', variant: 'standard' },
          style: {
            ...tw`bg-accent-12`,
            '& .MuiAlert-icon': {
              ...tw`text-accent-10`,
            },
          },
        },
        {
          props: { severity: 'error', variant: 'standard' },
          style: {
            ...tw`bg-error-2`,
            '& .MuiAlert-icon': {
              ...tw`text-error-1`,
            },
          },
        },
        {
          props: { severity: 'warning', variant: 'standard' },
          style: {
            ...tw`bg-warning-2`,
            '& .MuiAlert-icon': {
              ...tw`text-warning-1`,
            },
          },
        },
        {
          props: { severity: 'info', variant: 'standard' },
          style: {
            ...tw`bg-accent-23`,
            '& .MuiAlert-icon': {
              ...tw`text-primary`,
            },
          },
        },
        {
          props: { severity: 'success', variant: 'filled' },
          style: {
            ...tw`bg-accent-11`,
          },
        },
        {
          props: { severity: 'error', variant: 'filled' },
          style: {
            ...tw`bg-error-1`,
          },
        },
        {
          props: { severity: 'warning', variant: 'filled' },
          style: {
            ...tw`bg-warning-1`,
          },
        },
        {
          props: { severity: 'info', variant: 'filled' },
          style: {
            ...tw`bg-primary`,
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          ...tw`rounded-xl md:rounded-2xl absolute m-0 bottom-0 w-full md:relative`,
        },
      },
      variants: [
        {
          props: { fullScreen: true },
          style: {
            '& .MuiPaper-root': {
              ...tw`rounded-none`,
            },
          },
        },
        {
          props: { fullScreen: false },
          style: {
            ...tw`m-4`,
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...tw`px-8 pt-8 pb-0`,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...tw`px-8 !py-4`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          ...tw`px-8 pb-8 pt-0`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...tw`bg-level-0`,
          '&.Mui-disabled': {
            ...tw`opacity-50`,
          },
          '& .MuiInputBase-input': {
            ...tw`grow text-primary`,
            '&.Mui-disabled': {
              ...tw`text-secondary`,
            },
            '&::placeholder': {
              ...tw`text-secondary opacity-100`,
            },
          },
          '& fieldset': {
            ...tw`border-level-2 top-0`,
            '& legend': {
              ...tw`hidden`,
            },
          },
          '&&:hover fieldset': {
            ...tw`border-2 border-level-3`,
          },
          '&&.Mui-focused fieldset': {
            ...tw`border-2 border-primary`,
          },
          '&&.Mui-error.Mui-error fieldset': {
            ...tw`border-2 border-error-1`,
          },
          '&&.Mui-disabled.Mui-disabled fieldset': {
            ...tw`border-level-2`,
          },
          '&&.Mui-disabled:hover fieldset': {
            ...tw`border border-level-2`,
          },
        },
        input: {
          ...tw`focus:outline-none focus:shadow-none`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...tw`text-sm rounded text-white bg-blue4 p-2.5 font-medium shadow-tooltip`,
        },
        arrow: {
          ...tw`text-blue4`,
        },
        tooltipPlacementBottom: {
          ...tw`!mt-2`,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ...tw`border-none`,
          '& .MuiDataGrid-columnSeparator, & .MuiDataGrid-scrollbar--horizontal': {
            ...tw`hidden`,
          },
          '& .MuiDataGrid-columnHeaders': {
            '& .MuiDataGrid-row--borderBottom': {
              ...tw`bg-background`,
              '& > div[role="presentation"] + .MuiDataGrid-columnHeader': {
                ...tw`pl-6`,
              },
            },
            '& .MuiDataGrid-columnHeader': {
              ...tw`px-3 py-2.5 outline-none first-of-type:pl-6 last-of-type:pr-6`,
              '&.MuiDataGrid-columnHeader--pinnedLeft': {
                ...tw`bg-background`,
                '&.MuiDataGrid-columnHeader--withRightBorder': {
                  ...tw`pr-6`,
                },
              },
              '&.MuiDataGrid-columnHeader--last': {
                ...tw`pr-6`,
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                ...tw`text-xs text-secondary font-semibold uppercase`,
              },
            },
            // Sort header icons
            '& .MuiDataGrid-iconButtonContainer': {
              ...tw`pl-2 invisible`,
              '& .MuiIconButton-root': {
                ...tw`w-[18px] h-[18px] rounded-full p-0`,
              },
              '& .MuiDataGrid-sortIcon': {
                ...tw`w-3.5 h-3.5`,
              },
            },
            '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer': {
              ...tw`visible`,
            },
          },
          '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
            '& .MuiDataGrid-cellOffsetLeft + .MuiDataGrid-cell': {
              ...tw`pl-6`,
            },
            '& .MuiDataGrid-cell': {
              ...tw`flex items-center px-3 py-2.5 text-xs font-medium outline-none border-level-2 border-t-0 border-b first-of-type:pl-6 last-of-type:pr-6`,
              '&.MuiDataGrid-cell--withRightBorder.MuiDataGrid-cell--pinnedLeft': {
                ...tw`pr-6`,
              },
            },
          },
          // Checkboxes when row selection is turn on
          '& .MuiDataGrid-columnHeaderCheckbox, & .MuiDataGrid-cellCheckbox': {
            ...tw`min-w-[54px]`,
            '& .MuiCheckbox-root': {
              ...tw`w-4 h-4 bg-transparent`,
            },
            'svg': {
              ...tw`w-4 h-4`,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          ...tw`bg-level-0 text-level-2 w-5 h-5 m-2`,
          '&:hover': {
            ...tw`bg-level-1 text-level-3`,
          },
          '&.Mui-focusVisible': {
            ...tw`outline ring-2 ring-offset-2 ring-black rounded-full`,
          },
          '&.Mui-checked': {
            ...tw`text-accent-21`,
            '&:hover': {
              ...tw`bg-level-0 text-accent-22`,
            },
          },
          '&.Mui-disabled': {
            ...tw`text-level-2 opacity-50`,
          },
        },
      },
      defaultProps: {
        icon: <FontAwesomeIcon icon={faCircle} size="lg" />,
        checkedIcon: <FontAwesomeIcon icon={faCircleDot} size="lg" />,
      },
    },
  },
  palette: {
    primary: {
      main: ensureAlphaValue(tailwindTheme`colors.blue1`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.white`),
    },
    secondary: {
      main: '#e4e3e4',
      dark: '#d9dad9',
    },
    tertiary: {
      main: 'rgba(17, 24, 39, 0.06)',
      contrastText: '#111827',
    },
    alert: {
      main: '#C83933',
      contrastText: '#F9FAFB',
    },
    warning: {
      main: '#FDF0E6',
      contrastText: '#5F2B01',
    },
    info: {
      main: '#EFF6FF',
      contrastText: '#2563EB',
    },
    error: {
      main: '#FEF2F2',
      contrastText: '#DC2626',
    },
    success: {
      main: '#EBF9EB',
      contrastText: '#014F01',
    },
    black: {
      main: ensureAlphaValue(tailwindTheme`colors.accent-21`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.level-0`),
    },
    white: {
      main: ensureAlphaValue(tailwindTheme`colors.level-0`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.primary.DEFAULT`),
    },
    orange: {
      main: ensureAlphaValue(tailwindTheme`colors.warning-2`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.warning-1`),
    },
    red: {
      main: ensureAlphaValue(tailwindTheme`colors.error-2`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.error-1`),
    },
    green: {
      main: ensureAlphaValue(tailwindTheme`colors.accent-12`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.accent-11`),
    },
    grey: {
      main: ensureAlphaValue(tailwindTheme`colors.level-1`),
      contrastText: ensureAlphaValue(tailwindTheme`colors.secondary`),
    },
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: 14,
  },
  breakpoints: {
    values: {
      'xxs': pxToNumber(tailwindTheme`screens.xxs`),
      'xs': pxToNumber(tailwindTheme`screens.xs`),
      'sm': pxToNumber(tailwindTheme`screens.sm`),
      'md': pxToNumber(tailwindTheme`screens.md`),
      'lg': pxToNumber(tailwindTheme`screens.lg`),
      'xl': pxToNumber(tailwindTheme`screens.xl`),
      '2xl': pxToNumber(tailwindTheme`screens.2xl`),
    },
  },
} as ThemeOptions);

export function ensureAlphaValue(color: string) {
  if (color.includes(' / <alpha-value>')) {
    return color.replace(' / <alpha-value>', '');
  }

  return color;
}

function pxToNumber(pxString: string) {
  return parseInt(pxString.replace('px', ''), 10);
}
