import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import DescriptionItem from '@app/components/PaymentModal/DescriptionItem/DescriptionItem';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import AmountItem from '@app/components/PaymentModal/components/AmountItem';
import EditPaymentFooter from '@app/components/PaymentModal/components/EditPaymentFooter';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import Divider from '@atob-developers/shared/src/components/Divider';
import {
  convertNumbersWithCurrency,
  getCurrencyFromCents,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { ReactElement, useState } from 'react';
import DebitCardDetailItem from './components/DebitCardDetailItem';
interface DebitCardEditPaymentDetailsProps {
  closeModal: () => void;
  onNext: () => void;
  selectedDebitCard?: DebitCardPaymentMethodCombined;
  debitCards: DebitCardPaymentMethodCombined[];
  setSelectedDebitCard: (debitCard: DebitCardPaymentMethodCombined) => void;
  paymentAmount: string;
  setPaymentAmount: (value: string) => void;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const MINIMUM_PAYMENT_AMOUNT_CENTS = 150;

const DebitCardEditPaymentDetails = ({
  onNext,
  closeModal,
  selectedDebitCard,
  debitCards,
  setSelectedDebitCard,
  paymentAmount,
  setPaymentAmount,
  showAmountDue,
  setShowAmountDue,
}: DebitCardEditPaymentDetailsProps): ReactElement | null => {
  const [inputError, setInputError] = useState<string | null>(null);
  const [noDebitCardsErrors, setNoDebitCardsErrors] = useState<string | null>(null);
  const [waiveInstantAccessFeeEnabled] = useFeatureFlags('waive_instant_access_fee');

  const handleNextClick = () => {
    if (!selectedDebitCard) {
      setNoDebitCardsErrors('Please add a debit card to continue');
      return;
    }
    const inputAmountInCents = convertNumbersWithCurrency(paymentAmount) * 100;
    if (inputAmountInCents < MINIMUM_PAYMENT_AMOUNT_CENTS) {
      setInputError(
        `Amount must be greater than ${getCurrencyFromCents(MINIMUM_PAYMENT_AMOUNT_CENTS)}`,
      );
      return;
    } else {
      onNext();
    }
  };

  return (
    <>
      <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
        <div className="flex flex-col gap-4">
          <PaymentDetailsHeaderText>Payment Details</PaymentDetailsHeaderText>
          <div className="flex flex-col gap-4">
            <Divider className="bg-gray-200" />
            <DebitCardDetailItem
              selectedDebitCard={selectedDebitCard}
              debitCards={debitCards}
              setSelectedDebitCard={setSelectedDebitCard}
              noDebitCardsErrors={noDebitCardsErrors}
            />
            <Divider className="bg-gray-200" />
            <AmountItem
              amount={paymentAmount}
              setAmount={setPaymentAmount}
              error={inputError}
              setError={setInputError}
              showAmountDue={showAmountDue}
              setShowAmountDue={setShowAmountDue}
            />
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Est. Processing Date">Instant</PaymentDetailItem>
            {!waiveInstantAccessFeeEnabled && (
              <>
                <Divider className="bg-gray-200" />
                <DescriptionItem text="A 1% fee is applied to instant debit payments" />
              </>
            )}
          </div>
        </div>
      </div>
      <EditPaymentFooter onCancel={closeModal} onNext={handleNextClick} />
    </>
  );
};

export default DebitCardEditPaymentDetails;
