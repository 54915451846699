import { ReactivateCesOrUnlimitedParams } from '@app/components/Modals/CardModals/ReactivateCesOrUnlimitedOfferModal';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import Checkbox from '@atob-developers/shared/src/components/Checkbox';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export const UnlimitedDetails = ({
  params,
  setParams,
  onNext,
  onBack,
  closeModal,
}: {
  params: ReactivateCesOrUnlimitedParams;
  setParams: (_: ReactivateCesOrUnlimitedParams) => void;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  const [unlimitedAccepted, setUnlimitedAccepted] = useState<boolean>(false);

  const handleContinue = () => {
    setParams({ ...params, offer_accepted: unlimitedAccepted });
    onNext();
  };

  const isUF = useIsUberfreight(true);

  return (
    <>
      <ModalHeader title="Reactivate your account" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mb-4 font-bold">Review Your Offer:</div>

        <div className="mb-4 flex w-full flex-col rounded-md border-2 border-black text-left md:mb-0">
          <div className="w-full px-4 pt-4">
            <div className="flex items-center md:flex-col md:items-start">
              <div className="mt-2 text-lg font-semibold text-black">
                Reactivate with {isUF ? 'Prepaid' : 'AtoB Unlimited'}
              </div>
            </div>
          </div>
          <div className="ml-4 flex flex-col items-start p-2">
            <ul className="list-disc p-1 text-sm">
              <li>Reactivate with {isUF ? 'Uber Freight' : 'AtoB'} prepaid card</li>
              <li>Top-up your {isUF ? 'Uber Freight' : 'AtoB'} wallet to make transactions</li>
            </ul>
          </div>
        </div>

        <div className="flex items-center">
          <div className="xs:mt-5 mt-1">
            <Checkbox
              setChecked={() => {
                setUnlimitedAccepted(!unlimitedAccepted);
              }}
              checked={unlimitedAccepted}
              label="I accept the terms and conditions"
              size="lg"
              name="unlimited-reactivate-accept"
            />
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button
          className="flex-1"
          color="primary"
          onClick={handleContinue}
          disabled={unlimitedAccepted === false}
        >
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};
