import { MyLocation } from '@app/@types/fuel_listings.types';

export const DUMMY_MY_LOCATION: MyLocation = {
  location_type: 'my_location',
  address_components: [],
  place_id: 'current_location',
  formatted_address: 'Current Location',
  description: 'Current Location',
  structured_formatting: {
    main_text: 'Current Location',
    secondary_text: '',
  },
  geometry: {
    location: {
      lat: () => 0,
      lng: () => 0,
    },
  },
};

export const MILES_TO_METERS = 1.609 * 1000;

export const TA_PARTNER_ID = 7;

export const DISCOUNT_BOOST_BRAND_IDS = [7, 49, 86, 97];

export const DRIVER_REWARDED_BRAND_IDS = [7, 49, 86, 97];

export const DRIVER_REWARDED_PARTNER_IDS = [7, 25, 54];
