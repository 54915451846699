import { PaginationMeta } from '@app/@types/api.types';
import { DeviceData } from '@app/@types/device.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { phoneFormat } from '@atob-developers/shared/src/utils/formatters';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, useState } from 'react';
import { CardAssignedTo } from '../Cards/CardsListItems';
import ResponsiveTable from '../TableV2/ResponsiveTable';
import { DeviceSidebar } from './DeviceSidebar';

export const DEVICE_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.name}</Cell>,
  },
  {
    field: 'assignee.name',
    headerName: 'Assigned to',
    minWidth: 150,
    flex: 1,
    sortable: true,
    renderCell: (params) => (
      <Cell>
        <CardAssignedTo
          driver={params.row.assignee_type === 'Driver' && params.row.assignee_name}
          vehicle={params.row.assignee_type === 'Vehicle' && params.row.assignee_name}
        />
      </Cell>
    ),
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }) => {
      return <>{phoneFormat({ value: row.phone_number })}</>;
    },
  },
] as ColumnDefinition[];

interface DeviceListProps {
  rows: DeviceData[];
  paginationMeta: PaginationMeta | undefined;
  onPageIndexChange: (pageIndex: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  loading: boolean;
  refreshData: () => void;
}

const MobileDeviceItemRenderer = ({ row, onClick }: { row: DeviceData; onClick?: () => void }) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">{row.name}</div>
    <div>{row.assignee_name}</div>
    <div>{phoneFormat({ value: row.phone_number })}</div>
  </MobileListItem>
);

const DevicesList = ({
  rows,
  paginationMeta,
  onPageIndexChange,
  onSortModelChange,
  refreshData,
  loading,
}: DeviceListProps): ReactElement => {
  const [device, setDevice] = useState<DeviceData | null>(null);

  const handleRowClick = (row: DeviceData) => {
    setDevice(row);
  };

  return (
    <>
      <RoundedCard>
        <ResponsiveTable
          columns={DEVICE_COLUMNS}
          data={rows || []}
          paginationMeta={paginationMeta}
          onPageChange={onPageIndexChange}
          onSortModelChange={onSortModelChange}
          onRowClick={handleRowClick}
          loading={loading}
          mobileItemRenderer={MobileDeviceItemRenderer}
        />
      </RoundedCard>
      {!!device && (
        <DeviceSidebar device={device} hide={() => setDevice(null)} refresh={() => refreshData()} />
      )}
    </>
  );
};

export default DevicesList;
