import { AlertProps, Alert as MuiAlert, AlertTitle } from '@mui/material';
import classNames from 'classnames';

type Props = Omit<AlertProps, 'content'> & {
  primaryActionText?: string;
  primaryAction?: () => void;
  secondaryActionText?: string;
  secondaryAction?: () => void;
  content?: React.ReactNode;
};

export function Alert({
  title,
  content,
  primaryActionText,
  primaryAction,
  secondaryActionText,
  secondaryAction,
  variant = 'standard',
  ...rest
}: Props) {
  return (
    <MuiAlert {...rest} variant={variant}>
      <AlertTitle>{title}</AlertTitle>
      {content}
      {primaryAction && (
        <div className="flex gap-3 pt-2">
          <button
            className={classNames(
              'font-medium underline',
              variant === 'filled' ? 'text-level-0' : 'text-primary',
            )}
            onClick={primaryAction}
          >
            {primaryActionText}
          </button>
          {secondaryAction && (
            <button className="font-medium underline" onClick={secondaryAction}>
              {secondaryActionText}
            </button>
          )}
        </div>
      )}
    </MuiAlert>
  );
}
