import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriversCounts, DriverData } from '@app/@types/driver.types';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { getFetcher, apiGetFetcher } from '@app/utils/data/fetchers';
import React, { createContext, useState, useMemo, ReactNode, useContext } from 'react';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

type InviteDriversContextType = {
  isSelectedAll: boolean;
  setIsSelectedAll: React.Dispatch<React.SetStateAction<boolean>>;
  individualDrivers: Record<number, boolean>;
  setIndividualDrivers: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  driversCountsData: DriversCounts | undefined;
  data: PaginatedEndpointResponse<DriverData>[] | undefined;
  setSize: (
    size: number | ((_size: number) => number),
  ) => Promise<PaginatedEndpointResponse<DriverData>[] | undefined>;
  isValidating: boolean;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
};

const InviteDriversContext = createContext<InviteDriversContextType | undefined>(undefined);

export const InviteDriversProvider = ({ children }: { children: ReactNode }) => {
  const [isSelectedAll, setIsSelectedAll] = useState(true);
  const [individualDrivers, setIndividualDrivers] = useState<Record<number, boolean>>({});
  const [errors, setErrors] = useState<string[]>([]);

  const { data: driversCountsData } = useSWR<DriversCounts>({ url: '/drivers/counts' }, getFetcher);
  const { data, setSize, isValidating } = useSWRInfinite<PaginatedEndpointResponse<DriverData>>(
    getKey,
    apiGetFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const value = useMemo(
    () => ({
      isSelectedAll,
      setIsSelectedAll,
      individualDrivers,
      setIndividualDrivers,
      errors,
      setErrors,
      setSize,
      driversCountsData,
      data,
      isValidating,
    }),
    [
      isSelectedAll,
      setIsSelectedAll,
      individualDrivers,
      setIndividualDrivers,
      errors,
      setErrors,
      setSize,
      driversCountsData,
      data,
      isValidating,
    ],
  );

  return <InviteDriversContext.Provider value={value}>{children}</InviteDriversContext.Provider>;
};

export const useInviteDriversContext = () => {
  const context = useContext(InviteDriversContext);
  if (!context) {
    throw new Error(
      'useOnboardingInviteDrivers must be used within OnboardingInviteDriversProvider',
    );
  }

  return context;
};

function getKey(pageIndex: number, previousPage: PaginatedEndpointResponse<DriverData>) {
  if (
    previousPage &&
    previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
  ) {
    return null;
  }

  return {
    url: '/drivers',
    params: {
      page: pageIndex + 1,
      per: DEFAULT_PAGE_SIZE,
      sort: ['phone:asc'],
      all: false,
      archived_at: 'none',
    },
  };
}
