import { EndpointResponse } from '@app/@types/api.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { InstantPayoutMethod } from '@app/@types/instant_payout_method.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import RadioSelector from '@app/components/RadioSelector/RadioSelector';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import {
  faArrowRightArrowLeft,
  faCircleUser,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, RadioGroup } from '@mui/material';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { RecipientData } from '../transfer.types';
import { TransferPanel } from './TransferPanel';
import {
  AccountType,
  FeeConfiguration,
  filterMethodsByTabValue,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
  WithdrawalState,
} from './utils';

const mapAccountType = (type: string) => {
  switch (type) {
    case 'InstantPayoutMethod::Zelle':
      return 'zelle';
    case 'InstantPayoutMethod::Paypal':
      return 'paypal';
    case 'InstantPayoutMethod::Venmo':
      return 'venmo';
  }
};

export const WithdrawFundsSidebarNew = ({
  open,
  reset,
  onCompletedTansfer,
  walletBalance,
}: {
  open: boolean;
  reset: () => void;
  onCompletedTansfer: () => void;
  walletBalance?: string | null;
}) => {
  const [withdrawalState, setWithdrawalState] = useState<WithdrawalState>('initial');
  const [transferKind, setTransferKind] = useState<TransferKind | null>(null);
  const [step, setStep] = useState(0);

  const { data: transferMethodsData, isLoading: loadingMethods } = useSWR<
    EndpointResponse<FeeConfiguration[]>
  >({ url: `/treasury/financial_account/all_fees` }, apiGetFetcher);

  const { data: recipientDestinationsData, isLoading: loadingRecipientDestinations } = useSWR<
    EndpointResponse<RecipientData[]>
  >({ url: `/treasury/recipients` }, apiGetFetcher);

  const { data: instantPayoutMethodsData, isLoading: loadingInstantPayoutMethodsData } = useSWR<
    EndpointResponse<InstantPayoutMethod[]>
  >({ url: `/treasury/instant_payout_methods` }, apiGetFetcher);

  const normalizedExternalDestinations: NormalizedDestination[] = useMemo(() => {
    const processedExternalRecipientDestinationsData = (recipientDestinationsData?.data || [])
      .map((destination) => {
        const isOwned = destination.customer_owned;
        if (!isOwned) {
          return {
            id: destination.id,
            recipientType: 'external',
            name: destination.billing_details.name,
            type: 'bank_account',
            alternateName: destination.display_name,
            lastFour: destination.payment_details.bank_account.mask,
            supportedNetworks: destination.payment_details.bank_account
              .supported_networks as PaymentMethodType[],
            brand: null,
            recipientName: destination.billing_details.name,
            needsAddress: destination.billing_details.address === null,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null) as NormalizedDestination[];

    const processedZelleAccountDestinations = (instantPayoutMethodsData?.data || []).map(
      (destination) => {
        return {
          id: destination.id,
          recipientType: 'external',
          name: destination.username,
          type: `${mapAccountType(destination.type)}_account`,
          alternateName: destination.nickname,
          lastFour: '',
          supportedNetworks: [mapAccountType(destination.type)] as PaymentMethodType[],
          brand: null,
          recipientName:
            destination.username + (destination.nickname ? ` (${destination.nickname})` : ''),
          needsAddress: false,
        };
      },
    ) as NormalizedDestination[];

    return processedExternalRecipientDestinationsData.concat(processedZelleAccountDestinations);
  }, [recipientDestinationsData, instantPayoutMethodsData]);

  const { data: ownDestinationsData, isLoading: loadingOwnDestinations } = useSWR<
    EndpointResponse<PaymentMethodCombined[]>
  >(PAYMENT_METHOD_QUERY_KEY, apiGetFetcher);

  const normalizedOwnDestinations: NormalizedDestination[] = useMemo(() => {
    if (!ownDestinationsData?.data) return [];

    const processedZelleAccountDestinations = (instantPayoutMethodsData?.data || []).map(
      (destination) => {
        return {
          id: destination.id,
          recipientType: 'external',
          name: destination.username,
          type: `${mapAccountType(destination.type)}_account`,
          alternateName: destination.nickname,
          lastFour: '',
          supportedNetworks: [mapAccountType(destination.type)] as PaymentMethodType[],
          brand: null,
          recipientName:
            destination.username + (destination.nickname ? ` (${destination.nickname})` : ''),
          needsAddress: false,
        };
      },
    ) as NormalizedDestination[];

    const processedOwnRecipientDestinations = (recipientDestinationsData?.data || [])
      .map((destination) => {
        const isOwned = destination.customer_owned;
        if (isOwned) {
          return {
            id: destination.id,
            recipientType: 'own',
            name: destination.billing_details.name,
            type: 'bank_account',
            alternateName: destination.display_name,
            lastFour: destination.payment_details.bank_account.mask,
            supportedNetworks: destination.payment_details.bank_account
              .supported_networks as PaymentMethodType[],
            brand: null,
            recipientName: destination.billing_details.name,
            needsAddress: destination.billing_details.address === null,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null) as NormalizedDestination[];

    const processedOwnDestinationsData = ownDestinationsData.data
      .map((destination) => {
        const type = destination.payment_method_detail.type as AccountType;

        if (type === 'debit_card') {
          destination = destination as DebitCardPaymentMethodCombined;

          return {
            id: destination.id,
            recipientType: 'own',
            name: destination.payment_method_detail.description,
            alternateName: destination.payment_method_detail.expires,
            type: 'debit_card',
            lastFour: destination.payment_method_detail.last_four,
            brand: destination.payment_method_detail.brand,
            supportedNetworks: ['debit'],
            recipientName: null,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null) as NormalizedDestination[];

    return processedOwnDestinationsData
      .concat(processedZelleAccountDestinations)
      .concat(processedOwnRecipientDestinations);
  }, [ownDestinationsData?.data, instantPayoutMethodsData?.data, recipientDestinationsData?.data]);

  const getTitle = () =>
    step === 0
      ? 'Pay and transfer funds'
      : transferKind === 'own_transfer'
      ? 'Transfer funds to myself'
      : 'Transfer funds to someone else';

  const resetAndClose = () => {
    setWithdrawalState('initial');
    setTransferKind(null);
    setStep(0);

    reset();
  };

  const options: {
    kind: TransferKind;
    title: string;
    icon: IconDefinition;
  }[] = [
    {
      kind: 'external_transfer',
      title: 'Someone else',
      icon: faArrowRightArrowLeft,
    },
    {
      kind: 'own_transfer',
      title: 'Myself',
      icon: faCircleUser,
    },
  ];

  return (
    <Drawer open={open} onClose={resetAndClose} anchor="right">
      <div className="flex h-screen w-screen flex-col overflow-y-auto md:max-w-xl">
        <SidebarHeader title={getTitle()} onClose={resetAndClose} />
        {step === 0 ? (
          <div className="mx-6 mb-8 h-full md:mx-8 md:mb-8">
            <h4 className="mb-2 font-medium">Transfer to yourself or someone else?</h4>
            <div className="flex h-full flex-col justify-between pb-8">
              <RadioGroup className="gap-2">
                {options.map(({ kind, title, icon }) => (
                  <RadioSelector
                    key={kind}
                    onChange={() => {
                      setTransferKind(kind);
                      setStep(1);
                    }}
                    label={
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon
                          icon={icon}
                          className={classNames('text-base', {
                            'text-secondary': kind !== transferKind,
                          })}
                        />
                        {title}
                      </div>
                    }
                    labelPlacement="start"
                    checked={transferKind === kind}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
        ) : (
          <>
            {transferKind === 'external_transfer' && (
              <TransferPanel
                setTransferKind={setTransferKind}
                setStep={setStep}
                step={step}
                availableMethods={filterMethodsByTabValue(
                  transferMethodsData?.data || [],
                  transferKind,
                )}
                availableDestinations={normalizedExternalDestinations}
                loadingMethods={loadingMethods}
                loadingDestinations={
                  loadingRecipientDestinations || loadingInstantPayoutMethodsData
                }
                withdrawalState={withdrawalState}
                setWithdrawalState={setWithdrawalState}
                reset={resetAndClose}
                onCompletedTansfer={onCompletedTansfer}
                walletBalance={walletBalance}
                transferKind="external_transfer"
                recipients={recipientDestinationsData?.data || []}
              />
            )}
            {transferKind === 'own_transfer' && (
              <TransferPanel
                setTransferKind={setTransferKind}
                setStep={setStep}
                step={step}
                availableMethods={filterMethodsByTabValue(
                  transferMethodsData?.data || [],
                  transferKind,
                )}
                availableDestinations={normalizedOwnDestinations}
                loadingMethods={loadingMethods}
                loadingDestinations={loadingOwnDestinations || loadingRecipientDestinations}
                withdrawalState={withdrawalState}
                setWithdrawalState={setWithdrawalState}
                reset={resetAndClose}
                onCompletedTansfer={onCompletedTansfer}
                walletBalance={walletBalance}
                transferKind="own_transfer"
                recipients={recipientDestinationsData?.data || []}
              />
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};
