import { ReactElement, ReactNode } from 'react';

const MobileListItem = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: () => void;
}): ReactElement => {
  return (
    <div
      onClick={onClick}
      className="rounded-card border-grey5 rounded-md border bg-white p-4 text-sm "
    >
      {children}
    </div>
  );
};

export { MobileListItem };
