import { CircularProgress } from '@mui/material';
import cx from 'classnames';
import { ReactElement, HTMLAttributes } from 'react';

export type SpinnerBoundaryProps = {
  /** Background color. Set to null to disable background. (default: 'white') */
  backgroundColor?: BoundaryBackgroundColors;
  /** Expand to cover the entire parent element (use relative class) (default: true) */
  expand?: boolean;
  /** Whether to center the spinner vertically (default: true) */
  verticallyCenter?: boolean;
} & HTMLAttributes<HTMLDivElement>;

type BoundaryBackgroundColors = 'white' | 'gray' | null;

export default function SpinnerBoundary({ ...props }: SpinnerBoundaryProps): ReactElement {
  return (
    <div data-testid="loading-spinner" className={outerClass({ ...props })}>
      <CircularProgress />
    </div>
  );
}

function outerClass({
  backgroundColor = 'white',
  expand = true,
  verticallyCenter = true,
  className,
}: SpinnerBoundaryProps): string {
  const baseClass = 'absolute flex z-[2] flex justify-center rounded';
  const expandClasses = expand && 'inset-0';
  const centerSpinnerClass = verticallyCenter && 'items-center';

  return cx(
    baseClass,
    backgroundColorClass(backgroundColor),
    expandClasses,
    centerSpinnerClass,
    className,
  );
}

function backgroundColorClass(backgroundColor: BoundaryBackgroundColors): string | null {
  switch (backgroundColor) {
    case 'white':
      return 'bg-white/80';
    case 'gray':
      return 'bg-gray/80';
    default:
      return null;
  }
}
