import AssetCard from '@app/components/Onboarding/AssetCard';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingHowCanDriversMaximizeSavings() {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="max-w-[553px]">
      <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
        <Header title="How can I make sure my drivers maximize savings and discounts when buying fuel?" />
        <AssetCard
          image={
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="./images/onboarding/invite-drivers-to-the-atob-mobile-app-desktop.png"
              />
              <img
                className="w-full"
                src="./images/onboarding/invite-drivers-to-the-atob-mobile-app.png"
              />
            </picture>
          }
          title="Invite Drivers to the AtoB mobile app"
          description="Invite your drivers to the AtoB mobile app so they can use the fuel map themselves to plan their own routes."
        />
        <AssetCard
          image={
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="./images/onboarding/look-for-the-atob-savings-icon-desktop.png"
              />
              <img
                className="w-full"
                src="./images/onboarding/look-for-the-atob-savings-icon.png"
              />
            </picture>
          }
          title="Look for the AtoB Savings icon on each transaction"
          description="We'll show you when you earned discounts and how much you saved, and when you missed savings by fueling at a non-discounted station."
        />
        <AssetCard
          image={
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="./images/onboarding/use-the-fuel-map-desktop.png"
              />
              <img className="w-full" src="./images/onboarding/use-the-fuel-map.png" />
            </picture>
          }
          title="Use the Fuel map"
          description="Use the fuel map to look up where your drivers should fuel, and directly share that station suggestion to your drivers."
        />
        <FooterActionButtons hideBackButton handleClickNext={goToNextStep} />
      </div>
    </div>
  );
}
