import { TelematicsSetting } from '@app/@types/card.types';
import { ResourceTagData } from '@app/@types/tag.types';
import { Divider } from '@app/components/elements';
import Card, { CardHeader } from '@atob-developers/shared/src/components/Card';
import { ReactElement } from 'react';
import AssignSectionCardAuth from '../../components/CardDetails/AssignSection/AssignSectionCardAuth';
import AssignSectionTelematicsAuth from '../../components/CardDetails/AssignSection/AssignSectionTelematicsAuth';

interface AssignSectionCardProps {
  cardSecurityEnabled: boolean;
  resourceTag: ResourceTagData;
  cardId: string | number;
  telematicsEnabled: boolean;
  telematicsSetting: TelematicsSetting;
  smsUnlockEnabled: boolean;
}

const SecurityDetailCardContent = ({
  cardSecurityEnabled,
  resourceTag,
  cardId,
  telematicsEnabled,
  telematicsSetting,
  smsUnlockEnabled,
}: AssignSectionCardProps) => {
  return (
    <div className="flex flex-col gap-2 px-2 py-2">
      <div className="flex-col justify-between gap-4">
        {smsUnlockEnabled && (
          <AssignSectionCardAuth
            cardSecurityEnabled={cardSecurityEnabled}
            cardId={cardId}
            readonly={!!resourceTag}
          />
        )}
        {smsUnlockEnabled && telematicsEnabled && <Divider />}
        {telematicsEnabled && (
          <AssignSectionTelematicsAuth
            telematicsSetting={telematicsSetting}
            cardId={cardId}
            readonly={!!resourceTag}
          />
        )}
      </div>
    </div>
  );
};

const SecurityDetailCard = ({
  cardSecurityEnabled,
  resourceTag,
  cardId,
  telematicsEnabled,
  telematicsSetting,
  smsUnlockEnabled,
}: AssignSectionCardProps): ReactElement => {
  return (
    <>
      <div className="triumph:hidden block sm:hidden">
        <CardHeader title="Card Security" />
        <SecurityDetailCardContent
          cardId={cardId}
          resourceTag={resourceTag}
          cardSecurityEnabled={cardSecurityEnabled}
          telematicsEnabled={telematicsEnabled}
          telematicsSetting={telematicsSetting}
          smsUnlockEnabled={smsUnlockEnabled}
        />
      </div>
      <div className="triumph:block hidden h-full sm:block">
        <Card>
          <CardHeader title="Card Security" />
          <SecurityDetailCardContent
            cardId={cardId}
            resourceTag={resourceTag}
            cardSecurityEnabled={cardSecurityEnabled}
            telematicsEnabled={telematicsEnabled}
            telematicsSetting={telematicsSetting}
            smsUnlockEnabled={smsUnlockEnabled}
          />
        </Card>
      </div>
    </>
  );
};

export default SecurityDetailCard;
