import currency from 'currency.js';

export default function currencyFormat({
  value,
  onlyWholeNumber = false,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  onlyWholeNumber?: boolean;
}): string {
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-argument
  if (isNaN(value)) return '';
  const options = {
    style: 'currency',
    currency: 'USD',
    ...(onlyWholeNumber
      ? {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }
      : {}),
  };
  // eslint-disable-next-line  @typescript-eslint/no-unsafe-argument
  return new Intl.NumberFormat('en-US', options as Intl.NumberFormatOptions).format(value);
}

export const getCurrencyFromCents = (value: number): string => {
  return formatCurrency({
    value,
    options: {
      fromCents: true,
    },
  });
};

export function formatCurrency({
  value,
  options = {},
}: {
  value: currency.Any;
  options?: {
    fromCents?: boolean;
    precision?: number;
    separator?: string;
    pattern?: string;
  };
}): string {
  const defaultOptions = { fromCents: true, precision: 2 };
  options = { ...defaultOptions, ...options };
  const negativePattern = '- !#';

  return currency(value, { negativePattern, ...options }).format();
}

export function formatCurrencyMultiplyBy({
  ...args
}: {
  value: currency.Any;
  multiplyValue?: number;
  options?: { fromCents?: boolean; precision?: number; separator?: string; pattern?: string };
}): string {
  return multiplyCurrency(args).format();
}

export function multiplyCurrency({
  value,
  multiplyValue = 1,
  options = {},
}: {
  value: currency.Any;
  multiplyValue?: number;
  options?: { fromCents?: boolean; precision?: number; separator?: string; pattern?: string };
}): currency {
  const defaultOptions = { fromCents: true, precision: 2 };
  options = { ...defaultOptions, ...options };
  const negativePattern = '- !#';

  return currency(value, { negativePattern, ...options }).multiply(multiplyValue);
}

export function convertDollarsToCents({ value }: { value: number }): {
  value: currency.Any;
} {
  return currency(value).multiply(100);
}

export function convertCentsToDollars({ value }: { value: number }): {
  value: currency.Any;
  format: () => string;
} {
  return currency(value, { precision: 2 }).divide(100);
}

export const formatInputForMoneyInput = (value: string, onlyWholeNumber = true): string => {
  if (!value) return '';
  // allow only integers

  if (onlyWholeNumber) {
    const integerPortion = value.split('.')[0];
    const integerPortionWithOnlyNumbers = integerPortion.replaceAll(/[^\d]/gm, '');
    return currencyFormat({
      value: integerPortionWithOnlyNumbers,
      onlyWholeNumber: true,
    }).slice(1);
  } else {
    return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const convertNumbersWithCurrency = (stringNum: string): number => {
  return Number(stringNum?.replace(/[^\d.]/g, ''));
};

export function formatCurrencyInputWithPrefix(value: string, blur = false) {
  let formattedValue = value || '0.00';
  const onlyWholeNumber = false;
  const isNegative = (value.startsWith('-') || value?.[1] === '-') && formattedValue !== '- $';

  if (formattedValue.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from being entered
    const decimal_pos = formattedValue.indexOf('.');

    // split number by decimal point
    let left_side = formattedValue.substring(0, decimal_pos);
    let right_side = formattedValue.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatInputForMoneyInput(left_side, onlyWholeNumber);

    // validate right side
    right_side = formatInputForMoneyInput(right_side, onlyWholeNumber);

    // On blur make sure 2 numbers after decimal
    if (blur) {
      right_side += '00';
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    formattedValue = '$' + left_side + '.' + right_side;
  } else {
    // no decimal entered, add commas to number, remove all non-digits
    formattedValue = formatInputForMoneyInput(formattedValue, onlyWholeNumber);
    formattedValue = '$' + formattedValue;

    if (blur) {
      if (formattedValue === '$') {
        formattedValue += '0';
      }
      formattedValue += '.00';
    }
  }

  return isNegative ? '- ' + formattedValue : formattedValue;
}

export const formatCurrencyDollarOrCents = (value: number) => {
  let discountString = formatCurrency({
    value,
    options: { fromCents: true },
  }).replace('$', '');
  const isDollar = value > 100;
  if (!isDollar) {
    discountString = discountString.replace('0.', '').replace(/^0+(?!$)/, '');
  }
  return `${isDollar ? '$' : ''}${discountString}${!isDollar ? '¢' : ''}`;
};
