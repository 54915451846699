import { Entity, PaginatedEndpointResponse } from '@app/@types/api.types';
import {
  DriverSummaryData,
  PayrollTransactionData,
  TopLevelSummaryDataResponse,
} from '@app/@types/payroll.types';
import Header from '@app/components/Navigation/Header';
import { MiniSpacer } from '@app/components/elements';
import { beginningOfDay, endOfDay } from '@app/components/layout/DatePicker';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import dayjsObjectSupport from 'dayjs/plugin/objectSupport';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PayrollSuspendedBanner from '../Payroll/Banners/PayrollSuspendedBanner';
import PayrollTabs from '../Payroll/PayrollTabs';
import { mapTransactionstoState } from '../Payroll/payroll-context';
import PayrollSummaryTable from './PayrollSummaryTable';
import PayrollTaxesInformation from './PayrollTaxesInformation';
import PayrollTransactionTable from './PayrollTransactionsTable';

dayjs.extend(dayjsObjectSupport);

const initialStartDate = dayjs().month(0).date(1).set(beginningOfDay);
const initialEndDate = dayjs().set(endOfDay);

type Tab = 'summary' | 'transactions' | 'taxes';

const TABS: { tab: Tab; name: string }[] = [
  {
    tab: 'transactions',
    name: 'Transactions',
  },
  {
    tab: 'summary',
    name: 'Summary',
  },
  {
    tab: 'taxes',
    name: 'Taxes',
  },
];

export default function PayrollHistory(): ReactElement {
  const [summaryData, setSummaryData] = useState<DriverSummaryData[]>([]);
  const [allPayrollTransactions, setAllPayrollTransactions] = useState<PayrollTransactionData[]>(
    [],
  );
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const isTaxes = location.pathname.includes('taxes');

  const [currentTab, setCurrentTab] = useState<Tab>(isTaxes ? 'taxes' : 'transactions');
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    const fetchPayrollHistoryData = async () => {
      setLoading(true);
      const params = {
        'transaction_date[since]': startDate,
        'transaction_date[until]': endDate,
        'include_itemizations': true,
      };
      const [topLevelSummaryData, transactionData] = await Promise.all([
        axios.get<TopLevelSummaryDataResponse>('/payroll/transactions/summary', { params: params }),
        axios.get<PaginatedEndpointResponse<Entity<PayrollTransactionData>>>(
          '/payroll/transactions',
          { params: { ...params, include_itemizations: true, per: 400 } },
        ),
      ]);

      const topLevelData = { ...topLevelSummaryData.data.data.attributes, name: 'All Drivers' };

      const payrollHistorySummaryData: DriverSummaryData[] = [
        topLevelData,

        ...topLevelSummaryData.data.data.attributes.driver_summaries.data.map(
          (driverData: Entity<DriverSummaryData>) => {
            return {
              name: driverData.attributes.name,
              paid_last_week: driverData.attributes.paid_last_week,
              paid_this_month: driverData.attributes.paid_this_month,
              paid_in_daterange: driverData.attributes.paid_in_daterange,
            };
          },
        ),
      ];

      const transactions = deserialize(transactionData.data).data;
      const mappedPayrollTransactions = mapTransactionstoState(transactions);

      setSummaryData(payrollHistorySummaryData);
      setAllPayrollTransactions(mappedPayrollTransactions);
      setLoading(false);
    };

    fetchPayrollHistoryData();
  }, [startDate, endDate]);

  return (
    <PageContentWrapper
      header={<Header title="Driver Pay" />}
      pageTabs={<PayrollTabs tab="/payroll/history" />}
    >
      <PayrollSuspendedBanner />
      <div>
        <UnderlinedTabs
          selectedTab={currentTab}
          handleTabChange={(tab) => setCurrentTab(tab)}
          tabs={TABS}
        />
        {currentTab === 'taxes' ? (
          <PayrollTaxesInformation />
        ) : (
          <div className="py-2">
            <div>
              <h3 className="mb-3 font-bold">Payment Dates</h3>
              <DateRangePicker
                localeText={{ start: 'Start Date', end: 'End Date' }}
                value={[startDate, endDate]}
                onChange={([newStartDate, newEndDate]) => {
                  if (newStartDate) {
                    setStartDate(newStartDate);
                  }
                  if (newEndDate) {
                    setEndDate(newEndDate);
                  }
                }}
              />
            </div>
            <MiniSpacer />
            {currentTab === 'summary' ? (
              <PayrollSummaryTable
                transactions={summaryData}
                startDate={startDate}
                endDate={endDate}
              />
            ) : (
              <PayrollTransactionTable transactions={allPayrollTransactions} loading={loading} />
            )}
          </div>
        )}
      </div>
    </PageContentWrapper>
  );
}
