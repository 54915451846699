import { ReactNode } from 'react';
import { USACountryIcon } from '../icons';

export type CountryName = keyof typeof countries;

export type CountryInfo = {
  mask: string;
  placeholder: string;
  icon: ReactNode;
  dialingCode: string;
};

export const countries = {
  USA: {
    mask: '+1 (999) 999-9999',
    placeholder: '+1 (123) 456-7890',
    icon: <USACountryIcon />,
    dialingCode: '+1',
  },
};
