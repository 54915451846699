import { EndpointResponse } from '@app/@types/api.types';
import { FetcherKey, postFetcher } from '@app/utils/data/fetchers';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';
import useCustomer from '../useCustomer';
import { CUSTOMER_QUERY_KEY } from './useCustomerQuery';

// *** KEEP IN SYNC WITH CustomerOnboardingEvent IN BACKEND ***
export enum CustomerOnboardingEventName {
  PAYMENT_METHOD_CONNECTED = 'PAYMENT_METHOD_CONNECTED',
  CUSTOMER_PORTAL_LOGIN = 'CUSTOMER_PORTAL_LOGIN',
  CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION = 'CUSTOMER_PORTAL_DISCOUNT_AND_PERKS_IMPRESSION',
  CUSTOMER_PAID_SETUP_FEE = 'CUSTOMER_PAID_SETUP_FEE',
  CUSTOMER_ADD_FUNDS = 'CUSTOMER_ADD_FUNDS',
  DRIVER_APP_LOGIN = 'DRIVER_APP_LOGIN',
  FLEET_MANAGER_APP_LOGIN = 'FLEET_MANAGER_APP_LOGIN',
  CUSTOMER_PORTAL_DRIVER_ADDED = 'CUSTOMER_PORTAL_DRIVER_ADDED',
  CUSTOMER_PORTAL_VEHICLE_ADDED = 'CUSTOMER_PORTAL_VEHICLE_ADDED',
  CUSTOMER_PORTAL_CARD_ASSIGNED = 'CUSTOMER_PORTAL_CARD_ASSIGNED',
  FIRST_TRANSACTION_SETTLED = 'FIRST_TRANSACTION_SETTLED',
  CUSTOMER_PORTAL_TRANSACTIONS_IMPRESSION = 'CUSTOMER_PORTAL_TRANSACTIONS_IMPRESSION',
  FULL_WCL_SPEND_SUCCESS = 'FULL_WCL_SPEND_SUCCESS',
  CUSTOMER_ONBOARDING_COMPLETED = 'CUSTOMER_ONBOARDING_COMPLETED',
  UBER_FREIGHT_FUEL_FINDER_IMPRESSION = 'UBER_FREIGHT_FUEL_FINDER_IMPRESSION',
  CUSTOMER_TELEMATICS_CONNECTED = 'CUSTOMER_TELEMATICS_CONNECTED',
  CUSTOMER_SKIP_TELEMATICS = 'CUSTOMER_SKIP_TELEMATICS',
  CUSTOMER_APP_NOTIFICATION_SELECTED = 'CUSTOMER_APP_NOTIFICATION_SELECTED',
  CUSTOMER_OWNER_APP_DOWNLOAD = 'CUSTOMER_OWNER_APP_DOWNLOAD',
  CUSTOMER_ONBOARDING_DRIVER_INVITES = 'CUSTOMER_ONBOARDING_DRIVER_INVITES',
  CUSTOMER_SKIP_ONBOARDING_DRIVER_INVITE = 'CUSTOMER_SKIP_ONBOARDING_DRIVER_INVITE',
  CUSTOMER_SECURITY_DEPOSIT_PAID = 'CUSTOMER_SECURITY_DEPOSIT_PAID',

  // New onboarding flow events
  CUSTOMER_ONBOARDING_ACTIVATION_ACKNOWLEDGED = 'CUSTOMER_ONBOARDING_ACTIVATION_ACKNOWLEDGED',
  CUSTOMER_MANAGE_SPEND_ACKNOWLEDGED = 'CUSTOMER_MANAGE_SPEND_ACKNOWLEDGED',
  CUSTOMER_SUMMARY_COMPLETED = 'CUSTOMER_SUMMARY_COMPLETED',
  CUSTOMER_SKIP_MANAGE_SPEND = 'CUSTOMER_SKIP_MANAGE_SPEND',
  CUSTOMER_SKIP_ADD_VEHICLES = 'CUSTOMER_SKIP_ADD_VEHICLES',
  CUSTOMER_SKIP_ADD_DRIVERS = 'CUSTOMER_SKIP_ADD_DRIVERS',
  CUSTOMER_SKIP_INVITE_DRIVERS = 'CUSTOMER_SKIP_INVITE_DRIVERS',
}
// *** KEEP IN SYNC WITH CustomerOnboardingEvent IN BACKEND ***

export type CustomerOnboardingEventResponse = {
  id: string | null;
  metadata: string | null;
  name: CustomerOnboardingEventName;
  value: string | null;
};

function useCustomerOnboardingEvents(): Set<CustomerOnboardingEventName> {
  const customer = useCustomer();
  return useMemo(
    () => new Set((customer.customer_onboarding_events ?? []).map((event) => event.name)),
    [customer.customer_onboarding_events],
  );
}

export type CreateCustomerOnboardingEventParam = {
  customer_onboarding_event: { name: CustomerOnboardingEventName };
};

export function useCreateCustomerOnboardingEvent(
  { reloadCustomer }: { reloadCustomer: boolean } = { reloadCustomer: true },
): SWRMutationResponse<
  EndpointResponse<undefined>,
  AxiosError,
  FetcherKey,
  CreateCustomerOnboardingEventParam
> {
  const { mutate } = useSWRConfig();
  return useSWRMutation<
    EndpointResponse<undefined>,
    AxiosError,
    FetcherKey,
    CreateCustomerOnboardingEventParam
  >(
    {
      url: '/customer_onboarding_events/create_customer_onboarding_event',
    },
    postFetcher,
    {
      onSuccess: () => {
        if (reloadCustomer) {
          mutate(CUSTOMER_QUERY_KEY);
        }
      },
    },
  );
}

export default useCustomerOnboardingEvents;
