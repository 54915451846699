import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { ChargeEventData } from '@app/@types/charge_events.types';
import NoTransactions from '@app/assets/images/svg/no-transactions.svg';
import { TransactionsFilters, TRANSACTIONS_TABS } from '@app/constants/transactions';
import useChargeEvents from '@app/hooks/useChargeEvents';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorNotification } from '../../components/layout';
import TransactionsList from '../Transactions/TransactionsList';

interface AccountOverviewTransactionsProps {
  chargeEvents: PaginatedEndpointResponse<ChargeEventData> | undefined;
  loading: boolean;
  tab: TransactionsFilters;
}

export const PER_PAGE = 7;

const DEFAULT_PARAMS = {
  'page': 1,
  'per': PER_PAGE,
  'filter_authorized_at[to]': null,
  'filter_authorized_at[from]': null,
};

const AccountOverviewTransactions = (): ReactElement => {
  const [currentTab, setCurrentTab] = useState<TransactionsFilters>(TransactionsFilters.ALL);

  const filterParams =
    currentTab === TransactionsFilters.ALL
      ? {
          ...DEFAULT_PARAMS,
        }
      : {
          ...DEFAULT_PARAMS,
          auth_approved: currentTab === TransactionsFilters.APPROVED,
        };

  const { data: chargeEvents, error, isLoading } = useChargeEvents('/charge_events', filterParams);

  const filteredData = chargeEvents?.data ?? [];

  return (
    <>
      <UnderlinedTabs
        className="mt-3 pl-6 md:mt-0 md:h-12"
        selectedTab={currentTab}
        handleTabChange={(tab) => setCurrentTab(tab as TransactionsFilters)}
        tabs={TRANSACTIONS_TABS}
      />
      {error ? (
        <ErrorNotification error={error.message} />
      ) : (
        <AccountOverviewTransactionList
          chargeEvents={chargeEvents}
          loading={isLoading}
          tab={currentTab}
        />
      )}
      {filteredData?.length > 0 && (
        <div className="pb-0.5 pt-4 text-center">
          <Link to="/transactions">
            <span className="text-secondary cursor-pointer text-sm underline">
              View All Transactions
            </span>
          </Link>
        </div>
      )}
    </>
  );
};

const AccountOverviewTransactionList = ({
  chargeEvents,
  loading,
  tab,
}: AccountOverviewTransactionsProps): ReactElement => {
  const filteredData = chargeEvents?.data ?? [];

  if (filteredData?.length === 0 && !loading) {
    return (
      <EmptyTable
        imgComponent={<img src={NoTransactions} alt="" />}
        title="No transactions found"
        subtitle="Transactions will display here when you start using this card"
      />
    );
  }

  return (
    <TransactionsList
      rows={filteredData}
      isLoading={loading}
      currentTab={tab}
      cardSecurityEnabled={false}
      pageNavPrefix="transactions/"
    />
  );
};

export default AccountOverviewTransactions;
