import { PayrollTransactionData } from '@app/@types/payroll.types';
import Pagination from '@atob-developers/shared/src/components/Pagination';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionsTableExpandedRow } from './DesktopTransactionsTable';

interface MobileTransactionsTableProps {
  transactions: PayrollTransactionData[];
}

export default function MobileTransactionsTable({
  transactions,
}: MobileTransactionsTableProps): ReactElement {
  const [page, setPage] = useState(1);

  const [expandRowsIds, setExpandRowsIds] = useState<string[]>([]);
  const rowsPerPage = 10;
  const sliceData = (transactions: PayrollTransactionData[], page: number, rowsPerPage: number) => {
    return transactions.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  const handleCardClick = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    if (expandRowsIds.includes(id)) {
      setExpandRowsIds((expandRowsIds) => expandRowsIds.filter((i) => i !== id));
    } else {
      setExpandRowsIds((expandRowsIds) => [...expandRowsIds, id]);
    }
  };

  const formattedDate = (date: number) => {
    return dayjs.unix(date).format('MMM D');
  };

  return (
    <div className="flex w-full flex-col gap-6 p-6 pt-8 text-sm not-italic leading-4">
      <div className="space-y-3">
        {sliceData(transactions, page, rowsPerPage).map((transaction, index) => {
          return (
            <div
              className="rounded-lg bg-white p-4"
              key={index}
              onClick={(e) => handleCardClick(e, transaction.id)}
            >
              <div className="flex w-full flex-row">
                <div className="flex w-full flex-col gap-y-2">
                  <div className="flex w-full justify-between pb-1">
                    <Link to={`/payroll/driver/${transaction.driverId}`}>
                      <div className="text-black0 font-medium">{transaction.name}</div>
                    </Link>

                    <div className="text-black2 font-normal">
                      {capitalize(transaction.payment_status)}
                    </div>
                  </div>
                  <div className="text-black0 flex gap-x-4 font-medium opacity-70">
                    <div className="flex w-full">{formattedDate(transaction.payment_date)}</div>
                    <div className="flex w-full items-center">{transaction.amount}</div>
                    <div className="flex w-full items-center">{transaction.payment_card}</div>
                  </div>
                  <div className="mt-2 flex w-full items-center">"{transaction.description}"</div>
                </div>
              </div>
              {expandRowsIds.includes(transaction.id) && (
                <TransactionsTableExpandedRow data={transaction} />
              )}
            </div>
          );
        })}
      </div>
      <Pagination
        key={Math.ceil(transactions.length / rowsPerPage)}
        pages={Math.ceil(transactions.length / rowsPerPage)}
        currentPage={page}
        onPageChange={setPage}
      />
    </div>
  );
}
