import { faCheck, faInfoCircle, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch as MuiSwitch, SwitchProps } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

type Props = SwitchProps & {
  type?: 'default' | 'status';
  available?: boolean;
  title?: string;
  description?: string;
  statusLabels?: { allowed: string; restricted: string };
};

export default function Switch({
  type = 'default',
  available = true,
  statusLabels,
  title,
  description,
  checked,
  disabled,
  ...props
}: Props): ReactElement {
  const defaultSwitch = (
    <div className="flex">
      <MuiSwitch
        disabled={disabled}
        checked={checked}
        readOnly={disabled}
        color="default"
        {...props}
      />
      <div className={classNames('flex flex-col pl-2 pt-1', { 'opacity-50': disabled })}>
        <div className="text-primary text-sm font-medium">{title}</div>
        <p className="font-regular text-secondary text-sm">{description}</p>
      </div>
    </div>
  );

  const statusSwitch = (
    <>
      {available ? (
        <div className="flex items-center md:justify-between">
          {statusLabels && (
            <span
              className={classNames('text-primary pr-2 text-sm font-medium', {
                'opacity-50': disabled,
              })}
            >
              {checked ? statusLabels.allowed : statusLabels.restricted}
            </span>
          )}
          <MuiSwitch
            {...props}
            color="red-green"
            disabled={disabled}
            checked={checked}
            readOnly={disabled}
            icon={
              <div className="bg-level-0 flex h-4 w-4 items-center justify-center rounded-3xl">
                <FontAwesomeIcon
                  icon={faXmark}
                  className={classNames('text-error-1 text-xs', { 'opacity-50': disabled })}
                />
              </div>
            }
            checkedIcon={
              <div className="bg-level-0 flex h-4 w-4 items-center justify-center rounded-3xl">
                <FontAwesomeIcon
                  icon={faCheck}
                  className={classNames('text-accent-11 text-xs', { 'opacity-50': disabled })}
                />
              </div>
            }
          />
        </div>
      ) : (
        <span className="text-secondary mr-2 text-sm">
          <FontAwesomeIcon icon={faInfoCircle} /> Unavailable
        </span>
      )}
    </>
  );
  return <>{type === 'default' ? defaultSwitch : statusSwitch}</>;
}
