import { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import { PHONE_ERROR_MESSAGE, PHONE_REGEX } from '@atob-developers/shared/src/utils/phoneUtils';
import * as Yup from 'yup';

export type DriverFormDataType = {
  label: string;
  type: DataItemType;
  path:
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'phone'
    | 'notes'
    | 'account_status'
    | 'unlock_id'
    | 'external_username';
  required: boolean;
  validation?: Yup.StringSchema;
  validationOnChange?: Yup.StringSchema;
  synchronized: boolean;
};

export const DRIVER_FORM_DATA: DriverFormDataType[] = [
  {
    label: 'First Name',
    type: DataItemType.TEXT,
    path: 'first_name',
    required: true,
    validation: Yup.string().min(2).required(),
    synchronized: true,
  },
  {
    label: 'Last Name',
    type: DataItemType.TEXT,
    path: 'last_name',
    required: false,
    synchronized: true,
  },
  {
    label: 'Email',
    type: DataItemType.TEXT,
    path: 'email',
    required: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    validation: Yup.string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .email('Incorrect format. Example: test@gmail.com')
      .nullable(),
    synchronized: true,
  },
  {
    label: 'Phone Number',
    type: DataItemType.TEXT,
    path: 'phone',
    required: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    validation: Yup.string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(PHONE_REGEX, { message: PHONE_ERROR_MESSAGE.INVALID_LENGTH })
      .nullable(),
    validationOnChange: Yup.string().max(14, PHONE_ERROR_MESSAGE.INVALID_LENGTH),
    synchronized: true,
  },
  {
    label: 'Unlock ID',
    type: DataItemType.TEXT,
    path: 'unlock_id',
    required: false,
    synchronized: false,
  },
  {
    label: 'Notes',
    type: DataItemType.TEXT,
    path: 'notes',
    required: false,
    synchronized: true,
  },
  {
    label: 'External Username',
    type: DataItemType.TEXT,
    path: 'external_username',
    required: false,
    synchronized: true,
  },
  {
    label: 'AtoB App',
    type: DataItemType.TEXT,
    path: 'account_status',
    required: false,
    synchronized: false,
  },
];
