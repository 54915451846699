import {
  ReactivateCesOrUnlimitedParams,
  ReactivationOption,
} from '@app/components/Modals/CardModals/ReactivateCesOrUnlimitedOfferModal';
import { ModalOptionData, ModalOptions } from '@app/components/Modals/ModalOptions/ModalOptions';
import { useIsUberfreight } from '@app/hooks/partners/useIsUberfreight';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const CesOrUnlimitedSelect = ({
  params,
  setParams,
  onNext,
  closeModal,
}: {
  params: ReactivateCesOrUnlimitedParams;
  setParams: (_: ReactivateCesOrUnlimitedParams) => void;
  onNext: () => void;
  closeModal: () => void;
}): ReactElement => {
  const isUF = useIsUberfreight(true);

  const options: ModalOptionData[] = [
    {
      id: 'ces',
      heading: 'Reactivate with Credit',
      subheading: '',
      description: [
        '30 cents per gallon surcharge on all fuel transactions for 4 weeks*',
        '*surcharge is removed after 4 weeks of on-time payments',
      ],
    },
    {
      id: 'unlimited',
      heading: isUF ? 'Reactivate with Prepaid' : 'Reactivate with AtoB Unlimited',
      subheading: '',
      description: ['Add funds to your wallet to use your prepaid card'],
    },
  ];

  const handleContinue = () => {
    onNext();
  };

  return (
    <>
      <ModalHeader title="Reactivate your account" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-base text-gray-700">
          <div className="xs:mb-6 my-2 text-sm">
            Choose reactivation conditions. Detailed terms on next screen.
          </div>
          <ModalOptions
            options={options}
            selectedOptionId={params.reactivation_option}
            setSelectedOptionId={(reactivation_option) =>
              setParams({
                ...params,
                reactivation_option: reactivation_option as ReactivationOption,
              })
            }
          />
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-center md:justify-end">
          <Button className="w-full md:w-1/2" color="primary" onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
