import { getFetcher } from '@app/utils/data/fetchers';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';

export type EmbeddedDashboardUrl = {
  url: string;
};

export const useEmbeddedDashboardUrl = (
  dashboard_name: string,
): SWRResponse<EmbeddedDashboardUrl, Error> => {
  // Use SWRImmutable to fetch the dashboard URL
  return useSWRImmutable<EmbeddedDashboardUrl>(
    { url: `/metabase/dashboard_embedding/build_url`, params: { dashboard_name } },
    getFetcher,
  );
};
