import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { DeviceCounts, DeviceData } from '@app/@types/device.types';
import AddDeviceModal from '@app/components/Devices/AddDeviceModal';
import DevicesList from '@app/components/Devices/DevicesList';
import Header from '@app/components/Navigation/Header';
import { Loading } from '@app/components/layout';
import ErrorNotification from '@app/components/layout/ErrorNotification';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher, getFetcher } from '@app/utils/data/fetchers';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMobile, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, ChangeEvent, useMemo, useState } from 'react';
import useSWR from 'swr';

interface DevicesPageHeaderProps {
  onSearchChange: ((e: ChangeEvent<HTMLInputElement>) => void) | 'no-search';
  searchValue: string;
  setShowDeviceModal: () => void;
}

const DevicesPageHeader = ({
  onSearchChange,
  searchValue,
  setShowDeviceModal,
}: DevicesPageHeaderProps): ReactElement => {
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('lg'));
  const menuItem = useMemo(
    () => ({
      value: 'Add a device',
      onClick: setShowDeviceModal,
      disabled: () => false,
      order: 1,
    }),
    [setShowDeviceModal],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <Header
      title="Devices"
      mobileStickyButton={
        <StickyButtonWrapper>
          <Button
            size="small"
            fullWidth={fullWidth}
            onClick={setShowDeviceModal}
            startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" />}
          >
            Add a device
          </Button>
        </StickyButtonWrapper>
      }
      rightContent={
        <div className="flex max-w-[585px] flex-col content-center justify-end sm:flex-row">
          <div className="ml-3 flex max-w-full flex-wrap gap-4 sm:ml-3 sm:max-w-[313px]">
            <Button
              size="small"
              fullWidth={fullWidth}
              onClick={setShowDeviceModal}
              startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" />}
            >
              Add a device
            </Button>
          </div>
        </div>
      }
      searchBar={
        onSearchChange !== 'no-search' && (
          <Searchbar
            placeholder="Search devices"
            name="search"
            onChange={onSearchChange}
            value={searchValue}
          />
        )
      }
    />
  );
};

export const DevicesPage = (): ReactElement => {
  const [queryInfo, setQueryInfo] = useUrlState<{
    page: number;
    query: string;
    sortBy: string | null;
  }>({ query: '', sortBy: null, page: 1 });
  const {
    data,
    isLoading,
    error: pagingError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<DeviceData>>(
    {
      url: '/devices',
      params: {
        archived_at: 'none',
        page: queryInfo.page,
        per: DEFAULT_PAGE_SIZE,
        all: false,
        like: queryInfo.query !== '' ? queryInfo.query : undefined,
        sort: queryInfo.sortBy ? [queryInfo.sortBy] : undefined,
      },
    },
    apiGetFetcher,
  );

  const [showDeviceModal, setShowDeviceModal] = useState<boolean>(false);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQueryInfo({ ...queryInfo, query: e.target.value, page: 1 });
  };

  const toggleDeviceModal = () => setShowDeviceModal(!showDeviceModal);

  const {
    data: devicesCountsRaw,
    error: devicesCountError,
    mutate: devicesCountRefresh,
  } = useSWR<DeviceCounts>({ url: '/devices/counts' }, getFetcher);

  const devicesCounts = useMemo(() => {
    if (devicesCountsRaw == null) {
      return null;
    }
    return {
      hasAny: devicesCountsRaw?.total > 0,
      hasActive: devicesCountsRaw?.archived !== devicesCountsRaw?.total,
      hasArchived: devicesCountsRaw?.archived > 0,
      hasOnlyArchived: devicesCountsRaw?.archived === devicesCountsRaw?.total,
    };
  }, [devicesCountsRaw]);

  if (pagingError || devicesCountError) {
    return (
      <ErrorNotification error="We're having issues loading your device data. Please try again or if the issue persists, contact support." />
    );
  } else if (devicesCounts === null || data?.meta === null) {
    return <Loading />;
  }
  const reload = () => {
    mutate();
    devicesCountRefresh();
  };

  return (
    <PageContentWrapper
      className="pb-12"
      header={
        <DevicesPageHeader
          searchValue={queryInfo.query}
          onSearchChange={handleSearch}
          setShowDeviceModal={toggleDeviceModal}
        />
      }
    >
      <AddDeviceModal open={showDeviceModal} toggle={toggleDeviceModal} refreshData={reload} />
      {devicesCounts.hasAny && !devicesCounts.hasOnlyArchived ? (
        <DevicesList
          rows={data?.data ?? []}
          paginationMeta={data?.meta}
          refreshData={reload}
          loading={isLoading}
          onPageIndexChange={(num) => setQueryInfo({ ...queryInfo, page: num })}
          onSortModelChange={(model: GridSortModel) => {
            const sortByModel = model && model[0];
            if (!sortByModel) setQueryInfo({ ...queryInfo, sortBy: null });
            const { field, sort: order } = sortByModel;
            setQueryInfo({ ...queryInfo, sortBy: `${field}:${order}` });
          }}
        />
      ) : (
        <EmptyTable
          icon={faMobile}
          title="Add your first device"
          subtitle="(Optional)"
          buttonText="Add a Device"
          onClick={toggleDeviceModal}
        />
      )}
    </PageContentWrapper>
  );
};

export default DevicesPage;
