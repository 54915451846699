// These values come from https://github.com/AtoB-Developers/backend/blob/main/app/controllers/products_controller.rb
// That controller has the entire logic for each of these values

const ProductsList = [
  // Please keep sorted!
  'accounting',
  'admin',
  'billing',
  'bookkeeper_notification_settings',
  'card_requests',
  'cards_spend_limit',
  'cards',
  'challenge_match',
  'cli_requests',
  'dashboard',
  'devices',
  'driver_notification_settings',
  'drivers_unlock_id',
  'drivers',
  'engaged_customer',
  'engaged_onboarding_banner',
  'engaged_promotion_banner',
  'engaged_wallet_default',
  'engaged_wallet_existing',
  'fleetio',
  'fuel_card',
  'fuel_finder',
  'funding_requests',
  'funding_with_manual_requests',
  'integrations',
  'invoice_factoring',
  'logout',
  'nonfuel_sprint_cohort_1',
  'nonfuel_sprint_cohort_2',
  'nonfuel_sprint_cohort_3',
  'notification_settings',
  'onboarding',
  'oon_fee_card_fee_waived',
  'oon_fee_discount_boost',
  'partnership',
  'payment_methods',
  'payroll_history',
  'payroll_overview',
  'payroll',
  'perks',
  'premium',
  'prepaid',
  'promote_atob_plus',
  'quickbooks',
  'refer_and_earn',
  'referrals',
  'reporting',
  'resource_tags',
  'security_deposits',
  'settings_update_email',
  'settings',
  'show_subscription_payments',
  'sms_unlock',
  'spend_restrictions',
  'suspicious_activities',
  'ta_petro_banner',
  'telematics',
  'transactions_allow_restrict_merchant',
  'treasury_ach_debit',
  'treasury_trusted',
  'treasury',
  'treasury',
  'unlimited_activation_retention_promo',
  'unlimited_to_credit_requests',
  'unlimited_to_flex_promo_v2',
  'unlimited_to_flex_promo',
  'upside',
  'vehicles',
  'vehicles',
  'wallet_lite',
  'workspace_add_update_users',
  'workspace_roles',
  'workspace',
  'new_onboarding',
  'cost_plus_driver_cashback',
] as const;

type Product = (typeof ProductsList)[number];

const WorkspaceProductList = [
  'workspace_owner',
  'workspace_card_admin',
  'workspace_card_manager',
  'workspace_bookkeeper',
  'workspace_notifications_only',
] as const;

const SpendTierProductList = [
  'spend_tier_0',
  'spend_tier_1',
  'spend_tier_2',
  'spend_tier_3',
] as const;

export type WorkspaceProduct = (typeof WorkspaceProductList)[number];

export type SpendTierProduct = (typeof SpendTierProductList)[number];

export const CustomerProductList = [
  ...ProductsList,
  ...WorkspaceProductList,
  ...SpendTierProductList,
];

export type CustomerProduct = Product | WorkspaceProduct | SpendTierProduct;

export function workspaceOnly(products: CustomerProduct[]): WorkspaceProduct[] {
  return products
    .filter((p) => WorkspaceProductList.some((workspace_product) => workspace_product === p))
    .map((p) => p as WorkspaceProduct);
}

export function findSpendTier(products: CustomerProduct[]): SpendTierProduct {
  return products.find((p) =>
    SpendTierProductList.some((spend_tier) => spend_tier === p),
  ) as SpendTierProduct;
}
