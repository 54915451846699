import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

export default function OONFeeBanner(): ReactElement | null {
  const [oon_fee_discount_boost, oon_fee_card_fee_waived] = useProduct(
    'oon_fee_discount_boost',
    'oon_fee_card_fee_waived',
  );
  if (oon_fee_discount_boost) {
    return (
      <NewFeatureBanner bannerKey="oon_fee_discount_boost" variant="no_title" actionText="">
        As a valued cardholder, enjoy an additional 5¢ per gallon discount at TA, Petro, and TA
        Express locations on top of your current savings. As a reminder, a $2 fee now applies to
        fuel purchases made outside of our discount network.
      </NewFeatureBanner>
    );
  }
  if (oon_fee_card_fee_waived) {
    return (
      <NewFeatureBanner bannerKey="oon_fee_card_fee_waived" variant="no_title" actionText="">
        As a valued cardholder, enjoy waived monthly card fees and no extra charge for adding
        additional cards to your account. As a reminder, a $2 fee now applies to fuel purchases made
        outside of our discount network.
      </NewFeatureBanner>
    );
  }

  return null;
}
