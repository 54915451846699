import AssignDriver from '@app/components/AssignEntity/AssignDriver';
import AssignVehicle from '@app/components/AssignEntity/AssignVehicle';
import CardSectionTitle from '@app/components/Cards/CardSectionTitle';
import Card, { CardHeader } from '@atob-developers/shared/src/components/Card';
import axios from 'axios';
import { ReactElement } from 'react';

interface AssignSectionCardProps {
  cardId: string | number;
  vehicleId: number | undefined;
  driverId: number | undefined;
  onCardChange: () => void;
}

const AssignSectionContent = ({
  cardId,
  vehicleId,
  driverId,
  onCardChange,
}: AssignSectionCardProps) => {
  // Driver Logic
  const assignDriverToCardHandler = (driverId: string) => {
    axios
      .put(`/cards/${cardId}`, {
        card: { driver_id: driverId },
      })
      .then(() => {
        onCardChange();
      });
  };

  const removeAssignedDriver = () => {
    axios
      .put(`/cards/${cardId}`, {
        card: { driver_id: null },
      })
      .then(() => {
        onCardChange();
      });
  };

  // Vehicle logic
  const assignVehicleToCardHandler = (vehicleId: string) => {
    axios
      .put(`/cards/${cardId}`, {
        card: { vehicle_id: vehicleId },
      })
      .then(() => {
        onCardChange();
      });
  };

  const removeAssignedVehicle = () => {
    axios
      .put(`/cards/${cardId}`, {
        card: { vehicle_id: null },
      })
      .then(() => {
        onCardChange();
      });
  };

  return (
    <div className="flex flex-col px-2 py-2">
      <div className="flex-col justify-between gap-4">
        <div className="flex flex-auto flex-row gap-2">
          <div className="relative w-1/2 basis-full">
            <CardSectionTitle text="Driver" />
            <AssignDriver
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              driverId={driverId}
              onChange={(driverId) =>
                driverId ? assignDriverToCardHandler(driverId) : removeAssignedDriver()
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 pt-2 md:flex-row">
        <div className="relative w-1/2 basis-full">
          <CardSectionTitle text="Vehicle" />
          <AssignVehicle
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            vehicleId={vehicleId}
            onChange={(vehicleId) =>
              vehicleId ? assignVehicleToCardHandler(vehicleId) : removeAssignedVehicle()
            }
          />
        </div>
      </div>
    </div>
  );
};

const AssignSectionCard = (props: AssignSectionCardProps): ReactElement => (
  <>
    <div className="triumph:hidden block sm:hidden">
      <AssignSectionContent {...props} />
    </div>
    <div className="triumph:block hidden h-full sm:block">
      <Card>
        <CardHeader title="Assignment" />
        <AssignSectionContent {...props} />
      </Card>
    </div>
  </>
);

export default AssignSectionCard;
