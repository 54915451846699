import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useCallback, useState } from 'react';

type DriverInviteModalProps = {
  onClick: () => void;
  reset: () => void;
  isActive: boolean;
};

const DriverInviteModal = ({ onClick, reset, isActive }: DriverInviteModalProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  const inviteDrivers = useCallback(async () => {
    setIsLoading(true);
    await axios.post(`/drivers/driver_app_bulk_invite`).then(() => {
      setIsLoading(false);
      onClick();
    });
  }, [setIsLoading, onClick]);

  return (
    <Modal open={isActive} toggle={reset}>
      <ModalHeader title={'AtoB App Invite'} onClose={reset} />
      <ModalBodyContent>
        <div className="flex w-full flex-col items-baseline justify-between">
          <div>
            <p className="mb-4 text-base">
              All drivers with phone numbers will receive a text message to download the AtoB App if
              they have not already been invited.
            </p>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-center gap-2">
          <Button color="secondary" onClick={reset}>
            Cancel
          </Button>
          <LoadingButton color="primary" loading={isLoading} onClick={inviteDrivers}>
            Send Invites
          </LoadingButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DriverInviteModal;
