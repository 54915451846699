import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import ErrorMessage from '@app/components/PaymentModal/PaymentErrors/ErrorMessage';
import { formatCurrencyInputWithPrefix } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { TextField } from '@mui/material';
import { ReactElement, useState } from 'react';
import ChangeText from './ChangeText';

interface AmountItemProps {
  amount: string;
  setAmount: (amount: string) => void;
  setError: (message: string | null) => void;
  error: string | null;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const AmountItem = (props: AmountItemProps): ReactElement => {
  const [inputAllowed, setInputAllowed] = useState(false);
  const { error, showAmountDue } = props;

  return (
    <PaymentDetailItem text="Amount">
      <div className="flex max-w-[150px] flex-col">
        <AmountInput {...props} inputAllowed={inputAllowed} />
        {showAmountDue && <span>(Amount Due)</span>}
        <ChangeText onClick={() => setInputAllowed(!inputAllowed)}>
          {inputAllowed ? 'Save' : 'Change'}
        </ChangeText>
        {error && <ErrorMessage text={error} />}
      </div>
    </PaymentDetailItem>
  );
};

const AmountInput = ({
  amount,
  setAmount,
  setError,
  inputAllowed,
  setShowAmountDue,
}: AmountItemProps & {
  inputAllowed: boolean;
}): ReactElement => {
  if (!inputAllowed) {
    return <span>{amount}</span>;
  }
  return (
    <>
      <TextField
        id="payNow-amount"
        size="small"
        value={amount}
        onChange={(e) => {
          setError(null);
          setShowAmountDue(false);
          setAmount(formatCurrencyInputWithPrefix(e.target.value, false));
        }}
        onBlur={() => {
          setAmount(formatCurrencyInputWithPrefix(amount, true));
        }}
        disabled={!inputAllowed}
        inputProps={{ 'data-testid': 'PaymentDetailSectionItem-Input' }}
      />
    </>
  );
};

export default AmountItem;
