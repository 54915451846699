import GlobalBanners from '@app/components/Banner/GlobalBanners';
import PrepaidBanners from '@app/components/Banner/PrepaidBanners';
import NavBar from '@app/components/Navigation/NavBar';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import MWebToAppPopup from '@app/components/popups/components/MWebToAppPopup';
import MarketingPopupRoot from '@app/components/popups/components/MarketingPopupRoot';
import MobileNavHeaderMenuContextComponent from '@app/contexts/MobileNavHeaderMenuContextComponent';
import CustomerContext from '@app/contexts/customerContext';
import useProduct from '@app/hooks/useProduct';
import { ReactElement, ReactNode, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import getAppViewOverrideByProduct from '../getAppViewOverrideByProduct';
import useThemeConfiguration from '../useThemeConfiguration';

type AppContainerProps = {
  children: ReactNode;
  loading: boolean;
};

export default function AppContainer({ children, loading }: AppContainerProps): ReactElement {
  const location = useLocation();
  const [isOnboarding] = useProduct('onboarding');
  const shouldShowBanner = !location.pathname.includes('uberfreightconversion');
  const { showAppNav } = useThemeConfiguration();

  const { customer, customerError } = useContext(CustomerContext);

  const viewOverride = useMemo(
    () =>
      getAppViewOverrideByProduct({
        customer,
        error: customerError,
      }),
    [customer, customerError],
  );

  if (loading) {
    return <SpinnerBoundary />;
  }

  if (viewOverride != null) {
    return viewOverride;
  }

  return (
    // h-screen - set entire container to be 100vh
    <>
      <MWebToAppPopup />
      <MarketingPopupRoot />
      {/* TODO: Reintroduce Premium upsell banner when new NUX framework is ready */}
      <MobileNavHeaderMenuContextComponent>
        <PrepaidBanners />
        <div className="bg-grey5 relative flex min-h-screen flex-col md:flex-row">
          {showAppNav && <NavBar />}
          <div className="relative flex w-full min-w-0 flex-col md:min-h-screen">
            {shouldShowBanner && !isOnboarding && <GlobalBanners />}
            {children}
          </div>
        </div>
      </MobileNavHeaderMenuContextComponent>
    </>
  );
}
