import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { faBank } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  openPlaidModal: Function;
  isPlaidReady: boolean;
  bankAccount: BankAccountPaymentMethodCombined;
};

export default function ConnectWithPlaidCard({ openPlaidModal, isPlaidReady, bankAccount }: Props) {
  return (
    <div className="border-level-2 bg-level-0 flex w-full flex-col items-center gap-6 rounded-2xl border p-6">
      <div className="flex flex-col items-center gap-4">
        <div className="bg-accent-23 flex items-center justify-center rounded-full p-3">
          <FontAwesomeIcon icon={faBank} className="h-6 w-6" />
        </div>
        <div className="flex flex-col items-center gap-1.5">
          <h4 className="text-primary text-center font-semibold md:text-lg md:leading-6">
            Connect with Plaid
          </h4>
          <p className="text-secondary text-center text-sm leading-6 md:text-base">
            Plaid connection is easy, private, and secure. Your data is protected.
          </p>
        </div>
      </div>
      <LoadingButton
        onClick={() => openPlaidModal()}
        fullWidth
        size="medium"
        disabled={!isPlaidReady || !!bankAccount}
        loading={!isPlaidReady}
      >
        Connect Bank Account
      </LoadingButton>
    </div>
  );
}
