import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export default function UnlimitedToFlexMigrationSuccessful({
  onDone,
}: {
  onDone: () => void;
}): ReactElement {
  return (
    <div className="flex flex-col items-center p-14">
      <div className="mb-3 mt-8 text-green-600">
        <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
      </div>
      <h1 className="mb-1 text-2xl font-bold">Credit Offer Accepted</h1>
      <p className="text-md mb-8 w-full text-center">
        Your credit line will be activated in a few moments. You can continue to use your existing
        cards.
      </p>
      <Button className="w-1/2" color="primary" onClick={onDone}>
        Done
      </Button>
    </div>
  );
}
