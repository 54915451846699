import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { ReactElement, useState } from 'react';
import InstantTransfersPopup from '../popups/InstantTransfersPopup';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

export default function InstantTransfersBanner(): ReactElement | null {
  const [showBanner] = useFeatureFlags('checkbook_payouts');

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!showBanner) {
    return null;
  }

  return (
    <>
      <InstantTransfersPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
      <NewFeatureBanner
        onClick={() => setIsPopupOpen(true)}
        actionText="Learn More"
        bannerKey="instant-transfers-banner"
        variant="no_title"
      >
        Get a <strong>0.5% rebate</strong> by selecting “AtoB Digital Wallet” as your funding
        destination for factored invoices. Plus, <strong>enjoy expedited access</strong> to your
        funds with <strong>Instant Transfer</strong>, for only a 1% processing fee.
      </NewFeatureBanner>
    </>
  );
}
