import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';

export const SameDayPayoutModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement | null => {
  return (
    <Modal open={open} toggle={onClose}>
      <ModalHeader title="Same-day payout details" onClose={onClose} />
      <ModalBodyContent>
        <div className="mb-4 flex flex-col gap-8 text-sm">
          <p>
            Cardholders earn same-day payouts for a calendar month if their spend was at least
            $3,500/truck in the previous month.
          </p>
          <ul className="ml-5 list-disc space-y-4">
            <li className="border-b pb-4">
              Each business day Uber Freight disburses carriers’ earnings for loads that were
              approved for payment, and booked while eligible for same-day payouts.
            </li>
            <li className="border-b pb-4">
              If you fail to meet $3,500/truck spend in a month you will revert to your contractual
              payment terms.
            </li>
            <li className="border-b pb-4">
              You can regain same-day payouts by meeting the $3,500/truck spend in a calendar month.
            </li>
          </ul>
          <p>
            For full details see{' '}
            <a href="https://www.uberfreight.com/carrier/carrier-card/" className="underline">
              Uber Freight Carrier Card
            </a>
            . Eligibility is determined based on FMCSA data.
          </p>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
