import { EndpointResponse } from '@app/@types/api.types';
import { FuelCardAccountData, UpcomingPayment } from '@app/@types/fuel-card-account.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import useShippingDetails from '@app/hooks/prepaid/use-shipping-details';
import useShippingAddress from '@app/hooks/use-shipping-address';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { faCircleInfo, faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import currency from 'currency.js';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import BusinessCreditReportNotification from '../../pages/AccountOverview/BusinessCreditReportNotification';
import useScheduledBillingPayments from '../../pages/AccountOverview/useScheduledBillingPayments';
import PathToCredit from '../PathToCredit/PathToCredit';
import PaymentModalWithEstimates from '../PaymentModal/PaymentModalWithEstimates/PaymentModalWithEstimates';
import { useAutoTopUpData } from '../Prepaid/AutoTopUps/use-auto-topup-data';
import RoundedCard from '../RoundedCard/RoundedCard';
import SpinnerBoundary from '../Spinner/SpinnerBoundary';
import { ErrorNotification } from '../layout';
import { CardShipmentStatus } from './CardShipmentStatus';
import HeadingCard from './HeadingCard';
import RecentTopUps, { TopUpTransaction } from './RecentTopUps';
import { AutoTopUpDangerNotification } from './TopUpNotification';
import type { CustomerData } from '@app/@types/customer.types';

interface AccountOverviewCardsProps {
  customer: CustomerData;
  recentTopups: TopUpTransaction[];
}
export const AUTO_TOP_UP_SIDEBAR_ENABLED = 'auto_top_up_sidebar_enabled';
const DEFAULT_AMOUNT = '$0.00';

export default function PrepaidAccountOverviewCards({
  customer,
  recentTopups,
}: AccountOverviewCardsProps): ReactElement {
  const {
    isLoading: isFetching,
    error: fuelCardError,
    data: fuelCardAccountData,
  } = useSWR<EndpointResponse<FuelCardAccountData>>({ url: '/fuel_card_account' }, apiGetFetcher);

  const { error: scheduledBillingPaymentsError, data: upcomingPayment } =
    useScheduledBillingPayments();
  const { hasAutoTopUp, autoTopUp } = useAutoTopUpData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [autoTopUpSidebarOpen, setAutoTopUpSidebarOpen] = useState(
    searchParams.has(AUTO_TOP_UP_SIDEBAR_ENABLED),
  );
  const { extractPeriodSavingsTile } = useThemeConfiguration();

  const theme = useTheme();
  const isMediumScreenOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    searchParams.delete(AUTO_TOP_UP_SIDEBAR_ENABLED);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const showBalance = currency(fuelCardAccountData?.data.account_balance ?? '0').value > 0;
  const [UNLIMITED_PRESHIPMENT_ACTIVATION_FEE] = useFeatureFlags(
    'unlimited_preshipment_activation_fee',
  );
  const [hasSettingsProduct] = useProduct('settings');

  const { cardsOrdered, cardsDelivered } = useShippingDetails(recentTopups);

  const shippingAddress = useShippingAddress(customer);

  if (fuelCardError || scheduledBillingPaymentsError) {
    return <ErrorNotification error={fuelCardError || scheduledBillingPaymentsError} generic />;
  }

  return (
    <div className="relative flex flex-auto flex-col items-stretch">
      {showBalance && (
        <div className="relative my-4">
          {isFetching && <SpinnerBoundary />}
          <NewStatementCard customer={customer} upcomingPayment={upcomingPayment} />
        </div>
      )}
      {hasAutoTopUp && autoTopUp.error_message && (
        <div className="mb-4">
          <AutoTopUpDangerNotification
            topUp={autoTopUp}
            setAutoTopUpSidebarOpen={setAutoTopUpSidebarOpen}
          />
        </div>
      )}
      <div className="mb-6 flex flex-col gap-y-3 empty:hidden">
        {hasSettingsProduct && <BusinessCreditReportNotification />}
        {UNLIMITED_PRESHIPMENT_ACTIVATION_FEE && (
          <CardShipmentStatus
            shippingAddress={shippingAddress}
            cardsOrdered={cardsOrdered}
            cardsDelivered={cardsDelivered}
          />
        )}
        <RecentTopUps data={recentTopups} />
      </div>
      <div className="relative flex h-full flex-auto flex-col items-stretch gap-6">
        {isFetching && <SpinnerBoundary />}
        <HeadingCard
          periodAvailableAmount={
            fuelCardAccountData?.data.period_available_amount || DEFAULT_AMOUNT
          }
          potentialDiscountAmount={
            fuelCardAccountData?.data.potential_discount_amount || DEFAULT_AMOUNT
          }
          pendingSpendAmount={fuelCardAccountData?.data.pending_spend_amount || DEFAULT_AMOUNT}
          currentWeekSpend={fuelCardAccountData?.data.period_spend_amount || DEFAULT_AMOUNT}
          discountMetadata={fuelCardAccountData?.data.discount}
          discountAmount={fuelCardAccountData?.data.discount_amount || ''}
          lifetimeDiscountAmount={fuelCardAccountData?.data.lifetime_discount_amount || ''}
          lifetimeAverageDiscountPerGallon={
            fuelCardAccountData?.data.lifetime_average_discount_per_gallon || ''
          }
          autoTopUpSidebarOpen={autoTopUpSidebarOpen}
          setAutoTopUpSidebarOpen={setAutoTopUpSidebarOpen}
          periodEndDate={fuelCardAccountData?.data.current_period_end_date}
          periodStartDate={fuelCardAccountData?.data.current_period_start_date}
        />
      </div>
      {extractPeriodSavingsTile && !isMediumScreenOrLarger && (
        <ProgramSavingsCard
          discountAmount={fuelCardAccountData?.data.discount_amount || ''}
          periodStartDayOfWeek={'Monday'}
        />
      )}
      <PathToCredit />
    </div>
  );
}

const ProgramSavingsCard = ({
  discountAmount,
  periodStartDayOfWeek,
}: {
  discountAmount: string;
  periodStartDayOfWeek: string;
}) => {
  return (
    <RoundedCard className="mt-3 p-4">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-row justify-between text-sm font-semibold">
          <div className="flex flex-row gap-x-2">
            <div>Period Savings</div>
            <MobileFriendlyTooltip
              title={`Total discounts this period (starting ${periodStartDayOfWeek} at 12:00AM, Pacific time), excluding any transactions that have yet to settle.`}
            >
              <FontAwesomeIcon icon={faCircleInfo} className="text-lg text-gray-300" />
            </MobileFriendlyTooltip>
          </div>
          <div>{discountAmount.replace('-', '')}</div>
        </div>
        <div className="flex flex-row gap-x-2">
          <Link to="/fuel-map" className="w-full">
            <Button size="medium" fullWidth>
              Find Fuel
            </Button>
          </Link>
          <Link to="/fuel-programs" className="w-full">
            <Button size="medium" fullWidth>
              Benefits
            </Button>
          </Link>
        </div>
      </div>
    </RoundedCard>
  );
};

const NewStatementCard = ({
  upcomingPayment,
  customer,
}: {
  upcomingPayment: UpcomingPayment | undefined;
  customer: CustomerData;
}): ReactElement => {
  const { isSmallScreen } = useWindowWidth();
  const [paymentModalActive, setPaymentModalActive] = useState(false);

  return (
    <>
      <div className="bg-blue2 flex flex-col items-start justify-between p-6 font-medium md:flex-row">
        <div className="mr-24 flex">
          {!isSmallScreen && (
            <div className="mr-4">
              <FontAwesomeIcon icon={faFileAlt} size="1x" />
            </div>
          )}
          <div>
            <b className="font-semibold text-gray-700">New statement: </b>
            Your account has past transactions not deducted from your Wallet.
          </div>
        </div>
        <div className="mt-4 flex min-w-[180px] gap-2 text-gray-500 md:mt-0">
          <div
            onClick={() => {
              setPaymentModalActive(true);
            }}
            className="cursor-pointer underline"
          >
            Pay Now
          </div>
          <div>|</div>
          <div className="underline">
            <Link to="/billing/statements">Statement</Link>
          </div>
        </div>
      </div>
      <PaymentModalWithEstimates
        isModalActive={paymentModalActive}
        customer={customer}
        upcomingPayment={upcomingPayment}
        walletAvailable
        closeModal={() => setPaymentModalActive(false)}
      />
    </>
  );
};
