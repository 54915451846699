import { getFetcher } from '@app/utils/data/fetchers';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { currencyFormat } from '@atob-developers/shared/src/utils/formatters';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import useSWRImmutable from 'swr/immutable';

type SecurityDepositSummary = {
  paid_amount_cents: number;
};

export const SecurityDepositDetails = (): ReactElement => {
  const { data: depositSummary, isLoading: loading } = useSWRImmutable<SecurityDepositSummary>(
    { url: `/security_deposits/summary` },
    getFetcher,
  );
  const getSecurityDepositValue = () => {
    if (!depositSummary?.paid_amount_cents) {
      return '-';
    }
    const securityDepositValueSplit = currencyFormat({
      value: convertCentsToDollars({ value: depositSummary.paid_amount_cents }),
      onlyWholeNumber: true,
    }).split('.');
    return (
      <>
        <span className="text-primary text-2xl">{securityDepositValueSplit?.[0]}</span>
        <span className="text-tertiary text-lg">.{securityDepositValueSplit?.[1]}</span>
      </>
    );
  };

  return (
    <div className="flex w-full flex-col justify-between">
      <h2 className="text-secondary leading-12 font-regular text-sm">
        Security deposit
        <MobileFriendlyTooltip
          className="pl-2"
          title="You can contact support to refund your security deposit."
        >
          <FontAwesomeIcon icon={faCircleInfo} className=" text-gray-500" />
        </MobileFriendlyTooltip>
      </h2>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="text-primary mt-1 text-2xl font-semibold">{getSecurityDepositValue()}</div>
      )}
    </div>
  );
};
