import { ChannelPartner } from '@app/@types/customer.types';
import PremiumLogo from '@app/assets/images/svg/atob-premium-logo.svg';
import HeaderLogo from '@app/assets/images/svg/header_logo.svg';
import {
  ATOB_SUPPORT_EMAIL,
  ATOB_SUPPORT_PHONE_NUMBER,
  UF_SUPPORT_EMAIL,
  UF_SUPPORT_PHONE_NUMBER,
} from '@app/constants/support';
import CustomerContext from '@app/contexts/customerContext';
import Constants from '@app/utils/constants';
import { useContext, useMemo } from 'react';
/* eslint-disable no-explicit-atob-mentions */

const DEFAULT_PARTNER: ChannelPartner = {
  name: 'AtoB',
  full_name: 'AtoB',
  display_logo: HeaderLogo,
  display_logo_dark: HeaderLogo,
  show_custom_logo: false,
  theme_name: null,
  force_premium: false,
  id: null,
};

const PREMIUM_PARTNER: ChannelPartner = {
  name: 'AtoB Premium',
  full_name: 'AtoB Premium',
  display_logo: PremiumLogo,
  display_logo_dark: PremiumLogo,
  show_custom_logo: false,
  theme_name: null,
  force_premium: false,
  id: null,
};

/* eslint-enable no-explicit-atob-mentions */

export function useChannelPartner(showPremiumLogo = false): {
  name: string | null;
  partnerName: string | null;
  partnerLogoUrl: string;
  partnerDarkLogoUrl: string | null;
  hasChannelPartner: boolean;
  supportPhoneNumber: string | null;
  supportEmailAddress: string | null;
  forcePremium: boolean;
  id: number | null;
} {
  const { customer } = useContext(CustomerContext);

  return useMemo(() => {
    if (!customer) {
      return {
        name: null,
        partnerName: null,
        partnerLogoUrl: DEFAULT_PARTNER.display_logo,
        partnerDarkLogoUrl: null,
        hasChannelPartner: false,
        supportPhoneNumber: ATOB_SUPPORT_PHONE_NUMBER,
        supportEmailAddress: ATOB_SUPPORT_EMAIL,
        forcePremium: false,
        id: null,
      };
    }

    if (customer.channel_partner) {
      let supportPhoneNumber = ATOB_SUPPORT_PHONE_NUMBER;
      let supportEmailAddress = ATOB_SUPPORT_EMAIL;
      if (
        customer.channel_partner.name ===
        Constants.ChannelPartners.UBER_FREIGHT_CHANNEL_PARTNER_NAME
      ) {
        supportPhoneNumber = UF_SUPPORT_PHONE_NUMBER;
        supportEmailAddress = UF_SUPPORT_EMAIL;
      }

      return {
        name: customer.channel_partner.name,
        partnerName: customer.channel_partner.full_name,
        partnerLogoUrl: customer.channel_partner.show_custom_logo
          ? customer.channel_partner.display_logo ?? DEFAULT_PARTNER.display_logo
          : DEFAULT_PARTNER.display_logo,
        partnerDarkLogoUrl: customer.channel_partner.show_custom_logo
          ? customer.channel_partner.display_logo_dark
          : DEFAULT_PARTNER.display_logo,
        hasChannelPartner: true,
        supportPhoneNumber,
        supportEmailAddress,
        forcePremium: customer.channel_partner.force_premium,
        id: customer.channel_partner.id,
      };
    } else if (showPremiumLogo) {
      return {
        name: PREMIUM_PARTNER.name,
        partnerName: PREMIUM_PARTNER.full_name,
        partnerLogoUrl: PREMIUM_PARTNER.display_logo,
        partnerDarkLogoUrl: PREMIUM_PARTNER.display_logo_dark,
        hasChannelPartner: false,
        supportPhoneNumber: ATOB_SUPPORT_PHONE_NUMBER,
        supportEmailAddress: ATOB_SUPPORT_EMAIL,
        forcePremium: PREMIUM_PARTNER.force_premium,
        id: null,
      };
    }
    return {
      name: DEFAULT_PARTNER.name,
      partnerName: DEFAULT_PARTNER.full_name,
      partnerLogoUrl: DEFAULT_PARTNER.display_logo,
      partnerDarkLogoUrl: DEFAULT_PARTNER.display_logo_dark,
      hasChannelPartner: false,
      supportPhoneNumber: ATOB_SUPPORT_PHONE_NUMBER,
      supportEmailAddress: ATOB_SUPPORT_EMAIL,
      forcePremium: DEFAULT_PARTNER.force_premium,
      id: null,
    };
  }, [customer, showPremiumLogo]);
}

export default useChannelPartner;
