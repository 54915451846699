import useProduct from '@app/hooks/useProduct';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { ReactElement, useState } from 'react';
import { NewFeatureBanner } from './Designs/NewFeatureBanner';

const EngagedPromotionBanner = (): ReactElement | null => {
  const [showModal, setShowModal] = useState(false);
  const [showBanner] = useProduct('engaged_promotion_banner');

  const dismiss = () => {
    setShowModal(false);
  };

  return showBanner ? (
    <>
      <NewFeatureBanner
        onClick={() => {
          setShowModal(true);
        }}
        actionText="How It Works"
        bannerKey="engaged-promotion-banner"
        variant="limited_time_offer"
      >
        <div className="w-full">
          Enjoy zero factoring fees, up to $2,500 and get same-day funding
        </div>
      </NewFeatureBanner>

      <Modal open={showModal} toggle={dismiss}>
        <ModalHeader
          title="Limited time offer"
          onClose={dismiss}
          headerImage={
            <img src="/images/engaged-funding.png" alt="popup-header" className="w-full" />
          }
        />
        <ModalBodyContent>
          <div className="flex flex-col items-center gap-2 bg-white pb-8">
            <div className="pb-4 text-lg text-gray-900">
              Enjoy zero factoring fees for up to $2,500 in invoices and get express same-day
              funding
            </div>
            <div className="text-md text-gray-900">
              In your Engaged invoice submission portal, select{' '}
              <strong>&ldquo;Fuel Account&ldquo;</strong> as your funding option when submitting
              funding instructions for your factored invoice. The funds will reach your account in a
              few hours. The factoring fees you typically pay will be refunded into your Fuel
              Account, within a week. You can use this on one invoice or multiple invoices till you
              hit $2,500 in total funding. Once you hit the limit, you can continue enjoying express
              same-day funding on all your factored invoices.
            </div>
          </div>
        </ModalBodyContent>
      </Modal>
    </>
  ) : null;
};

export default EngagedPromotionBanner;
