import { ModalOptionData, ModalOptions } from '@app/components/Modals/ModalOptions/ModalOptions';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { CardOrderConstraints } from './CardOrderModal';
import type { CardRequestParams, CardType } from '@app/@types/card_requests.types';

export const CardOrderSelectType = ({
  params,
  setParams,
  orderConstraints,
  onNext,
  closeModal,
}: {
  params: CardRequestParams;
  setParams: (_: CardRequestParams) => void;
  orderConstraints: CardOrderConstraints;
  onNext: () => void;
  closeModal: () => void;
}): ReactElement => {
  const options: ModalOptionData[] = [
    {
      id: 'physical',
      heading: 'Physical Card',
      subheading: orderConstraints.fee_waived ? 'Free' : '$5 per card',
      description: ['Delivers in about 2-10 days. Shipping speed options are available.'],
    },
    {
      id: 'virtual',
      heading: 'Virtual Card',
      subheading: 'Free',
      description: ['Available to use instantly, anywhere contactless payments are accepted'],
    },
  ];

  const handleContinue = () => {
    onNext();
  };

  return (
    <>
      <ModalHeader title="Get Your Cards" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col text-base text-gray-700">
          <div className="xs:mb-6 my-2 text-sm">
            Choose if you'd like a physical or virtual card.
          </div>
          <ModalOptions
            options={options}
            selectedOptionId={params.card_type}
            setSelectedOptionId={(card_type) =>
              setParams({ ...params, card_type: card_type as CardType })
            }
          />
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-center md:justify-end">
          <Button className="w-full md:w-1/2" size="small" onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
