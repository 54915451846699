import dayjs, { extend } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

extend(utc);
extend(timezone);
extend(localizedFormat);

export type DateValue = string | number | Date;

export default function dateFormat({ value }: { value: string | number | Date }): string {
  if (!value) {
    return '-';
  }

  const date = new Date(value);

  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Los_Angeles',
  }).format(date);
}

interface FormatDateTzParams {
  // In any format accepted by the Date constructor
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#parameters
  dateValue: DateValue;

  // pattern: String ['L' == long localized date (e.g. 04/29/1453)]
  pattern?: string;

  timeZone?: string;
}

export function formatDateTz({
  dateValue,
  pattern = 'L',
  timeZone = 'America/Los_Angeles',
}: FormatDateTzParams): string {
  if (!dateValue) {
    return '-';
  }

  const date = new Date(dateValue);
  const zonedDate = dayjs(date).tz(timeZone);
  const formattedDateTz = zonedDate.format(pattern);

  return formattedDateTz;
}

interface FormatDateParams {
  dateValue: DateValue;
  pattern?: string;
}

export function formatDate({ dateValue, pattern = 'L' }: FormatDateParams): string {
  if (!dateValue) {
    return '-';
  }

  const date = new Date(dateValue);
  const formattedDate = dayjs(date).format(pattern);

  return formattedDate;
}

export function formatTime(date: Date): string {
  return date.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

export function formatDateLocale(date: Date): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function formatDateTimeLocale(dateValue: DateValue): string {
  if (!dateValue) {
    return '-';
  }
  const date = new Date(dateValue);
  return dayjs(date).format('MM/DD/YY H:mm');
}

export function getShortTimeZone(locale: string = 'en-US'): string {
  return new Intl.DateTimeFormat(locale, { timeZoneName: 'short' })
    .format(Date.now())
    .split(' ')[1];
}
