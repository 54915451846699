import { ReactNode } from 'react';

type AssetCardProps = {
  image: ReactNode;
  title: string;
  description: string;
};

export default function AssetCard({ image, title, description }: AssetCardProps) {
  return (
    <div className="border-level-2 bg-level-0 rounded-2xl border">
      <div className="flex flex-col gap-6">
        {image}
        <div className="flex flex-col gap-1.5 px-4 pb-6 md:px-6 md:pb-8">
          <h3 className="text-primary text-base font-semibold md:text-lg md:leading-6">{title}</h3>
          <p className="text-secondary text-sm leading-6 md:text-base">{description}</p>
        </div>
      </div>
    </div>
  );
}
