import { useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import RoundedCard from '../RoundedCard/RoundedCard';
import SpinnerBoundary from '../Spinner/SpinnerBoundary';

type Classes = {
  root?: string;
  gridContainer?: string;
};

interface TilesContainerProps {
  isLoading: boolean;
  children: React.ReactNode[];
  classes?: Classes;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  collapsedPrimaryAction?: React.ReactNode;
}

const CollapsibleTilesContainer = ({
  isLoading,
  children,
  classes,
  header,
  footer,
  collapsedPrimaryAction,
}: TilesContainerProps): ReactElement => {
  const theme = useTheme();
  const isMediumScreenOrLarger = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <RoundedCard className={classNames('relative w-full overflow-hidden', classes?.root)}>
      {isLoading && <SpinnerBoundary />}
      {header}
      <div className={classNames(`grid h-full w-full gap-px bg-gray-100`, classes?.gridContainer)}>
        {children}
      </div>
      {footer}
      {!isMediumScreenOrLarger && (
        <div
          className={classNames(
            'flex p-4',
            collapsedPrimaryAction ? 'justify-between' : 'justify-end',
          )}
        >
          {collapsedPrimaryAction}
        </div>
      )}
    </RoundedCard>
  );
};

export default CollapsibleTilesContainer;
