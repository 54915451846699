import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { VehicleData } from '@app/@types/vehicle.types';
import TelematicsNudgeBanner from '@app/components/Banner/TelematicsNudgeBanner';
import AddVehicleModal from '@app/components/Modals/VehicleModals/AddVehicleModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification, Loading } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_PAGE_SIZE, SortBy } from '@app/hooks/paging/types';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher, getFetcher } from '@app/utils/data/fetchers';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { faCar } from '@fortawesome/pro-regular-svg-icons';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, ChangeEvent, useEffect, useState, useMemo } from 'react';
import useSWR from 'swr';
import VehiclesList from './VehiclesList';
import VehiclesPageHeader from './VehiclesPageHeader';
import type { VehiclesCounts } from './endpoint.types';

type VehicleTab = {
  name: 'Active' | 'Archived';
  tab: 'active' | 'archived';
};

const VEHICLE_TABS: VehicleTab[] = [
  {
    name: 'Active',
    tab: 'active',
  },
  {
    name: 'Archived',
    tab: 'archived',
  },
];

export default function Vehicles(): ReactElement {
  const [inputValue, setInputValue] = useSingleUrlState<string | null>('query', '');
  const [showVehicleModal, setShowVehicleModal] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(VEHICLE_TABS[0].tab);
  // Pages are 1-indexed, not 0-indexed
  const [page, setPage] = useState(1);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const [sort, setSort] = useState<SortBy | null>(null);

  const toggleVehicleModal = () => setShowVehicleModal(!showVehicleModal);

  const {
    data: vehiclesCountsData,
    error: vehiclesCountError,
    mutate: vehiclesCountsRefresh,
  } = useSWR<VehiclesCounts>({ url: '/vehicles/counts' }, getFetcher);

  const vehiclesCounts = useMemo(
    () =>
      vehiclesCountsData
        ? {
            hasAny: vehiclesCountsData.total > 0,
            hasActive: vehiclesCountsData.archived !== vehiclesCountsData.total,
            hasArchived: vehiclesCountsData.archived > 0,
            hasOnlyArchived: vehiclesCountsData.archived === vehiclesCountsData.total,
          }
        : null,
    [vehiclesCountsData],
  );

  const { data, error, isLoading, mutate } = useSWR<PaginatedEndpointResponse<VehicleData>>(
    {
      url: '/vehicles',
      params: {
        page: page,
        per: DEFAULT_PAGE_SIZE,
        all: false,
        sort: sort ? [`${sort.field}:${sort.order}`] : undefined,
        like: inputValue && inputValue.length > 0 ? inputValue : null,
        [currentTab === 'archived' ? 'not_archived_at' : 'archived_at']: 'none',
      },
    },
    apiGetFetcher,
  );

  useEffect(() => {
    if (vehiclesCounts === null) {
      return;
    }

    if (currentTab === 'archived' && !vehiclesCounts?.hasArchived) {
      setCurrentTab('active');
    }
  }, [currentTab, vehiclesCounts]);

  if (vehiclesCountError || error) {
    return (
      <ErrorNotification error="We're having issues loading your vehicle data. Please try again or if the issue persists, contact support." />
    );
  } else if (vehiclesCounts === null) {
    return (
      <div className="min-h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <AddVehicleModal
        open={showVehicleModal}
        toggle={toggleVehicleModal}
        refreshData={() => {
          vehiclesCountsRefresh();
          mutate();
        }}
      />
      <PageContentWrapper
        className="pb-12"
        header={
          <>
            <VehiclesPageHeader
              value={inputValue ?? ''}
              handleChange={handleSearch}
              setShowVehicleModal={toggleVehicleModal}
              data={data?.data || []}
              refreshData={() => {
                mutate();
                vehiclesCountsRefresh();
              }}
            />
            <div className="px-4 md:px-0">
              <TelematicsNudgeBanner />
            </div>
          </>
        }
        pageTabs={
          vehiclesCounts.hasArchived && (
            <UnderlinedTabs
              selectedTab={currentTab}
              handleTabChange={(tab) => setCurrentTab(tab)}
              tabs={VEHICLE_TABS}
            />
          )
        }
      >
        {vehiclesCounts.hasAny ? (
          <>
            {currentTab === 'archived' ||
            (currentTab === 'active' && !vehiclesCounts.hasOnlyArchived) ? (
              <RoundedCard>
                <VehiclesList
                  rows={data?.data ?? []}
                  paginationMeta={data?.meta}
                  refreshData={() => {
                    mutate();
                    vehiclesCountsRefresh();
                  }}
                  loading={isLoading}
                  onPageIndexChange={setPage}
                  onSortModelChange={(model: GridSortModel) => {
                    const sortByModel = model && model[0];
                    if (!sortByModel) {
                      setSort(null);
                      return;
                    }
                    const { field, sort: order } = sortByModel;
                    setPage(1);
                    setSort({ field, order });
                  }}
                />
              </RoundedCard>
            ) : (
              <EmptyTable
                icon={faCar}
                title="Add your first vehicle"
                subtitle="(Optional)"
                buttonText="Add a Vehicle"
                onClick={toggleVehicleModal}
              />
            )}
          </>
        ) : (
          <EmptyTable
            icon={faCar}
            title="Add your first vehicle"
            subtitle="(Optional)"
            buttonText="Add a Vehicle"
            onClick={toggleVehicleModal}
          />
        )}
      </PageContentWrapper>
    </>
  );
}
