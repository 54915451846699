import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { useState } from 'react';

export default function OnboardingSummaryModal() {
  const { customer_onboarding_events, products } = useCustomer();
  const [isOpen, setIsOpen] = useState(
    !customer_onboarding_events?.some(
      (event) => event.name === CustomerOnboardingEventName.CUSTOMER_SUMMARY_COMPLETED,
    ),
  );

  const isNewOnboarding = products?.some((product) => product === 'new_onboarding');

  const { trigger: createOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const finishOnboardingFlow = () => {
    createOnboardingEvent({
      customer_onboarding_event: { name: CustomerOnboardingEventName.CUSTOMER_SUMMARY_COMPLETED },
    });

    setIsOpen(false);
  };

  if (!isNewOnboarding) {
    return null;
  }

  return (
    <Modal open={isOpen}>
      <ModalHeader
        title="You're all done"
        onClose={finishOnboardingFlow}
        className="justify-center"
        headerImage={<img src="images/onboarding/summary-header.png" />}
      >
        <p className="text-secondary pt-1.5 text-center text-base font-normal">
          When your AtoB card arrives in the mail, they’ll be auto-activated and ready for immediate
          use.
        </p>
      </ModalHeader>
      <ModalBodyContent>
        <Alert
          severity="info"
          title="Do you have any question?"
          content="Our support team is available to you 24x7."
          // primaryActionText="Start a live chat"
          // TODO: add primary action callback
          primaryAction={() => {}}
          // secondaryActionText="Call support"
          // TODO: add secondary action callback
          secondaryAction={() => {}}
        />
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex flex-1 flex-col">
          <Button onClick={finishOnboardingFlow} className="w-full">
            Go to dashboard
          </Button>
          <p className="text-secondary pt-1.5 text-center text-base font-normal">
            You can also explore{' '}
            <a
              href="https://atobco.zendesk.com/hc/en-us/sections/4407033456795-FAQ"
              target="_blank"
              className="text-primary font-medium underline"
              rel="noreferrer"
            >
              our FAQ
            </a>{' '}
            and help articles anytime you have questions.
          </p>
        </div>
      </ModalFooter>
    </Modal>
  );
}
