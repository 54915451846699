import { PaginationMeta } from '@app/@types/api.types';
import { BankPaymentResponse, DeserializedPaymentsData } from '@app/@types/payments.types';
import { ErrorNotification, Loading } from '@app/components/layout';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import { PaymentHistory } from './PaymentHistory';

const DEFAULT_PAGINATION_META: PaginationMeta = {
  pagination: {
    current_page: 1,
    total_pages: 1,
    total_count: 0,
  },
};

const PaymentHistoryPage = ({
  withContentWrapper = true,
  showMonthlySubscription = false,
}: {
  withContentWrapper: boolean;
  showMonthlySubscription: boolean;
}): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [payments, setPayments] = useState<DeserializedPaymentsData[] | null>(null);
  const [paymentsMeta, setPaymentsMeta] = useState<PaginationMeta>(DEFAULT_PAGINATION_META);
  const [subscriptionsMeta, setSubscriptionsMeta] =
    useState<PaginationMeta>(DEFAULT_PAGINATION_META);
  const [monthlySubscription, setMonthlySubscription] = useState(null);

  // fetch customer payment history
  useEffect(() => {
    handlePaymentsPageChangeClick(1);
  }, []);

  useEffect(() => {
    if (showMonthlySubscription) {
      handleSubscriptionsPageChangeClick(1);
    }
  }, [showMonthlySubscription]);

  const handlePaymentsPageChangeClick = (pageNumber: number) => {
    axios
      .get<BankPaymentResponse>(
        `/ach_charges?include=payment_method.payment_method_detail&page=${pageNumber}&per=25&all=false`,
      )
      .then((response) => {
        const { data: achResponseData, meta } = deserialize(response.data);
        setPaymentsMeta(meta);
        setPayments(achResponseData);
      })
      .catch((e) => setError(e.message));
  };

  const handleSubscriptionsPageChangeClick = (pageNumber: number) => {
    axios
      .get<BankPaymentResponse>(`/subscription_invoices?page=${pageNumber}&per=25&all=false`)
      .then((response) => {
        const { data, meta } = deserialize(response.data);
        setSubscriptionsMeta(meta);
        setMonthlySubscription(data);
      })
      .catch((e) => setError(e.message));
  };

  let view;
  if (error) {
    view = (
      <ErrorNotification error="We are having issues loading your payment history. Please contact support for assistance." />
    );
  } else if (!payments) {
    view = (
      <div className="min-h-screen">
        <Loading />
      </div>
    );
  } else {
    view = (
      <PaymentHistory
        withContentWrapper={withContentWrapper}
        showMonthlySubscription={showMonthlySubscription}
        monthlySubscription={monthlySubscription || []}
        payments={payments}
        paymentsPaginationMeta={paymentsMeta}
        subscriptionsPaginationMeta={subscriptionsMeta}
        handlePaymentsPageChangeClick={handlePaymentsPageChangeClick}
        handleSubscriptionsPageChangeClick={handleSubscriptionsPageChangeClick}
        rowsPerPage={25}
      />
    );
  }

  return <>{view}</>;
};

export default PaymentHistoryPage;
