import Card from '@app/components/Onboarding/Card';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import {
  faBuildings,
  faGasPump,
  faShield,
  faStar,
  faTruck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingHowToUseMyAtoBCards() {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header title="How to use my AtoB cards?" />
      <div className="flex flex-col gap-8 md:gap-10">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3 md:flex-row">
            <Card icon={faGasPump} title="Cards can be used at the pump to purchase fuel" />
            <Card
              icon={faBuildings}
              title="If you face issues at the pump, cards can also be used inside the station"
            />
            <Card icon={faShield} title="If prompted for a pin or ZIP Code, enter all zeros" />
          </div>
          <Alert
            severity="success"
            icon={<FontAwesomeIcon icon={faStar} className="h-5 w-5" />}
            title="Best practice"
            content="For cleanest transaction reporting, we recommend purchasing additional fuel (e.g., DEF) on a second transaction"
          />
        </div>

        <div className="flex flex-col gap-4">
          <h3 className="text-primary font-semibold md:text-lg">Your cards are on the way</h3>
          <Alert
            severity="info"
            icon={<FontAwesomeIcon icon={faTruck} className="h-5 w-5" />}
            title="Cards will arrive in 10–14 business days"
          />
        </div>
      </div>
      <FooterActionButtons hideBackButton handleClickNext={goToNextStep} />
    </div>
  );
}
