import { Step, StepLabel, Stepper } from '@mui/material';
import classNames from 'classnames';

export const STEPPER_LABELS = [
  { label: 'Activate your account', key: 'activate_your_account' },
  { label: 'Manage your spend', key: 'manage_your_spend' },
  { label: 'Discover savings & discounts', key: 'discover_savings_and_discounts' },
];

type OnboardingStepperProps = {
  activeStep: number;
};

export default function OnboardingStepper({ activeStep }: OnboardingStepperProps) {
  return (
    <>
      {/* Desktop */}
      <Stepper activeStep={activeStep} orientation="vertical" className="hidden md:flex">
        {STEPPER_LABELS.map(({ label, key }) => (
          <Step key={key}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Mobile */}
      <div className="bg-level-0 px-6 pb-4 pt-2 md:hidden">
        <div className="flex gap-3">
          {STEPPER_LABELS.map(({ key }, index) => (
            <div
              key={key}
              className={classNames(
                'h-1 flex-1',
                index < activeStep ? 'bg-accent-21' : 'bg-accent-23',
              )}
            />
          ))}
        </div>
      </div>
    </>
  );
}
