import { ChargeEventData } from '@app/@types/charge_events.types';
import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

export const getStatusesHelper = (row: ChargeEventData) => {
  const { auth_approved, auth_status, discount_amount_cents, potential_savings, quantity } = row;
  const quantityNumber = parseFloat(quantity ?? '0');
  const totalSavings = (potential_savings?.savings_amount_in_cents ?? 0) * quantityNumber;

  return {
    hasSaved: auth_approved && (discount_amount_cents ?? 0) > 0,
    hasMissed: auth_approved && totalSavings > 0,
    hasDeclined: auth_status === 'closed' && !auth_approved,
    isPending: auth_status === 'pending',
    totalSavings,
  };
};

export const getRowClassName = (params: GridRowClassNameParams<ChargeEventData>): string => {
  const { hasSaved, hasMissed } = getStatusesHelper(params.row);

  if (hasSaved) {
    return '!bg-accent-12';
  }

  if (hasMissed) {
    return '!bg-warning-2';
  }

  return '';
};
