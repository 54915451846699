import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChipProps, ButtonPropsColorOverrides, Chip, Button } from '@mui/material';

type CardProps = {
  icon?: IconDefinition;
  chips?: ChipProps[];
  title: string;
  descriptions?: string[];
  buttonText?: string;
  buttonColor?: keyof ButtonPropsColorOverrides;
  onClick?: () => void;
  disabled?: boolean;
};

export default function Card({
  icon,
  chips,
  title,
  descriptions,
  buttonText,
  buttonColor,
  onClick,
  disabled,
}: CardProps) {
  return (
    <div className="border-level-2 bg-level-0 shadow-marker flex flex-col gap-2 rounded-lg border p-3 md:flex-1 md:gap-3 md:p-4">
      <div className="flex flex-col gap-2 md:gap-3">
        <div className="flex flex-wrap items-center justify-between gap-2">
          {icon && (
            <div className="bg-level-1 flex h-10 w-10 items-center justify-center rounded-full">
              <FontAwesomeIcon icon={icon} className="h-5 w-5 shrink-0" />
            </div>
          )}
          {!!chips?.length && (
            <div className="flex gap-2">
              {chips.map((props, index) => (
                <Chip key={index} color="green" {...props} />
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-0.5">
          <h4 className="text-primary text-sm font-medium leading-6 md:leading-5">{title}</h4>
          {!!descriptions?.length && (
            <div className="flex flex-col gap-5">
              {descriptions.map((description) => (
                <p key={description} className="text-secondary text-xs leading-5 md:text-sm">
                  {description}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
      {buttonText && (
        <Button
          fullWidth
          color={buttonColor}
          disabled={disabled}
          className="mt-auto"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}
