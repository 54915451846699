import IconEarnMoney from '@app/assets/images/svg/referral_earn_money.svg';
import IconJoinUnderOneMinutes from '@app/assets/images/svg/referral_join_under_one_minute.svg';
import IconSendUniqueLinks from '@app/assets/images/svg/referral_send_unique_links.svg';
import IconTransaction from '@app/assets/images/svg/referral_transaction.svg';
import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { Button } from '@mui/material';

const BENEFITS = [
  { icon: IconJoinUnderOneMinutes, text: 'Join in under 1 minute' },
  { icon: IconSendUniqueLinks, text: 'Send your unique link to carriers' },
  { icon: IconTransaction, text: 'Referred carrier makes 1 transaction' },
  { icon: IconEarnMoney, text: 'You earn $50' },
];

const BenefitCard = ({ icon, text, index }: { icon: string; text: string; index: number }) => {
  return (
    <div className="flex h-32 w-36 flex-col justify-between gap-2 rounded-2xl border bg-white p-4 shadow-lg md:h-36 md:w-40">
      <div className="relative">
        <img src={icon} alt={text} className="mx-auto h-16 w-16" />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white md:text-3xl">
          {index + 1}
        </span>
      </div>
      <p className="text-primary mx-auto w-28 text-center text-[10px] font-bold leading-4 md:w-32 md:text-xs">
        {text}
      </p>
    </div>
  );
};

const Benefits = () => (
  <div className="grid max-h-max min-h-max min-w-max max-w-max grid-cols-2 gap-4">
    {BENEFITS.map((benefit, index) => (
      <BenefitCard key={index} icon={benefit.icon} text={benefit.text} index={index} />
    ))}
  </div>
);

export default function ReferralProgram() {
  return (
    <PageContentWrapper header={<Header title="Join the AtoB Referral Program" />}>
      <div
        className="bg-telematics-connect-bg-mobile md:bg-telematics-connect-bg-desktop flex w-full flex-row
    gap-14 rounded-md bg-white bg-cover bg-no-repeat px-2 py-12 md:justify-evenly md:px-16"
      >
        <div className="my-auto flex flex-col items-center gap-6 md:items-start">
          <div className="mb-6 space-y-1 text-center md:text-start">
            <p className="text-primary text-3xl font-semibold md:text-4xl">
              Make $50 in 4 simple steps
            </p>
            <p className="text-primary text-lg font-medium md:text-xl">
              Refer a carrier, and earn $50 when they make a purchase on their new AtoB Fuel Card.
            </p>
          </div>
          <div className="lg:hidden">
            <Benefits />
          </div>
          <Button
            fullWidth
            size="small"
            href="https://atobb6815.referralrock.com/v2/1/access"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join the AtoB Referral Program today
          </Button>
        </div>
        <div className="hidden lg:block">
          <Benefits />
        </div>
      </div>
    </PageContentWrapper>
  );
}
