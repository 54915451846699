import { TelematicsSetting } from '@app/@types/card.types';

enum ChannelPartners {
  UBER_FREIGHT_CHANNEL_PARTNER_FULL_NAME = 'Uber Freight',
  UBER_FREIGHT_CHANNEL_PARTNER_NAME = 'uberfreight',
  // eslint-disable-next-line @cspell/spellchecker
  UBER_FREIGHT_INTERMODAL_CHANNEL_PARTNER_NAME = 'uber_intermodal',
  // eslint-disable-next-line @cspell/spellchecker
  UBER_FREIGHT_THEME = 'ufgrayscale',
  UBER_FREIGHT_CARRIER_MANAGEMENT_DEEPLINK = 'https://freight.uber.com/deeplink/freight-carrier-management',
  EG_AMERICA_CHANNEL_PARTNER_NAME = 'eg_america',
}

enum CellType {
  TABLE_TYPE = 'table-cell',
  BLOCK_TYPE = 'inline-block',
}

enum CellMargin {
  TABLE_TYPE,
  BLOCK_TYPE = '0 .3rem',
}

enum ELDEnums {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not connected',
  TELEMATICS_ENDPOINT = '/telematics',
  TELEMATICS_ENDPOINT_MAP = '/telematics/trucks',
}

enum ErrorMessages {
  TOO_MANY_PLAID_REQUESTS = `There was an error processing your payment. Please try again after waiting 60 seconds. If the issue persists, please contact our support team.`,
}

enum QuickbooksEnums {
  CONNECTED = 'connected',
}

const PHONE_REGEX = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;

enum IntentTypes {
  FUEL_CARD_PAYMENT = 'payment',
  PAYROLL_PAYMENT = 'payroll_payment',
  SETUP = 'setup',
}

enum PayrollEnums {
  // Driver employee states
  INVITED = 'invited',
  ENROLLED = 'enrolled',
  NOT_ENROLLED = 'not_enrolled',
  UPDATE_REQUIRED = 'update_required',

  // Payroll driver actions
  INVITE = 'INVITE',
  ACTIVATE = 'ACTIVATE',
  PAY = 'PAY',
  RESEND = 'RESEND',

  // Driver employee types
  W2 = 'w2',
  TEN_NINETY_NINE = '1099',
  OTHER = 'other',

  // Error messages
  SHOW_AMOUNT_TOO_LITTLE_ERROR = 'SHOW_AMOUNT_TOO_LITTLE_ERROR',
  SHOW_NEEDS_CARD_ERROR = 'SHOW_NEEDS_CARD_ERROR',
}

enum SuspiciousActivityReason {
  UNKNOWN = 'Unknown',
  TOO_FAR_FROM_VEHICLE = 'too_far_from_vehicle',
}

enum SettingsPageEnums {
  SUCCESS = 'success',
  FAIL = 'fail',
}

enum CustomerSettingEnums {
  EMAIL = 'customer_email',
  PHONE = 'customer_phone_number',
  COMPANY_ADDRESS = 'company_address',
  SHIPPING_ADDRESS = 'card_shipping_address',
  COMPANY_PREFERRED_NAME = 'customer_company_preferred_name',
  TELEMATICS_NOTIFICATIONS_ENABLED = 'telematics_notifications_enabled',
}

enum OmniAuthEnums {
  COOKIE = 'omni_auth_jwt',
  PROVIDER = 'omni_auth_provider',
}

enum PaymentEventTypes {
  AUTHORIZATION = 'authorization',
  TRANSACTION = 'transaction',
}

const TelematicsOptions: { id: TelematicsSetting; name: string; description: string }[] = [
  {
    id: 'off',
    name: 'Off',
    description: 'Telematics will not be used',
  },
  {
    id: 'monitor',
    name: 'Monitor',
    description: 'Sends alerts for suspicious activity',
  },
  {
    id: 'block',
    name: 'Block',
    description: 'Block suspicious activity immediately',
  },
];

export default class Constants {
  static SMALL_SCREEN_BREAKPOINT = 640;

  static MOBILE_BREAKPOINT = 768;

  static TABLET_BREAKPOINT = 1023;

  static readonly CellType = CellType;

  static readonly CellMargin = CellMargin;

  static readonly ELDEnums = ELDEnums;

  static readonly ErrorMessages = ErrorMessages;

  static readonly PHONE_REGEX = PHONE_REGEX;

  static readonly IntentTypes = IntentTypes;

  static readonly QuickbooksEnums = QuickbooksEnums;

  static readonly PayrollEnums = PayrollEnums;

  static readonly SuspiciousActivityReason = SuspiciousActivityReason;

  static readonly SettingsPageEnums = SettingsPageEnums;

  static readonly CustomerSettingEnums = CustomerSettingEnums;

  static readonly PaymentEventTypes = PaymentEventTypes;

  static readonly TelematicsOptions = TelematicsOptions;

  static readonly OmniAuthEnums = OmniAuthEnums;

  static readonly ChannelPartners = ChannelPartners;
}
