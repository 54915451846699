import CardDesign from '@app/assets/images/svg/card_design.svg';
import { ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export const CardOrderSuccessPhysical = ({
  closeModal,
}: {
  closeModal: () => void;
}): ReactElement => {
  const navigate = useNavigate();

  const handleDone = () => {
    closeModal();
    navigate('/');
  };

  return (
    <>
      <ModalHeader title="" onClose={closeModal} />
      <div className="flex flex-col items-center">
        <div className="mb-3 mt-8 text-green-600">
          <FontAwesomeIcon className="fa-3x " icon={faCircleCheck} />
        </div>
        <h1 className="mb-1 text-center text-2xl font-bold">Order Placed</h1>
        <p className="text-md mb-2 w-full text-center">
          We’re now processing your order, and you’ll receive an email once the cards have shipped.
        </p>

        <img src={CardDesign} alt="card-design" className="w-3/4 py-8" />
        <div className="mb-12 flex w-full justify-center">
          <Button className="w-3/4" size="small" onClick={handleDone}>
            Done
          </Button>
        </div>
      </div>
    </>
  );
};
