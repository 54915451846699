import { DailyTransactionSummaryData } from '@app/@types/transaction.types';
import useChartJSStyling from '@app/hooks/useChartJSStyling';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { getLabelsFontSize } from '@app/utils/charts';
import { formatCurrency, formatDate } from '@atob-developers/shared/src/utils/formatters';
import {
  ActiveElement,
  BarElement,
  BubbleDataPoint,
  CategoryScale,
  ChartEvent,
  Chart as ChartJS,
  ChartTypeRegistry,
  Legend,
  LinearScale,
  ScatterDataPoint,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Align, Anchor } from 'chartjs-plugin-datalabels/types/options';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface TransactionsChartProps {
  transactions: DailyTransactionSummaryData[];
  allowViewTransactions: boolean;
}

interface TransactionsDataInterface {
  labels: string[];
  datasets: {
    label: string;
    data: string[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    hoverBackgroundColor: string;
    hoverBorderColor: string;
  }[];
}

const TransactionsChart = ({ transactions, allowViewTransactions }: TransactionsChartProps) => {
  const [data, setData] = useState<TransactionsDataInterface | null>(null);
  const { width } = useWindowWidth();
  const navigate = useNavigate();
  useChartJSStyling();

  useEffect(() => {
    const sortedTransactions = [...transactions].sort((a, b) =>
      a['transaction_date'] > b['transaction_date']
        ? 1
        : b['transaction_date'] > a['transaction_date']
        ? -1
        : 0,
    );

    const transactedAt = sortedTransactions.map((transaction) =>
      formatDate({
        dateValue: new Date(`${transaction.transaction_date}T12:00`),
        pattern: 'MMM DD',
      }),
    );
    const netAmount = sortedTransactions.map((transaction) => transaction.net_amount);

    setData({
      labels: transactedAt,
      datasets: [
        {
          label: 'Amount Spent',
          data: netAmount,
          backgroundColor: '#F0F5F3',
          borderColor: '#2C8452',
          borderWidth: 1,
          hoverBackgroundColor: '#2C8452',
          hoverBorderColor: '#2C8452',
        },
      ],
    });
  }, [transactions]);

  const options = {
    onClick: (
      _e: ChartEvent,
      element: ActiveElement[],
      chart: ChartJS<
        keyof ChartTypeRegistry,
        (number | ScatterDataPoint | BubbleDataPoint)[],
        unknown
      >,
    ) => {
      if (!allowViewTransactions || !element[0]) {
        return;
      }

      const highlightValue = chart.scales.x.ticks[element[0].index].label;
      if (typeof highlightValue === 'string' && highlightValue.length > 0) {
        const highlightLabel = 'transacted_at';
        const highlightExcludeLabel = 'posted_at';
        const highlightExcludeValue = '-';
        navigate(
          `/transactions?hv=${highlightValue}&hl=${highlightLabel}&hxv=${highlightExcludeValue}&hxl=${highlightExcludeLabel}`,
        );
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Amount Spent',
          fontStyle: 'bold',
          fontSize: 14,
        },
        beginAtZero: true,
        ticks: {
          callback: (value: string | number) => `$${value}`,
        },
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        labels: {
          title: {
            font: {
              size: getLabelsFontSize(width),
            },
          },
        },
        display: 'auto',
        align: 'top' as Align,
        anchor: 'end' as Anchor,
        formatter: (value: string) => formatCurrency({ value, options: { fromCents: false } }),
      },
    },
  };

  if (data === null) {
    return null;
  }
  return (
    <div className="w-11/12">
      <Bar data={data} options={options} />
    </div>
  );
};

export default TransactionsChart;
