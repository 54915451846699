import { TextField } from '@mui/material';
import { SetStateAction } from 'react';

export const DescriptionInput = ({
  description,
  setDescription,
}: {
  description: string;
  setDescription: (value: SetStateAction<string>) => void;
}) => (
  <div>
    <h4 className="mb-2 flex items-center font-medium">
      Description <span className="text-secondary ml-1">(optional)</span>
    </h4>
    <div className="flex gap-2">
      <TextField
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        placeholder="Describe the reason of this transaction..."
      />
    </div>
  </div>
);
