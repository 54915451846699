import { EndpointResponse } from '@app/@types/api.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import UnlimitedToFlexMigrationConnectBankAccount from '@app/pages/Wallet/UnlimitedToFlexMigration/ConnectBankAccount';
import { FetcherKey, apiPostFetcher } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import { ULToCreditRequestConstraints } from './ULToCreditRequest';
import { ULToCreditRequestReviewPending } from './ULToCreditRequestReviewPending';

export const ULToCreditRequestModal = ({
  open,
  onClose,
  requestConstraints,
}: {
  open: boolean;
  onClose: () => void;
  requestConstraints: ULToCreditRequestConstraints | undefined;
}): ReactElement => {
  type Screen = 'confirm_intent' | 'success' | 'connect_bank_account';

  const { addToast } = useToasts();

  const initialScreen: Screen = 'confirm_intent';
  const [screen, setScreen] = useState<Screen>(initialScreen);

  let child = null;

  const { trigger: sendCreditRequest, isMutating: requestLoading } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey
  >(
    {
      url: '/unlimited_to_credit_requests/request_credit',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          addToast(
            'Something went wrong! Please try again or contact support if the issue persists',
            { appearance: 'error' },
          );
        }
      },
      onSuccess: () => {
        setScreen('success');
      },
    },
  );

  if (!requestConstraints || requestLoading) {
    child = (
      <ModalBodyContent overflowVisible={true}>
        <div className="md:mx-40">
          <SpinnerBoundary />
        </div>
      </ModalBodyContent>
    );
  } else if (screen === 'confirm_intent') {
    child = (
      <>
        <ModalHeader title="Credit Limit Evaluation" onClose={onClose} />
        <ModalBodyContent overflowVisible={true}>
          <div className="my-6">
            <p className="mb-2">
              Would you like to request to convert your prepaid account to a credit account?
            </p>
            <ul className="list-disc px-4">
              <li className="mb-2">
                The review generally takes 2-3 business days; we will be in touch with results via
                email.
              </li>
              <li className="mb-2">Additional terms may apply upon conversion.</li>
            </ul>
          </div>
        </ModalBodyContent>

        <ModalFooter>
          <Button className="flex-1" color="secondary" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="flex-1"
            size="small"
            disabled={requestLoading}
            onClick={() => {
              if (requestConstraints?.should_request_bank_info) {
                setScreen('connect_bank_account');
              } else {
                sendCreditRequest();
              }
            }}
          >
            Submit Request
          </Button>
        </ModalFooter>
      </>
    );
  } else if (screen === 'connect_bank_account') {
    child = (
      <UnlimitedToFlexMigrationConnectBankAccount
        onNext={() => {
          sendCreditRequest();
          setScreen('success');
        }}
        onBack={() => {
          setScreen('confirm_intent');
        }}
        onClose={onClose}
        showBankStatementOption={true}
      />
    );
  } else if (screen === 'success') {
    child = <ULToCreditRequestReviewPending onClose={onClose} />;
  }

  return (
    <Modal open={open} toggle={onClose}>
      {child}
    </Modal>
  );
};
