import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import { ErrorNotification } from '@app/components/layout';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import useCustomer from '@app/hooks/useCustomer';
import { useEmbeddedDashboardUrl } from '@app/pages/Reporting/useEmbeddedDashboardUrl';
import { ReactElement, useState } from 'react';
import AccountOverviewHeader from '../AccountOverview/AccountOverviewHeader';

type DashboardLoaderProps = {
  dashboardName: string;
};

function DashboardLoader({ dashboardName }: DashboardLoaderProps) {
  const { data, error, isLoading } = useEmbeddedDashboardUrl(dashboardName);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error loading the dashboard. Please try again later.</div>;
  }
  if (data?.url) {
    return <iframe src={data.url} width="100%" height="2000px"></iframe>;
  }
  return null;
}

export default function PartnerHome(): ReactElement {
  const customer = useCustomer();
  const [error, _setError] = useState(false);

  return (
    <>
      <MobileNavHeader title={customer ? customer.name : 'Partner Home'} />
      <div className="m-4 md:mr-0" />
      <PageContentWrapper>
        <div className="flex items-center justify-between px-4 first:pt-4 md:px-0 md:pt-0">
          <AccountOverviewHeader title={customer.company_name} />
        </div>
        <h1 className="tw text-lg font-medium">
          Welcome to AtoB. This is your partner account with AtoB
        </h1>
        {error && (
          <ErrorNotification
            error={
              "We're having issues loading your page. Please try again; if the issue persists, please contact support."
            }
          />
        )}
        <div>
          <DashboardLoader dashboardName="partner_factoring_home" />
        </div>
      </PageContentWrapper>
    </>
  );
}
