import { SettingsMobileDropdown } from '@app/components/Cards/Settings/SettingsMobileDropdown';
import { SettingsRowCurrentValue } from '@app/components/Cards/Settings/SettingsRowCurrentValue';
import { SettingsRowHeadline } from '@app/components/Cards/Settings/SettingsRowHeadline';
import { SettingsPageAddressType, SettingsPageEntityType } from '@app/constants/settings';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

export interface IAddressProps {
  type: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zip: string;
  typeOfAddress: SettingsPageAddressType;
  id?: string;
}

export interface IRowProps {
  type: SettingsPageEntityType;
  value: string | IAddressProps | boolean;
  disableUpdate?: boolean;
  onClickUpdate: (newValues: unknown) => void;
}

const SettingsPageRow = ({
  type,
  value,
  onClickUpdate,
  disableUpdate,
}: IRowProps): ReactElement => {
  const [isShowMore, setIsShowMore] = useState(false);
  return (
    <>
      <div className="flex justify-between">
        <SettingsRowHeadline type={type} />
        <SettingsRowCurrentValue type={type} value={value} />
        <div className="xxs:basis-24 xs:block hidden w-full">
          {disableUpdate ? (
            <div />
          ) : (
            <Button color="secondary" size="medium" onClick={onClickUpdate}>
              Update
            </Button>
          )}
        </div>
        <div className="xs:hidden block sm:w-fit">
          <FontAwesomeIcon
            onClick={() => {
              setIsShowMore(!isShowMore);
            }}
            className="cursor-pointer"
            icon={isShowMore ? faAngleUp : faAngleDown}
          />
        </div>
      </div>
      {isShowMore && (
        <SettingsMobileDropdown
          type={type}
          value={value}
          onClickUpdate={onClickUpdate}
          disableUpdate={disableUpdate}
        />
      )}
    </>
  );
};

export default SettingsPageRow;
