import { PaymentHistoryContext } from '@app/contexts/paymentHistoryContext';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useContext } from 'react';

interface RemovePaymentMethodModalProps {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  message: string;
  buttonText: string;
  handleRefreshPaymentMethods?: () => void;
  isPrimary: boolean;
}
const RemovePaymentMethodModal = ({
  isActive,
  setIsActive,
  message,
  buttonText,
  handleRefreshPaymentMethods,
  isPrimary,
}: RemovePaymentMethodModalProps): ReactElement => {
  const { handleDeletePaymentMethod } = useContext(PaymentHistoryContext);
  const onClick = async (): Promise<void> => {
    await handleDeletePaymentMethod();
    setIsActive(false);
    handleRefreshPaymentMethods && (await handleRefreshPaymentMethods());
  };
  return (
    <Modal open={isActive} toggle={() => setIsActive}>
      <ModalHeader title={buttonText} onClose={() => setIsActive(false)} />
      <ModalBodyContent overflowVisible={true}>
        {isPrimary ? (
          <CannotRemovePaymentMethodModalContent />
        ) : (
          <RemovePaymentMethodModalContent
            onClick={onClick}
            message={message}
            buttonText={buttonText}
          />
        )}
      </ModalBodyContent>
    </Modal>
  );
};

const RemovePaymentMethodModalContent = ({
  onClick,
  buttonText,
  message,
}: {
  onClick: () => void;
  buttonText: string;
  message: string;
}): ReactElement => {
  return (
    <div className="flex w-full flex-col items-end">
      <div className="text-black2 mb-8 w-full text-sm">
        {message}
        <div className=" text-grey mt-4 text-xs">
          Note: Payment method removal takes up to 15 business days to fully process.
        </div>
      </div>
      <div className="mb-4 w-fit">
        <Button color="alert" size="medium" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

const CannotRemovePaymentMethodModalContent = (): ReactElement => {
  return (
    <div className="flex w-full flex-col items-end">
      <p className="text-black2 mb-8 w-full text-sm">
        You cannot remove a payment method that is set as your primary payment method. To remove
        this payment method, connect another payment method to your account, and set that as the
        primary.
      </p>
    </div>
  );
};

export default RemovePaymentMethodModal;
