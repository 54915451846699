export const partners = [
  { id: 0, imageSrc: 'images/onboarding/petro.png', alt: 'Petro Stopping Centers' },
  { id: 1, imageSrc: 'images/onboarding/seven-fleet.png', alt: 'Seven Fleet Diesel Network' },
  { id: 2, imageSrc: 'images/onboarding/loves.png', alt: 'Loves' },
  { id: 3, imageSrc: 'images/onboarding/sheetz.png', alt: 'Sheetz' },
  { id: 4, imageSrc: 'images/onboarding/caseys.png', alt: 'Caseys' },
  { id: 5, imageSrc: 'images/onboarding/race-trac.png', alt: 'RaceTrac' },
  { id: 6, imageSrc: 'images/onboarding/sapp-bros.png', alt: 'Sapp Bros' },
  { id: 7, imageSrc: 'images/onboarding/petroleum.png', alt: 'Petroleum' },
  { id: 8, imageSrc: 'images/onboarding/allsups.png', alt: 'Allsups' },
  { id: 9, imageSrc: 'images/onboarding/kum-and-go.png', alt: 'Kum & Go' },
  { id: 10, imageSrc: 'images/onboarding/yes-way.png', alt: 'Yes Way' },
  { id: 11, imageSrc: 'images/onboarding/rutters.png', alt: 'Rutters' },
  { id: 12, imageSrc: 'images/onboarding/kwik-trip.png', alt: 'Kwik Trip' },
  { id: 13, imageSrc: 'images/onboarding/dk.png', alt: 'DK' },
];
