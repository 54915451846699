import { FuelGrade, FuelListing, PriceListingType } from '@app/@types/fuel_listings.types';
import logger from '@app/utils/datadog-logger';
import { choosePreferredFuelPrice, formatFuelPrice } from '@app/utils/fuel-listings';
import classNames from 'classnames';
import { BrandLogo } from './Logos/BrandLogo';
import { useHasDiscount } from './useHasDiscount';
import { usePriceListingType } from './usePriceListingType';

interface MapLabelProps {
  lat: number;
  lng: number;
  fuelListing: FuelListing;
  highlighted: boolean;
  onSelect: (fuelListing: FuelListing) => void;
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
}

const MapLabel = ({ fuelListing, highlighted = false, onSelect, selectedGrade }: MapLabelProps) => {
  const prices = fuelListing.attributes.fuel_prices.data;

  const priceToShow = choosePreferredFuelPrice(prices ?? [], selectedGrade);
  const effectiveCostPerGallon = formatFuelPrice(
    priceToShow?.attributes.effective_cost_per_gallon_cents ?? 0,
  );

  const priceListingType = usePriceListingType(priceToShow ?? null);
  const hasDiscount = useHasDiscount(priceListingType);

  const isLogoHidden = !hasDiscount && selectedGrade !== 'ELECTRIC';
  const isPriceHidden =
    selectedGrade === 'ELECTRIC' ||
    priceListingType === PriceListingType.GUARANTEED_DISCOUNT_INVALID_PRICE ||
    priceListingType === PriceListingType.NO_DISCOUNT_INVALID_PRICE;

  if (isPriceHidden && isLogoHidden) {
    return null;
  }

  return (
    <div className="flex -translate-x-1/2 -translate-y-full flex-col items-center justify-center">
      <div
        className={classNames(
          'shadow-marker flex  w-fit flex-row items-center gap-2 rounded-full p-0.5  hover:cursor-pointer',
          hasDiscount ? 'bg-accent-11 hover:bg-accent-10' : 'hover:bg-background bg-level-0',
          isPriceHidden ? '' : 'pr-2',
          {
            'border-primary shadow-highlighted-marker border-2': highlighted,
          },
        )}
        onClick={() => {
          logger.info('fuel-listing-select', {
            from: 'map',
            id: fuelListing.attributes.site_id,
          });
          onSelect(fuelListing);
        }}
      >
        <div className="flex h-8 flex-grow flex-col justify-center">
          {!isLogoHidden && (
            <BrandLogo
              logoURL={fuelListing.attributes.logo_url}
              brand={fuelListing.attributes.location.name}
              size={{ width: 32, height: 32 }}
              rounded
              border
              electric={selectedGrade === 'ELECTRIC'}
            />
          )}
        </div>
        {!isPriceHidden && (
          <span
            className={classNames(
              'text-sm font-medium',
              hasDiscount ? 'text-level-0' : 'text-secondary',
            )}
          >
            ${effectiveCostPerGallon}
          </span>
        )}
      </div>
    </div>
  );
};

export default MapLabel;
