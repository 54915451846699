import * as yup from 'yup';
import Constants from '../constants';
import { numericRegex } from '../is-numeric';
import type { AddressType } from '@app/@types/customer.types';

type PhoneNumberSchemaType = {
  phoneNumber: string;
};

type EmailSchemaType = {
  email: string;
};

const phoneNumberSchema: yup.ObjectSchema<PhoneNumberSchemaType> = yup
  .object({
    phoneNumber: yup.string().matches(Constants.PHONE_REGEX).required(),
  })
  .defined();

const emailSchema: yup.ObjectSchema<EmailSchemaType> = yup
  .object({
    email: yup.string().email().required(),
  })
  .defined();

const addressSchema: yup.ObjectSchema<AddressType> = yup
  .object({
    address1: yup.string().required('Address is required'),
    address2: yup.string().nullable(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zip: yup.string().required('Zip is required').matches(numericRegex, 'Zip must be numeric'),
  })
  .defined();

export { addressSchema, emailSchema, phoneNumberSchema };
