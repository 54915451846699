import { ChargeEventData } from '@app/@types/charge_events.types';
import { getStatusesHelper } from '@app/pages/Transactions/utils';
import { faArrowTrendUp, faArrowTrendDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactNode, useCallback } from 'react';
import { BrandLogo } from '../FuelListings/Logos/BrandLogo';

type MerchantProps = {
  row: ChargeEventData;
};

const IconWrapper = ({ children }: { children: ReactNode }) => {
  return <div className="absolute -bottom-0.5 left-5 h-4 w-4 rounded-full p-0.5">{children}</div>;
};

export default function Merchant({ row }: MerchantProps) {
  const { merchant_name, location } = row;
  const { isPending, hasSaved, hasMissed, hasDeclined } = getStatusesHelper(row);

  const renderIcon = useCallback(() => {
    if (isPending) {
      return (
        <IconWrapper>
          <div className="flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faClock} className="text-tertiary h-3 w-3" />
          </div>
        </IconWrapper>
      );
    }

    if (hasSaved) {
      return (
        <IconWrapper>
          <div className="bg-accent-11 flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faArrowTrendUp} className="h-2 w-2 text-white" />
          </div>
        </IconWrapper>
      );
    }

    if (hasMissed) {
      return (
        <IconWrapper>
          <div className="bg-warning-1 flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faArrowTrendDown} className="h-2 w-2 text-white" />
          </div>
        </IconWrapper>
      );
    }

    if (hasDeclined) {
      return (
        <IconWrapper>
          <div className="bg-error-1 flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faXmark} className="h-2 w-2 text-white" />
          </div>
        </IconWrapper>
      );
    }

    return null;
  }, [isPending, hasSaved, hasMissed, hasDeclined]);

  return (
    <div className="flex flex-1 items-center gap-2 overflow-hidden md:gap-3">
      <div className="relative shrink-0">
        <BrandLogo
          rounded
          border
          logoURL={row.logo_url}
          brand={row.merchant_name}
          size={34}
          opacity={hasDeclined}
        />
        {renderIcon()}
      </div>
      <div className="flex-1 truncate md:space-y-1">
        <p
          className={classNames(
            'truncate text-xs font-medium leading-5 md:leading-4',
            hasDeclined ? 'text-tertiary' : 'text-primary',
          )}
        >
          {merchant_name}
        </p>
        <p
          className={classNames(
            'truncate text-[10px] leading-4 md:text-xs',
            hasDeclined ? 'text-tertiary' : 'text-secondary',
          )}
        >
          {location}
        </p>
      </div>
    </div>
  );
}
