import { PaginatedEndpointResponse } from '@app/@types/api.types';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import useProduct from '@app/hooks/useProduct';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { debounce } from 'lodash-es';
import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import useSWR from 'swr';
import FundingRequests from './FundingRequests';

const Title = ({ title }: { title: string }): ReactElement => {
  return <h2 className="text-grey leading-12 text-sm font-bold uppercase">{title}</h2>;
};

const WalletBalance = ({ balance }: { balance: string | null }): ReactElement => {
  return (
    <div className="flex flex-col justify-between">
      <Title title="Wallet Balance" />
      <div className="text-atob-green text-[40px] font-normal">
        {balance === null ? 'Pending' : balance}
      </div>
    </div>
  );
};

export type FundingStatus =
  | 'created'
  | 'processing'
  | 'failed'
  | 'succeeded'
  | 'reverted'
  | 'abandoned';

export type FundingRequest = {
  id: number;
  customer_id: number;
  channel_partner_id: number;
  reference_id: string;
  status: FundingStatus;
  description: string;
  customer_name: string;
  created_at: string;
  updated_at: string;
  processed_at: string; // when processing started
  fulfilled_at: string; // when processing was successful
};

type FinancialAccountDetails = {
  balance: string | null;
};

const initialFinancialAccountDetails: FinancialAccountDetails = {
  balance: null,
};

type Carrier = {
  id: number;
  company_name: string;
  dot_number: string | null;
  mc_number: string | null;
  ein: string | null;
  external_id: string;
  wallet_balance: number | null;
  card_shipping_address: string;
  is_credit: boolean;
  status: string;
  suspended_at: string | null;
};

function highlightMatch(text: string, query: string): ReactElement {
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part,
      )}
    </>
  );
}

function CarrierDetails({
  carriers,
  handleSelect,
  query,
}: {
  carriers: Carrier[];
  handleSelect?: (carrier: Carrier) => void;
  query: string;
}): ReactElement {
  const carrierList = Array.isArray(carriers) ? carriers : [carriers];

  return (
    <Paper elevation={3} className="p-0">
      <List className="p-0">
        {carrierList.map((carrier, index) => {
          const isSuspended = carrier.suspended_at != null;
          const isNotApproved = carrier.status !== 'approved';
          const isInactive = isNotApproved || isSuspended;

          return (
            <React.Fragment key={carrier.id}>
              {index === 0 && <Divider />}
              <ListItem
                onClick={handleSelect ? () => handleSelect(carrier) : undefined}
                className={isInactive ? 'cursor-not-allowed bg-red-50' : ''}
              >
                <ListItemText
                  primary={<>{highlightMatch(carrier.company_name, query)}</>}
                  secondary={
                    <>
                      {carrier.id && <>ID: {highlightMatch(carrier.id.toString(), query)}, </>}
                      {carrier.ein && <>EIN: {highlightMatch(carrier.ein, query)}, </>}
                      {carrier.dot_number && (
                        <>DOT: {highlightMatch(carrier.dot_number, query)}, </>
                      )}
                      {carrier.mc_number && <>MC: {highlightMatch(carrier.mc_number, query)}, </>}
                      {carrier.external_id && (
                        <>External ID: {highlightMatch(carrier.external_id, query)}, </>
                      )}
                      {isInactive && (
                        <>
                          Status: <strong>{isSuspended ? 'suspended' : carrier.status}</strong>{' '}
                        </>
                      )}
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
}

function CustomerSearch({
  onCustomerSelect,
  onSearchBegin,
}: {
  onCustomerSelect: (customer: Carrier) => void;
  onSearchBegin: () => void;
}): ReactElement {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState<Carrier[]>([]);
  const [searchCompleted, setSearchCompleted] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        if (!value) {
          setSuggestions([]);
          setSearchCompleted(false);
          return;
        }

        setSearchCompleted(false);
        axios
          .get('/partners/customers', { params: { query: value } })
          .then((response) => {
            // Transform JSON:API response to Carrier format
            const carriers = deserialize(response.data).data;

            setSuggestions(carriers);
            setSearchCompleted(true);
          })
          .catch(() => {
            setSuggestions([]);
            setSearchCompleted(true);
          });
      }, 200),
    [setSuggestions, setSearchCompleted],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setQuery(value);

    if (!value) {
      setSuggestions([]);
      setSearchCompleted(false);
    } else {
      onSearchBegin();
      debouncedSearch(value);
    }
  };

  const handleSelect = (carrier: Carrier): void => {
    onCustomerSelect(carrier);
    setQuery('');
    setSuggestions([]);
  };

  return (
    <div className="w-full">
      <TextField
        value={query}
        onChange={handleQueryChange}
        placeholder="Enter Carrier Name, DOT, MC, EIN, or ID"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      {query && searchCompleted && suggestions.length === 0 && (
        <Typography variant="body1" className="py-5 text-red-500">
          No results found
        </Typography>
      )}

      {query && suggestions.length > 0 && (
        <CarrierDetails carriers={suggestions} handleSelect={handleSelect} query={query} />
      )}
    </div>
  );
}

type FundingRequestFormProps = {
  carrierId: number;
  onCancel: () => void;
  refetchFundingRequests: () => void;
};

function FundingRequestForm({
  carrierId,
  onCancel,
  refetchFundingRequests,
}: FundingRequestFormProps): ReactElement {
  const [dollarAmount, setDollarAmount] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [description, setDescription] = useState('');
  const [requestError, setRequestError] = useState('');

  const isValidDollarAmount = (amount: string): boolean => {
    const dollarAmountRegex = /^\d+(\.\d{2})?$/;
    return dollarAmountRegex.test(amount);
  };

  const handleSubmit = (): void => {
    setRequestError('');

    if (!dollarAmount || !referenceId) {
      setRequestError('Dollar Amount and Reference ID are required.');
      return;
    }

    if (!isValidDollarAmount(dollarAmount)) {
      setRequestError('Please enter a valid dollar amount (e.g., 100 or 100.00).');
      return;
    }

    const data = {
      customer_id: carrierId,
      dollar_amount: dollarAmount,
      reference_id: referenceId,
      description: description, // This field is optional
    };

    axios
      .post('/partners/funding_requests', data)
      .then(() => {
        refetchFundingRequests(); // Trigger a refetch of the funding requests after success
        onCancel(); // Reset the form and go back to the initial state after form submission
      })
      .catch((error) => {
        // Extract error message or use a generic fallback
        const errorMessage =
          error.response?.data?.error || 'An unexpected error occurred. Please try again.';
        setRequestError(`An error occurred while submitting your request: ${errorMessage}`);
      })
      .finally(() => {
        refetchFundingRequests(); // Always refetch the funding requests even in case of error
      });
  };

  return (
    <div>
      {requestError && <div className="mb-2 text-red-500">{requestError}</div>}
      <TextField
        label="Dollar Amount"
        variant="outlined"
        fullWidth
        required
        value={dollarAmount}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDollarAmount(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Reference ID"
        variant="outlined"
        fullWidth
        required
        value={referenceId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferenceId(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
        margin="normal"
      />
      <div className="mt-4 flex space-x-4">
        <Button color="primary" onClick={handleSubmit}>
          Submit Funding Request
        </Button>
        <Button className="bg-gray-500 text-white" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default function Funding(): ReactElement {
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const [loading, setLoading] = useState(false);
  const [showFundingForm, setShowFundingForm] = useState(false);
  const [customerSearchKey, setCustomerSearchKey] = useState(0);
  const [error, setError] = useState<boolean>(false);

  const [financialData, setFinancialData] = useState<FinancialAccountDetails>(
    initialFinancialAccountDetails,
  );

  const [page, setPage] = useSingleUrlState<number>('page', 1);
  const {
    data,
    isLoading,
    error: fundingRequestsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<FundingRequest>>(
    {
      url: '/partners/funding_requests',
      params: {
        per: DEFAULT_PAGE_SIZE,
        page,
        all: false,
        customer_id: carrier ? carrier.id : null,
      },
    },
    apiGetFetcher,
  );

  const [canManuallyFund] = useProduct('funding_with_manual_requests');

  const handleCustomerSelect = (selectedCarrier: Carrier): void => {
    setCarrier(selectedCarrier);
    setShowFundingForm(false);
  };

  const handleCancel = (): void => {
    setCarrier(null);
    setShowFundingForm(false);
    setCustomerSearchKey((prevKey) => prevKey + 1); // Reset CustomerSearch component
  };

  const handleSearchBegin = (): void => {
    setCarrier(null);
  };

  const fetchFinancialData = async (): Promise<void> => {
    setLoading(true);
    try {
      const financialAccountResponse = await axios.get('/treasury/financial_account');

      if (financialAccountResponse.data.data === null) {
        setFinancialData(initialFinancialAccountDetails);
      } else {
        const { data: financialAccountData } = deserialize(financialAccountResponse.data);
        setFinancialData({
          balance: financialAccountData.balance,
        });
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinancialData();
  }, []);

  const balance = financialData.balance;

  return (
    <>
      <MobileNavHeader title="Wallet Funding Requests" />
      <div className="m-4 md:mr-0" />
      <PageContentWrapper>
        <h1 className="tw text-lg font-medium">Funding Requests</h1>
        {error && (
          <ErrorNotification
            error={
              "We're having issues loading your Wallet details. Please try again; if the issue persists, please contact support."
            }
          />
        )}
        <div>
          <div className="rounded bg-white shadow-md">
            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex justify-between">
                <div className="flex w-full flex-col">
                  <div className="flex justify-between gap-x-6 p-5 pb-3">
                    <div className="mr-3 flex w-full">
                      <div className="mr-4">
                        <WalletBalance balance={balance} />
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-100"></div>
                  <div className="flex items-center justify-between p-5"></div>
                  <div className="flex justify-between pb-10 pl-5 pr-5">
                    <div className="mr-3 flex w-full">
                      <div className="mr-4 w-full">
                        <div className="flex flex-col justify-between">
                          {!carrier ? (
                            <>
                              <Title title="Carrier Search" />
                              <CustomerSearch
                                key={customerSearchKey}
                                onCustomerSelect={handleCustomerSelect}
                                onSearchBegin={handleSearchBegin}
                              />
                            </>
                          ) : (
                            <>
                              <Title title="Selected Carrier" />
                              <CarrierDetails
                                carriers={[carrier]}
                                handleSelect={undefined}
                                query={''}
                              />

                              {!showFundingForm ? (
                                <div className="mt-4 flex space-x-4">
                                  {canManuallyFund && (
                                    <Button
                                      color="primary"
                                      onClick={() => setShowFundingForm(true)}
                                      disabled={
                                        carrier.status !== 'approved' ||
                                        carrier.suspended_at != null
                                      }
                                      className={
                                        carrier.status !== 'approved' ||
                                        carrier.suspended_at != null
                                          ? 'bg-gray-200 text-gray-500'
                                          : ''
                                      }
                                    >
                                      Create Funding Request
                                    </Button>
                                  )}
                                  <Button color="secondary" onClick={handleCancel}>
                                    Cancel
                                  </Button>
                                </div>
                              ) : (
                                <div className="mt-4">
                                  <FundingRequestForm
                                    carrierId={carrier.id}
                                    onCancel={handleCancel}
                                    refetchFundingRequests={mutate}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <h1 className="text-lg font-medium">
              Funding Requests for {carrier ? carrier.company_name : 'All Carriers'}
            </h1>

            {data?.data?.length ? (
              <FundingRequests
                fundingRequestsPage={data.data}
                fundingRequestsError={fundingRequestsError}
                metadata={data.meta}
                goToPage={setPage}
              />
            ) : (
              <p>No funding requests yet</p>
            )}
          </>
        )}
      </PageContentWrapper>
    </>
  );
}
