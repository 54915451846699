import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

function SidebarCloseButton({
  onClick,
  iconClassname,
}: {
  onClick: () => void;
  iconClassname: string;
}) {
  return (
    <IconButton onClick={onClick} color="tertiary">
      <FontAwesomeIcon icon={faXmark} className={iconClassname} />
    </IconButton>
  );
}

export default function SidebarHeader({
  title,
  onClose,
}: {
  title: string | ReactNode;
  onClose: () => void;
}): ReactElement {
  return (
    <>
      <div className="hidden items-center justify-between p-8 md:flex">
        <h3 className="text-2xl font-semibold">{title}</h3>
        <SidebarCloseButton onClick={onClose} iconClassname="h-4 w-4" />
      </div>
      <div className="flex items-center gap-x-2 px-4 py-6 md:hidden">
        <SidebarCloseButton onClick={onClose} iconClassname="text-xl" />
        <h3 className="text-primary text-lg font-semibold">{title}</h3>
      </div>
    </>
  );
}
