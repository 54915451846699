import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { useOnboardingGuide } from './useOnboardingGuide';
import { partners } from './utils';

export default function OnboardingWhereCanIGetDiscounts() {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header
        title="Where can I get discounts with my AtoB card?"
        description="With your AtoB card, you have access to fuel discounts via AtoB Savings at multiple truckstops and gas stations. Just pay with your card at any one of those stations to automatically earn the discount."
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-primary font-semibold md:text-lg">Our discount partners</h3>
        <div className="grid grid-cols-2 gap-y-3 md:gap-x-4">
          {partners.slice(0, 2).map((partner) => (
            <div
              key={partner.id}
              className="bg-level-1 flex h-[64px] items-center justify-center rounded"
            >
              <img src={partner.imageSrc} alt={partner.alt} className="max-h-[48px] p-1" />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-6 gap-y-3 md:gap-x-4">
          {partners.slice(2).map((partner) => (
            <div
              key={partner.id}
              className="bg-level-1 flex h-[64px] items-center justify-center rounded"
            >
              <img src={partner.imageSrc} alt={partner.alt} className="max-h-[48px] p-1" />
            </div>
          ))}
        </div>
      </div>
      <FooterActionButtons hideBackButton handleClickNext={goToNextStep} />
    </div>
  );
}
