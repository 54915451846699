import { KeyframeOptions, animate, cubicBezier, useInView } from 'framer-motion';
import { useLayoutEffect, useRef } from 'react';

type AnimatedCounterProps = {
  from: number;
  to: number;
  duration?: number;
  animationOptions?: KeyframeOptions;
};

export default function AnimatedCounter({
  from,
  to,
  duration = 2,
  animationOptions,
}: AnimatedCounterProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const inView = useInView(ref, { once: true });

  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) return;
    if (!inView) return;

    element.textContent = from.toLocaleString();

    if (window.matchMedia('(prefers-reduced-motion)').matches) {
      element.textContent = to.toLocaleString();
      return;
    }

    const controls = animate(from, to, {
      duration,
      ease: cubicBezier(0.3, 0.2, 0, 1),
      ...animationOptions,
      onUpdate(value) {
        element.textContent = Number(value.toFixed(0)).toLocaleString();
      },
    });

    return () => {
      controls.stop();
    };
  }, [ref, inView, from, to, duration, animationOptions]);

  return <span ref={ref} />;
}
