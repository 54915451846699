import { BankAccountWithEstimateData } from '@app/@types/payments.types';
import DescriptionItem from '@app/components/PaymentModal/DescriptionItem/DescriptionItem';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import AmountItem from '@app/components/PaymentModal/components/AmountItem';
import EditPaymentFooter from '@app/components/PaymentModal/components/EditPaymentFooter';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import { getProcessingDate } from '@app/components/PaymentModal/helpers/getProcessingDate';
import Divider from '@atob-developers/shared/src/components/Divider';
import {
  convertNumbersWithCurrency,
  getCurrencyFromCents,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { ReactElement, useState } from 'react';
import BankAccountDetailItem from './components/BankAccountDetailItem';
interface BankTransferEditPaymentDetailsProps {
  selectedBankAccount?: BankAccountWithEstimateData;
  closeModal: () => void;
  onNext: () => void;
  paymentAmount: string;
  setPaymentAmount: (value: string) => void;
  setSelectedBankAccount: (selectedBankAccount: BankAccountWithEstimateData) => void;
  bankAccountsWithEstimates: BankAccountWithEstimateData[];
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const MINIMUM_PAYMENT_AMOUNT_CENTS = 500;

const BankTransferEditPaymentDetails = ({
  selectedBankAccount,
  closeModal,
  onNext,
  paymentAmount,
  setPaymentAmount,
  setSelectedBankAccount,
  bankAccountsWithEstimates,
  showAmountDue,
  setShowAmountDue,
}: BankTransferEditPaymentDetailsProps): ReactElement | null => {
  const [inputError, setInputError] = useState<string | null>(null);
  const [bankAccountError, setBankAccountError] = useState<string | null>(null);

  const handleNextClick = () => {
    if (!selectedBankAccount) {
      setBankAccountError('Please add an account.');
      return;
    }
    const inputAmountInCents = convertNumbersWithCurrency(paymentAmount) * 100;
    if (inputAmountInCents < MINIMUM_PAYMENT_AMOUNT_CENTS) {
      setInputError(
        `Minimum payment of ${getCurrencyFromCents(MINIMUM_PAYMENT_AMOUNT_CENTS)} required`,
      );
    } else {
      onNext();
    }
  };

  return (
    <>
      <div className="w-full space-y-10 bg-white px-6 py-6 sm:px-8">
        <div className="flex flex-col gap-4">
          <PaymentDetailsHeaderText>Payment Details</PaymentDetailsHeaderText>
          <div className="flex flex-col gap-4">
            <Divider className="bg-gray-200" />
            <BankAccountDetailItem
              selectedBankAccount={selectedBankAccount}
              setSelectedBankAccount={setSelectedBankAccount}
              bankAccountsWithEstimates={bankAccountsWithEstimates}
              bankAccountError={bankAccountError}
            />
            <Divider className="bg-gray-200" />
            <AmountItem
              amount={paymentAmount}
              setAmount={setPaymentAmount}
              error={inputError}
              setError={setInputError}
              setShowAmountDue={setShowAmountDue}
              showAmountDue={showAmountDue}
            />
            <Divider className="bg-gray-200" />
            <PaymentDetailItem text="Est. Processing Date">
              {selectedBankAccount
                ? getProcessingDate(selectedBankAccount.estimated_apply_at)
                : '3 Days'}
            </PaymentDetailItem>
          </div>
        </div>
        <DescriptionItem text="ACH payments are processed in 3 business days. We'll email you when the payment is received or if it fails." />
      </div>
      <EditPaymentFooter
        disableNext={
          selectedBankAccount?.bank_account.data.attributes.plaid_item_status === 'inactive'
        }
        onCancel={closeModal}
        onNext={handleNextClick}
      />
    </>
  );
};

export default BankTransferEditPaymentDetails;
