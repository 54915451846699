import {
  Plan,
  ProductSubscriptionResponse,
  SubscriptionPaymentMethodDetails,
} from '@app/@types/subscriptions.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useChannelPartner from '@app/hooks/useChannelPartner';
import WelcomeToAtoBPremium from '@app/pages/Settings/Plans/WelcomeToAtoBPremium';
import { FetcherKey, normalizeFetcherKey } from '@app/utils/data/fetchers';
import { CustomTooltip } from '@atob-developers/shared/src/components/Tooltip';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import currency from 'currency.js';
import { deserialize } from 'deserialize-json-api';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import AtoBPlusBanner from './AtoBPlusBanner';
import ChangePlan from './ChangePlan';
import ProductCard from './ProductCard';

export type ProductSubscription = {
  type: string;
  plan: 'basic' | 'monthly' | 'premium';
  cost: string;
  details: {
    active_cards?: number;
    employees?: number;
    upcoming_payment: string;
    billing_period: string;
    in_trial_period: boolean;
  };
  payment_method_details: SubscriptionPaymentMethodDetails;
  minimum_subscription_amount?: {
    cents: number;
    currency: string;
  };
};

export const getDetails = (
  product: ProductSubscription,
): { type: string; value: string | number }[] => {
  if (product.type === 'payroll') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return [
      product.details.employees && { type: 'Active employees', value: product.details.employees },
      product.details.upcoming_payment && {
        type: 'Upcoming Payment',
        value: product.details.upcoming_payment,
      },
      product.details.billing_period && {
        type: 'Billing Period',
        value: capitalize(product.details.billing_period),
      },
      product.payment_method_details && {
        type: 'Payment Method',
        value: `${capitalize(product.payment_method_details.name)} ••••${
          product.payment_method_details.last4
        }`,
      },
    ].filter((x) => x);
  }

  if (product.type === 'atob_plus_premium') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return [
      product.details.active_cards && { type: 'Active cards', value: product.details.active_cards },
      product.details.upcoming_payment && {
        type: 'Upcoming Payment',
        value: product.details.upcoming_payment,
      },
      product.details.billing_period && {
        type: product.details.in_trial_period ? 'Free Trial Period' : 'Billing Period',
        value: capitalize(product.details.billing_period),
      },
      product.payment_method_details && {
        type: 'Payment Method',
        value: `${capitalize(product.payment_method_details.name)} ••••${
          product.payment_method_details.last4
        }`,
        tooltipHeader: `${capitalize(product.payment_method_details.name)} ••••${
          product.payment_method_details.last4
        }`,
        TooltipContent: () => (
          <span>
            By default we use your primary payment method. To change this, go to{' '}
            <a className="text-atob-green" href="/billing/payment-methods">
              Payment Methods
            </a>
            .
          </span>
        ),
      },
    ].filter((x) => x);
  }

  return [];
};

export const convertToProductSubscription = (
  product: ProductSubscriptionResponse,
): ProductSubscription => {
  const { attributes } = product.data;
  const isBasic = attributes.plan === 'basic';
  const hasBaseCost = attributes.details.base_cost !== 0;
  const formattedBaseCost = currency(attributes.details.base_cost, { fromCents: true }).format();
  const formattedUsageCost = currency(attributes.details.usage_cost, { fromCents: true }).format();
  const amount = hasBaseCost ? `${formattedBaseCost}` : formattedUsageCost;
  const min_cards = 5;
  let monthlyCost: string;

  if (isBasic && attributes.minimum_subscription_amount && attributes.details.usage < min_cards) {
    const formattedMinimumCost = currency(attributes.minimum_subscription_amount.cents, {
      fromCents: true,
    }).format();

    monthlyCost = `${formattedMinimumCost} flat fee for 1-${min_cards} cards, and ${amount} per month for each additional card after ${min_cards}`;
  } else {
    monthlyCost = `${amount} per month ${hasBaseCost ? `+ ${formattedUsageCost}` : ''} /
      ${attributes.details.usage_unit}`;
  }

  // Base card fees are calculated through a different mechanism than Premium
  // for now. Just hide the upcoming payment amount for now.
  const upcomingPayment =
    !isBasic && attributes.details.upcoming_payment
      ? currency(attributes.details.upcoming_payment, {
          fromCents: true,
        }).format()
      : null;

  const productSubscription = {
    type: attributes.product,
    plan: attributes.plan,
    cost: monthlyCost,
    details: {
      [attributes.product === 'atob_plus_premium' ? 'active_cards' : '']: attributes.details.usage,
      upcoming_payment: upcomingPayment,
      billing_period: attributes.details.billing_period,
      in_trial_period: attributes.details.in_trial_period,
    },
    payment_method_details: attributes.payment_method_details,
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return productSubscription;
};

export default function PlansOverview({
  productTypeToChangePlan,
  showAtoBPlusBanner,
}: {
  productTypeToChangePlan: ProductSubscription['type'];
  showAtoBPlusBanner: boolean;
}): ReactElement {
  const location = useLocation();
  const { forcePremium } = useChannelPartner();
  const isConfirmed = location.pathname.includes('confirmed');

  const { data: productSubscriptions, isLoading } = useSWR<ProductSubscription[]>(
    { url: '/subscriptions' },
    async (fetcherKey: FetcherKey) => {
      const { url, params } = normalizeFetcherKey(fetcherKey);
      const res = await axios(url, { params });
      const subscriptions = deserialize(res.data).data as ProductSubscriptionResponse[];
      return subscriptions
        .filter((s) => s.data.attributes.product !== 'payroll')
        .map(convertToProductSubscription);
    },
  );

  if (isConfirmed) {
    return <WelcomeToAtoBPremium />;
  }

  if (productTypeToChangePlan) {
    const currentProduct = productSubscriptions?.find(
      (product) => product.type === productTypeToChangePlan,
    );

    if (currentProduct) {
      const currentPlan: Plan = {
        current: true,
        type: currentProduct.type,
        name: currentProduct.plan,
        amount: currentProduct.cost,
        valueProps: [],
      };

      return <ChangePlan currentPlan={currentPlan} />;
    }
  }

  return (
    <div>
      {showAtoBPlusBanner && <AtoBPlusBanner />}
      {isLoading && <SpinnerBoundary />}
      {productSubscriptions?.map((product) => (
        <div key={product.type + product.plan} className="my-4">
          <ProductCard
            key={product.cost}
            product={product}
            editable={!forcePremium || product.type !== 'atob_plus_premium'}
          >
            {(Detail) => (
              <>
                {getDetails(product).map(
                  (detail: {
                    type: string;
                    value: string | number;
                    TooltipContent?: () => ReactElement;
                    tooltipHeader?: string;
                  }) => {
                    const { TooltipContent } = detail;
                    return (
                      <div key={detail.type} className="flex items-center">
                        <Detail {...detail} />
                        <span className="ml-1" />
                        {detail.TooltipContent && (
                          <CustomTooltip icon={faInfoCircle}>
                            <div className="px-3 py-3 text-center text-sm font-medium text-gray-700">
                              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                              {/* @ts-expect-error */}
                              <TooltipContent />
                            </div>
                          </CustomTooltip>
                        )}
                      </div>
                    );
                  },
                )}
              </>
            )}
          </ProductCard>
        </div>
      ))}
      {!isLoading && (
        <div className="my-12 flex w-full flex-col items-center md:my-6">
          <h2 className="text-[32px] font-bold">Got Questions?</h2>
          <div className="font-[500]">
            We've got answers. Visit the{' '}
            <a
              href="https://atobco.zendesk.com/hc/en-us/sections/8173530705947"
              target="_blank"
              rel="noopener noreferrer"
              className="text-atob-green"
            >
              Help Center
            </a>
            .
          </div>
        </div>
      )}
    </div>
  );
}
