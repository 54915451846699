import { useMediaQuery, useTheme } from '@mui/material';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import MobileListView from './MobileListView';
import Table, { TableProps } from './Table';

export type ResponsiveTableProps<T extends GridValidRowModel> = {
  mobileItemRenderer: React.FC<{ row: GridRowModel<T>; onClick?: () => void }>;
} & TableProps<T>;

export default function ResponsiveTable<T extends GridValidRowModel>(
  props: ResponsiveTableProps<T>,
) {
  const theme = useTheme();
  const showMobileView = useMediaQuery(theme.breakpoints.down('md'));

  return <>{showMobileView ? <MobileListView {...props} /> : <Table {...props} />}</>;
}
