import { NotificationTypes } from '@app/@types/notification_setting.types';
import { ErrorNotification, Loading } from '@app/components/layout';
import { notificationsList } from '@app/constants/notifications';
import Switch from '@atob-developers/shared/src/components/Switch';
import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactElement } from 'react';

export interface NotificationsSectionProps {
  isLoading?: boolean;
  error?: Error;
  userHasPhone: boolean;
  allResources: { id: string; name: string }[];
  selectedResources: string[];
  enabledNotifications: NotificationTypes[];
  setSelectedResources: (value: string[]) => void;
  setEnabledNotifications: (value: NotificationTypes[]) => void;
}

export const NotificationsSection = ({
  isLoading,
  error,
  allResources,
  selectedResources,
  enabledNotifications,
  setSelectedResources,
  setEnabledNotifications,
  userHasPhone,
}: NotificationsSectionProps): ReactElement => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    Sentry.captureException(error);
    return (
      <ErrorNotification error="We are having issues loading your notification settings. Please try back later." />
    );
  }

  return (
    <div className="text-gray-700">
      <p className="text-lg font-bold">Notifications</p>
      <div>
        <p className="mt-4 font-medium">Subscribe to notifications for these tags:</p>
        <NotificationResources
          allResources={allResources}
          selectedResources={selectedResources}
          setSelectedResources={setSelectedResources}
        />
      </div>
      <p className="mt-4 font-medium">Select which notifications to receive:</p>
      <div className="divide-y">
        {notificationsList
          .filter((n) => !n.globalOnly)
          .map((n) => (
            <NotificationSetting
              key={n.id}
              userHasPhone={userHasPhone}
              notification={n}
              enabledNotifications={enabledNotifications}
              setEnabledNotifications={setEnabledNotifications}
              disabled={selectedResources.length == 0}
            />
          ))}
      </div>
    </div>
  );
};

const NotificationResources = ({
  allResources,
  selectedResources,
  setSelectedResources,
}: {
  allResources: { id: string; name: string }[];
  selectedResources: string[];
  setSelectedResources: (value: string[]) => void;
}): ReactElement => {
  return (
    <Select
      multiple
      value={selectedResources}
      fullWidth
      className="my-2"
      onChange={(e) => {
        const selections = e.target.value;
        setSelectedResources(typeof selections === 'string' ? selections.split(',') : selections);
      }}
      displayEmpty
      renderValue={(selections) => {
        if (selections.length === 0) {
          return <span className="text-grey17 font-normal">Select tags</span>;
        }
        if (selections.length === 1 && selections[0] === 'all') {
          return 'All tags';
        }
        const names = selections.map((id) => allResources.find((tag) => tag.id == id)?.name);
        return names.join(', ');
      }}
    >
      {allResources.map((option) => {
        const isChecked =
          selectedResources.includes('all') || !!selectedResources.includes(option.id);
        return (
          <MenuItem
            key={option.id}
            value={option.id}
            disabled={
              option.id == 'all'
                ? !selectedResources.includes('all') && selectedResources.length > 0
                : selectedResources.includes('all')
            }
          >
            <Checkbox checked={isChecked} />
            <ListItemText primary={option.name} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

const NotificationSetting = ({
  notification,
  enabledNotifications,
  setEnabledNotifications,
  userHasPhone,
  disabled,
}: {
  notification: (typeof notificationsList)[0];
  enabledNotifications: NotificationTypes[];
  setEnabledNotifications: (value: NotificationTypes[]) => void;
  userHasPhone: boolean;
  disabled: boolean;
}): ReactElement => {
  const isEnabled = !!enabledNotifications.includes(notification.id);
  const isSMSOnly = notification.channels.length === 1 && notification.channels[0] === 'SMS';

  let disabledText = undefined;
  if (disabled) {
    disabledText = 'Please select tags to modify';
  } else if (!userHasPhone && isSMSOnly) {
    disabledText = 'Please add a phone number for this user to enable this notification';
  }

  return (
    <div className="flex py-5">
      <div className="flex-1">
        <p className="font-medium">{`${notification.name} (${notification.channels.join(
          ' & ',
        )})`}</p>
        <p className="text-gray-500">{notification.description}</p>
      </div>

      <Switch
        canChange={!disabled && (userHasPhone || !isSMSOnly)}
        enabled={isEnabled}
        disabledText={disabledText}
        setEnabled={() => {
          if (isEnabled || userHasPhone || !isSMSOnly) {
            setEnabledNotifications(
              isEnabled
                ? enabledNotifications.filter((n) => n !== notification.id)
                : [...enabledNotifications, notification.id],
            );
          }
        }}
      />
    </div>
  );
};
