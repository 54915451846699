import { NewFeatureBanner } from '@app/components/Banner/Designs/NewFeatureBanner';
import { ConfirmCardRequestModal } from '@app/components/Modals/ConfirmCardRequestModal';
import { useCardRequestQuery } from '@app/hooks/query/useCardRequestQuery';
import useCustomer from '@app/hooks/useCustomer';
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';

export default function ConfirmCardRequestBanner(): ReactElement | null {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const customer = useCustomer();
  const { data: cardRequestData } = useCardRequestQuery(customer?.confirm_card_request_id);

  if (!cardRequestData) {
    return <></>;
  }

  const isReplacement = cardRequestData?.data.replacement_reason;
  const isMigration = cardRequestData?.data.replacement_reason === 'migration';
  return (
    <>
      <ConfirmCardRequestModal isOpen={isModalOpen} toggle={toggleModal}>
        {isReplacement &&
          `We need to replace your ${
            (isMigration && `existing Visa cards with Mastercard`) || `cards`
          }.
          There is no additional cost to you for this.`}
      </ConfirmCardRequestModal>
      <NewFeatureBanner
        actionText="Begin"
        bannerKey="confirm-card-request-banner"
        icon={faCreditCardFront}
        nonDismissable={true}
        onClick={toggleModal}
        variant="no_title"
      >
        We need to replace your cards.
      </NewFeatureBanner>
    </>
  );
}
