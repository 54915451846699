import WeeklyDiscountBoostModal from '@app/pages/AccountOverview/WeeklyDiscountBoostModal';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useState } from 'react';
import Switch from '../Switch/Switch';

export default function DiscountBoostToggle() {
  const [showModal, setShowModal] = useState(false);
  const { discountBoostOnly, setDiscountBoostOnly } = useFuelMapContext();

  return (
    <div className="flex h-auto flex-col gap-2 px-6">
      <div className="border-level-2 hover:bg-background flex flex-col gap-2.5 rounded border p-3 shadow-sm">
        <div className="flex items-center gap-2">
          <div className="bg-accent-12 flex h-10 w-10 items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faRocketLaunch} className="text-accent-11 m-3 h-4 w-4" />
          </div>
          <div className="flex flex-col items-start justify-center gap-0.5 truncate">
            <p className="text-primary w-full truncate text-sm font-semibold">
              Show only discount boost stations
            </p>
            <Link
              onClick={() => setShowModal(true)}
              className="text-accent-11 hidden w-full cursor-pointer items-center gap-1 text-xs underline md:flex"
            >
              View details
            </Link>
          </div>
          <div className="flex shrink-0 grow justify-end gap-2">
            <Switch
              checked={discountBoostOnly}
              onChange={() => setDiscountBoostOnly((prev) => !prev)}
            />
          </div>
        </div>
        <Button
          size="small"
          color="secondary"
          className="block w-full md:hidden"
          onClick={() => setShowModal(true)}
        >
          View details
        </Button>
      </div>
      <WeeklyDiscountBoostModal open={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}
