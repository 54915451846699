import { faDotCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, FormControlLabelProps, Radio } from '@mui/material';
import classNames from 'classnames';

interface Props
  extends Pick<
    FormControlLabelProps,
    'value' | 'checked' | 'disabled' | 'className' | 'label' | 'onChange'
  > {
  labelPlacement?: 'start' | 'end';
}

export default function RadioSelector({
  checked,
  label,
  value,
  onChange,
  labelPlacement = 'end',
  disabled,
  className,
}: Props) {
  return (
    <FormControlLabel
      className={classNames(
        'border-level-2 hover:border-level-3 flex w-full justify-between gap-2 rounded border p-3 shadow-sm hover:border-2 hover:p-[11px] md:p-4 md:hover:p-[15px]',
        {
          'border-primary hover:border-primary border-2 p-[11px] hover:border-2 md:p-[15px]':
            checked,
        },
        className,
      )}
      value={value}
      label={<div className="text-primary text-base font-medium">{label}</div>}
      onChange={onChange}
      control={
        <Radio className="m-0" checkedIcon={<FontAwesomeIcon icon={faDotCircle} size="lg" />} />
      }
      labelPlacement={labelPlacement}
      disabled={disabled}
      checked={checked}
    />
  );
}
