import { PaginationMeta } from '@app/@types/api.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { DateAndTimeColumn, DefaultColumn } from '@app/components/TableV2/StandardColumns';
import Table, { ColumnDefinition } from '@app/components/TableV2/Table';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactElement } from 'react';
import { FundingStatus, FundingRequest } from './Funding';

dayjs.extend(utc);
dayjs.extend(timezone);

const StatusPill = ({ status }: { status: FundingStatus }) => {
  if (status === 'created') {
    return <Chip label="Created" color="warning" />;
  }
  if (status === 'processing') {
    return <Chip label="Processing" color="warning" />;
  }
  if (status === 'failed') {
    return <Chip label="Failed" color="error" />;
  }
  if (status === 'succeeded') {
    return <Chip label="Succeeded" color="success" />;
  }
  if (status === 'reverted') {
    return <Chip label="Reverted" color="default" />;
  }
  if (status === 'abandoned') {
    return <Chip label="Abandoned (do not retry)" color="default" />;
  }
  return null;
};

interface FundingRequestsTableProps {
  fundingRequestsPage: FundingRequest[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
}

export default function FundingRequestsTable({
  fundingRequestsPage,
  metadata,
  onPageIndexChange,
}: FundingRequestsTableProps): ReactElement {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'created_at',
      valueGetter: (_, row: FundingRequest) =>
        dayjs(row.created_at).local().format('YYYY-MM-DD HH:mm:ss'),
      ...DateAndTimeColumn('Created'),
    },
    {
      field: 'processed_at',
      valueGetter: (_, row: FundingRequest) =>
        dayjs(row.processed_at).local().format('YYYY-MM-DD HH:mm:ss'),
      ...DateAndTimeColumn('Processed', 150),
    },
    // {
    //   field: 'fulfilled_at',
    //   valueGetter: ({ row }) => dayjs(row.fulfilled_at).local().format('YYYY-MM-DD HH:mm:ss'),
    //   ...DateAndTimeColumn('Fulfilled', 125),
    // },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => <StatusPill status={row.status} />,
    },
    {
      field: 'customer_id',
      headerName: 'AtoB ID',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'customer_name',
      headerName: 'Carrier Name',
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'reference_id',
      headerName: 'Reference ID',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 50,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      ...DefaultColumn,
    },
  ] as ColumnDefinition[];

  return (
    <RoundedCard>
      <Table
        columns={columns}
        data={fundingRequestsPage}
        paginationMeta={metadata}
        onPageChange={onPageIndexChange}
        loading={false}
      />
    </RoundedCard>
  );
}
