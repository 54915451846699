import TosReacceptanceModal from '@app/components/Modals/TosReacceptanceModal';
import AddressInput from '@app/components/elements/AddressInput';
import { confirmCardRequest } from '@app/hooks/query/useCardRequestQuery';
import { CUSTOMER_QUERY_KEY } from '@app/hooks/query/useCustomerQuery';
import useCustomer from '@app/hooks/useCustomer';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { addressSchema } from '@app/utils/validation/schema-validation';
import Modal, {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { PropsWithChildren, ReactElement, useState } from 'react';
import { mutate } from 'swr';
import type { CardRequestParams } from '@app/@types/card_requests.types';
import type { AddressType } from '@app/@types/customer.types';

export interface ConfirmCardRequestModalProps {
  isOpen: boolean;
  toggle: () => void;
}

export const ConfirmCardRequestModal = ({
  isOpen,
  toggle,
  children,
}: PropsWithChildren<ConfirmCardRequestModalProps>): ReactElement | null => {
  const customer = useCustomer();
  const { isMobile } = useWindowWidth();

  const [params, setParams] = useState<CardRequestParams>({
    shipping_address: customer?.card_shipping_address || {
      address1: '',
      city: '',
      state: '',
      zip: '',
    },
  });
  const [isAddressConfirmed, setIsAddressConfirmed] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const onSubmit = () => {
    confirmCardRequest(customer?.confirm_card_request_id || '', params, () => setIsConfirmed(true));
  };

  const onDone = () => {
    mutate(CUSTOMER_QUERY_KEY);
    toggle();
  };

  const confirmAddressView = (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="Confirm Shipping Address" onClose={toggle} />
      <ModalBodyContent>
        {children}
        {` `}
        Please confirm your shipping address to continue.
        <Formik
          initialValues={params.shipping_address || {}}
          validationSchema={addressSchema}
          onSubmit={onSubmit}
        >
          {(props: FormikProps<AddressType>) => (
            <>
              <ModalBodyContent overflowVisible={true}>
                <Form onSubmit={onSubmit} className="md:max-w-md">
                  <div className="mb-2 py-1">
                    <AddressInput
                      addressValues={props.values}
                      setAddressValues={(addressValues) =>
                        setParams({ ...params, shipping_address: addressValues as AddressType })
                      }
                      label=""
                      errors={props.errors || {}}
                      setErrors={() => {}}
                      isMobile={isMobile}
                      touched={props.touched}
                      setTouched={props.setTouched}
                    />
                  </div>
                </Form>
              </ModalBodyContent>
              <ModalFooter>
                <Button className="flex-1" color="secondary" size="small" onClick={toggle}>
                  Cancel
                </Button>
                <Button className="flex-1" size="small" onClick={() => setIsAddressConfirmed(true)}>
                  Confirm
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalBodyContent>
    </Modal>
  );

  const agreeToTermsView = <TosReacceptanceModal open={isOpen} onAccept={onSubmit} />;

  const confirmedView = (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="" onClose={onDone} />
      <div className="flex flex-col items-center">
        <div className="mb-3 mt-8 text-green-600">
          <FontAwesomeIcon className="fa-3x " icon={faCircleCheck} />
        </div>
        <h1 className="mb-1 text-center text-2xl font-bold">Your cards are on their way</h1>
        <p className="text-md mb-2 w-full text-center">
          You will receive an email once the cards have shipped.
        </p>

        <div className="mb-12 flex w-full justify-center">
          <Button className="w-3/4" size="small" onClick={onDone}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );

  return isConfirmed ? confirmedView : isAddressConfirmed ? agreeToTermsView : confirmAddressView;
};
