import Card from '@app/components/Onboarding/Card';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import {
  faBuildings,
  faCalendar,
  faCar,
  faCog,
  faShield,
  faStar,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingControlsAndLimits() {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header title="What controls and limits can I set on my cards?" />
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-3 md:grid md:grid-cols-3">
          <Card
            icon={faCalendar}
            title="Spend limits"
            descriptions={['per transaction, day and/or week']}
          />
          <Card icon={faBuildings} title="Operational hours" />
          <Card icon={faCog} title="Category and merchant restrictions" />
          <Card icon={faShield} title="Driver authentication to unlock cards" />
          <Card icon={faCar} chips={[{ label: 'Premium' }]} title="Vehicle telematics validation" />
        </div>
        <Alert
          severity="success"
          icon={<FontAwesomeIcon icon={faStar} className="h-5 w-5" />}
          title="Best practice"
          content="For cleanest transaction reporting, we recommend purchasing additional fuel (e.g., DEF) on a second transaction"
        />
      </div>
      <FooterActionButtons hideBackButton handleClickNext={goToNextStep} />
    </div>
  );
}
