export default function IssuesDisclaimer() {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-primary text-sm font-semibold leading-6 md:text-base">
        Any issues during this step?
      </p>
      <div className="text-secondary text-xs font-medium leading-5 md:text-sm">
        <span>Contact our support team at AtoB by phone </span>
        <span className="cursor-pointer underline">
          <a href="tel:+18339472862">833-947-2862</a>
        </span>
        <span> or email </span>
        <a href="mailto:support@atob.com" className="cursor-pointer underline">
          support@atob.com
        </a>
      </div>
    </div>
  );
}
