import { faLinkSimpleSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NoDataAvailable() {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
      <div className="border-level-2 bg-background inline-flex items-center justify-center gap-2.5 rounded border px-4 py-3">
        <FontAwesomeIcon icon={faLinkSimpleSlash} className="text-secondary h-5 w-5" />
        <span className="text-primary text-xs font-medium leading-5 md:text-sm">
          No data available
        </span>
      </div>
    </div>
  );
}
