import { ErrorNotification } from '@app/components/layout';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const calculateWarningState = (
  disableConfirm: boolean,
  hasUnsavedEdits: boolean,
  fromDisabledToEnabled: boolean,
): 'disable' | 'discard' | 'exit' | null => {
  if (disableConfirm) {
    if (fromDisabledToEnabled) {
      return null;
    }
    return 'disable';
  }

  if (fromDisabledToEnabled) {
    return 'exit';
  }

  if (hasUnsavedEdits) {
    return 'discard';
  }

  return null;
};

export default function WarningModal({
  disableConfirm,
  hasUnsavedEdits,
  fromDisabledToEnabled,
  isOpen,
  toggle,
  onContinue,
  disableTopUp,
  error,
}: {
  disableConfirm: boolean;
  hasUnsavedEdits: boolean;
  fromDisabledToEnabled: boolean;
  isOpen: boolean;
  toggle: () => void;
  onContinue: () => void;
  disableTopUp: () => void;
  error: string | null;
}): ReactElement {
  const warningState = calculateWarningState(
    disableConfirm,
    hasUnsavedEdits,
    fromDisabledToEnabled,
  );

  if (warningState === 'disable') {
    return <DisableModal error={error} {...{ isOpen, toggle, onContinue: disableTopUp }} />;
  }

  if (warningState === 'discard') {
    return <DiscardModal {...{ isOpen, toggle, onContinue }} />;
  }

  if (warningState === 'exit') {
    return <ExitModal {...{ isOpen, toggle, onContinue }} />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
}

const DisableModal = ({
  error,
  isOpen,
  toggle,
  onContinue,
}: {
  error: string | null;
  isOpen: boolean;
  toggle: () => void;
  onContinue: () => void;
}) => {
  return (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="Disable Auto-Deposit" onClose={toggle} />
      <ModalBodyContent>
        <div className="mt-4">{error && <ErrorNotification error={error} />}</div>
        <div className="my-8 flex w-full text-[16px] font-medium">
          Are you sure you want to disable Auto-Deposit? This means that you'll have to closely
          track current Wallet balance and manually deposit to avoid disruption to your operations.
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-end gap-4">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button onClick={onContinue} color="alert" className="max-w-[130px]">
            Yes, disable
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const DiscardModal = ({
  isOpen,
  toggle,
  onContinue,
}: {
  isOpen: boolean;
  toggle: () => void;
  onContinue: () => void;
}) => {
  return (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="Discard changes" onClose={toggle} />
      <ModalBodyContent>
        <div className="flex w-full text-[16px] font-medium">
          There are changes that have not been saved.
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-end gap-4">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button onClick={onContinue} color="primary" className="max-w-[130px]">
            Yes, cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const ExitModal = ({
  isOpen,
  toggle,
  onContinue,
}: {
  isOpen: boolean;
  toggle: () => void;
  onContinue: () => void;
}) => {
  return (
    <Modal open={isOpen} toggle={toggle}>
      <ModalHeader title="Exit Auto-Deposit Setup" onClose={toggle} />
      <ModalBodyContent>
        <div className="flex w-full text-[16px] font-medium">
          You have not enabled Auto-Deposit. If you wish to enable it, please go back and finish
          setup.
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-end gap-4">
          <Button onClick={toggle} color="secondary">
            Cancel
          </Button>
          <Button onClick={onContinue} color="primary" className="max-w-[130px]">
            Yes, exit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
