import { useState } from 'react';
import OnboardingAddYourDrivers from './OnboardingAddYourDrivers';
import OnboardingAddYourVehicles from './OnboardingAddYourVehicles';
import OnboardingTrackAndManageFleetsSpend from './OnboardingTrackAndManageFleetsSpend';
import { useOnboardingGuide } from './useOnboardingGuide';

enum Step {
  TrackAndManageFleetsSpend = 'track_and_manage_fleets-spend',
  SetVehicles = 'set_vehicles',
  SetDrivers = 'set_drivers',
}

export default function OnboardingManageSpendWrapper() {
  const [step, setStep] = useState(Step.TrackAndManageFleetsSpend);
  const { goToNextStep } = useOnboardingGuide();

  const nextStep = () => {
    if (step === Step.TrackAndManageFleetsSpend) {
      setStep(Step.SetVehicles);
      return;
    }

    if (step === Step.SetVehicles) {
      setStep(Step.SetDrivers);
      return;
    }

    goToNextStep();
  };

  if (step === Step.TrackAndManageFleetsSpend) {
    return <OnboardingTrackAndManageFleetsSpend handleAddManually={nextStep} />;
  }

  if (step === Step.SetVehicles) {
    return <OnboardingAddYourVehicles nextStep={nextStep} />;
  }

  return <OnboardingAddYourDrivers nextStep={nextStep} />;
}
