import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { faBank, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  openPlaidModal: Function;
  isPlaidReady: boolean;
  bankAccount: BankAccountPaymentMethodCombined;
};

export default function ConnectedBankCard({ openPlaidModal, isPlaidReady, bankAccount }: Props) {
  return (
    <div className="bg-level-0 border-level-2 flex w-full flex-col items-center gap-6 rounded-2xl border p-8">
      <div className="bg-accent-23 flex items-center justify-center rounded-full p-3">
        <FontAwesomeIcon icon={faBank} className="h-6 w-6" />
      </div>
      <div className="flex gap-3 font-semibold md:text-lg">
        <h3>{bankAccount.institution_name}</h3>
        <h3>**** {bankAccount.mask}</h3>
      </div>
      <LoadingButton
        onClick={() => openPlaidModal()}
        fullWidth
        size="medium"
        color="secondary"
        loading={!isPlaidReady}
        startIcon={<FontAwesomeIcon icon={faPen} />}
      >
        Edit payment source
      </LoadingButton>
    </div>
  );
}
