import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button, useMediaQuery, useTheme, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const InstantTransfersPopup = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}): JSX.Element | null => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Modal open={isOpen} toggle={() => setIsOpen(false)}>
        <ModalHeader
          title=""
          onClose={() => setIsOpen(false)}
          headerImage={<img src={'/images/instant-transfer-banner.png'} />}
        />
        <ModalBodyContent>
          <h2 className="pb-4 text-lg font-semibold">
            Say hello to instant cash flow. Factor directly into your AtoB Digital Wallet, then move
            your money in minutes with Instant Transfer.
          </h2>
          <p>
            Select "AtoB Digital Wallet" as the funding destination on your next factored invoice to
            enjoy a 0.5% rebate and same day access to your funds. Then use{' '}
            <strong>Instant Transfer</strong> to move your money to your bank account, for a low 1%
            processing fee. Funds are available within minutes.
          </p>
        </ModalBodyContent>
        <ModalFooter>
          <div className="flex flex-row items-center justify-end gap-4">
            {isNotMobile && (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Dismiss
              </Link>
            )}
            <Button
              size="small"
              className="mx-auto w-full sm:mx-0 sm:w-60"
              onClick={() => {
                navigate('/wallet/overview');
              }}
            >
              Done
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InstantTransfersPopup;
