import { createContext } from 'react';

type OnboardingWizardContextType = {
  goToPrevStep: () => void;
  goToNextStep: () => void;
};

export const OnboardingWizardContext = createContext<OnboardingWizardContextType>({
  goToPrevStep: () => {},
  goToNextStep: () => {},
});
