import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import { getCurrencyFromCents } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faCheck, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { TransferSummary } from '../TransferSummary';
import {
  computeEstimatedReceptionTime,
  computeTotalFeeAmount,
  transferTypeMapping,
  estimatedReceptionTimeMapping,
  FeeConfiguration,
  NormalizedDestination,
  PaymentMethodType,
} from '../utils';

export const SuccessModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
  method,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee: FeeConfiguration;
  description: string;
  method: PaymentMethodType;
}) => {
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = getCurrencyFromCents(
    computeTotalFeeAmount({ transferAmountCents, feeData: fee }),
  );
  const estimatedReceptionTime = estimatedReceptionTimeMapping[fee.type];
  const isInstant = estimatedReceptionTime === 'shortly';
  const title = isInstant ? 'Transfer complete' : 'Transfer initiated';
  const secondaryTitle = isInstant
    ? 'The funds were successfully transferred from your AtoB Wallet.'
    : `Transferred funds should arrive ${estimatedReceptionTimeMapping[fee.type]}.`;

  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader
        title={title}
        secondaryTitle={secondaryTitle}
        className="text-center"
        headerImage={
          <FontAwesomeIcon
            icon={isInstant ? faCheck : faClock}
            className={classNames('flex h-5 w-5 justify-self-center rounded-full p-4', {
              'bg-accent-23 text-accent-20': !isInstant,
              'bg-accent-12 text-accent-10': isInstant,
            })}
          />
        }
      />
      <ModalBodyContent>
        <div className="mb-4 mt-2 flex flex-col gap-6">
          <TransferSummary
            transferType={transferTypeMapping[method]}
            arrivalTime={formatDate({
              dateValue: computeEstimatedReceptionTime(fee.type),
              pattern: 'MMMM D, YYYY',
            })}
            recipientName={destination.recipientName}
            feePercentage={fee.percentage}
            feeAmount={feeAmount}
            description={description}
            amount={transferAmountCents}
          />
          <div className="flex gap-3">
            <Button color="primary" fullWidth onClick={onClose} className="p-3">
              Back to Wallet
            </Button>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
