import { LoadingButton } from '@mui/lab';
import { DialogActions, Button } from '@mui/material';
import { ReactElement } from 'react';

interface TelematicsConnectionModalFooterProps {
  clickHandler: () => void;
  isLoading: boolean;
  closeModal: () => void;
}

const TelematicsConnectionModalFooter = ({
  clickHandler,
  isLoading,
  closeModal,
}: TelematicsConnectionModalFooterProps): ReactElement => {
  return (
    <DialogActions>
      <div className="flex justify-between gap-2">
        <LoadingButton size="small" onClick={clickHandler} loading={isLoading}>
          Confirm
        </LoadingButton>
        <Button color="secondary" size="small" onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </DialogActions>
  );
};

export default TelematicsConnectionModalFooter;
