import { Plan } from '@app/@types/subscriptions.types';
import { ErrorNotification } from '@app/components/layout';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';

const ConfirmPlan = ({
  plan,
  paymentMethodDetails,
}: {
  plan: Plan;
  paymentMethodDetails: string;
}): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const confirmPlan = async () => {
    setIsLoading(true);
    try {
      await axios.post('/subscriptions', { product: plan.type });
      window.location.href = `/settings/plans/${plan.type}/confirmed`;
    } catch (e: unknown) {
      setError((e as AxiosError).message);
    }

    setIsLoading(false);
  };

  return (
    <>
      {error && <ErrorNotification error={error} />}
      <div className="flex w-full flex-col items-center">
        <h1 className="mb-5 text-3xl font-bold md:mb-12">Confirm Your New Plan</h1>
        <div className="w-full max-w-[624px] rounded-md border border-gray-300 bg-white sm:w-[60%] md:w-[60%]">
          <div className="px-8 py-6">
            <div className="mb-3">
              <h2 className="text-lg font-bold capitalize text-gray-700">{plan.name}</h2>
              {plan.trialPeriodDays ? (
                <>
                  <div className="text-[18px] font-medium line-through">
                    {plan.amount} {plan.amountCadence} {plan.amountDetails}
                  </div>
                  <div className="text-atob-green text-[18px] font-medium">
                    $0 {plan.amountCadence} {plan.amountDetails}
                  </div>
                </>
              ) : (
                <div className="text-[18px] font-medium">
                  {plan.amount} {plan.amountCadence} {plan.amountDetails}
                </div>
              )}
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 py-5">
              {plan.trialPeriodDays ? (
                <>
                  <div className="text-[16px] font-medium">Your free trial ends on</div>
                  <div className="ml-2 text-right text-sm font-bold">{plan.billingStartDate}</div>
                </>
              ) : (
                <>
                  <div className="text-[16px] font-medium">Your first payment will be on</div>
                  <div className="ml-2 text-right text-sm font-bold">{plan.firstPaymentDate}</div>
                </>
              )}
            </div>
            <div className="flex items-center justify-between border-t border-gray-200 pt-5">
              <div className="text-[16px] font-medium">Payment method</div>
              <div className="text-right text-sm font-bold">{paymentMethodDetails}</div>
            </div>
          </div>
        </div>
        <p className="mt-5 text-sm md:mt-12">
          By confirming, you agree to AtoB's{' '}
          <a
            className="underline"
            href="https://www.atob.com/platform-agreement"
            target="_blank"
            rel="noreferrer"
          >
            Platform agreement
          </a>{' '}
          and{' '}
          <a
            className="underline"
            href="https://www.atob.com/plan-terms"
            target="_blank"
            rel="noreferrer"
          >
            Plan terms
          </a>
        </p>
        <LoadingButton color="primary" loading={isLoading} onClick={() => confirmPlan()}>
          Confirm & Start Plan
        </LoadingButton>
      </div>
    </>
  );
};

export default ConfirmPlan;
