import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { DriverData, DriversCounts } from '@app/@types/driver.types';
import NoDrivers from '@app/assets/images/svg/no-drivers.svg';
import TelematicsNudgeBanner from '@app/components/Banner/TelematicsNudgeBanner';
import FilterGroup from '@app/components/Filters/FilterGroup';
import { TagsFilter } from '@app/components/Filters/ResourceFilters/TagsFilter';
import CreateDriverModal from '@app/components/Modals/DriverModals/CreateDriverModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { ErrorNotification } from '@app/components/layout';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { useUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher, getFetcher } from '@app/utils/data/fetchers';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { isEqual, pick } from 'lodash-es';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import DriverInviteBanner from '../../components/Drivers/DriverInviteBanner';
import { DriverQueryFilters } from './DriverQueryFilters';
import DriversList from './DriversList';
import DriversPageHeader from './DriversPageHeader';

const initialDriversFiltersState = {
  cards_tag_id: [],
};

export const DriversPage = ({
  resourceTagsEnabled,
}: {
  resourceTagsEnabled: boolean;
}): ReactElement => {
  const {
    data: driversCountsData,
    error: driversCountError,
    mutate: driverCountRefresh,
  } = useSWR<DriversCounts>({ url: '/drivers/counts' }, getFetcher);

  const driversCounts = useMemo(
    () =>
      driversCountsData
        ? {
            pendingInvite: driversCountsData.pending_invites,
            hasAny: driversCountsData.total > 0,
            hasArchived: driversCountsData.archived > 0,
            hasOnlyArchived: driversCountsData.archived === driversCountsData.total,
          }
        : null,
    [driversCountsData],
  );
  const [showDriverModal, setShowDriverModal] = useState<boolean>(false);

  const [streamlinedDriverAppOnboarding, blocklistDriverAppOnboarding] = useFeatureFlags(
    'streamlined_driver_app_onboarding',
    'blocklist_driver_app_onboarding',
  );
  const toggleDriverModal = () => setShowDriverModal(!showDriverModal);
  const enableStreamlinedDriverAppOnboarding =
    streamlinedDriverAppOnboarding && !blocklistDriverAppOnboarding;

  const [selectedFilters, setSelectedFilters] = useUrlState<DriverQueryFilters>({
    ...initialDriversFiltersState,
    page: 1,
    like: null,
    sort: null,
  });

  const clearFilters = useCallback(() => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, cards_tag_id: [] }));
  }, [setSelectedFilters]);

  const showClearFilters = useMemo(() => {
    const queryStateFilters = pick(selectedFilters, Object.keys(initialDriversFiltersState));
    return !isEqual(queryStateFilters, initialDriversFiltersState);
  }, [selectedFilters]);

  const { data, error, isLoading, mutate } = useSWR<PaginatedEndpointResponse<DriverData>>(
    {
      url: '/drivers',
      params: {
        per: DEFAULT_PAGE_SIZE,
        all: false,
        archived_at: 'none',
        ...selectedFilters,
      },
    },
    apiGetFetcher,
  );

  if (driversCountError != null || error != null) {
    return (
      <ErrorNotification error="We're having issues loading your driver data. Please try again or if the issue persists, contact support." />
    );
  }

  const onDriverUpdate = () => {
    driverCountRefresh();
    mutate();
  };

  return (
    <>
      <CreateDriverModal
        open={showDriverModal}
        toggle={() => setShowDriverModal(!showDriverModal)}
        enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
        onDriversUpdate={onDriverUpdate}
      />
      <PageContentWrapper
        className="pb-12"
        header={
          <DriversPageHeader
            value={selectedFilters.like ?? ''}
            onSearchChange={(e) =>
              setSelectedFilters((p) => ({ ...p, like: e.target.value, page: 1 }))
            }
            setShowDriverModal={toggleDriverModal}
            selectedFilters={selectedFilters}
          />
        }
      >
        <TelematicsNudgeBanner />
        <DriverInviteBanner
          bannerKey={'driversPageBulkDriverInvited'}
          isDismissible={false}
          onClick={onDriverUpdate}
        />
        {driversCounts && !driversCounts.hasAny ? (
          <EmptyTable
            imgComponent={<img src={NoDrivers} alt="no-drivers" />}
            title="Add your first driver"
            subtitle="(Optional)"
            buttonText="Add a Driver"
            onClick={() => setShowDriverModal(true)}
          />
        ) : (
          <RoundedCard>
            {resourceTagsEnabled && (
              <FilterGroup
                className="border-b-level-2 !mx-0 border-b !py-4 px-6"
                clearFilters={showClearFilters ? clearFilters : undefined}
              >
                <div>
                  <TagsFilter
                    onTagsSelect={(values) =>
                      setSelectedFilters((p) => ({ ...p, cards_tag_id: values, page: 1 }))
                    }
                    selectedTagIds={selectedFilters.cards_tag_id}
                  />
                </div>
              </FilterGroup>
            )}
            <DriversList
              data={data?.data ?? []}
              paginationMeta={data?.meta}
              loading={isLoading}
              enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
              onDriversUpdate={onDriverUpdate}
              onPageIndexChange={(page) => setSelectedFilters((p) => ({ ...p, page }))}
              onSortModelChange={(model: GridSortModel) => {
                const sortByModel = model && model[0];
                setSelectedFilters((p) => ({
                  ...p,
                  sort: sortByModel ? [`${sortByModel.field}:${sortByModel.sort}`] : null,
                  page: 1,
                }));
              }}
            />
          </RoundedCard>
        )}
      </PageContentWrapper>
    </>
  );
};

export default DriversPage;
