import { CustomerData } from '@app/@types/customer.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import Header from '@app/components/Navigation/Header';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { CustomerProduct } from '@app/constants/customerProducts';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import Constants from '@app/utils/constants';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AccountLimitsSettings from './AccountLimitsSettings';
import BusinessCreditReporting from './BusinessCreditReporting';
import NotificationSettings from './NotificationSettings';
import PlansOverview from './Plans/PlansOverview';
import ProfileSettings from './ProfileSettings';
import SettingsPageHeader from './SettingsPageHeader';

export type SettingsTab =
  | 'profile'
  | 'plans'
  | 'account-limits'
  | 'notifications'
  | 'business-credit-reporting';

const BASE_SETTINGS_TABS: { tab: SettingsTab; name: string }[] = [
  {
    tab: 'profile',
    name: 'Company Profile',
  },
  {
    tab: 'notifications',
    name: 'Notifications',
  },
  {
    tab: 'plans',
    name: 'Plans',
  },
  {
    tab: 'account-limits',
    name: 'Account Limits',
  },
  {
    tab: 'business-credit-reporting',
    name: 'Business Credit Reporting',
  },
];

const SettingPage = (props: {
  products: CustomerProduct[];
  customer: CustomerData;
  initialTab?: SettingsTab;
  contentOverride?: ReactElement;
}): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const { planId } = useParams();
  const productPlanToChange = planId ?? null;
  const { showPlans, showCompanyProfile } = useThemeConfiguration();
  const { name } = useChannelPartner();
  const [customer, setCustomer] = useState<CustomerData>(props.customer);

  // Filter out the notification tab when feature is not enabled
  const hasNotificationProducts = props.products.some((p) => p.indexOf('notification') >= 0);
  const hasSettings = props.products.some((p) => p === 'settings');
  const showAtoBPlusBanner =
    props.products.includes('promote_atob_plus') &&
    props.products.includes('settings') &&
    name != Constants.ChannelPartners.EG_AMERICA_CHANNEL_PARTNER_NAME;

  const [hasBusinessCreditReportingEnabled] = useFeatureFlags('business_credit_reporting');

  let settingsTabs = BASE_SETTINGS_TABS;
  // Do not show other settings tabs if settings is not allowed
  settingsTabs = settingsTabs.filter((tab) => tab.tab === 'notifications' || hasSettings);
  // Do not show the notifications tab if no notification products
  settingsTabs = settingsTabs.filter(
    (tab) => tab.tab !== 'notifications' || hasNotificationProducts,
  );
  // Do not show the business credit reporting tab if not enabled
  settingsTabs = settingsTabs.filter(
    (tab) => tab.tab !== 'business-credit-reporting' || hasBusinessCreditReportingEnabled,
  );
  // do not show the plans tab if not enabled
  settingsTabs = settingsTabs.filter((tab) => tab.tab !== 'plans' || showPlans);
  // do not show the company profile tab if not enabled
  settingsTabs = settingsTabs.filter((tab) => tab.tab !== 'profile' || showCompanyProfile);
  const defaultTab = hasSettings ? 'profile' : settingsTabs[0]?.tab;
  const initialTab = props.initialTab ? props.initialTab : defaultTab;
  // If there are no available tabs, navigate to home
  useEffect(() => {
    if (!initialTab) {
      navigate('/');
    }
  }, [initialTab, navigate]);

  const tab =
    location.pathname.split('/')[location.pathname.split('/').indexOf('settings') + 1] ||
    initialTab;

  const getNextTabRoute = (tab: string): string => {
    if (tab === 'plans') {
      return '/settings/plans';
    }
    if (tab === 'profile') {
      return '/settings';
    }
    if (tab === 'notifications') {
      if (hasNotificationProducts) {
        return '/settings/notifications';
      }
      return '/settings';
    }
    if (tab === 'account-limits') {
      return '/settings/account-limits';
    }
    if (tab === 'business-credit-reporting') {
      return '/settings/business-credit-reporting';
    }

    return '';
  };

  let content: ReactElement = <></>;
  if (props.contentOverride) {
    content = props.contentOverride;
  } else if (tab === 'profile') {
    content = (
      <ProfileSettings products={props.products} customer={customer} setCustomer={setCustomer} />
    );
  } else if (tab === 'notifications') {
    content = <NotificationSettings {...props} />;
  } else if (tab === 'plans') {
    content = (
      <PlansOverview
        showAtoBPlusBanner={showAtoBPlusBanner}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        productTypeToChangePlan={productPlanToChange}
      />
    );
  } else if (tab === 'account-limits') {
    content = <AccountLimitsSettings isPrepaid={props.products.includes('prepaid')} />;
  } else if (tab === 'business-credit-reporting') {
    content = <BusinessCreditReporting />;
  }

  return (
    <PageContentWrapper
      header={
        productPlanToChange ? (
          <Header
            title="Settings"
            onBack={() => {
              if (location.pathname.includes('summary')) {
                navigate(`/settings/plans/${productPlanToChange}`);

                return;
              }
              navigate('/settings/plans');
            }}
          />
        ) : (
          <SettingsPageHeader />
        )
      }
      pageTabs={
        settingsTabs.length > 1 && (
          <UnderlinedTabs
            selectedTab={tab}
            handleTabChange={(tab) => {
              navigate(getNextTabRoute(tab));
            }}
            tabs={settingsTabs}
          />
        )
      }
    >
      {content}
    </PageContentWrapper>
  );
};
export default SettingPage;
