import {
  AccountingExportCleanData,
  AccountingExportDataAPIWrapped,
} from '@app/@types/accounting_export.types';
import { shortcutsItems } from '@app/components/Exporting/Transactions/ExportTransactionsDatePicker';
import { ErrorNotification, Loading } from '@app/components/layout';
import CustomerContext from '@app/contexts/customerContext';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { formatDateTz } from '@atob-developers/shared/src/utils/formatters';
import { LoadingButton } from '@mui/lab';
import { DateRangePicker as MUIDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import { ReactElement, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateRangePicker, ExportModal, PropsType } from '../../components/Export/ExportModal';

import type { DateValue } from '@atob-developers/shared/src/utils/formatters/dateFormat';

type FormatTransactionDateOpts = {
  includeTimezone?: boolean;
  includeHoursMinutesSeconds?: boolean;
  format12hour?: boolean;
};

const DEFAULT_TRANSACTION_DATE_OPTS = {
  includeTimezone: false,
  includeHoursMinutesSeconds: true,
  format12hour: false,
};

export function formatTransactionDate(date: DateValue, opts?: FormatTransactionDateOpts): string {
  const { includeTimezone, includeHoursMinutesSeconds, format12hour } = {
    ...DEFAULT_TRANSACTION_DATE_OPTS,
    ...opts,
  };

  // 03/27/2022 2:26:46
  let format = 'MM/DD/YY';

  if (includeHoursMinutesSeconds) {
    format += ' hh:mm:ss';
  }

  if (format12hour) {
    format += ' A';
  }

  if (includeTimezone) {
    format += ' ZZ';
  }

  return formatDateTz({ dateValue: date, pattern: format });
}

class AccountingExportModal extends ExportModal {
  closeModal = (): void => {
    const { closeModal } = this.props;
    closeModal();
  };

  exportCSV = (): void => {
    this.setState({ loading: true, error: null }, () => {
      const { startDate, endDate } = this.state;
      axios
        .get<AccountingExportDataAPIWrapped>('/accounting/transactions_export?', {
          params: {
            'transaction_date[since]': startDate,
            'transaction_date[until]': endDate,
          },
        })
        .then((response) => {
          // Format and export data to CSV
          const startingBalanceHeader = `Starting Balance on ${formatTransactionDate(
            startDate.toJSON(),
            {
              includeHoursMinutesSeconds: false,
            },
          )}`;
          const endingBalanceHeader = `Ending Balance on ${formatTransactionDate(endDate.toJSON(), {
            includeHoursMinutesSeconds: false,
          })}`;
          const cleanData = response.data.transactions.map((transaction) => {
            return {
              Date: formatTransactionDate(transaction.date, {
                includeHoursMinutesSeconds: false,
              }),
              Description: transaction.description,
              Credits: `$${transaction.money_received}`,
              Debits: `$${transaction.money_spent}`,
              [startingBalanceHeader]: '',
              [endingBalanceHeader]: '',
            };
          });

          // Append information for starting and ending balance in first row
          if (cleanData.length > 0) {
            cleanData[0][startingBalanceHeader] = `$${response.data.starting_balance}`;
            cleanData[0][endingBalanceHeader] = `$${response.data.ending_balance}`;
          }

          this.generateCSV(cleanData);
        });
    });
  };

  generateCSV = (data: AccountingExportCleanData): void => {
    this.setState({ loading: true, error: null }, () => {
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        title: 'Accounting Transactions',
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        filename: 'accounting_transactions',
      };
      const csvExporter = new ExportToCsv(options);
      if (!data || data.length === 0) {
        this.setState({
          error: { message: 'No transactions found for these days.' },
          loading: null,
        });
      } else {
        this.setState({ loading: null });
        csvExporter.generateCsv(data);
      }
    });
  };

  render(): ReactElement {
    const { error, loading, custom, timezone } = this.state;

    return (
      <>
        <ModalHeader
          title="Export Accounting Transactions"
          onClose={() => this.props.closeModal()}
        />
        <ModalBodyContent>
          <div className="field">
            <h1 className="mb-2 text-lg font-semibold">Date Range ({timezone})</h1>
            <DateRangePicker handleTimerangeChange={this.handleTimerangeChange} />
            {custom && (
              <MUIDateRangePicker
                slotProps={{
                  actionBar: {
                    actions: [],
                  },
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                value={[this.state.startDate, this.state.endDate]}
                onChange={(newValue) => {
                  // We need to set the start and end of the day to make sure we get all the transactions
                  const [start, end] = newValue;
                  if (start != null && end != null) {
                    this.setState({ startDate: start.startOf('day'), endDate: end.endOf('day') });
                  }
                }}
                disableFuture={true}
              />
            )}
          </div>
          {error && <ErrorNotification error={error.message} />}
          {loading && <Loading />}
        </ModalBodyContent>
        <ModalFooter>
          <div className="flex flex-wrap justify-end gap-3">
            <div className="max-w-40">
              <LoadingButton
                loading={loading || false}
                color="primary"
                onClick={() => this.exportCSV()}
              >
                Export CSV
              </LoadingButton>
            </div>
          </div>
        </ModalFooter>
      </>
    );
  }
}

function AccountingExport(props: PropsType): ReactElement {
  const navigate = useNavigate();
  const { customer } = useContext(CustomerContext);
  const { resourceTagsEnabled } = props;

  return (
    <AccountingExportModal
      {...props}
      navigate={navigate}
      customer={customer}
      resourceTagsEnabled={resourceTagsEnabled}
    />
  );
}

export default AccountingExport;
