import { mapBankAndDebitCardData } from '@app/components/PaymentMethods/PaymentMethodUtils';
import useChannelPartner from '@app/hooks/useChannelPartner';
import usePlaidForOnboarding from '@app/utils/onboarding/usePlaidForOnboarding';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import OnboardingForm from './OnboardingForm';
import OnboardingRightUpsell from './OnboardingRightUpsell';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingBankStep(): ReactElement | null {
  const { isPlaidReady, openPlaidModal, paymentMethods } = usePlaidForOnboarding({
    reloadCustomer: false,
  });
  const { combinedPaymentMethods } = mapBankAndDebitCardData(paymentMethods?.data || []);
  const { partnerName } = useChannelPartner();
  const { goToNextStep } = useOnboardingGuide();
  if ((combinedPaymentMethods.bankAccounts.length ?? 0) == 0) {
    return (
      <OnboardingForm>
        <h1 className="text-2xl font-semibold">Connect a primary bank for bill payments</h1>
        <p className="text-base font-normal">
          Your bill statements will be auto-paid from this bank account
        </p>
        <div className="ufgrayscale:rounded-none flex h-60 flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300">
          <img className="self-center" src="/images/bank_icon.svg" width="100" />
          <Button className="mt-4 w-1/2" onClick={() => openPlaidModal()} disabled={!isPlaidReady}>
            Choose Bank
          </Button>
        </div>
        <p className="text-gray-500">
          {partnerName} uses{' '}
          <a href="https://www.plaid.com" target="_blank" rel="noreferrer" className="underline">
            Plaid
          </a>{' '}
          to securely connect your account
        </p>
      </OnboardingForm>
    );
  }
  const bankAccount = combinedPaymentMethods.bankAccounts[0];
  if (bankAccount) {
    return (
      <OnboardingForm>
        <h1 className="text-2xl font-semibold">You’ve successfully connected a bank!</h1>
        <p className="text-base font-normal">
          Your bill statements will be auto-paid from this bank account
        </p>
        <div className="ufgrayscale:rounded-none flex h-24 flex-row items-center justify-between rounded-lg border border-gray-300 px-6">
          <div className="flex flex-col">
            <div>{bankAccount.institution_name}</div>
            <div>•••• {bankAccount.mask}</div>
          </div>
          <FontAwesomeIcon className="text-green14" icon={faCircleCheck} size="2x" />
        </div>
        <Button className="mt-4 w-full self-end md:w-1/4" color="primary" onClick={goToNextStep}>
          Next
        </Button>
      </OnboardingForm>
    );
  }
  // Should never get here
  return null;
}

export function OnboardingBankUpsell(): ReactElement {
  return (
    <OnboardingRightUpsell>
      <img src="/images/img_bank_connection.svg" width="600" />
    </OnboardingRightUpsell>
  );
}
