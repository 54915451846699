import { Chip, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import { ReactElement, HTMLAttributes } from 'react';

type TransactionStatusBadgeProps = {
  auth_approved?: boolean;
  auth_status?: string;
} & HTMLAttributes<HTMLButtonElement>;

export const TransactionStatusBadge = ({
  auth_approved,
  auth_status,
}: TransactionStatusBadgeProps): ReactElement | null => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (auth_status === 'pending') {
    return <Chip label="Pending" color="default" />;
  }

  if (isXsScreen && auth_approved) {
    // Hide the badge on mobile view, to save space.
    return null;
  }

  if (auth_approved === null && auth_status === null) {
    // Force capture
    return (
      <Tooltip title="A force capture is a direct withdrawal without prior authorization, often used for delayed charges in hotels, car rentals, or when previous authorizations expire.">
        <Chip label="Force capture" color="success" />
      </Tooltip>
    );
  }

  return auth_approved ? (
    <Chip label="Approved" color="success" />
  ) : (
    <Chip label="Declined" color="error" />
  );
};
