import { ChargeEventData } from '@app/@types/charge_events.types';
import useThemeConfiguration from '@app/app/useThemeConfiguration';
import GetPremiumBanner from '@app/components/Banner/GetPremiumBanner';
import TelematicsBanner from '@app/components/Banner/TelematicsBanner';
import CustomerContext from '@app/contexts/customerContext';
import useProduct from '@app/hooks/useProduct';
import { ReactElement, useContext } from 'react';
import ChargeEventDetailsBar from './ChargeEventDetailsBar';
import ChargeEventDriverDetails from './ChargeEventDriverDetails';
import ChargeEventFuelLevelDetails from './ChargeEventFuelLevelDetails';
import ChargeEventTelematics from './ChargeEventTelematics';
import ChargeEventTransactionDetails from './ChargeEventTransactionDetails';
import ChargeEventVehicleDetails from './ChargeEventVehicleDetails';
import HeaderTitle from './HeaderTitle';

const TelematicsMapAndFuelLevels = ({
  hasPremiumProduct,
  telematicsDataAvailable,
  chargeEvent,
}: {
  hasPremiumProduct: boolean;
  telematicsDataAvailable: boolean;
  chargeEvent: ChargeEventData;
}): ReactElement | null => {
  const { supportsLocalStorage, showPremiumUpsell } = useThemeConfiguration();
  if (hasPremiumProduct && telematicsDataAvailable) {
    return <ChargeEventTelematics chargeEvent={chargeEvent} />;
  }

  if (!supportsLocalStorage) {
    return null;
  }

  if (hasPremiumProduct && !telematicsDataAvailable) {
    return <TelematicsBanner />;
  }

  if (!showPremiumUpsell) {
    return null;
  }

  return <GetPremiumBanner />;
};

export const ChargeEventDetails = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const { customer } = useContext(CustomerContext);
  const [hasPremiumProduct] = useProduct('premium');

  const telematicsDataAvailable = customer.telematics_enabled;

  return (
    <div className="flex flex-col gap-4 md:gap-6">
      <div className="md:hidden">
        <HeaderTitle chargeEvent={chargeEvent} />
      </div>
      <ChargeEventDetailsBar chargeEvent={chargeEvent} />
      <TelematicsMapAndFuelLevels
        hasPremiumProduct={hasPremiumProduct}
        chargeEvent={chargeEvent}
        telematicsDataAvailable={telematicsDataAvailable}
      />
      <ChargeEventTransactionDetails chargeEvent={chargeEvent} />
      <ChargeEventVehicleDetails chargeEvent={chargeEvent} />
      <div className="flex h-fit flex-col gap-4 md:gap-6 lg:flex-row">
        <ChargeEventFuelLevelDetails chargeEvent={chargeEvent} />
        <ChargeEventDriverDetails chargeEvent={chargeEvent} />
      </div>
    </div>
  );
};
