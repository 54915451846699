import classNames from 'classnames';
import { ReactElement } from 'react';
export enum DetailStatus {
  Pending,
  NotAvailable,
  Hidden,
}

export enum DetailAlertType {
  Default,
  Alert,
  Warn,
}

export type DetailElementValue = string | DetailStatus | false | ReactElement;

export const DetailSection = ({
  title,
  titleRight,
  children,
}: {
  title: string;
  titleRight?: React.ReactNode;
  children: React.ReactNode;
}): ReactElement => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between border-b border-gray-100">
        <div className="p-4 text-base font-semibold">{title}</div>
        {titleRight}
      </div>
      <div className="flex flex-col divide-y divide-gray-100 pt-2">{children}</div>
    </div>
  );
};

export const DetailElement = ({
  name,
  value,
  alert = DetailAlertType.Default,
  highlighted = false,
  nameClassName,
  valueClassName,
}: {
  name: string;
  value: DetailElementValue;
  alert?: DetailAlertType;
  tooltip?: string | false;
  highlighted?: boolean;
  nameClassName?: string;
  valueClassName?: string;
}): ReactElement => {
  if (value === DetailStatus.Hidden) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  if (value === null || value === false || value === undefined) {
    value = DetailStatus.NotAvailable;
  }

  const valueElement = () => {
    switch (value) {
      case DetailStatus.Pending:
        return <div className="text-gray-500">Pending</div>;
      case DetailStatus.NotAvailable:
        return <div className="text-gray-500">N/A</div>;
      default:
        return value;
    }
  };

  const valuesClass = classNames(
    'flex-1 font-medium flex flex-row gap-2',
    'text-right justify-end md:text-left md:justify-start',
    alert === DetailAlertType.Alert && 'text-red-500',
    alert === DetailAlertType.Warn && 'text-orange-500',
    alert === DetailAlertType.Default && 'text-gray-900',
    valueClassName,
  );

  return (
    <div className="flex flex-row gap-2 px-4 py-1">
      <div className={classNames('flex-1 text-[rgba(0,0,0,0.70)]', nameClassName)}>{name}</div>
      <div className={valuesClass}>
        {highlighted ? (
          <div className="rounded bg-slate-200 px-2 py-1">{valueElement()}</div>
        ) : (
          valueElement()
        )}
      </div>
    </div>
  );
};
