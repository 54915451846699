import { EndpointResponse } from '@app/@types/api.types';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { PromotionResponse } from '@app/components/Prepaid/SetupActivation/SetupPromotionsModal';
import { ACCOUNT_SETUP_FEE_DESCRIPTION } from '@app/constants/unlimited';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import { apiPostFetcher, FetcherKey, getFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { AddFundsModalDataWrapper } from '../Wallet/WalletOverview/TransferFunds/AddFunds';
import IssuesDisclaimer from './IssuesDisclaimer';
import PaidFeeCard from './PaidFeeCard';
import PaySetUpFeeCard from './PaySetUpFeeCard';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingActivateYourAccountPrepaid() {
  const [accountSetupFeeModalActive, setAccountSetupFeeModalActive] = useState(false);

  const customer = useCustomer();
  const { company_name: companyName, company_address: companyAddress } = customer;

  const onboardingEvents = useCustomerOnboardingEvents();
  const feesPaid = useMemo(
    () => onboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE),
    [onboardingEvents],
  );

  const { goToNextStep } = useOnboardingGuide();

  const { data: promotionsResponse, isLoading } = useSWR<EndpointResponse<PromotionResponse[]>>(
    {
      url: '/treasury/account_promotions?account_setup_fee=true',
    },
    getFetcher,
    {
      async onSuccess(data) {
        const promotions = data.data;
        if (promotions.length == 0) {
          goToNextStep();
        }
        if (promotions.length == 1) {
          await selectPromotion({ account_promotion_id: promotions[0].id });
        }
      },
    },
  );

  const { trigger: selectPromotion } = useSWRMutation<
    EndpointResponse<unknown>,
    AxiosError,
    FetcherKey,
    { account_promotion_id: string }
  >(
    {
      url: '/treasury/account_promotions/accept',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          logger.error('Error accepting promotion', e);
        }
      },
    },
  );

  const setupPromotions = useMemo(
    () =>
      promotionsResponse?.data
        ?.map((promotion: PromotionResponse) => ({
          id: promotion.id,
          fee_amount_cents: promotion.attributes.details.fee_amount_cents,
          cost: formatCurrency({
            value: promotion.attributes.details.fee_amount_cents,
            options: { fromCents: true },
          }),
          description: 'One-time account setup fee',
          discount_cents: promotion.attributes.details.discount_boost_per_gallon_cents,
          discount_description: promotion.attributes.details.discount_description,
          premium_included: promotion.attributes.details.premium_included,
        }))
        .sort((a, b) => {
          return a.fee_amount_cents > b.fee_amount_cents ? 1 : -1;
        }) || [],
    [promotionsResponse],
  );

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header title={feesPaid ? 'Your account is now activated' : 'Activate your account'} />
      {feesPaid ? (
        <PaidFeeCard />
      ) : (
        <PaySetUpFeeCard
          setAccountSetupFeeModalActive={setAccountSetupFeeModalActive}
          isLoading={isLoading}
        />
      )}
      <AddFundsModalDataWrapper
        customerCompanyName={companyName}
        customerCompanyAddress={companyAddress}
        onAddFunds={() => (window.location.href = '/')}
        modalActive={accountSetupFeeModalActive}
        setModalActive={setAccountSetupFeeModalActive}
        minimumDepositAmountCents={setupPromotions[0]?.fee_amount_cents}
        initialAmount={setupPromotions[0]?.fee_amount_cents}
        initialDescription={ACCOUNT_SETUP_FEE_DESCRIPTION}
        scenario="account_setup_fee"
      />
      <FooterActionButtons hideBackButton isDisabled={!feesPaid} handleClickNext={goToNextStep} />
      <Divider flexItem />
      <IssuesDisclaimer />
    </div>
  );
}
