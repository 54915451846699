export default function OnboardingCard() {
  return (
    <div className="hidden h-screen flex-1 p-6 md:flex">
      <div className="border-dark-level-1 bg-dark-background flex flex-col justify-center gap-14 rounded-2xl border px-16 py-20">
        <img src="images/onboarding/atob-card.png" alt="Card image" />
        <div className="flex flex-col gap-2">
          <p className="text-dark-primary text-3xl font-semibold leading-10 tracking-[-0.80px]">
            More miles for your money.
          </p>
          <p className="text-dark-secondary font-medium">
            Cut fuel costs with leading discounts, security features, and controls.
          </p>
        </div>
      </div>
    </div>
  );
}
