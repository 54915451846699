import Card from '@app/components/Onboarding/Card';
import Header from '@app/components/Onboarding/Header';
import {
  useConnectTelematicsOAuth,
  useTelematicsProvider,
} from '@app/hooks/query/useConnectTelematics';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { Alert } from '@atob-developers/shared/src/components/Alert';
import { faLinkSimple, faStar, faTruckFront } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnboardingGuide } from './useOnboardingGuide';

type OnboardingTrackAndManageFleetsSpendProps = {
  handleAddManually: () => void;
};

export default function OnboardingTrackAndManageFleetsSpend({
  handleAddManually,
}: OnboardingTrackAndManageFleetsSpendProps) {
  const { goToNextStep } = useOnboardingGuide();
  const { mutate } = useTelematicsProvider();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();
  const { trigger: startTelematicsFlow, isMutating: isGettingOAuthURL } = useConnectTelematicsOAuth(
    undefined,
    async () => {
      await Promise.all([
        mutate(),
        createCustomerOnboardingEvent({
          customer_onboarding_event: {
            name: CustomerOnboardingEventName.CUSTOMER_MANAGE_SPEND_ACKNOWLEDGED,
          },
        }),
      ]);
      goToNextStep();
    },
  );

  const handleSkip = async () => {
    await Promise.all([
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_MANAGE_SPEND_ACKNOWLEDGED,
        },
      }),
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_SKIP_MANAGE_SPEND,
        },
      }),
    ]);
    goToNextStep();
  };

  return (
    <div className="flex flex-col items-start gap-6 px-6 py-8 md:gap-10 md:p-0 md:pb-16">
      <Header
        title="How do I track and manage my fleet's spend?"
        description="With AtoB, you can review each transaction, track spend in aggregate, and export reports for accounting, IFTA filing, and general fleet management."
      />
      <Alert
        severity="success"
        icon={<FontAwesomeIcon icon={faStar} className="h-5 w-5" />}
        title="Best practice, assign your AtoB cards to a driver and/or vehicle"
        content="Having your cards assigned to a driver and/or vehicle will give you the best visibility into your spend and enhance the usefulness of your transaction reports."
      />
      <div className="flex flex-col gap-6 md:flex-row">
        <Card
          icon={faLinkSimple}
          chips={[{ label: 'Recommended' }, { label: '2min' }]}
          title="Automatically sync vehicles and drivers with Telematics"
          descriptions={[
            'Your driver and vehicle information will stay synced as long as your Telematics connection is active.',
            "You won't need to make changes manually, whether it's adding, updating, or deactivating drivers or vehicles.",
          ]}
          buttonText="Sync automatically"
          disabled={isGettingOAuthURL}
          onClick={startTelematicsFlow}
        />
        <Card
          icon={faTruckFront}
          title="Add Drivers and Vehicles manually"
          descriptions={[
            'Add each driver and vehicle in your fleet manually. Be sure to have driver name, phone number, and vehicle VIN on hand.',
            "Note, you'll also need to manually add, update, or deactivate drivers or vehicles whenever there's a change in your fleet.",
          ]}
          buttonText="Add manually"
          buttonColor="secondary"
          onClick={handleAddManually}
        />
      </div>
      <div className="bg-level-3 h-0.5 w-full" />
      <div className="flex flex-col gap-2">
        <p className="text-primary text-sm font-semibold leading-6 md:text-base">
          Don’t want to add Drivers now?
        </p>
        <div className="text-secondary text-xs font-medium leading-5 md:text-sm">
          <span>You can set it up anytime through Drivers Page and </span>
          <button onClick={handleSkip} className="cursor-pointer underline">
            skip this step for now.
          </button>
        </div>
      </div>
    </div>
  );
}
