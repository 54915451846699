import { EndpointResponse } from '@app/@types/api.types';
import {
  useConnectTelematicsOAuth,
  useTelematicsProvider,
} from '@app/hooks/query/useConnectTelematics';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { CUSTOMER_INCLUDES } from '@app/hooks/query/useCustomerQuery';

import useProduct from '@app/hooks/useProduct';
import { FetcherKey, apiPostFetcher } from '@app/utils/data/fetchers';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import { Benefits } from '../Integrations/Telematics/TelematicsIntegrationOptions';
import OnboardingForm from './OnboardingForm';
import OnboardingRightUpsell from './OnboardingRightUpsell';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingTelematicsStep(): ReactElement | null {
  const [noBenefits, setNoBenefits] = useState(false);
  const [noELD, setNoELD] = useState(false);
  const { data: telematicsProviders, mutate } = useTelematicsProvider();
  const { trigger: startTelematicsFlow, isMutating: isGettingOAuthURL } = useConnectTelematicsOAuth(
    undefined,
    mutate,
  );
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent({
    reloadCustomer: false,
  });

  const connectedProvider = telematicsProviders?.data.providers.filter(
    (provider) => provider.connected,
  )[0];

  const { trigger: optOutTelematics } = useSWRMutation<
    EndpointResponse<undefined>,
    AxiosError,
    FetcherKey,
    {
      change_request: {
        change_request_type: 'telematics_opt_out';
        new_value: {
          no_eld?: true;
          no_benefit?: true;
        };
      };
    }
  >(
    {
      url: '/customer/change_request',
      params: {
        include: CUSTOMER_INCLUDES,
      },
    },
    apiPostFetcher,
  );
  const { goToNextStep } = useOnboardingGuide();
  const [isPrepaid] = useProduct('prepaid');
  if (!connectedProvider) {
    return (
      <OnboardingForm>
        <h1 className="text-2xl font-semibold">Connect your telematics</h1>
        <div
          className="ufgrayscale:rounded-none bg-telematics-connect-bg-mobile md:bg-telematics-connect-bg-desktop flex w-full flex-col
        rounded-md border border-gray-300 bg-white bg-cover bg-no-repeat p-2 md:p-6"
        >
          <div className="mb-6 text-xl font-semibold md:text-start">New benefits you'll get:</div>

          <div className="hidden flex-col gap-2 md:flex">
            <div className="flex flex-row gap-2">
              <span className="flex flex-1 flex-row items-center gap-2">
                <FontAwesomeIcon className="text-green14" icon={faCircleCheck} size="2x" />
                <span className="font-medium">
                  {isPrepaid ? 'Pathway to credit*' : 'Higher credit limit*'}
                </span>
              </span>
              <span className="flex flex-1 flex-row items-center gap-2">
                <FontAwesomeIcon className="text-green14" icon={faCircleCheck} size="2x" />
                <span className="font-medium">Smart discount offers</span>
              </span>
            </div>
            <div className="flex flex-row gap-2">
              <span className="flex flex-1 flex-row items-center gap-2">
                <FontAwesomeIcon className="text-green14" icon={faCircleCheck} size="2x" />
                <span className="font-medium">Automatic fleet syncing</span>
              </span>
              <span className="flex flex-1 flex-row items-center gap-2">
                <FontAwesomeIcon className="text-green14" icon={faCircleCheck} size="2x" />
                <span className="font-medium">Enhanced fraud protection</span>
              </span>
            </div>
          </div>
          <div className="block self-center md:hidden">
            <Benefits isPrepaid={isPrepaid} />
          </div>
          <Button
            className="mt-9"
            color="primary"
            size="large"
            disabled={isGettingOAuthURL}
            onClick={startTelematicsFlow}
          >
            Connect Telematics Provider
          </Button>
        </div>
        <div>
          <div className="flex flex-row items-center text-sm">
            <Checkbox
              checked={noBenefits}
              onChange={(e) => setNoBenefits(e.target.checked)}
              size="small"
              disabled={noELD}
            />
            I don’t want these benefits, and will skip connecting
          </div>
          <div className="flex flex-row items-center text-sm">
            <Checkbox
              checked={noELD}
              onChange={(e) => setNoELD(e.target.checked)}
              size="small"
              disabled={noBenefits}
            />
            My fleet is ELD exempt and doesn't use telematics
          </div>
        </div>
        {(noBenefits || noELD) && (
          <Button
            color="primary"
            onClick={async () => {
              await optOutTelematics({
                change_request: {
                  change_request_type: 'telematics_opt_out',
                  new_value: {
                    no_benefit: noBenefits ? noBenefits : undefined,
                    no_eld: noELD ? noELD : undefined,
                  },
                },
              });
              createCustomerOnboardingEvent({
                customer_onboarding_event: {
                  name: CustomerOnboardingEventName.CUSTOMER_SKIP_TELEMATICS,
                },
              });
              goToNextStep();
            }}
          >
            Confirm
          </Button>
        )}
        <p className="mt-auto pb-2 text-xs">* Credit line increase subject to approval</p>
      </OnboardingForm>
    );
  }
  if (connectedProvider) {
    return (
      <OnboardingForm>
        <h1 className="text-2xl font-semibold">
          You’ve successfully connected your telematics provider!
        </h1>
        <p className="text-base font-normal">
          We'll start syncing your drivers and vehicles shortly
        </p>
        <div className="ufgrayscale:rounded-none flex h-24 flex-row items-center justify-between rounded-lg border border-gray-300 px-6">
          <div className="flex flex-row items-center gap-3">
            <img src={connectedProvider.logo} className="h-20" />
            <div className="text-base font-semibold">{connectedProvider.displayName}</div>
          </div>
          <FontAwesomeIcon className="text-green14" icon={faCircleCheck} size="2x" />
        </div>
        <Button
          className="mt-4 w-full self-end md:w-1/4"
          color="primary"
          onClick={() => {
            createCustomerOnboardingEvent({
              customer_onboarding_event: {
                name: CustomerOnboardingEventName.CUSTOMER_TELEMATICS_CONNECTED,
              },
            });
            goToNextStep();
          }}
        >
          Next
        </Button>
      </OnboardingForm>
    );
  }
  // Should never get here
  return null;
}

export function OnboardingTelematicsUpsell(): ReactElement {
  const [isPrepaid] = useProduct('prepaid');
  return (
    <OnboardingRightUpsell>
      <Benefits isPrepaid={isPrepaid} />
    </OnboardingRightUpsell>
  );
}
