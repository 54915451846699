import { EndpointResponse } from '@app/@types/api.types';
import {
  ComputeRouteWithListingsResponse,
  Coordinates,
  FuelGrade,
  FuelListing,
  SortType,
} from '@app/@types/fuel_listings.types';
import {
  getRadiusFromDistance,
  bestFuelListingsWithRadius,
  sortDiscountedPrice,
  sortDistance,
  sortRecommended,
} from '@app/utils/fuel-listings';
import { useMemo } from 'react';

const filterFuelData = (
  fuelData: FuelListing[],
  fuelGradeToFilter: FuelGrade,
  discountOnly: boolean,
  isElectric: boolean,
) => {
  if (isElectric) {
    return fuelData;
  }
  return fuelData.filter((fuelListing) => {
    const prices = fuelListing.attributes.fuel_prices.data;
    const selectedPrice = (prices ?? []).find((price) => {
      return (
        price.attributes.fuel_grade === fuelGradeToFilter &&
        (!discountOnly || (price.attributes.discount_per_gallon_cents ?? 0) > 2)
      );
    });
    return selectedPrice !== undefined;
  });
};

const sortFuelListings = (
  fuelData: FuelListing[],
  method: SortType,
  options: {
    currentLocation?: Coordinates;
    selectedGrade?: FuelGrade | 'TRUCK DIESEL';
  },
) => {
  if (method === 'discountedPrice') {
    return [...fuelData].sort(sortDiscountedPrice(options.selectedGrade ?? 'REGULAR'));
  }

  if (method === 'distance' && options.currentLocation) {
    return [...fuelData].sort(sortDistance(options.currentLocation));
  }

  return sortRecommended(fuelData, options.selectedGrade ?? 'REGULAR');
};

const exxonMobileNameFixer = (item: FuelListing): FuelListing => {
  const location = item.attributes.location;
  if (location.name.toLowerCase() === 'exxonmobil' && item.id.toLowerCase().includes('mobil')) {
    location.name = 'MOBIL';
  } else if (
    location.name.toLowerCase() === 'exxonmobil' &&
    item.id.toLowerCase().includes('exxon')
  ) {
    location.name = 'EXXON';
  }
  return {
    ...item,
    attributes: {
      ...item.attributes,
      location,
    },
  };
};

type useSortFuelListingsProps = {
  fuelRadiusData?: EndpointResponse<FuelListing[]>;
  fuelBoundsData?: EndpointResponse<FuelListing[]>;
  routing: boolean;
  selectedSort: SortType;
  selectedGrade: FuelGrade;
  addressCoords?: Coordinates;
  discountOnly: boolean;
  routeData?: ComputeRouteWithListingsResponse;
  routingDisplayRadius: number | null;
};

export const useSortFuelListings = ({
  fuelRadiusData,
  fuelBoundsData,
  routing,
  selectedSort,
  selectedGrade,
  addressCoords,
  discountOnly,
  routeData,
  routingDisplayRadius,
}: useSortFuelListingsProps) => {
  const [filteredFuelData, routeCoordinates, defaultRouteRadius] = useMemo(() => {
    // Always first sort then filter the data. If the user chooses to change
    // a single operation, we still must update the state in this order.
    const listingData = fuelRadiusData || fuelBoundsData;
    if (!routing && listingData != null) {
      const sortedData = sortFuelListings(
        listingData.data.map(exxonMobileNameFixer),
        selectedSort,
        {
          selectedGrade,
          currentLocation: addressCoords,
        },
      );

      return [
        filterFuelData(sortedData, selectedGrade, discountOnly, selectedGrade === 'ELECTRIC'),
        [],
        null,
      ];
    }

    if (routeData != null && addressCoords != null) {
      const cleanedData = routeData.data.fuel_listings.data.map(exxonMobileNameFixer);
      const routeRadius = getRadiusFromDistance(routeData.data.route_distance_meters);
      const data = bestFuelListingsWithRadius(
        cleanedData,
        routingDisplayRadius || routeRadius,
        selectedGrade ?? 'DIESEL',
        addressCoords,
      );

      return [data, routeData.data.route_coordinates, routeRadius];
    }

    return [[], [], null];
  }, [
    fuelRadiusData,
    fuelBoundsData,
    discountOnly,
    routing,
    routeData,
    addressCoords,
    selectedSort,
    selectedGrade,
    routingDisplayRadius,
  ]);

  return { filteredFuelData, routeCoordinates, defaultRouteRadius };
};
