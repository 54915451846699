import { DeserializedMonthlySubscriptionData } from '@app/@types/payments.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { PageInfo } from '@app/lib/paging/PageInfo';
import { formatDate, formatDateNoYear } from '@app/utils/formatDate';
import EmptyTable from '@atob-developers/shared/src/components/EmptyTable';
import { getCurrencyFromCents } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Chip } from '@mui/material';
import { capitalize } from 'lodash-es';
import { ReactElement } from 'react';

const StatusChip = ({ status }: { status: string }) => {
  switch (status) {
    case 'pending':
    case 'scheduled':
      return <Chip label={capitalize(status)} color="default" />;
    case 'successful':
      return <Chip label={capitalize(status)} color="success" />;
    case 'failed':
    default:
      return <Chip label={capitalize(status)} color="error" />;
  }
};

const MobileDriverRenderer = ({ row }: { row: DeserializedMonthlySubscriptionData }) => (
  <MobileListItem>
    <div className="flex w-full justify-between">
      <div>
        <p className="text-black0 mb-3 text-base font-medium">{formatDate(new Date(row.date))}</p>
        <p className="text-ds-cool-gray-500 text-sm font-medium">
          {row?.payment_method_details?.type}
        </p>
        <p className="text-ds-cool-gray-500 text-sm font-medium">
          {formatDateNoYear(new Date(row.period_start))} -{' '}
          {formatDateNoYear(new Date(row.period_end))}
        </p>
        <p className="text-ds-cool-gray-500 text-sm font-medium">{`${row.active_cards} active cards`}</p>
      </div>
      <div>
        <p className="text-black0 mb-3 text-right text-base font-medium">
          {getCurrencyFromCents(row.total.cents)}
        </p>
        {StatusChip({ status: row.status })}
      </div>
    </div>
  </MobileListItem>
);

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    sortable: false,
    disableReorder: true,
    minWidth: 120,
    valueFormatter: (value: string) => formatDate(new Date(value)),
  },
  {
    field: 'method',
    headerName: 'Method',
    flex: 1,
    sortable: false,
    disableReorder: true,
    minWidth: 120,
    valueGetter: (_, row: DeserializedMonthlySubscriptionData) =>
      row?.payment_method_details?.type === 'debit_card' ? 'Debit card' : 'Bank transfer',
  },
  {
    field: 'account',
    headerName: 'Account',
    flex: 2,
    sortable: false,
    disableReorder: true,
    minWidth: 200,
    valueGetter: (_, row: DeserializedMonthlySubscriptionData) => row?.payment_method_details?.type,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    disableReorder: true,
    minWidth: 100,
    renderCell: ({ value }) => StatusChip({ status: value }),
  },
  {
    field: 'billing_period',
    headerName: 'Billing Period',
    flex: 2,
    sortable: false,
    disableReorder: true,
    minWidth: 200,
    valueGetter: (_, row: DeserializedMonthlySubscriptionData) =>
      `${formatDateNoYear(new Date(row?.period_start))} - ${formatDateNoYear(
        new Date(row?.period_end),
      )}`,
  },
  {
    field: 'active_cards',
    headerName: 'Active Cards',
    flex: 1,
    sortable: false,
    disableReorder: true,
    minWidth: 100,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    sortable: false,
    disableReorder: true,
    minWidth: 100,
    valueGetter: (_, row: DeserializedMonthlySubscriptionData) => row?.total.cents,
    valueFormatter: (value: number) => getCurrencyFromCents(value),
  },
] as ColumnDefinition[];

const MonthlySubscription = ({
  data,
  handlePageChangeClick,
}: {
  data: PageInfo<DeserializedMonthlySubscriptionData>;
  handlePageChangeClick?: (updater: number) => void;
}): ReactElement => {
  const paginationMeta = data.getPaginationInfo?.();
  if (!paginationMeta) return <></>;

  const monthlySubscriptionData = data.getPage(paginationMeta.pagination.current_page);

  if (monthlySubscriptionData.length === 0) {
    return <EmptyTable icon={faArrowRightArrowLeft} title="No payment history" />;
  }

  return (
    <RoundedCard>
      <ResponsiveTable
        columns={columns}
        data={monthlySubscriptionData}
        loading={false}
        onPageChange={handlePageChangeClick}
        paginationMeta={paginationMeta}
        mobileItemRenderer={MobileDriverRenderer}
      />
    </RoundedCard>
  );
};

export default MonthlySubscription;
