import OnboardingStepper from '@app/components/Onboarding/NewOnboardingStepper';
import useCustomer from '@app/hooks/useCustomer';
import useProduct from '@app/hooks/useProduct';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import OnboardingHeader from './OnboardingHeader';
import OnboardingLayout from './OnboardingLayout';
import { NEW_STEP_MAP, NewOnboardingStep, OnboardingStep, STEP_MAP } from './OnboardingStepMap';
import { OnboardingWizardContext } from './OnboardingWizardContext';
import OnboardingWrapper from './OnboardingWrapper';

export default function OnboardingWizard(): ReactElement | null {
  const { interstitials } = useCustomer();
  const [isNewOnboarding] = useProduct('new_onboarding');

  const staticSteps = useRef(
    (interstitials?.steps ?? []).map((step) =>
      isNewOnboarding ? NEW_STEP_MAP[step.name] : STEP_MAP[step.name],
    ),
  );

  const [stepNumber, setStepNumber] = useState(0);

  const goToPrevStep = useCallback(() => {
    setStepNumber((s) => {
      for (let i = s - 1; i >= 0; i--) {
        if (interstitials?.steps[i].status === false) {
          return i;
        }
      }
      return 0;
    });
  }, [interstitials?.steps]);

  const goToNextStep = useCallback(() => {
    setStepNumber((s) => {
      for (let i = s + 1; i < staticSteps.current.length; i++) {
        if (interstitials?.steps[i].status === false) {
          return i;
        }
      }
      return staticSteps.current.length - 1;
    });
  }, [interstitials?.steps]);

  const contextValue = useMemo(
    () => ({
      goToNextStep,
      goToPrevStep,
    }),
    [goToNextStep, goToPrevStep],
  );

  if (interstitials == null) {
    return null;
  }

  if (isNewOnboarding) {
    const step = staticSteps.current[stepNumber] as NewOnboardingStep;
    const RightContent = step.rightContent;
    const hideStepper = step.hideStepper;
    const Header = step.header;

    const stepGroup = interstitials.steps[stepNumber].grouping;

    const activeStep =
      stepGroup === 'activate_your_account' ? 0 : stepGroup === 'manage_your_spend' ? 1 : 2;

    return (
      <OnboardingWizardContext.Provider value={contextValue}>
        <OnboardingWrapper
          header={<Header />}
          leftContent={<OnboardingStepper activeStep={activeStep} />}
          rightContent={<RightContent />}
          hideStepper={hideStepper}
        />
      </OnboardingWizardContext.Provider>
    );
  }

  const step = staticSteps.current[stepNumber] as OnboardingStep;
  const RightContent = step.stepComponent;
  const Upsell = step.upsellComponent;

  return (
    <OnboardingWizardContext.Provider value={contextValue}>
      <OnboardingLayout
        header={
          <OnboardingHeader
            hideProgress={step.hideProgress}
            currentStep={stepNumber}
            totalSteps={
              staticSteps.current.filter((step) => !(step as OnboardingStep).hideProgress).length
            }
          />
        }
        rightContent={<Upsell />}
        leftContent={<RightContent />}
      />
    </OnboardingWizardContext.Provider>
  );
}
