import { ChargeEventData } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import { capitalize } from 'lodash-es';
import DetailsItem from './DetailsItem';
import { formatHelper, formatLocation, relativeTimestamp, vehicleAssociationReason } from './utils';

export default function ChargeEventVehicleDetails({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}) {
  const distance = chargeEvent?.vehicle_location?.distance_from_merchant_miles;
  const speedMph = chargeEvent?.vehicle_location?.speed_mph;
  const distanceDisplay = formatHelper(
    distance ? (distance < 0.1 ? 'Less than 0.1 miles' : `${distance.toFixed(1)} miles`) : null,
  );

  return (
    <RoundedCard>
      <RoundedCardTitle title="Vehicles" />
      <div className="flex flex-col lg:flex-row">
        <RoundedCardCore className="border-level-2 :border-r border-b lg:border-b-0">
          <DetailsItem
            label="Vehicle"
            value={
              chargeEvent.vehicle_name ||
              chargeEvent.vehicle_vin ||
              'No vehicle assigned to this card'
            }
          />
          <DetailsItem
            label="Identification Method"
            value={vehicleAssociationReason(chargeEvent.vehicle_association_reason)}
          />
          <DetailsItem label="VIN (Telematics)" value={formatHelper(chargeEvent.vehicle_vin)} />
          <DetailsItem label="Vehicle Make" value={formatHelper(chargeEvent.vehicle_make)} />
          <DetailsItem label="Vehicle Model" value={formatHelper(chargeEvent.vehicle_model)} />
          <DetailsItem
            label="Vehicle Year"
            value={formatHelper(chargeEvent.vehicle_year, { isCurrency: false })}
          />
        </RoundedCardCore>
        <RoundedCardCore>
          <DetailsItem
            label="Prompted Odometer"
            value={formatHelper(chargeEvent.service_prompts_odometer)}
          />
          <DetailsItem
            label="Telematics Odometer"
            value={formatHelper(chargeEvent.telematics_odometer)}
          />
          <DetailsItem
            label="Location"
            value={formatHelper(
              chargeEvent?.vehicle_location ? formatLocation(chargeEvent?.vehicle_location) : null,
            )}
          />
          <DetailsItem
            label="Timestamp"
            value={formatHelper(
              chargeEvent?.vehicle_location?.captured_at
                ? capitalize(
                    relativeTimestamp(
                      chargeEvent.vehicle_location.captured_at,
                      chargeEvent.authorized_at,
                    ),
                  )
                : null,
            )}
          />
          <DetailsItem label="Distance from Merchant" value={distanceDisplay} />
          <DetailsItem label="Speed" value={formatHelper(speedMph ? `${speedMph} mph` : null)} />
        </RoundedCardCore>
      </div>
    </RoundedCard>
  );
}
