import { OrderCardsButton } from '@app/components/Cards/CardOrder/CardOrderModal';
import DefaultExportButton from '@app/components/Exporting/Default/DefaultExportButton';
import Header from '@app/components/Navigation/Header';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import Button from '@mui/material/Button';
import { ReactElement, ChangeEvent, PropsWithChildren, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CardQueryFilters } from './CardFilterTypes';

interface CardsPageHeaderProps {
  showSelfServeCardOrdering: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  selectedFilters: CardQueryFilters;
}
export default function CardsPageHeader({
  showSelfServeCardOrdering,
  handleChange,
  value,
  selectedFilters,
}: PropsWithChildren<CardsPageHeaderProps>): ReactElement {
  const nav = useNavigate();

  const menuItem = useMemo(
    () => ({
      value: 'Edit Default Spend Policy',
      onClick: () => nav('/policies/default'),
      disabled: () => false,
      order: 4,
    }),
    [nav],
  );
  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <Header
      title="Cards"
      mobileStickyButton={
        showSelfServeCardOrdering && (
          <StickyButtonWrapper>
            <OrderCardsButton fullWidth={true} />
          </StickyButtonWrapper>
        )
      }
      rightContent={
        <div className="flex items-center justify-end gap-2">
          <Link to="/policies/default">
            <Button color="secondary" size="small" className="hidden gap-2 md:block">
              Edit Spend Policy
            </Button>
          </Link>
          <DefaultExportButton
            selectedFilters={selectedFilters}
            exportType="cards"
            buttonText="Export Cards"
          />
          {showSelfServeCardOrdering && <OrderCardsButton />}
        </div>
      }
      searchBar={
        <Searchbar placeholder="Search cards" name="search" onChange={handleChange} value={value} />
      }
    />
  );
}
