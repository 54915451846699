import { ChargeEventData } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import DetailsItem from './DetailsItem';
import { formatHelper } from './utils';

export default function ChargeEventFuelLevelDetails({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}) {
  const { fuel_levels_for_transaction } = chargeEvent;

  const gallonsMissing = parseFloat(
    fuel_levels_for_transaction?.purchase_difference_by_min_max || '0',
  );

  const excessAmount =
    fuel_levels_for_transaction &&
    fuel_levels_for_transaction.overcharge_amount &&
    formatCurrency({
      value: fuel_levels_for_transaction?.overcharge_amount?.cents,
    });

  const gallonsMissingAdjusted = Math.max(gallonsMissing, 0);

  const tankCapacityDisplay = formatHelper(
    fuel_levels_for_transaction?.tank_capacity_gallons
      ? `${parseFloat(fuel_levels_for_transaction?.tank_capacity_gallons).toFixed(1)} gal`
      : null,
    { isCurrency: false },
  );

  const prePurchaseFuelDisplay = formatHelper(
    fuel_levels_for_transaction?.gallons_before_authorization_min
      ? `${parseFloat(fuel_levels_for_transaction?.gallons_before_authorization_min).toFixed(
          1,
        )} gal`
      : null,
    { isCurrency: false },
  );

  const gallonsPurchasedDisplay = formatHelper(
    fuel_levels_for_transaction?.gallons_purchased
      ? `${parseFloat(fuel_levels_for_transaction?.gallons_purchased).toFixed(1)} gal`
      : null,
    { isCurrency: false },
  );

  const expectedFuelLevelDisplay = formatHelper(
    fuel_levels_for_transaction?.expected_fuel_level
      ? `${parseFloat(fuel_levels_for_transaction?.expected_fuel_level).toFixed(1)} gal`
      : null,
    { isCurrency: false },
  );

  const actualFuelLevelDisplay = formatHelper(
    fuel_levels_for_transaction?.gallons_after_authorization_max
      ? `${parseFloat(fuel_levels_for_transaction?.gallons_after_authorization_max).toFixed(1)} gal`
      : null,
    { isCurrency: false },
  );

  const excessGallonsDisplay = formatHelper(
    gallonsMissingAdjusted ? `${gallonsMissingAdjusted.toFixed(1)} gal` : null,
    { isCurrency: false },
  );

  return (
    <RoundedCard className="lg:flex-1">
      <RoundedCardTitle title="Fuel level" />
      <RoundedCardCore>
        <DetailsItem label="Tank Capacity" value={tankCapacityDisplay} />
        <DetailsItem label="Pre-Purchase Fuel Level" value={prePurchaseFuelDisplay} />
        <DetailsItem label="Gallons Purchased" value={gallonsPurchasedDisplay} />
        <DetailsItem label="Expected Fuel Level" value={expectedFuelLevelDisplay} />
        <DetailsItem label="Actual Fuel Level" value={actualFuelLevelDisplay} />
        <DetailsItem label="Excess Gallons" value={excessGallonsDisplay} />
        <DetailsItem
          label="Excess Transaction Amount"
          value={formatHelper(gallonsMissingAdjusted > 0 ? excessAmount : null)}
        />
      </RoundedCardCore>
    </RoundedCard>
  );
}
