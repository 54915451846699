import usePrepaidDetails from '@app/components/Prepaid/use-prepaid-details';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { useUrlState } from '@app/hooks/useUrlState';
import { ReactElement } from 'react';
import PrepaidSteps from './PrepaidSteps';
import usePrepaidOnboardingSteps from './use-onboarding-steps';

export default function PrepaidOnboardingFlow(): ReactElement | null {
  const customer = useCustomer();
  const { treasury, company_name: companyName } = customer;
  const prepaidDetails = usePrepaidDetails({
    treasury,
    isPrepaid: true,
    cardholderStatus: customer.cardholder_status,
    activatedAt: customer.activated_at,
    hasVirtualCards: customer.has_virtual_cards,
  });
  const { steps: onboardingSteps, title: onboardingTitle } = usePrepaidOnboardingSteps({
    prepaidDetails,
    companyName,
    customer,
  });

  const onboardingEvents = useCustomerOnboardingEvents();

  const [{ opt_in_complete }] = useUrlState({
    opt_in_complete: false,
  });

  const [migratingToFlex] = useFeatureFlags('ul_to_flex_promo_v2');

  if (migratingToFlex) {
    return null;
  }

  return (
    <PrepaidSteps
      title={onboardingTitle}
      steps={onboardingSteps}
      completions={{
        opt_in_complete,
        activation_complete: onboardingEvents.has(
          CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE,
        ),
        done: onboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED),
      }}
    />
  );
}
