import Header from '@app/components/Onboarding/Header';
import { STEPPER_LABELS } from '@app/components/Onboarding/NewOnboardingStepper';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function OnboardingWelcomeToAtoB() {
  const { goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex h-full flex-col gap-6 md:justify-center md:gap-10 md:p-0 md:pb-16">
      <Header
        title="Welcome to AtoB"
        description="Here are the steps to configure your AtoB account in just a few minutes."
      />

      <Stepper activeStep={-1} orientation="vertical">
        {STEPPER_LABELS.map(({ label, key }) => (
          <Step key={key}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Button onClick={goToNextStep} className="md:w-fit">
        Get started
      </Button>
    </div>
  );
}
