import { EndpointResponse } from '@app/@types/api.types';
import { SecurityDepositNetNewOfferDetails } from '@app/components/Banner/SecurityDepositNetNewBanner';
import { apiPostFetcher, FetcherKey } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Slider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import useSWRMutation from 'swr/mutation';
import { ModalOptionData, ModalOptions } from '../ModalOptions/ModalOptions';
import { SecurityDepositOfferDetails } from '../SecurityDepositModals/SecurityDepositOfferDetails';
import { SecurityDeposit } from '../SecurityDepositModals/SecurityDepositOfferModal';
import { SecurityDepositPaymentPending } from '../SecurityDepositModals/SecurityDepositPaymentPending';

export const SecurityDepositNetNewModal = ({
  open,
  toggleModal,
  offerDetails,
  refetchOfferDetails,
}: {
  open: boolean;
  toggleModal: () => void;
  offerDetails: SecurityDepositNetNewOfferDetails;
  refetchOfferDetails: () => void;
}): ReactElement => {
  const { addToast } = useToasts();
  const fixedOffer = offerDetails.offer_type === 'fixed';

  // in the fixed offer flow, the CL and deposit amount are fixed. For the variable flow, they are user editable.
  const [desiredCreditLine, setDesiredCreditLine] = useState<number>(
    fixedOffer ? offerDetails?.credit_limit || 1000 : 0,
  );
  const depositAmount = fixedOffer
    ? offerDetails.deposit_amount || 300
    : (desiredCreditLine * (offerDetails.deposit_percentage || 30)) / 100;

  const [selectedOption, setSelectedOption] = useState<'security_deposit'>('security_deposit');
  const [securityDepositModalOpen, setSecurityDepositModalOpen] = useState<boolean>(false);

  const options: ModalOptionData[] = [
    {
      id: 'security_deposit',
      heading: <div className="text-4xl">${desiredCreditLine}</div>,
      subheading: 'Weekly credit line. Billed weekly.',
      description: [
        <div key="deposit_amount">
          Make a refundable deposit of <b>${depositAmount}</b>
        </div>,
        'Credit Line is available in 5 mins if debit card is used to make the deposit.',
        'After 3 months of on time payments, you will automatically be considered for a deposit refund.',
        offerDetails.offer_open ? 'Your cards will be shipped upon accepting the offer below.' : '',
      ].filter((x) => x !== ''),
    },
  ];

  const { trigger: confirmSecurityDepositNetNew, isMutating: isConfirmSecurityDepositNetNew } =
    useSWRMutation<
      EndpointResponse<Record<string, string>>,
      AxiosError,
      FetcherKey,
      Record<string, string>
    >(
      {
        url: '/customer/enroll_security_deposit_net_new',
      },
      apiPostFetcher,
      {
        onError: (e) => {
          if (guardAxiosError(e)) {
            addToast(
              'Something went wrong! Please try again or contact support if the issue persists',
              { appearance: 'error' },
            );
            Sentry.captureException(e);
          }
        },
      },
    );

  const { trigger: upsertSecurityDeposit, isMutating: isUpdatingSecurityDeposit } = useSWRMutation<
    EndpointResponse<SecurityDeposit>,
    AxiosError,
    FetcherKey,
    { credit_limit_cents: number; amount_cents: number }
  >(
    {
      url: '/security_deposits/create_deposit_for_net_new',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          const errorMessage = e.response?.data?.error
            ? `${e.response.data.error}.`
            : 'Something went wrong! Please try again or contact support if the issue persists';
          addToast(errorMessage, { appearance: 'error' });
          Sentry.captureException(e);
        }
      },
      onSuccess: () => {
        refetchOfferDetails();
        setSecurityDepositModalOpen(true);
        toggleModal();
      },
    },
  );

  const handleContinue = async () => {
    try {
      if (offerDetails.offer_open) {
        await confirmSecurityDepositNetNew({});
      }

      if (!fixedOffer) {
        await upsertSecurityDeposit({
          credit_limit_cents: desiredCreditLine * 100,
          amount_cents: depositAmount * 100,
        });
      }

      refetchOfferDetails();
      setSecurityDepositModalOpen(true);
      toggleModal();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  if (
    offerDetails.existing_deposit?.status === 'deposit_pending' ||
    offerDetails.existing_deposit?.status === 'deposit_paid_pending_hold'
  ) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <SecurityDepositPaymentPending
          securityDeposit={offerDetails.existing_deposit}
          onClose={toggleModal}
        />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        open={securityDepositModalOpen}
        toggle={() => {
          setSecurityDepositModalOpen(false);
          toggleModal();
        }}
      >
        <SecurityDepositOfferDetails
          securityDeposit={offerDetails.existing_deposit || null}
          onClose={() => {
            setSecurityDepositModalOpen(false);
            toggleModal();
          }}
        />
      </Modal>
      <Modal open={open} toggle={toggleModal}>
        <ModalHeader title="Activate your Credit Line" onClose={toggleModal} />
        <ModalBodyContent overflowVisible={true}>
          <h2 className="mb-2">
            {offerDetails.offer_open
              ? fixedOffer
                ? 'See your offer details below'
                : 'Select your desired credit limit below'
              : 'Your cards have been shipped. Pay the deposit to activate your credit line.'}
          </h2>
          {!fixedOffer && (
            <Slider
              className="mb-4"
              value={desiredCreditLine}
              onChange={(_, value) => setDesiredCreditLine(value as number)}
              marks={[
                { value: 500, label: '$500' }, // add extra mark at $500
                ...Array.from(
                  { length: (offerDetails.max_credit_limit || 4000) / 1000 + 1 },
                  (_, index) => ({
                    value: index * 1000,
                    label: `$${index * 1000}`,
                  }),
                ),
              ]}
              min={0}
              max={offerDetails.max_credit_limit || 4000}
              step={null}
              valueLabelFormat={(value) => `$${value}`}
            />
          )}
          <div className="mt-4 flex flex-col text-base text-gray-700">
            <ModalOptions
              options={options}
              selectedOptionId={selectedOption}
              setSelectedOptionId={setSelectedOption as Dispatch<SetStateAction<string | null>>}
            />
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <LoadingButton
            loading={isUpdatingSecurityDeposit || isConfirmSecurityDepositNetNew}
            className="flex-1"
            color="primary"
            disabled={desiredCreditLine === 0}
            onClick={handleContinue}
          >
            {offerDetails.offer_open ? 'Accept offer and ship my cards!' : 'Continue'}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
