import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import logger from '@app/utils/datadog-logger';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { PropsWithChildren, ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

export type ProductVariant = 'telematics' | 'premium';

const VARIANT_LABEL: Record<ProductVariant, string> = {
  telematics: 'Telematics',
  premium: 'Premium',
};

export interface ProductBannerProps {
  bannerKey: string;
  variant: ProductVariant;
  onClick?: () => void;
  actionText?: string;
  onLinkClick?: () => void;
  linkText?: string;
  icon?: IconProp;
  nonDismissable?: boolean;
  iconImg?: React.ReactNode;
}

export const ProductBanner = ({
  bannerKey,
  variant = 'telematics',
  onClick,
  actionText,
  onLinkClick,
  linkText,
  nonDismissable = false,
  icon,
  iconImg,
  children,
}: PropsWithChildren<ProductBannerProps>): ReactElement | null => {
  const { dismissed, loading, setDismissed } = useEventDismissed(bannerKey);

  const { hideDismissableBanners } = useThemeConfiguration();
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '50%',
    onChange: (inView) => {
      if (inView) {
        logger.info('banner-shown', { key: bannerKey });
      }
    },
  });

  if (loading || dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="bg-dark-background bg-transaction-details-banner-bg-mobile md:bg-transaction-details-banner-bg-desktop relative w-full rounded-md bg-[length:auto_100%] bg-right bg-no-repeat px-4 py-4 text-sm"
    >
      <div className="flex gap-4">
        <div className="hidden justify-center md:flex">
          {iconImg ? (
            <div className="h-11 w-11">{iconImg}</div>
          ) : icon ? (
            <div className="bg-dark-level-1 flex items-center justify-center self-start rounded-full p-2.5">
              <FontAwesomeIcon icon={icon} className="text-level-0 h-5 w-5" />
            </div>
          ) : (
            <div className="border-level-0 text-level-0 h-6 rounded border px-2 py-1 text-xs uppercase">
              New
            </div>
          )}
        </div>

        <div className="text-level-0 flex grow flex-col justify-between gap-3 xl:flex-row xl:gap-1">
          <div className="flex flex-col justify-center">
            {actionText && onClick && (
              <div className="text-dark-accent-11 text-xs font-semibold uppercase tracking-[0.8px]">
                {VARIANT_LABEL[variant]}
              </div>
            )}
            {children}
          </div>

          <div className="flex gap-2">
            {actionText && onClick && (
              <Button
                size="small"
                className="text-primary bg-level-0 w-1/2 md:w-fit"
                onClick={() => {
                  logger.info('banner-cta-clicked', { key: bannerKey });
                  onClick();
                }}
              >
                {actionText}
              </Button>
            )}

            {linkText && onLinkClick && (
              <Link
                component="button"
                onClick={() => {
                  logger.info('banner-link-clicked', { key: bannerKey });
                  onLinkClick();
                }}
                className="text-level-0 decoration-level-0 hover:text-level-0 bg-opacity-90 hover:bg-opacity-90"
              >
                {linkText}
              </Link>
            )}

            {!nonDismissable && (
              <>
                <Button
                  size="small"
                  className="text-level-0 bg-dark-accent-23 hidden backdrop-blur-md xl:block"
                  onClick={() => {
                    logger.info('banner-dismissed', { key: bannerKey });
                    setDismissed();
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} className="text-level-0 h-5 w-5" />
                </Button>
                <Button
                  size="small"
                  className="text-level-0 bg-dark-accent-23 w-1/2 backdrop-blur-md md:w-fit xl:hidden"
                  onClick={() => {
                    logger.info('banner-dismissed', { key: bannerKey });
                    setDismissed();
                  }}
                >
                  Dismiss
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
