import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import AccountInfo from './AccountInfo';

type AccountInfoModalProps = {
  showAccountInfoModal: boolean;
  modalToggle: () => void;
  routingNumber?: string | null;
  accountNumber?: string | null;
};

const AccountInfoModal = ({
  showAccountInfoModal,
  modalToggle,
  routingNumber,
  accountNumber,
}: AccountInfoModalProps): ReactElement => {
  return (
    <Modal maxWidth="xs" open={showAccountInfoModal} toggle={modalToggle}>
      <div className="flex justify-start">
        <ModalHeader title="" onClose={modalToggle}>
          <div className="bg-accent-23 flex w-fit items-center justify-center rounded-full p-3">
            <FontAwesomeIcon icon={faCircleUser} className="text-primary h-6 w-6 rounded-full" />
          </div>
          <div className="min-h-8 pt-4 text-2xl font-semibold tracking-tight">Account info</div>
        </ModalHeader>
      </div>
      <ModalBodyContent>
        <AccountInfo routingNumber={routingNumber} accountNumber={accountNumber} />
      </ModalBodyContent>
    </Modal>
  );
};

export default AccountInfoModal;
