import { DriverData } from '@app/@types/driver.types';
import DataItem, { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import { phoneFormat } from '@atob-developers/shared/src/utils/formatters';
import { ReactElement } from 'react';
import PreviewDriverSidebarPhoneButton from './PreviewDriverSidebarPhoneButton';

type PreviewDriverSidebarItemType = {
  label: string;
  type: DataItemType;
  path: string;
  required: boolean;
};

interface PreviewDriverSidebarPhoneProps<T> {
  item: PreviewDriverSidebarItemType;
  driver: Record<string, T>;
}

const PreviewDriverSidebarPhone = <T,>({
  item,
  driver,
}: PreviewDriverSidebarPhoneProps<T>): ReactElement => {
  return (
    <>
      <DataItem
        item={{
          ...item,
          type: DataItemType.TEXT,
          content:
            item?.path && driver[item?.path]
              ? phoneFormat({ value: driver[item?.path] as unknown as string })
              : '-',
        }}
      />
      <PreviewDriverSidebarPhoneButton driver={driver as unknown as DriverData} />
    </>
  );
};

export default PreviewDriverSidebarPhone;
