import { Entity } from './api.types';
import { UserData } from './users.types';

export const NotificationResourceList = ['Customer', 'Tag'];
export type NotificationResource = (typeof NotificationResourceList)[number];

export const NotificationChannelsList = ['Email', 'SMS'];
export type NotificationChannels = (typeof NotificationChannelsList)[number];

export type NotificationSettingsData = {
  id: number;
  notification_types: string[];
  resource_id: number;
  resource_type: NotificationResource;
  user?: UserData;
};

export type SingleNotificationSettingsDataResponseType = {
  data: {
    data: Entity<NotificationSettingsData>;
  };
};

export const NotificationTypesList = [
  'Notifications::FuelCard::AuthorizationDeclined',
  'Notifications::FuelCard::SuspiciousActivity',
  'Notifications::FuelCard::BillingStatement',
  'Notifications::FuelCard::SuspiciousActivitiesSummary',
  'Notifications::Discount::WeeklyLookback',
] as const;

export const NotificationTypeNamesList = [
  'DECLINED_AUTHORIZATION',
  'SUSPICIOUS_ACTIVITY',
  'BILLING_STATEMENT',
  'SUSPICIOUS_ACTIVITIES_SUMMARY',
  'DISCOUNT_WEEKLY_LOOKBACK',
] as const;

export type NotificationTypes = (typeof NotificationTypesList)[number];

export const NotifiableRolesList = ['owner', 'card_admin', 'card_manager', 'bookkeeper', 'driver'];
export type NotifiableRoles = (typeof NotifiableRolesList)[number];

export type NotificationData = {
  id: number;
  name: string;
  notifiable_roles: NotifiableRoles[];
  notification_type: NotificationTypes;
  possible_notification_channels: NotificationChannels[];
  trigger: string;
};

export type UserNotificationSettingsData = {
  id: number;
  notification_channels: NotificationChannels[];
  resource_id: number;
  resource_type: NotificationResource;
  resource_name: string;
  enabled: boolean;
  user: UserData;
  notification: NotificationData;
};

export type RoleNotificationSettingsData = {
  id: number;
  resource_id: number;
  resource_type: NotificationResource;
  resource_name: string;
  customer_role: NotifiableRoles;
  notification: NotificationData;
};
