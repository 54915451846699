import { EndpointResponse } from '@app/@types/api.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { apiPostFetcher, FetcherKey, getFetcher } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Slider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { ModalOptionData, ModalOptions } from '../ModalOptions/ModalOptions';
import { SecurityDepositOfferDetails } from '../SecurityDepositModals/SecurityDepositOfferDetails';
import { SecurityDeposit } from '../SecurityDepositModals/SecurityDepositOfferModal';
import { SecurityDepositPaymentPending } from '../SecurityDepositModals/SecurityDepositPaymentPending';
import { ULToCreditRequestModal } from './ULToCreditRequestModal';
import { ULToCreditRequestReviewPending } from './ULToCreditRequestReviewPending';

export type ULToCreditRequestOption = 'security_deposit' | 'manual_review';

export type ULToCreditRequestConstraints = {
  should_request_bank_info: boolean;
  max_credit_limit: number;
  request_options: ('security_deposit' | 'manual_review')[];
  security_deposit_ratio: number;
  pending_deposit: EndpointResponse<{ attributes: SecurityDeposit } | null>;
  pending_review: boolean;
};

export const ULToCreditRequest = ({
  open,
  toggleModal,
}: {
  open: boolean;
  toggleModal: () => void;
}): ReactElement => {
  const { addToast } = useToasts();
  const {
    data: requestConstraints,
    mutate: mutateRequestConstraints,
    isLoading,
  } = useSWR<ULToCreditRequestConstraints>(
    { url: '/unlimited_to_credit_requests/new' },
    getFetcher,
  );

  const [desiredCreditLine, setDesiredCreditLine] = useState<number>(0);
  const depositAmount = desiredCreditLine * (requestConstraints?.security_deposit_ratio ?? 0.5);

  const [selectedOption, setSelectedOption] = useState<ULToCreditRequestOption>('security_deposit');

  const [manualReviewModalOpen, setManualReviewModalOpen] = useState<boolean>(false);
  const [securityDepositModalOpen, setSecurityDepositModalOpen] = useState<boolean>(false);

  const [securityDeposit, setSecurityDeposit] = useState<SecurityDeposit | null>(null);

  const options: ModalOptionData[] = [
    {
      id: 'security_deposit',
      heading: 'Guaranteed Credit Line',
      subheading: 'Instant with Security Deposit',
      description: [
        <div key="deposit_amount">Make a deposit of ${depositAmount}</div>,
        'Credit Line is available in 5 mins if debit card is used to make the deposit',
        'After 3 months, you will automatically be considered for a deposit refund (subject to approval)',
        'You can withdraw your deposit after one month, if you want to move back to prepaid',
      ],
    },
    {
      id: 'manual_review',
      heading: 'Credit Line Evaluation',
      subheading: '2-3 days, no deposit needed',
      description: [
        'No security deposit required, but approval is not guaranteed',
        'If approved, credit line update may take up to 2 business days',
      ],
    },
  ];

  const { trigger: upsertSecurityDeposit, isMutating: isUpdatingSecurityDeposit } = useSWRMutation<
    EndpointResponse<SecurityDeposit>,
    AxiosError,
    FetcherKey,
    { credit_limit_cents: number; amount_cents: number }
  >(
    {
      url: '/security_deposits/credit_limit_request',
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (guardAxiosError(e)) {
          const errorMessage = e.response?.data?.error
            ? `${e.response.data.error}.`
            : 'Something went wrong! Please try again or contact support if the issue persists';
          addToast(errorMessage, { appearance: 'error' });
          Sentry.captureException(e);
        }
      },
      onSuccess: (data) => {
        setSecurityDeposit(data.data);
        setSecurityDepositModalOpen(true);
        toggleModal();
      },
    },
  );

  const handleContinue = () => {
    if (selectedOption === 'manual_review') {
      setManualReviewModalOpen(true);
      toggleModal();
    } else {
      upsertSecurityDeposit({
        credit_limit_cents: desiredCreditLine * 100,
        amount_cents: depositAmount * 100,
      });
    }
  };

  if (isLoading || !requestConstraints) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <ModalBodyContent overflowVisible={true}>
          <div className="md:mt-80">
            <SpinnerBoundary />
          </div>
        </ModalBodyContent>
      </Modal>
    );
  }

  if (requestConstraints?.pending_deposit?.data) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <SecurityDepositPaymentPending
          securityDeposit={requestConstraints.pending_deposit.data.attributes}
          onClose={toggleModal}
        />
      </Modal>
    );
  }

  if (requestConstraints?.pending_review) {
    return (
      <Modal open={open} toggle={toggleModal}>
        <ULToCreditRequestReviewPending
          onClose={() => {
            toggleModal();
          }}
        />
      </Modal>
    );
  }

  return (
    <>
      <ULToCreditRequestModal
        open={manualReviewModalOpen}
        requestConstraints={requestConstraints}
        onClose={() => {
          setManualReviewModalOpen(false);
          toggleModal();
          mutateRequestConstraints();
        }}
      />
      <Modal
        open={securityDepositModalOpen}
        toggle={() => {
          setSecurityDepositModalOpen(false);
          toggleModal();
        }}
      >
        <SecurityDepositOfferDetails
          securityDeposit={securityDeposit}
          onClose={() => {
            setSecurityDepositModalOpen(false);
            toggleModal();
          }}
        />
      </Modal>
      <Modal open={open} toggle={toggleModal}>
        <ModalHeader title="Credit Line Request" onClose={toggleModal} />
        <ModalBodyContent overflowVisible={true}>
          <h2>Select your desired credit limit below</h2>
          <Slider
            className="mb-8"
            value={desiredCreditLine}
            onChange={(_, value) => setDesiredCreditLine(value as number)}
            marks={[
              // marks for every $1000 up to the max credit limit
              ...Array.from(
                { length: Math.floor((requestConstraints?.max_credit_limit ?? 4000) / 1000) + 1 },
                (_, index) => ({
                  value: index * 1000,
                  label: `$${index * 1000}`,
                }),
              ),
              { value: 500, label: '$500' }, // add extra mark at $500
            ]}
            min={0}
            max={requestConstraints?.max_credit_limit ?? 4000}
            step={null}
            valueLabelFormat={(value) => `$${value}`}
          />
          <div className="mb-2 w-full text-center md:mb-10">
            Desired Credit Line: <span className="font-semibold">${desiredCreditLine}</span>
          </div>
          <div className="flex flex-col text-base text-gray-700">
            <ModalOptions
              options={
                // only show options that are available as defined in requestConstraints
                options.filter(
                  (option) =>
                    requestConstraints?.request_options.includes(
                      option.id as ULToCreditRequestOption,
                    ),
                )
              }
              selectedOptionId={selectedOption}
              setSelectedOptionId={setSelectedOption as Dispatch<SetStateAction<string | null>>}
            />
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <LoadingButton
            loading={isUpdatingSecurityDeposit}
            className="flex-1"
            color="primary"
            disabled={selectedOption === null || desiredCreditLine === 0}
            onClick={handleContinue}
          >
            {selectedOption === null ? 'Select an Option' : 'Continue'}
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
