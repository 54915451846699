import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import { faLinkSimple } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductBanner } from './Designs/ProductBanner';

export default function TelematicsBanner(): ReactElement | null {
  const navigate = useNavigate();
  const { data, isLoading } = useTelematicsProvider();
  if (isLoading) {
    return null;
  }

  const providers = data?.data.providers;
  const connectedProvider = providers?.filter((provider) => provider.connected);
  if (connectedProvider && connectedProvider.length > 0) {
    return null;
  }

  return (
    <ProductBanner
      actionText="Connect Telematics"
      bannerKey="integrations-transactions-telematics"
      variant="telematics"
      onClick={() => {
        navigate('/integrations');
      }}
      icon={faLinkSimple}
    >
      Connect your telematics provider and automate industry-leading checks against fuel theft.
    </ProductBanner>
  );
}
