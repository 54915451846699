import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';

export default function ShimmerEffect() {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      className="bg-gradient-beam pointer-events-none absolute top-0 h-full w-16"
      initial={{ left: -64 }}
      animate={inView ? { left: '100%' } : {}}
      transition={{
        delay: 2,
        repeatType: 'loop',
        repeat: 2,
        repeatDelay: 1.25,
        duration: 0.75,
        ease: 'easeInOut',
      }}
    />
  );
}
