import { FlattenedPayrollData } from '@app/@types/payroll.types';
import CustomerContext from '@app/contexts/customerContext';
import Constants from '@app/utils/constants';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { Button } from '@mui/material';
import { ReactElement, useContext } from 'react';

const NotAvailable = () => {
  return (
    <Button size="small" disabled>
      Payroll Not Available
    </Button>
  );
};

export default function DriverSidebarButton({
  driver,
  amountToPayDriver,
  onClick = () => {},
  noPaymentSource,
}: {
  driver: FlattenedPayrollData;
  amountToPayDriver: number;
  onClick: (driver: FlattenedPayrollData) => void;
  noPaymentSource: boolean;
  loading?: boolean;
}): ReactElement {
  const { customer } = useContext(CustomerContext);
  const isSuspended = !!customer.suspended_at;
  const { status, tax_classification } = driver;

  const onButtonClick = () => {
    onClick(driver);
  };
  const isW2Employee = tax_classification === Constants.PayrollEnums.W2;

  if (isW2Employee) {
    return <NotAvailable />;
  }

  if (status === Constants.PayrollEnums.UPDATE_REQUIRED) {
    return (
      <Button size="small" disabled>
        Driver update required
      </Button>
    );
  }

  if (status === Constants.PayrollEnums.INVITED) {
    return (
      <Button size="small" onClick={onButtonClick}>
        Resend invite to {driver.first_name}
      </Button>
    );
  }

  if (status === Constants.PayrollEnums.NOT_ENROLLED) {
    return (
      <Button size="small" onClick={onButtonClick}>
        Invite {driver.name}
      </Button>
    );
  }

  const currencyAmountToPayDriverInDollars = convertCentsToDollars({
    value: amountToPayDriver,
  });

  const amountToPayDriverInDollars = currencyAmountToPayDriverInDollars.format();

  if (status !== Constants.PayrollEnums.ENROLLED) {
    return <NotAvailable />;
  }

  if (noPaymentSource) {
    return (
      <Button size="small" disabled>
        Please connect a payment method to continue
      </Button>
    );
  }

  if (isSuspended) {
    return (
      <Button size="small" disabled>
        Your account has been suspended
      </Button>
    );
  }

  return (
    <Button size="small" disabled={amountToPayDriver === 0} onClick={onButtonClick}>
      Pay {amountToPayDriver > 0 && amountToPayDriverInDollars}
    </Button>
  );
}
