import { Plan } from '@app/@types/subscriptions.types';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';

export default function PlanOption({
  plan,
  onSelect,
}: {
  plan: Plan;
  onSelect: (plan: Plan) => void;
}): ReactElement {
  return (
    <div
      className={classNames(
        'mb-4 flex w-[90vw] w-full cursor-pointer justify-between rounded-md border-2 bg-white px-8 py-4 sm:w-[370px] md:w-[500px] ',
        plan.selected ? 'border-black' : 'border-transparent',
      )}
      onClick={() => onSelect(plan)}
    >
      <div className="flex flex-col justify-between">
        <h3 className="text-2xl font-bold capitalize">{plan.name}</h3>
        <div className="text-sm font-medium text-gray-500 md:w-[300px] md:text-lg">
          {plan.name === 'premium' && (
            <div className="text-secondary pb-3 text-sm font-semibold">
              All Basic Card benefits plus:
            </div>
          )}
          {plan.valueProps.map((prop) => (
            <div
              key={prop}
              className="text-secondary flex flex-row items-center gap-2 pb-3 text-sm font-normal"
            >
              <FontAwesomeIcon icon={faCheck} />
              {prop}
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-[100px] flex-col items-center justify-center border-l border-gray-200 pl-4">
        <div className="text-2xl font-bold">{plan.amount}</div>
        {plan.amountCadence && (
          <span className="w-[80px] text-center text-sm font-medium text-gray-500">
            {plan.amountCadence}
          </span>
        )}
        {plan.amountDetails && (
          <div className="w-[80px] text-center text-sm font-medium text-gray-500">
            {plan.amountDetails}
          </div>
        )}
      </div>
    </div>
  );
}
